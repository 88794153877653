import { useReactiveVar } from '@apollo/client'

import {
  ContentAccessLevel,
  useGetPublicSnapshotVersionDataQuery,
  useGetSnapshotVersionDataQuery,
} from '@/generated/graphql'
import { contentPrivacyStateVar } from '@/shared/apollo/apolloLocalState'

export const useSnapshotVersion = (id: string | undefined, skip?: boolean, fromCache?: boolean) => {
  const { accessLevel } = useReactiveVar(contentPrivacyStateVar)
  const { data, error, loading } = useGetSnapshotVersionDataQuery({
    fetchPolicy: fromCache ? 'cache-only' : 'cache-first',
    skip: skip || typeof id === 'undefined' || accessLevel !== ContentAccessLevel.Private,
    variables: { id: id as string },
  })
  const {
    data: publicData,
    error: publicError,
    loading: publicLoading,
  } = useGetPublicSnapshotVersionDataQuery({
    fetchPolicy: fromCache ? 'cache-only' : 'cache-first',
    skip: skip || typeof id === 'undefined' || accessLevel !== ContentAccessLevel.Public,
    variables: { id: id as string },
  })

  return {
    error: error || publicError,
    loading: !accessLevel || loading || publicLoading,
    snapshotVersion:
      typeof id !== 'undefined'
        ? data?.snapshotVersion || publicData?.publicSnapshotVersion?.snapshotVersion
        : null,
  }
}
export type SnapshotVersionHookReturnType = ReturnType<typeof useSnapshotVersion>

export default useSnapshotVersion
