import * as React from 'react'
import { Link } from 'react-router-dom'

import { Document, SnapshotFieldsFragment } from '@/generated/graphql'
import { Button } from '@/shared/components'
import EditableTextInput from '@/shared/components/EditableTextInput'

export const HeaderBreadcrumbs = ({
  content,
  name,
  onChangeName,
  onBlurName,
}: {
  content: SnapshotFieldsFragment | Document
  name: string
  onChangeName: React.ChangeEventHandler<HTMLInputElement>
  onBlurName: React.FocusEventHandler<HTMLInputElement>
}): JSX.Element => (
  <div className="flex flex-row items-center">
    <div className="text-copy-secondary z-10 flex flex-row items-center gap-1 text-base font-semibold">
      <Link
        className="text-copy-secondary hover:text-interactive-primary active:text-interactive-primary"
        to={`/${content.organization.slug}${content.team ? `/team/${content.team.id}` : ''}`}
      >
        <Button variant={'ghost'} className="text-copy-secondary">
          {content.team ? 'Team' : 'Personal'}
        </Button>
      </Link>
      {content.folder && (
        <>
          <span className="select-none">/</span>
          <Link
            className="text-copy-secondary hover:text-interactive-primary active:text-interactive-primary"
            to={`/${content.organization.slug}${
              content.team ? `/team/${content.team.id}` : ''
            }/folders/${content.folder.id}`}
          >
            <Button variant={'ghost'} className="text-copy-secondary">
              {content.folder.name}
            </Button>
          </Link>
        </>
      )}
      <span className="select-none">/</span>
    </div>
    <div className="overflow-hidden">
      <EditableTextInput
        value={name}
        onChange={onChangeName}
        onBlur={onBlurName}
        className="bg-transparent text-base font-semibold"
        name="header-title-edit"
        maxLength={100}
      />
    </div>
  </div>
)
