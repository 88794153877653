import { useEffect } from 'react'

import { useCreatePaymentPortalLinkMutation } from '@/generated/graphql'
import { FullPageLoader } from '@/web/components/FullPageLoader'
import { useViewer } from '@/web/hooks'

export function BillingPortal(): JSX.Element {
  const { loading, viewer } = useViewer()

  const [createPaymentPortalLink] = useCreatePaymentPortalLinkMutation()

  useEffect(() => {
    if (loading) {
      return
    }

    if (!viewer || !viewer.currentOrganization) {
      window.location.href = '/'
      return
    }

    if (!viewer.currentOrganization.isStripeCustomer) {
      window.location.href = `/start-trial`
      return
    }

    void createPaymentPortalLink()
      .then((v) => {
        if (v.data?.createPaymentPortalLink) {
          window.location.href = v.data.createPaymentPortalLink
          return
        }

        window.location.href = `/${viewer.currentOrganization?.slug as string}/settings/billing`
        return
      })
      .catch(() => {
        window.location.href = `/start-trial`
      })
  }, [createPaymentPortalLink, loading, viewer])

  return (
    <div className="m-auto">
      <FullPageLoader loading />
    </div>
  )
}
