import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useInvitationsQuery } from '@/generated/graphql'
import { PlusLogo } from '@/shared/components'
import { OrgInvitation } from '@/web/components'
import { FullPageLoader } from '@/web/components/FullPageLoader'
import { useViewer } from '@/web/hooks'

const InvitationList = (): JSX.Element => {
  const history = useHistory()
  const { loading, data } = useInvitationsQuery()
  const { organizationCount } = useViewer()

  useEffect(() => {
    if (!loading && data?.viewer.invitations.length === 0) {
      history.replace('/')
    }
  }, [data?.viewer.invitations, history, loading])

  if (!data?.viewer.invitations || loading) {
    return (
      <div className="m-auto">
        <FullPageLoader loading={true} />
      </div>
    )
  }

  return (
    <>
      <div className="bg-base-gray960 flex h-screen flex-col items-center p-3 sm:justify-center">
        <div className="border-divider-light-gray bg-base-white shadow-soft flex w-full max-w-[500px] flex-col items-center justify-center rounded border sm:w-[500px]">
          <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-1 border-b p-6">
            <PlusLogo width="48" height="48" />
            <h1 className="m-auto text-center text-xl font-semibold">Invitations to collaborate</h1>
          </div>
          <div className="flex w-full flex-col items-center gap-y-3 p-6">
            {data.viewer.invitations.map((invitation) => (
              <OrgInvitation
                invitationId={invitation.id}
                invitationType={invitation.type}
                invitedBy={invitation.createdBy?.email ?? 'user@example.com'}
                key={invitation.id}
                orgLogo={invitation.organization.image}
                orgMemberCount={invitation.organization.users.totalCount}
                orgName={invitation.organization.name}
              />
            ))}
            <div className="flex flex-col gap-3 text-center">
              <a
                className="text-interactive-primary block cursor-pointer text-base hover:underline"
                onClick={() => history.push('/onboarding')}
              >
                Create a new organization
              </a>
              {organizationCount > 0 && (
                <Link
                  to="/"
                  className="text-interactive-primary cursor-pointer text-base hover:underline"
                >
                  Back to Plus
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvitationList
