import { useReactiveVar } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  ContentAccessLevel,
  useInOrgDocumentContentAccessQuery,
  useInOrgSnapshotContentAccessQuery,
} from '@/generated/graphql'
import { contentPrivacyStateVar } from '@/shared/apollo/apolloLocalState'
import { useAuth } from '@/shared/auth/useAuth'
import useViewer from '@/shared/hooks/useViewer'
import { FullPageLoader } from '@/web/components/FullPageLoader'
import NotFound from '@/web/routes/NotFound'

import AnonContentPrivacyContainer from './AnonContentPrivacyContainer'

const ContentPrivacyContainer = (props: { children: JSX.Element }): JSX.Element => {
  const { isLoggedIn } = useAuth()
  const { loading: viewerLoading } = useViewer()

  const { hasCheckedInOrgAccess, isInOrgAccessAllowed, loading } =
    useInOrgAccessQuery(viewerLoading)

  const { documentId, snapshotId } = useParams<{
    documentId?: string
    snapshotId?: string
  }>()

  const isAnonAccess = !isLoggedIn()

  // Set content access level if in-org access it allowed
  const contentPrivacyState = useReactiveVar(contentPrivacyStateVar)
  useEffect(() => {
    if (isInOrgAccessAllowed && !contentPrivacyState.accessLevel) {
      contentPrivacyStateVar({
        ...contentPrivacyState,
        accessLevel: ContentAccessLevel.Private,
      })
    }
  }, [isInOrgAccessAllowed, contentPrivacyState])

  if (loading && !hasCheckedInOrgAccess) {
    return (
      <div className="m-auto">
        <FullPageLoader loading={true} />
      </div>
    )
  }

  if (!documentId && !snapshotId) {
    return <NotFound />
  }

  // We need to send the user to the public API if they are:
  // 1. Not logged in
  // 2. Logged in but failed the internal API query
  if (isAnonAccess || (!isInOrgAccessAllowed && hasCheckedInOrgAccess)) {
    return <AnonContentPrivacyContainer>{props.children}</AnonContentPrivacyContainer>
  }

  return props.children
}

export default ContentPrivacyContainer

const useInOrgAccessQuery = (viewerLoading: boolean) => {
  const [hasCheckedAccess, setHasCheckedAccess] = useState(false)
  const { documentId, snapshotId } = useParams<{
    documentId?: string
    snapshotId?: string
  }>()

  const setCheckedAccessTrue = () => setHasCheckedAccess(true)
  const defaultQueryOptions = {
    onCompleted: setCheckedAccessTrue,
    onError: setCheckedAccessTrue,
  }

  const documentQueryResponse = useInOrgDocumentContentAccessQuery({
    ...defaultQueryOptions,
    skip: viewerLoading || !documentId,
    variables: { id: documentId as string },
  })
  const snapshotQueryResponse = useInOrgSnapshotContentAccessQuery({
    ...defaultQueryOptions,
    skip: viewerLoading || !snapshotId,
    variables: { id: snapshotId as string },
  })

  return {
    hasCheckedInOrgAccess: hasCheckedAccess,
    isInOrgAccessAllowed: !!(
      documentQueryResponse.data?.document?.id || snapshotQueryResponse.data?.snapshot?.id
    ),
    loading:
      viewerLoading || documentId ? documentQueryResponse.loading : snapshotQueryResponse.loading,
  }
}
