import { useCallback } from 'react'

import {
  ContentType,
  DocumentCardFragment,
  FolderCardFragment,
  SnapshotFieldsFragment,
  useDeleteDocumentMutation,
  useDeleteFolderMutation,
  useDeleteSnapshotMutation,
} from '@/generated/graphql'
import { deleteFromCacheDuringMutation } from '@/shared/apollo/apolloClient'
import { Modal } from '@/shared/components'
import { ContentV2 } from '@/shared/types'
import { showModal } from '@/util'
import { capitalize } from '@/util/helpers'

import { getItemCounts } from '../BulkActionsToolbar'

const getTypeText = (selectedContent: readonly ContentV2[]) => {
  if (
    selectedContent.filter((item) => item.__typename === 'Document').length ===
    selectedContent.length
  ) {
    return 'pages'
  }

  if (
    selectedContent.filter((item) => item.__typename === 'Snapshot').length ===
    selectedContent.length
  ) {
    return 'snapshots'
  }

  if (
    selectedContent.filter((item) => item.__typename === 'Folder').length === selectedContent.length
  ) {
    return 'folders'
  }

  return 'items'
}

const useDelete = (): ((
  selectedContent: readonly ContentV2[],
  unselectAllItems?: () => void,
) => Promise<void>) => {
  const deleteItemsCallback = useDeleteContent()

  const deleteContentCallback = useCallback(
    async (selectedContent: readonly ContentV2[], unselectAllItems?: () => void) => {
      const count =
        selectedContent.filter((item) => item.__typename === 'Folder').length ===
        selectedContent.length
          ? selectedContent.length
          : getItemCounts(selectedContent)
      const deleteTitleText =
        selectedContent.length > 1
          ? `Delete ${count} ${getTypeText(selectedContent)}?`
          : `Delete "${selectedContent[0].name}"?`

      const deleteDescriptionText = getDeleteDescriptionText(selectedContent)

      await showModal(
        <Modal
          variant="destructive"
          title={deleteTitleText}
          content={deleteDescriptionText}
          primaryButtonText="Delete"
          onSubmit={() => {
            deleteItemsCallback(selectedContent)
            if (unselectAllItems) {
              unselectAllItems()
            }
          }}
          secondaryButtonText="Cancel"
        />,
      )
    },
    [deleteItemsCallback],
  )

  return deleteContentCallback
}

const getDeleteDescriptionText = (contentToDelete: readonly ContentV2[]) => {
  if (contentToDelete.length > 1) {
    const originFolder = (contentToDelete[0] as DocumentCardFragment | SnapshotFieldsFragment)
      .folder?.name
    const contentsWithoutFolders = contentToDelete.filter(
      (content) => content.__typename !== 'Folder',
    )

    const hasMultipleAuthors = () => {
      const snapshots = contentsWithoutFolders.filter(
        (item) => item.__typename === 'Snapshot',
      ) as SnapshotFieldsFragment[]
      const snapshotsWithMultipleAuthors =
        snapshots.filter((item) => item.createdBy !== snapshots[0].createdBy).length > 0

      const documents = contentsWithoutFolders.filter(
        (item) => item.__typename === 'Document',
      ) as DocumentCardFragment[]
      const documentsWithMultipleAuthors =
        documents.filter((item) => item.createdBy !== documents[0].createdBy).length > 0

      return snapshotsWithMultipleAuthors || documentsWithMultipleAuthors
    }

    const descriptionText = `This action will delete ${getItemCounts(contentToDelete)} ${
      contentsWithoutFolders.length === 0 ? 'items' : getTypeText(contentToDelete)
    }${
      contentToDelete.filter((content) => content.__typename === 'Folder').length > 0
        ? ''
        : hasMultipleAuthors()
        ? ' created by multiple teammates'
        : ' created by you'
    }${
      originFolder
        ? ' from the folder "' + originFolder + '"'
        : ' from the ' + (contentToDelete[0].team ? 'folder "Team"' : 'folder "Personal"')
    }. This action can not be undone.`

    return descriptionText
  }

  if ((contentToDelete[0].__typename.toUpperCase() as ContentType) === ContentType.Folder) {
    const folder = contentToDelete[0] as FolderCardFragment
    const counts = folder.contentCounts.documents + folder.contentCounts.snapshots
    return `This folder and ${counts} item${
      counts != 1 ? 's' : ''
    } inside will be permanently deleted.`
  }

  return `The ${capitalize(
    contentToDelete[0].__typename.toLocaleLowerCase(),
  )} will be permanently deleted.`
}

const useDeleteContent = () => {
  const [deleteDocumentMutation] = useDeleteDocumentMutation()
  const [deleteFolderMutation] = useDeleteFolderMutation()
  const [deleteSnapshotMutation] = useDeleteSnapshotMutation()

  const deleteItemsCallback = (contentToDelete: readonly ContentV2[]) => {
    contentToDelete.map((contentItem) => {
      const id = contentItem.id
      const variables = { id }
      switch (contentItem.__typename.toUpperCase() as ContentType) {
        case ContentType.Document:
          void deleteDocumentMutation({
            update: deleteFromCacheDuringMutation({
              __typename: 'Document',
              id,
            }),
            variables,
          })
          break
        case ContentType.Folder:
          void deleteFolderMutation({
            update: deleteFromCacheDuringMutation({ __typename: 'Folder', id }),
            variables,
          })
          break
        case ContentType.Snapshot:
          void deleteSnapshotMutation({
            update: deleteFromCacheDuringMutation({
              __typename: 'Snapshot',
              id,
            }),
            variables,
          })
          break
        default:
          break
      }
    })
  }

  return deleteItemsCallback
}

export default useDelete
