import { ApolloProvider } from '@apollo/client'
import { useCallback, useState } from 'react'
import { X } from 'react-feather'
import { useParams } from 'react-router-dom'

import {
  Content,
  ContentGridSearchDocument,
  SearchDocument,
  SelectedContent,
  SnapshotDocument,
  useMoveContentToTeamMutation,
  useMoveToFolderMutation,
} from '@/generated/graphql'
import { apolloClient } from '@/shared/apollo/apolloClient'
import { Button, Dialog } from '@/shared/components'
import useViewer from '@/shared/hooks/useViewer'
import { ContentV2 } from '@/shared/types'
import { showModal } from '@/util'
import { MoveToSearchPane } from '@/web/components/Search'
import useControlledPopover from '@/web/hooks/useControlledPopover'

export const useMoveToFolderCallback = (
  selectedContent: Content[] | SelectedContent[],
): (() => void) => {
  const { teamId } = useParams<{ teamId?: string }>()
  const { activeOrganization } = useViewer()
  const [, { closePopover }] = useControlledPopover('snapshots')
  const [moveToFolder] = useMoveToFolderMutation({
    refetchQueries: [
      SearchDocument,
      ContentGridSearchDocument,
      SnapshotDocument,
      /* PagesEditorQueryDocument */ // uncomment PagesEditorQueryDocument when we move all of Pages into GQL
    ],
  })
  const [moveToTeam] = useMoveContentToTeamMutation({
    refetchQueries: [
      SearchDocument,
      ContentGridSearchDocument,
      SnapshotDocument,
      /* PagesEditorQueryDocument */ // uncomment PagesEditorQueryDocument when we move all of Pages into GQL
    ],
  })

  const addItemsToFolderCallback = useCallback(
    async (folder: ContentV2) => {
      if (folder.id === 'personal') {
        for (const contentItem of selectedContent) {
          await moveToTeam({
            variables: {
              id: contentItem.id,
            },
          })
        }
      } else if (folder.id === 'team') {
        for (const contentItem of selectedContent) {
          await moveToTeam({
            variables: {
              id: contentItem.id,
              teamId: activeOrganization?.teams.nodes[0].id,
            },
          })
        }
      } else {
        for (const contentItem of selectedContent) {
          await moveToFolder({
            variables: { folderId: folder.id, id: contentItem.id },
          })
        }
      }
      closePopover()
    },
    [activeOrganization, closePopover, moveToFolder, moveToTeam, selectedContent],
  )

  const showMoveToFolderModalCallback = useCallback(() => {
    showModal(
      <MoveToFolderModal
        addItemsToFolderCallback={addItemsToFolderCallback}
        itemCount={selectedContent.length}
        teamId={teamId}
      />,
    )
  }, [addItemsToFolderCallback, selectedContent, teamId])

  return showMoveToFolderModalCallback
}

interface MoveToFolderModalProps {
  addItemsToFolderCallback(folder: ContentV2): void
  itemCount: number
  teamId?: string
}

const MoveToFolderModal = (props: MoveToFolderModalProps) => {
  const { addItemsToFolderCallback, itemCount, teamId } = props
  const [isOpen, setIsOpen] = useState(true)
  const title = `Move ${itemCount} item${itemCount > 1 ? 's' : ''}`

  const submitCallback = (folder: ContentV2) => {
    addItemsToFolderCallback(folder)
    setIsOpen(false)
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Dialog isOpen={isOpen} setIsOpen={setIsOpen} showBackgroundOverlay={true}>
        <div className="border-base-black bg-background-white inline-block h-[600px] w-full max-w-[600px] transform rounded-md border text-left align-middle transition-all">
          <div className="flex h-full flex-col px-3">
            <div className="flex items-center justify-between pt-3">
              <span className="text-xl font-semibold">{title}</span>
              <Button variant="ghost" tabIndex={-1} onClick={() => setIsOpen(false)}>
                <X size={16} />
              </Button>
            </div>
            <MoveToSearchPane onSubmit={submitCallback} teamId={teamId} />
          </div>
        </div>
      </Dialog>
    </ApolloProvider>
  )
}
