import { useEffect } from 'react'

// Note: implementation taken and modified from https://github.com/SAITS/use-keyboard-shortcuts

export type Shortcut = {
  readonly keys: readonly string[]
  readonly onEvent: (event: ShortcutEvent) => void
  readonly disabled?: boolean
}

export enum EventType {
  keydown = 'keydown',
  wheel = 'wheel',
}

export enum ComboKey {
  ctrl = 'ctrl',
  shift = 'shift',
  alt = 'alt',
}

export type ShortcutEvent = KeyboardEvent | WheelEvent

const ALLOWED_COMBO_KEYS = Object.keys(ComboKey)
const ALLOWED_EVENTS = Object.keys(EventType)
export const EDITABLE_TAGS = ['INPUT', 'TEXTAREA', 'SELECT']

const throwError = (message: string): void => {
  throw new Error(`Error thrown for useKeyboardShortcuts: ${message}`)
}

const allComboKeysPressed = (keys: readonly string[], event: ShortcutEvent) => {
  const lowercaseKeys = keys.map((key) => key.toLowerCase())
  if (lowercaseKeys.includes('ctrl') && !event.ctrlKey && !event.metaKey) return false
  if (lowercaseKeys.includes('shift') && !event.shiftKey) return false
  if (lowercaseKeys.includes('alt') && !event.altKey) return false
  return true
}

const validateKeys = (keys: readonly string[]): boolean => {
  let errorMessage: string | null = null

  if (!keys.length)
    errorMessage = `You need to specify a key besides ${JSON.stringify(
      ALLOWED_COMBO_KEYS,
    )} for keydown events.`
  if (keys.length > 1)
    errorMessage = `Only one key besides ${JSON.stringify(ALLOWED_COMBO_KEYS)} can be used. Found ${
      keys.length
    }: [${keys.map((key) => `"${key}"`)}].`

  if (errorMessage) {
    throwError(errorMessage)
    return false
  }

  return true
}

const withoutComboKeys = (key: string) => !ALLOWED_COMBO_KEYS.includes(key.toLowerCase())

const comboKeys = (key: string) => ALLOWED_COMBO_KEYS.includes(key.toLowerCase())

const isSingleKeyEvent = (e: ShortcutEvent) => !e.shiftKey && !e.metaKey && !e.altKey && !e.ctrlKey

const isSingleKeyShortcut = (shortcut: Shortcut) => !shortcut.keys.filter(comboKeys).length

const getKeyCode = (key: string) => {
  if (key.length === 1 && key.search(/[^a-zA-Z]+/) === -1) return `Key${key.toUpperCase()}`

  if (key.length === 1 && typeof Number(key) === 'number') return `Digit${key}`

  return key
}

const useKeyboardShortcuts = (
  shortcuts: readonly Shortcut[],
  active = true,
  dependencies: readonly unknown[] = [],
  eventType: 'keydown' | 'wheel' = 'keydown',
): void => {
  if (!shortcuts || !shortcuts.length)
    return throwError('You need to pass at least one shortcut as an argument.')

  if (!ALLOWED_EVENTS.includes(eventType))
    return throwError(
      `Unsupported event. Supported events are: ${JSON.stringify(
        ALLOWED_EVENTS,
      )}. Found event: "${eventType}".`,
    )

  const shortcutHasPriority = (inputShortcut: Shortcut, event: ShortcutEvent) => {
    if (shortcuts.length === 1) return true
    if (isSingleKeyShortcut(inputShortcut) && isSingleKeyEvent(event)) return true

    return !shortcuts.find(
      (shortcut) =>
        allComboKeysPressed(shortcut.keys, event) &&
        shortcut.keys.length > inputShortcut.keys.length,
    )
  }

  const callCallback = (shortcut: Shortcut, event: ShortcutEvent) => {
    if (event instanceof KeyboardEvent) {
      const keys = shortcut.keys.filter(withoutComboKeys)
      const valid = validateKeys(keys)
      const singleKey = shortcut.keys.length === 1 && shortcut.keys[0] === event.key

      if (!valid || !(singleKey || getKeyCode(keys[0]) === event.code)) return
    }

    // MODIFICATION BY US: originally this would still fire events while focused on an input
    // if ctrl, meta, or escape was pressed. we don't want that. we really want these keyboard
    // events to only happen in non-inputs.
    // If the targetted element is a input for example, and the user doesn't
    // press ctrl or meta or escape it probably means that they are trying to type in the
    // input field
    const writing =
      EDITABLE_TAGS.includes(event.target ? (event.target as HTMLElement)['tagName'] : '') &&
      'code' in event

    const shouldExecAction =
      !shortcut.disabled &&
      !writing &&
      allComboKeysPressed(shortcut.keys, event) &&
      shortcutHasPriority(shortcut, event)

    if (shouldExecAction) {
      event.preventDefault()
      shortcut.onEvent(event)
    }
  }

  const handleKeyboardShortcuts = (e: ShortcutEvent) =>
    shortcuts.forEach((shortcut) => callCallback(shortcut, e))

  const addEventListener = () =>
    document.addEventListener(eventType, handleKeyboardShortcuts, {
      passive: false,
    })

  const removeEventListener = () => document.removeEventListener(eventType, handleKeyboardShortcuts)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    active ? addEventListener() : removeEventListener()
    return removeEventListener
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, ...dependencies])
}

export default useKeyboardShortcuts
