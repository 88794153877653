import cx from 'clsx'
import * as React from 'react'

const ContextMenuButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, className, ...rest } = props
  return (
    <button
      type="button"
      className={cx(
        'w-full px-2 py-2',
        'hover:bg-interactive-secondary-hover rounded',
        'disabled:opacity-50',
        'text-copy text-left text-base',
        'disabled:cursor-not-allowed',
        'active:border-divider-dark-blue active:bg-interactive-secondary-active active:text-interactive-primary active:outline-none',
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  )
}

export default ContextMenuButton
