import * as Popover from '@radix-ui/react-popover'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import clsx from 'clsx'
import { format } from 'date-fns'
import { uniqBy } from 'lodash-es'
import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { AlertCircle, Check, ChevronDown, Clock, Loader, XCircle } from 'react-feather'
import { useInView } from 'react-intersection-observer'

import { RepairType, SnapshotFieldsFragment, SnapshotStatus } from '@/generated/graphql'
import { IUseSnapshot } from '@/shared/hooks/useSnapshot'
import { SnapshotVersions } from '@/shared/hooks/useSnapshotVersions'
import { getSnapshotStatus, PrimaryRepairOption } from '@/util/snapshotHelpers'

import useSnapshotVersion, { SnapshotVersionHookReturnType } from '../hooks/useSnapshotVersion'
import { Connection } from './Icons'

type SnapshotVersionEdge = {
  node: NonNullable<SnapshotVersionHookReturnType['snapshotVersion']>
}

export type SnapshotVersionPaginationParams = {
  after?: string
  before?: string
}

export interface ISnapshotVersionDropdownProps {
  /**
   * The initial list of all of the captured snapshot versions as well as a function to fetch more pages.
   */
  capturedSnapshotVersions: SnapshotVersions

  /**
   * The Snapshot that was originally
   */
  originalSnapshot: NonNullable<IUseSnapshot>

  snapshotVersionId?: string | null

  onSnapshotVersionChange: (id: string | null, query?: SnapshotVersionPaginationParams) => void
  showMostRecentOption?: boolean
  displayType?: 'regular' | 'compact' | 'embed'
}

const getDropdownIcon = (primaryRepairOption: PrimaryRepairOption | undefined) => {
  switch (primaryRepairOption) {
    case undefined:
      return <Clock size={16} />
    case 'captureFailed':
      return <XCircle size={16} />
    case 'login':
      return <Connection size={16} />
    case 'review':
      return <AlertCircle size={16} />
  }
}

const getDropdownText = (
  createdAt: string | Date | number,
  primaryRepairOption: PrimaryRepairOption | undefined,
) => {
  switch (primaryRepairOption) {
    case undefined:
      return format(new Date(createdAt), 'p • LLL dd y')
    case 'captureFailed':
      return 'Capture failed'
    case 'login':
      return 'Reconnect'
    case 'review':
      return 'Review'
  }
}

const MiniVersionDropdownTrigger = ({
  active,
  primaryRepairOption,
}: {
  readonly active: boolean
  readonly primaryRepairOption?: PrimaryRepairOption
}) => {
  return (
    <Popover.Trigger
      className={clsx(
        'bg-background-panel hover:bg-interactive-secondary-hover m-auto rounded border border-transparent p-1 text-center',
        {
          '!border-divider-light-blue bg-interactive-secondary-active text-interactive-primary border':
            active,
        },
      )}
    >
      <div className="m-0">{getDropdownIcon(primaryRepairOption)}</div>
    </Popover.Trigger>
  )
}

const VersionDropdownTrigger = ({
  active,
  id,
  edge,
  primaryRepairOption,
}: {
  readonly active: boolean
  readonly edge?: SnapshotVersionEdge | null
  readonly id?: string | null
  readonly primaryRepairOption?: PrimaryRepairOption
}) => {
  return (
    <Popover.Trigger
      className={clsx(
        'shadow-button-secondary border-divider-light-gray bg-interactive-secondary hover:bg-interactive-secondary-hover flex h-8 w-full items-center rounded border py-1 px-1 text-base sm:w-[200px]',
        {
          '!border-divider-light-blue bg-interactive-secondary-active text-interactive-primary':
            active,
        },
      )}
    >
      {id !== null && <div className="ml-1 mr-2">{getDropdownIcon(primaryRepairOption)}</div>}
      {id === null ? (
        <div className="flex flex-row">
          <Clock size="16" className="ml-1 mr-2" />
          Most recent
        </div>
      ) : (
        getDropdownText(edge?.node?.createdAt ?? Date.now(), primaryRepairOption)
      )}

      <ChevronDown size={16} className="text-copy-secondary ml-auto mr-1" />
    </Popover.Trigger>
  )
}

const BrokenPlaceholderVersionDropdownItem = ({
  isSelected,
  onClick,
  primaryRepairOption,
}: {
  readonly isSelected: boolean
  readonly onClick: () => void
  readonly primaryRepairOption?: PrimaryRepairOption
}) => {
  return (
    <div
      className={clsx('flex cursor-pointer rounded p-2 text-xs', {
        'active:bg-interactive-secondary-active active:text-interactive-primary-active': true,
        'bg-background-selection text-interactive-primary': isSelected,
        'hover:bg-interactive-secondary-hover': true,
      })}
      onClick={onClick}
    >
      {getDropdownText(Date.now(), primaryRepairOption)}
      {isSelected && <Check size={16} className="mr-0 ml-auto" />}
    </div>
  )
}

const VersionDropdownItem = ({
  primaryRepairOption,
  edge,
  isMostRecentVersion,
  isSelected,
  onClick,
  selectedRef,
}: {
  readonly primaryRepairOption?: PrimaryRepairOption
  readonly isMostRecentVersion?: boolean
  readonly isSelected: boolean
  readonly edge?: SnapshotVersionEdge
  readonly onClick: () => void
  readonly selectedRef: RefObject<HTMLDivElement> | null
}) => {
  return (
    <div
      key={edge?.node.id ?? 'most-recent-version'}
      className={clsx('flex cursor-pointer rounded p-2 text-xs', {
        'active:bg-interactive-secondary-active active:text-interactive-primary-active': true,
        'bg-background-selection text-interactive-primary': isSelected,
        'hover:bg-interactive-secondary-hover': true,
      })}
      onClick={onClick}
      ref={selectedRef}
    >
      {isMostRecentVersion
        ? 'Most Recent'
        : getDropdownText(edge?.node?.createdAt ?? Date.now(), primaryRepairOption)}
      {isSelected && <Check size={16} className="mr-0 ml-auto" />}
    </div>
  )
}

// eslint-disable-next-line complexity
function SnapshotVersionDropdown(props: ISnapshotVersionDropdownProps) {
  const isEmbedded = props.displayType === 'embed'
  const [open, setOpen] = useState(false)
  const selectedRef = useRef<HTMLDivElement>(null)

  const { capturedSnapshotVersions, originalSnapshot, showMostRecentOption, snapshotVersionId } =
    props

  const latestSnapshotVersion = originalSnapshot.latestVersion

  const { ref: nextPageRef, inView: nextPageInView } = useInView()
  const { ref: previousPageRef, inView: previousPageInView } = useInView({
    delay: 100,
    threshold: 1,
    trackVisibility: true,
  })

  const {
    hasNextPage,
    hasPreviousPage,
    loadMore,
    loadingMore,
    snapshot: snapshotWithVersions,
  } = capturedSnapshotVersions

  const renderPlaceholderBrokenVersion =
    originalSnapshot.__typename === 'Snapshot' && originalSnapshot.status === SnapshotStatus.Error

  const isBroken =
    originalSnapshot.__typename === 'Snapshot' &&
    ((originalSnapshot.suggestedRepairType != null &&
      originalSnapshot.suggestedRepairType !== RepairType.None) ||
      originalSnapshot.status === SnapshotStatus.Error)

  useEffect(() => {
    if (nextPageInView && !loadingMore) {
      void loadMore('after')
    }
  }, [nextPageInView, loadingMore, loadMore])

  useEffect(() => {
    if (previousPageInView && !loadingMore) {
      void loadMore('before')
    }
  }, [previousPageInView, loadingMore, loadMore])

  const activeVersionId = showMostRecentOption
    ? snapshotVersionId
    : snapshotVersionId || originalSnapshot.currentVersion?.id

  const sortedVersions = uniqBy<SnapshotVersionEdge>(
    latestSnapshotVersion
      ? [...(snapshotWithVersions?.allVersions.edges ?? []), { node: latestSnapshotVersion }]
      : snapshotWithVersions?.allVersions.edges,
    (x) => x.node.id,
  ).sort((x, y) => new Date(y.node.createdAt).getTime() - new Date(x.node.createdAt).getTime())

  const maybeCachedVersion = useSnapshotVersion(activeVersionId ?? undefined, false, true)
  const activeVersion = sortedVersions.find((x, i) =>
    activeVersionId === null ? i === 0 : x.node.id === activeVersionId,
  ) ?? { node: maybeCachedVersion.snapshotVersion as NonNullable<SnapshotVersionEdge['node']> }

  const onChangeSnapshotVersion = useCallback(
    (nodeIndex?: number | null) => {
      setOpen(false)

      if (nodeIndex === null) {
        return props.onSnapshotVersionChange(null)
      }

      if (typeof nodeIndex === 'undefined') {
        return props.onSnapshotVersionChange('_')
      }

      const query: SnapshotVersionPaginationParams = {}
      if (snapshotWithVersions) {
        // If the user selects the last node use after direction,
        // Otherwise always use before direction
        if (nodeIndex > 9 && nodeIndex === sortedVersions.length - 1) {
          query.after = sortedVersions[nodeIndex - 1].node.id
        } else if (nodeIndex > 9) {
          query.before = sortedVersions[nodeIndex + 1].node.id
        }
      }

      return props.onSnapshotVersionChange(sortedVersions[nodeIndex].node.id, query)
    },
    [props, snapshotWithVersions, sortedVersions],
  )

  const onDropdownOpen = useCallback((isOpen: boolean) => {
    setOpen(isOpen)
    // Because the dropdown needs to first open, we need to defer the request to scroll
    requestAnimationFrame(() => {
      selectedRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    })
  }, [])

  const showTriggerAsBroken =
    isBroken && !hasPreviousPage && sortedVersions[0].node.id === activeVersionId

  const { primaryRepairOption } = getSnapshotStatus(originalSnapshot as SnapshotFieldsFragment)

  return (
    <>
      <Popover.Root onOpenChange={onDropdownOpen} open={open}>
        {isEmbedded ? (
          <MiniVersionDropdownTrigger
            active={open}
            primaryRepairOption={showTriggerAsBroken ? primaryRepairOption : undefined}
          />
        ) : (
          <VersionDropdownTrigger
            active={open}
            id={activeVersionId}
            edge={activeVersion}
            primaryRepairOption={showTriggerAsBroken ? primaryRepairOption : undefined}
          />
        )}
        <Popover.Portal>
          <Popover.Content asChild sideOffset={8}>
            <ScrollArea.Root>
              <ScrollArea.Viewport className="border-divider-light-gray bg-background-panel shadow-soft max-h-[300px] w-[200px] rounded-md border p-2">
                {hasPreviousPage && (
                  <div ref={previousPageRef}>
                    <Loader className="animate-spin-slow text-copy m-auto h-4 w-4" />
                  </div>
                )}
                {showMostRecentOption && (
                  <VersionDropdownItem
                    isSelected={activeVersionId === null}
                    onClick={() => onChangeSnapshotVersion(null)}
                    selectedRef={activeVersionId === null ? selectedRef : null}
                    isMostRecentVersion
                  />
                )}
                {!isBroken && renderPlaceholderBrokenVersion && !hasPreviousPage && (
                  <BrokenPlaceholderVersionDropdownItem
                    isSelected={activeVersionId === '_'}
                    onClick={() => onChangeSnapshotVersion()}
                    primaryRepairOption={primaryRepairOption}
                  />
                )}
                {sortedVersions.map((edge, index) => {
                  const isSelected = edge.node.id === activeVersionId
                  return (
                    <VersionDropdownItem
                      primaryRepairOption={
                        isBroken && index === 0 && !hasPreviousPage
                          ? primaryRepairOption
                          : undefined
                      }
                      isSelected={isSelected}
                      edge={edge}
                      key={edge.node.id}
                      onClick={() => onChangeSnapshotVersion(index)}
                      selectedRef={isSelected ? selectedRef : null}
                    />
                  )
                })}
                {hasNextPage && (
                  <div ref={nextPageRef}>
                    <Loader className="animate-spin-slow text-copy m-auto h-4 w-4" />
                  </div>
                )}
              </ScrollArea.Viewport>
              <ScrollArea.Scrollbar orientation="vertical" className="p-1">
                <ScrollArea.Thumb className="text-divider-dark-gray rounded border-2" />
              </ScrollArea.Scrollbar>
            </ScrollArea.Root>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </>
  )
}
export default SnapshotVersionDropdown
