import clsx from 'clsx'
import { File, Folder } from 'react-feather'
import { Link } from 'react-router-dom'

import { ContentType } from '@/generated/graphql'
import SnapshotIcon from '@/shared/components/Icons/Snapshot'

interface ISeeMoreCardProps {
  count: number
  type: ContentType
  disableLink?: boolean
}

const getFilterString = (type: ContentType) => {
  switch (type) {
    case ContentType.Document:
      return 'pages'
    case ContentType.Folder:
      return 'folders'
    case ContentType.Snapshot:
      return 'snapshots'
    default:
      return ''
  }
}

export const getFeedFilterParams = (type: ContentType) => {
  return new URLSearchParams({ filter: getFilterString(type) })
}

export const SeeMoreCard = (props: ISeeMoreCardProps): JSX.Element => {
  const icon = (): JSX.Element => {
    switch (props.type) {
      case ContentType.Folder:
        return <Folder className="h-7 w-7" />
      case ContentType.Document:
        return <File className="h-7 w-7" />
      case ContentType.Snapshot:
        return <SnapshotIcon className="h-7 w-7" />
      default:
        return <></>
    }
  }

  const typeText = (): string => {
    switch (props.type) {
      case ContentType.Folder:
        return 'Folders'
      case ContentType.Document:
        return 'Pages'
      case ContentType.Snapshot:
        return 'Snapshots'
      default:
        return ''
    }
  }

  const card = (
    <div className={clsx('absolute inset-0 flex flex-col items-center justify-center gap-5 p-1')}>
      {icon()}
      <div className="text-center text-base">
        See all {props.count} {typeText()}
      </div>
    </div>
  )

  if (props.disableLink) {
    return (
      <div
        tabIndex={0}
        className={clsx(
          'h-full w-full',
          'relative',
          'group flex min-w-[8.25rem] max-w-sm',
          'select-none flex-col overflow-hidden rounded border',
          'hover:cursor-pointer active:cursor-auto',
          'focus:outline-none focus-visible:outline-none',
          'border-divider-light-gray active:border-divider-light-blue',
          'focus-visible:shadow-bordershadow',
          'bg-background-panel hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover active:bg-interactive-secondary-active',
          'border-divider-light-gray',
          'text-copy-secondary hover:text-copy focus-visible:text-copy active:text-copy',
          'hover:shadow-dark-hard focus-visible:border-interactive-primary-active',
        )}
      >
        {card}
      </div>
    )
  } else {
    return (
      <Link
        to={{
          ...location,
          search: getFeedFilterParams(props.type).toString(),
        }}
        className={clsx(
          'h-full w-full',
          'relative',
          'group flex min-w-[8.25rem] max-w-sm',
          'select-none flex-col overflow-hidden rounded border',
          'hover:cursor-pointer active:cursor-auto',
          'focus:outline-none focus-visible:outline-none',
          'border-divider-light-gray active:border-divider-light-blue',
          'focus-visible:shadow-bordershadow',
          'bg-background-panel hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover active:bg-interactive-secondary-active',
          'border-divider-light-gray',
          'text-copy-secondary hover:text-copy focus-visible:text-copy active:text-copy',
          'hover:shadow-dark-hard focus-visible:border-interactive-primary-active',
        )}
      >
        {card}
      </Link>
    )
  }
}
