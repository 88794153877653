import clsx from 'clsx'
import { useCallback } from 'react'
import { CheckCircle, Folder, Link, Trash2, Type } from 'react-feather'
import { useRouteMatch } from 'react-router-dom'

import {
  Document,
  DocumentCardFragment,
  RepairType,
  SnapshotFieldsFragment,
  SnapshotStatus,
} from '@/generated/graphql'
import { useAuth } from '@/shared/auth/useAuth'
import { Button, PlusLogo, Toast, UserImage } from '@/shared/components'
import useDelete from '@/shared/components/ContentGrid/Actions/delete'
import useMoveTo from '@/shared/components/ContentGrid/Actions/moveto'
import useRename from '@/shared/components/ContentGrid/Actions/rename'
import { PopoverMenu } from '@/shared/components/PopoverMenu/PopoverMenu'
import useRefreshSnapshot from '@/shared/hooks/useRefreshSnapshot'
import { showModal, useIsLinkPreview } from '@/util'
import { copyTextToClipboard, getPublicLink } from '@/util/helpers'
import {
  CurrentSnapshotStatus,
  getSnapshotStatus,
  PrimaryRepairOption,
} from '@/util/snapshotHelpers'

import { ShareModalTrigger } from './ShareModal'

interface IEmbedFooterProps {
  content: SnapshotFieldsFragment | Document
  appLinkOverride?: string
  isNewTab?: boolean
  isFeedView?: boolean
  removePinnedSnapshot?: (id: string) => void
  snapshotVersionId?: string | null
  itemCanBeDeleted?: boolean
  allowRename?: boolean
  showPopover?: boolean
}

const RefreshSnapshotButton = ({ snapshot }: { snapshot: SnapshotFieldsFragment }): JSX.Element => {
  const refreshSnapshotCallback = useRefreshSnapshot(snapshot.id)
  const isCapturingOrRefreshingSnapshot =
    snapshot.status === SnapshotStatus.New ||
    snapshot.status === SnapshotStatus.Processing ||
    snapshot.status === SnapshotStatus.RefreshRequested

  const isSnapshotBroken =
    (snapshot?.suggestedRepairType && snapshot.suggestedRepairType !== RepairType.None) ||
    snapshot.status === SnapshotStatus.Error

  return (
    <Button
      className="whitespace-nowrap p-0 text-base"
      disabled={isCapturingOrRefreshingSnapshot || isSnapshotBroken}
      onClick={refreshSnapshotCallback}
      variant="ghost"
      size="tiny"
    >
      <div className="p-1">{isCapturingOrRefreshingSnapshot ? 'Refreshing...' : 'Refresh'}</div>
    </Button>
  )
}

const getRepairButtonText = (primaryRepairOption?: PrimaryRepairOption) => {
  switch (primaryRepairOption) {
    case 'captureFailed':
      return 'View'
    case 'login':
      return 'Reconnect'
    case 'review':
      return 'Review'
    default:
      return ''
  }
}

const publicLinkForContent = ({
  content,
  isNewTabOrFeed,
  snapshotVersionId,
}: {
  content: SnapshotFieldsFragment | Document
  isNewTabOrFeed?: boolean
  snapshotVersionId?: string | null
}) =>
  isNewTabOrFeed
    ? getPublicLink(
        content,
        content.organization.slug,
        false,
        content.__typename === 'Document' ||
          (content.__typename === 'Snapshot' && snapshotVersionId === content.latestVersion?.id)
          ? undefined
          : snapshotVersionId,
      )
    : window.location.href

const getContentStatus = (content: Document | SnapshotFieldsFragment) =>
  content.__typename === 'Snapshot' ? getSnapshotStatus(content) : undefined

const getRepairButtonStatus = (
  content: SnapshotFieldsFragment,
  status: CurrentSnapshotStatus | undefined,
) =>
  (content.viewer.canEdit && status?.primaryRepairOption === 'captureFailed') ||
  (content.viewer.isOwner && status?.primaryRepairOption)

const getPausedBadgeStatus = (
  showRepairButton: boolean,
  content: Document | SnapshotFieldsFragment,
  status: CurrentSnapshotStatus | undefined,
) => !showRepairButton && content.viewer.canEdit && status?.primaryRepairOption

// eslint-disable-next-line complexity
export function EmbedFooter({
  content,
  isNewTab,
  isFeedView,
  removePinnedSnapshot,
  snapshotVersionId,
  itemCanBeDeleted,
  allowRename,
  showPopover,
}: IEmbedFooterProps): JSX.Element {
  const isGallery = useRouteMatch([`/:organizationSlug/gallery`, `/gallery`])

  const deleteCallback = useDelete()
  const renameCallback = useRename()
  const moveToCallback = useMoveTo()

  const auth = useAuth()
  const isLinkPreview = useIsLinkPreview()

  const snapshotStatus = getContentStatus(content)

  const shouldShowRepairButton =
    content.__typename === 'Snapshot' && getRepairButtonStatus(content, snapshotStatus)

  const shouldShowPausedText =
    content.__typename === 'Snapshot' &&
    getPausedBadgeStatus(!!shouldShowRepairButton, content, snapshotStatus)

  const publicLink = publicLinkForContent({
    content,
    isNewTabOrFeed: isNewTab || isFeedView,
    snapshotVersionId,
  })

  const copyLinkCallback = useCallback(() => {
    void copyTextToClipboard(publicLink)
    void showModal(
      <Toast>
        <CheckCircle size={15} className="text-interactive-primary mr-2" />
        <span className="text-base">Link copied!</span>
      </Toast>,
    )
  }, [publicLink])

  return (
    <div
      className={clsx(
        'border-divider-light-gray bg-background-panel bottom-0 h-10 w-full border p-2',
        {
          'rounded-b': !isLinkPreview,
        },
      )}
    >
      <div className="flex h-full items-center text-base">
        {!isLinkPreview && !isNewTab && !isFeedView ? (
          <a href={publicLink} rel={'noreferrer'} target={'_top'} className="flex items-center">
            <PlusLogo width="16" height="16" fillColor="#0400E5" className="min-w-5" />
            <span className="text-dark min-w-8 mx-1 font-semibold">Plus</span>
          </a>
        ) : (
          <div className="flex flex-row items-center gap-2">
            {isGallery ? (
              <>
                <PlusLogo className="h-[16px] w-[16px]" />
                <div className="line-clamp-1">Plus</div>
              </>
            ) : content.createdBy && !isLinkPreview ? (
              <>
                <UserImage
                  size="tiny"
                  userName={content.createdBy.name}
                  userImageUrl={content.createdBy.image}
                  userEmail={content.createdBy.email}
                />
                <div className="line-clamp-1">{content.createdBy.name}</div>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
        {content && (
          <div
            className={clsx(
              'flex flex-1 items-center gap-1',
              isLinkPreview ? 'justify-start' : 'justify-end',
            )}
          >
            {snapshotStatus && (
              <>
                {snapshotStatus && !snapshotStatus.primaryRepairOption ? (
                  <RefreshSnapshotButton snapshot={content as SnapshotFieldsFragment} />
                ) : (
                  shouldShowRepairButton && (
                    <a
                      href={
                        isNewTab || isFeedView
                          ? getPublicLink(
                              content,
                              content.organization.slug,
                              false,
                              snapshotVersionId === content.latestVersion?.id
                                ? undefined
                                : snapshotVersionId,
                            )
                          : window.location.href
                      }
                      rel={'noreferrer'}
                      target={'_top'}
                    >
                      <Button
                        className="mr-1 whitespace-nowrap p-1 text-base"
                        variant="destructive"
                        size="tiny"
                      >
                        <div className="p-1">
                          {getRepairButtonText(snapshotStatus.primaryRepairOption)}
                        </div>
                      </Button>
                    </a>
                  )
                )}
                {shouldShowPausedText && (
                  <div className="text-copy-alert line-clamp-1 px-1 font-semibold">Paused</div>
                )}
              </>
            )}
            {isNewTab && removePinnedSnapshot && (
              <div className="flex justify-end">
                <Button
                  className="whitespace-nowrap p-0 text-base"
                  onClick={() => removePinnedSnapshot?.(content.id)}
                  size="tiny"
                  variant="ghost"
                >
                  <div className="p-1">Remove</div>
                </Button>
              </div>
            )}
            {content?.__typename === 'Snapshot' && content?.viewer?.canEdit && (
              <a href={content?.instructions.url} target="_blank" rel="noreferrer">
                <Button className="whitespace-nowrap p-0 text-base" size="tiny" variant="ghost">
                  <div className="p-1">Source</div>
                </Button>
              </a>
            )}
            {content?.viewer?.canEdit && !isNewTab && !isFeedView && (
              <ShareModalTrigger content={content} />
            )}
            {!auth.isLoggedIn() && (
              <a
                href={`${import.meta.env.WEB_APP_HOST}/register`}
                rel={'noreferrer'}
                target={'_top'}
              >
                <Button className="whitespace-nowrap p-0 text-base" size="tiny" variant="ghost">
                  <div className="p-1">Sign up</div>
                </Button>
              </a>
            )}
            <Button
              className="whitespace-nowrap p-0 text-base"
              onClick={copyLinkCallback}
              size="tiny"
              variant="ghost"
            >
              <div className="p-1">
                <Link size={16} />
              </div>
            </Button>
            {showPopover && !isGallery && (
              <PopoverMenu
                items={[
                  {
                    callback: () =>
                      moveToCallback([content as SnapshotFieldsFragment | DocumentCardFragment]),
                    icon: Folder,
                    name: 'Move To',
                  },
                  {
                    callback: () =>
                      renameCallback([content as SnapshotFieldsFragment | DocumentCardFragment]),
                    disabled: !allowRename,
                    icon: Type,
                    name: 'Rename',
                  },
                  {
                    callback: () =>
                      void deleteCallback([
                        content as SnapshotFieldsFragment | DocumentCardFragment,
                      ]),
                    disabled: !itemCanBeDeleted,
                    icon: Trash2,
                    name: 'Delete',
                    styles: clsx([
                      'text-interactive-destructive',
                      'active:text-interactive-destructive',
                      'active:bg-interactive-destructive-light',
                    ]),
                  },
                ]}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
