import { useStarTour } from '@plusdocs/star-tours'
import { isValidString } from '@plusdocs/utils/common/string'
import clsx from 'clsx'
import { ReactNode, useEffect, useState } from 'react'
import { AlertCircle, Icon, XCircle } from 'react-feather'

import { DismissableUi, useUpdateUserMutation } from '@/generated/graphql'
import { Button, Connection as ConnectionIcon } from '@/shared/components'
import { ButtonTestId } from '@/shared/types/testIds'
import { PrimaryRepairOption } from '@/util/snapshotHelpers'
import { AvailableTours } from '@/web/fixtures/tours'
import { useViewer } from '@/web/hooks'

import { RefreshButton, RefreshOrCaptureState, RepairButton } from '../SnapshotSidebarSettings'

declare global {
  interface Window {
    __SNAPSHOT_DOMAIN_NAME__: string
  }
}

const RepairSnapshotSidebarContainer = ({
  SidebarIcon,
  children,
  nuxTour,
  primaryText,
  secondaryText,
}: {
  readonly SidebarIcon: Icon
  readonly children: ReactNode
  readonly primaryText: string
  readonly nuxTour?: AvailableTours
  readonly secondaryText?: string
}): JSX.Element => {
  const { start } = useStarTour()
  const onStartTourClick = () => {
    if (nuxTour) {
      start(nuxTour)
    }
  }

  return (
    <div className="border-divider-light-gray bg-background-panel lg:h-viewport-minus-header flex h-full w-full flex-col overflow-y-auto py-3 px-3 leading-4 md:border md:border-t-0 lg:w-[248px] lg:border-l">
      <div className="flex h-full grow flex-col justify-center space-y-3">
        <div className="align-center flex w-full flex-col justify-center space-y-1">
          <SidebarIcon size={32} className="text-copy-alert mx-auto" />
          <span role="alert" className="text-center text-lg font-semibold">
            {primaryText}
          </span>
          <span
            className={clsx('text-center text-xs ', {
              hidden: !isValidString(secondaryText),
            })}
          >
            {secondaryText}
          </span>
        </div>
        <div className="flex w-full flex-col space-y-2">{children}</div>
      </div>
      <Button
        className="text-center text-base font-semibold"
        onClick={onStartTourClick}
        testId={ButtonTestId.ShowRepairNux}
        variant="ghost"
      >
        Why am I seeing this?
      </Button>
    </div>
  )
}

const CaptureFailedRepairSidebarPanel = ({
  domainName,
  handleHideThisMessage,
  handleReportIssue,
  handleRetryCapture,
  refreshOrCaptureState,
}: {
  readonly domainName: string
  readonly handleHideThisMessage: () => void
  readonly handleReportIssue: () => void
  readonly handleRetryCapture: () => void
  readonly refreshOrCaptureState: RefreshOrCaptureState
}): JSX.Element => {
  const { start } = useStarTour()
  const { viewer } = useViewer()
  const [updateUser] = useUpdateUserMutation()

  useEffect(() => {
    if (viewer?.user?.dismissedUi.captureFailedNux || !viewer?.user?.id) {
      return
    }

    start(AvailableTours.CaptureFailed)
    void updateUser({
      variables: {
        id: viewer.user?.id,
        input: {
          dismissUI: DismissableUi.CaptureFailedNux,
        },
      },
    })
  }, [start, viewer?.user, updateUser])

  return (
    <RepairSnapshotSidebarContainer
      SidebarIcon={XCircle}
      nuxTour={AvailableTours.CaptureFailed}
      primaryText="Capture failed"
      secondaryText="We'll automatically try to capture this Snapshot again when it's time for its next scheduled refresh."
    >
      <RefreshButton
        label="Refresh Snapshot"
        onClickRefreshSnapshot={handleRetryCapture}
        refreshingLabel="Retrying…"
        refreshOrCaptureState={refreshOrCaptureState}
      />
      <RepairButton
        domainName={domainName}
        label="More options"
        onClickHideThisMessage={handleHideThisMessage}
        onClickReportSnapshotIssue={handleReportIssue}
      />
    </RepairSnapshotSidebarContainer>
  )
}

const ReconnectSidebarPanel = ({
  handleDismissRepair,
  handleHideThisMessage,
  handleReconnect,
  handleReportIssue,
  handleRetakeSnapshot,
  domainName,
}: {
  readonly domainName: string
  readonly handleDismissRepair?: () => void
  readonly handleHideThisMessage: () => void
  readonly handleReconnect: () => void
  readonly handleReportIssue: () => void
  readonly handleRetakeSnapshot: () => void
}): JSX.Element => {
  const { start } = useStarTour()
  const { viewer } = useViewer()
  const [updateUser] = useUpdateUserMutation()

  // Effect used to pass snapshot domain name to Reconnect NUX
  // because NUX is rendered in a separate react instance.
  useEffect(() => {
    window.__SNAPSHOT_DOMAIN_NAME__ = domainName
  }, [domainName])

  useEffect(() => {
    if (viewer?.user?.dismissedUi.loggedOutNux || !viewer?.user?.id) {
      return
    }

    start(AvailableTours.LoggedOut)
    void updateUser({
      variables: {
        id: viewer.user?.id,
        input: {
          dismissUI: DismissableUi.LogBackInNux,
        },
      },
    })
  }, [start, viewer?.user, updateUser])

  return (
    <RepairSnapshotSidebarContainer
      SidebarIcon={ConnectionIcon}
      nuxTour={AvailableTours.LoggedOut}
      primaryText="Reconnect"
      secondaryText={`Log back into ${domainName} to reconnect all Snapshots using this connection.`}
    >
      <Button onClick={handleReconnect} variant="primary" testId={ButtonTestId.Reconnect}>
        Reconnect
      </Button>
      <RepairButton
        domainName={domainName}
        label="More options"
        onClickHideThisMessage={handleHideThisMessage}
        onClickReportSnapshotIssue={handleReportIssue}
        onClickRetakeSnapshot={handleRetakeSnapshot}
        onClickSnapshotNotBroken={handleDismissRepair}
      />
    </RepairSnapshotSidebarContainer>
  )
}

type RepairWorkflowStep = 'step1-AskUserIfBroken' | 'step2-RefreshFailed-SuggestRetake'

/**
 * Renders the sidebar panel that presents the user with a step-by-step way to diagnose and fix Snapshot issues.
 */
// Here's the state diagram of the workflow (I used https://asciiflow.com to generate).
// To edit this diagram, use this URL: https://asciiflow.com/#/share/eJyrVspLzE1VssorzcnRUcpJrEwtUrJSqo5RqohRsrI0s9CJUaoEsozMTYGsktSKEiAnRkkBAh5N2UM7FBOTh7AGylJwzslMzlaITC1WQAK4FGMFj6aBFJPkElT95PqHTFMotxviXaD24JLUAgVDUgyatgvmArBeI9KcAdUN0apnSqRGNDuNkaIYJBFQlJ9bUKIQWpxaBI7roNS0otTijMy8dEjcB5emp6cWl8DEsapB4gO5iqhcJBNKErNTEVaPpASDyCz4MxOhzIZX%2FhHWfBucl1hQnJFfouDpopCckZiXnppClFPwykNyPcIq2qAZYEQjw2mCYpRqlWoB65g1xg%3D%3D)
//
//        ┌─────────────────────────────┐
//        │        Click Yes            │
//        │                             ▼
// ┌──────┴──────┐             ┌─────────────────┐             ┌────────────┐            ┌────────────────┐
// │   Step 1    ├────────────►│     Step 2      ├────────────►│  Step 2.5  ├───────────►│     Step 3     │
// │ Prompt User │ Refreshing  │ Suggest Refresh │ Refreshing  │ Refreshing │!Refreshing │ Suggest Retake │
// └─────────────┘             └────────┬────────┘             └──────┬─────┘            └────────┬───────┘
//        ▲                             │                             │                           │
//        │      Snapshot ID changed    ▼                             ▼                           ▼
//        └───────────────────────────────────────────────────────────────────────────────────────┘
const RepairSnapshotOptionsSidebarPanel = ({
  domainName,
  handleDismissRepair,
  handleHideThisMessage,
  handleReconnect,
  handleReportIssue,
  handleRetakeSnapshot,
  handleRetryCapture,
  refreshOrCaptureState,
  snapshotId,
}: {
  readonly domainName: string
  readonly handleDismissRepair?: () => void
  readonly handleHideThisMessage: () => void
  readonly handleReconnect: () => void
  readonly handleReportIssue: () => void
  readonly handleRetakeSnapshot: () => void
  readonly handleRetryCapture: () => void
  readonly refreshOrCaptureState: RefreshOrCaptureState
  readonly snapshotId: string
}): JSX.Element => {
  const [resetWorkflow, setResetWorkflow] = useState(false)
  const [workflowStep, setWorkflowStep] = useState<RepairWorkflowStep>('step1-AskUserIfBroken')

  const isRefreshingOrCapturing =
    refreshOrCaptureState === 'refreshing' || refreshOrCaptureState === 'capturing'

  const { start } = useStarTour()
  const { viewer } = useViewer()
  const [updateUser] = useUpdateUserMutation()

  useEffect(() => {
    if (viewer?.user?.dismissedUi.reviewNux || !viewer?.user?.id) {
      return
    }

    start(AvailableTours.Review)
    void updateUser({
      variables: {
        id: viewer.user?.id,
        input: {
          dismissUI: DismissableUi.ReviewNux,
        },
      },
    })
  }, [start, viewer?.user, updateUser])

  // Go back to step 1 when the user batch navigates to a new snapshot.
  useEffect(() => setResetWorkflow(true), [snapshotId])

  // Here are the transitions - see the diagram above.
  useEffect(() => {
    if (resetWorkflow) {
      setWorkflowStep('step1-AskUserIfBroken')
      setResetWorkflow(false)
    }
  }, [handleDismissRepair, isRefreshingOrCapturing, resetWorkflow, workflowStep])

  const Step1Panel = (): JSX.Element => {
    return (
      <RepairSnapshotSidebarContainer
        nuxTour={AvailableTours.Review}
        primaryText="Review this Snapshot"
        secondaryText="Our AI thinks something is wrong with the most recent version of this Snapshot. Does it look broken to you?"
        SidebarIcon={AlertCircle}
      >
        <Button
          variant="secondary"
          onClick={() => setWorkflowStep('step2-RefreshFailed-SuggestRetake')}
          testId={ButtonTestId.ConfirmBrokenSnapshot}
        >
          It looks broken
        </Button>
        <Button
          variant="secondary"
          onClick={handleDismissRepair}
          testId={ButtonTestId.ConfirmNotBrokenSnapshot}
        >
          It looks normal
        </Button>
        <Button
          variant="ghost"
          onClick={handleHideThisMessage}
          testId={ButtonTestId.HideThisMessage}
        >
          Hide this message
        </Button>
      </RepairSnapshotSidebarContainer>
    )
  }

  const Step2Panel = (): JSX.Element => {
    return (
      <RepairSnapshotSidebarContainer
        SidebarIcon={AlertCircle}
        nuxTour={AvailableTours.Review}
        primaryText="Take a new Snapshot"
        secondaryText="Thanks for letting us know. To fix this issue, return to the source for this Snapshot and capture a new version."
      >
        <Button
          variant="primary"
          onClick={handleRetakeSnapshot}
          disabled={isRefreshingOrCapturing}
          testId={ButtonTestId.RetakeSnapshot}
        >
          Take a new Snapshot
        </Button>
        <RefreshButton
          label="Retry capture"
          onClickRefreshSnapshot={handleRetryCapture}
          refreshingLabel="Retrying…"
          refreshOrCaptureState={refreshOrCaptureState}
          variant="secondary"
        />
        <RepairButton
          domainName={domainName}
          label="More options"
          onClickHideThisMessage={handleHideThisMessage}
          onClickReauthSnapshot={isRefreshingOrCapturing ? undefined : handleReconnect}
          onClickReportSnapshotIssue={handleReportIssue}
          onClickSnapshotNotBroken={isRefreshingOrCapturing ? undefined : handleDismissRepair}
        />
      </RepairSnapshotSidebarContainer>
    )
  }

  switch (workflowStep) {
    case 'step1-AskUserIfBroken':
      return <Step1Panel />
    case 'step2-RefreshFailed-SuggestRetake':
      return <Step2Panel />
  }
}

export interface RepairSnapshotSidebarProps {
  readonly domainName: string
  readonly handleDeleteSnapshot: () => void
  readonly handleDimissRepair?: () => void
  readonly handleHideThisMessage: () => void
  readonly handleReconnect: () => void
  readonly handleReportIssue: () => void
  readonly handleRetryCapture: () => void
  readonly handleRetakeSnapshot: () => void
  readonly primaryRepairOption: PrimaryRepairOption
  readonly refreshOrCaptureState: RefreshOrCaptureState
  readonly snapshotId: string
}

export const RepairSnapshotSidebar = ({
  domainName,
  handleDimissRepair,
  handleHideThisMessage,
  handleReconnect,
  handleReportIssue,
  handleRetakeSnapshot,
  handleRetryCapture,
  primaryRepairOption,
  refreshOrCaptureState,
  snapshotId,
}: RepairSnapshotSidebarProps): JSX.Element => {
  switch (primaryRepairOption) {
    case 'captureFailed':
      return (
        <CaptureFailedRepairSidebarPanel
          domainName={domainName}
          handleHideThisMessage={handleHideThisMessage}
          handleReportIssue={handleReportIssue}
          handleRetryCapture={handleRetryCapture}
          refreshOrCaptureState={refreshOrCaptureState}
        />
      )

    case 'login':
      return (
        <ReconnectSidebarPanel
          domainName={domainName}
          handleDismissRepair={handleDimissRepair}
          handleHideThisMessage={handleHideThisMessage}
          handleReconnect={handleReconnect}
          handleReportIssue={handleReportIssue}
          handleRetakeSnapshot={handleRetakeSnapshot}
        />
      )

    case 'review':
      return (
        <RepairSnapshotOptionsSidebarPanel
          domainName={domainName}
          handleDismissRepair={handleDimissRepair}
          handleHideThisMessage={handleHideThisMessage}
          handleReconnect={handleReconnect}
          handleReportIssue={handleReportIssue}
          handleRetakeSnapshot={handleRetakeSnapshot}
          handleRetryCapture={handleRetryCapture}
          refreshOrCaptureState={refreshOrCaptureState}
          snapshotId={snapshotId}
        />
      )
  }
}
