import clsx from 'clsx'
import { IconProps } from 'react-feather'

const Connection = ({ className, size = 16 }: IconProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={clsx('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52868 1.52859C9.78903 1.26824 10.2111 1.26824 10.4715 1.52859L13.1382 4.19526C13.3985 4.45561 13.3985 4.87772 13.1382 5.13807L10.4715 7.80473C10.2111 8.06508 9.78903 8.06508 9.52868 7.80473C9.26833 7.54438 9.26833 7.12227 9.52868 6.86192L11.0573 5.33333H3.33341C2.96522 5.33333 2.66675 5.03485 2.66675 4.66666C2.66675 4.29847 2.96522 3.99999 3.33341 3.99999H11.0573L9.52868 2.4714C9.26833 2.21105 9.26833 1.78894 9.52868 1.52859Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.47132 14.4714C6.21097 14.7318 5.78886 14.7318 5.52852 14.4714L2.86185 11.8047C2.73682 11.6797 2.66659 11.5102 2.66659 11.3333C2.66659 11.1565 2.73682 10.987 2.86185 10.8619L5.52852 8.19527C5.78886 7.93492 6.21098 7.93492 6.47132 8.19527C6.73167 8.45562 6.73167 8.87773 6.47132 9.13808L4.94273 10.6667L12.6666 10.6667C13.0348 10.6667 13.3333 10.9651 13.3333 11.3333C13.3333 11.7015 13.0348 12 12.6666 12L4.94273 12L6.47132 13.5286C6.73167 13.789 6.73167 14.2111 6.47132 14.4714Z"
      />
    </svg>
  )
}

export default Connection
