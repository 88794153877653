import clsx from 'clsx'
import { format } from 'date-fns'
import { Link, useRouteMatch } from 'react-router-dom'

import {
  Document,
  DocumentCardFragment,
  PublicDocument,
  SnapshotFieldsFragment,
} from '@/generated/graphql'
import SnapshotVersionNavigationBar from '@/shared/components/SnapshotVersionNavigationBar'
import { IUseSnapshot } from '@/shared/hooks/useSnapshot'
import { SnapshotVersionHookReturnType } from '@/shared/hooks/useSnapshotVersion'
import { getContentLinkUrl, getPublicLink, useIsLinkPreview } from '@/util'

interface EmbedHeaderProps {
  content: IUseSnapshot | Document | PublicDocument
  snapshotVersion?: SnapshotVersionHookReturnType['snapshotVersion']
  snapshotVersionId?: string | null | undefined
  onChangeSnapshotVersion?: (snapshotVersionId: string | null) => void
  isNewTab?: boolean
  isFeedView?: boolean
  isUnseen?: boolean
}

// eslint-disable-next-line complexity
const EmbedHeader = ({
  content,
  isNewTab,
  isFeedView,
  isUnseen,
  snapshotVersionId,
  snapshotVersion,
  onChangeSnapshotVersion,
}: EmbedHeaderProps): JSX.Element => {
  const isLinkPreview = useIsLinkPreview()
  const isGallery = useRouteMatch([`/:organizationSlug/gallery`, `/gallery`])

  if (!content) {
    return <></>
  }

  return (
    <div
      className={clsx(
        'border-divider-light-gray bg-background-panel flex h-10 w-full items-center gap-1 border p-2',
        {
          'rounded-t': !isLinkPreview,
        },
      )}
    >
      {!isLinkPreview && !isFeedView && (
        <a
          className={clsx(
            'flex-1 select-none text-ellipsis text-xs font-semibold',
            content?.__typename === 'Snapshot' || content?.__typename === 'PublicSnapshot'
              ? 'xs:line-clamp-1 hidden'
              : 'line-clamp-1',
          )}
          href={
            isNewTab && content?.__typename === 'Snapshot'
              ? getPublicLink(
                  content,
                  content.organization.slug,
                  false,
                  snapshotVersionId === content.latestVersion?.id ? undefined : snapshotVersionId,
                )
              : getContentLinkUrl(content as DocumentCardFragment, undefined, !!isGallery)
          }
          rel={'noreferrer'}
          target={'_top'}
        >
          {content?.name}
        </a>
      )}
      {isFeedView && (
        <Link
          className={clsx(
            'flex-1 select-none text-ellipsis text-xs font-semibold',
            content?.__typename === 'Snapshot' || content?.__typename === 'PublicSnapshot'
              ? 'xs:line-clamp-1 hidden'
              : 'line-clamp-1',
          )}
          to={getContentLinkUrl(content as SnapshotFieldsFragment | DocumentCardFragment)}
        >
          {content?.name}
        </Link>
      )}

      <div className="flex items-center">
        {(content?.__typename === 'Snapshot' || content?.__typename === 'PublicSnapshot') &&
          onChangeSnapshotVersion && (
            <SnapshotVersionNavigationBar
              displayType="embed"
              snapshot={content}
              snapshotVersionId={snapshotVersionId}
              onChangeSnapshotVersion={onChangeSnapshotVersion}
              isFeedView={isFeedView}
            />
          )}
        {(content?.__typename === 'Snapshot' || content?.__typename === 'PublicSnapshot') &&
          snapshotVersion?.createdAt && (
            <div className="text-copy-secondary line-clamp-1 w-[148px] grow select-none text-ellipsis text-right text-xs">
              {format(new Date(snapshotVersion.createdAt), `p 'on' LLL dd y`)}
            </div>
          )}
      </div>
      {isUnseen && (
        <div className="bg-interactive-destructive text-base-white ml-2 rounded-[8px] px-1 text-base">
          New
        </div>
      )}
    </div>
  )
}

export default EmbedHeader
