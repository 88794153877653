import { HelpCircle } from 'react-feather'

import { SnapshotChangeSensitivity, SnapshotFieldsFragment } from '@/generated/graphql'
import { Label, SelectMenu } from '@/shared/components'
import useUpdateSnapshot from '@/shared/hooks/useUpdateSnapshot'

export const ChangeNotificationSetting = ({
  snapshot,
}: {
  snapshot: SnapshotFieldsFragment
}): JSX.Element => {
  const updateSnapshotMutation = useUpdateSnapshot(snapshot)

  const CHANGE_SENSITIVITY = [
    { label: `Don't notify me about changes`, value: SnapshotChangeSensitivity.None },
    { label: `Large changes`, value: SnapshotChangeSensitivity.Low },
    { label: `Small changes`, value: SnapshotChangeSensitivity.High },
  ]

  if (!snapshot.viewer.isOwner) {
    return <></>
  }

  return (
    <div>
      <Label
        className="w-full text-base font-semibold"
        tooltip={`We can notify you when we detect changes to your Snapshot based on the amount of change detection you specify.`}
        tooltipPlacement="top-end"
      >
        Change notifications
        <HelpCircle className="ml-1 inline" size={16} />
      </Label>

      <div className="w-full pb-1">
        <SelectMenu
          options={CHANGE_SENSITIVITY}
          onChange={(option) => {
            updateSnapshotMutation({
              changeSensitivity: option.value as SnapshotChangeSensitivity,
            })
          }}
          value={
            CHANGE_SENSITIVITY.find((item) => item.value === snapshot.changeSensitivity) ||
            CHANGE_SENSITIVITY[0]
          }
          modal
          isDropdown
        />
      </div>
    </div>
  )
}
