import { useCallback } from 'react'

import {
  SnapshotFieldsFragment,
  SnapshotStatus,
  useCaptureSnapshotMutation,
} from '@/generated/graphql'
import { getSnapshotStatus } from '@/util/snapshotHelpers'

const useRefresh = (): ((
  snapshots: SnapshotFieldsFragment[],
  unselectAllItems?: () => void,
) => void) => {
  const [captureSnapshotMutation] = useCaptureSnapshotMutation()

  return useCallback(
    (snapshots: SnapshotFieldsFragment[], unselectAllItems?: () => void) => {
      snapshots.forEach((snapshot) => {
        if (!getSnapshotStatus(snapshot).isCapturingOrRefreshingSnapshot) {
          captureSnapshotMutation({
            optimisticResponse: {
              captureSnapshot: {
                code: 'success',
                message: 'Snapshot capture initiated.',
                snapshot: {
                  __typename: 'Snapshot',
                  id: snapshot.id,
                  status: SnapshotStatus.RefreshRequested,
                },
                success: true,
              },
            },
            variables: { snapshotId: snapshot.id },
          })
        }
        if (unselectAllItems) {
          unselectAllItems()
        }
      })
    },
    [captureSnapshotMutation],
  )
}

export default useRefresh
