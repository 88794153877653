import cx from 'clsx'
import * as React from 'react'

type ContainerProps = { className?: string; children: React.ReactNode }

export const MobileDisplayOnly = ({ className, children }: ContainerProps): JSX.Element => {
  return <div className={cx('flex lg:hidden', className)}>{children}</div>
}

export const DesktopDisplayOnly = ({ className, children }: ContainerProps): JSX.Element => {
  return <div className={cx('hidden lg:flex', className)}>{children}</div>
}
