import clsx from 'clsx'
import * as React from 'react'

import { useAuth } from '@/shared/auth/useAuth'
import { Button, Input } from '@/shared/components'

type ChangePasswordPayload = {
  existingPassword: string
  newPassword: string
  newPasswordConfirm: string
}

enum UpdatePasswordErrorType {
  MissingInfo = 1,
  IncorrectExistingPassword = 2,
  MatchingExistingAndNewPassword = 3,
  NonMatchingNewPasswords = 4,
  Unknown = 5,
}

const validateForm = (formValues: ChangePasswordPayload): UpdatePasswordErrorType | undefined => {
  const { existingPassword, newPassword, newPasswordConfirm } = formValues
  if (Object.values(formValues).some((value) => !value)) {
    return UpdatePasswordErrorType.MissingInfo
  }

  if (existingPassword == newPassword) {
    return UpdatePasswordErrorType.MatchingExistingAndNewPassword
  }

  if (newPassword != newPasswordConfirm) {
    return UpdatePasswordErrorType.NonMatchingNewPasswords
  }

  return
}

const UpdatePassword = (): JSX.Element => {
  const { changePassword } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const [isPasswordChanged, setIsPasswordChanged] = React.useState(false)
  const [errorType, setErrorType] = React.useState<UpdatePasswordErrorType | null>(null)
  const [formValues, setFormValues] = React.useState<ChangePasswordPayload>({
    existingPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  })

  const onFormFieldChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormValues((prev) => ({ ...prev, [name]: value }))
  }, [])

  const validateFormCallback = React.useCallback(() => {
    if (errorType) {
      return false
    }

    const validationError = validateForm(formValues)
    if (validationError) {
      setErrorType(validationError)
    }

    return validationError
  }, [errorType, formValues])

  const onSubmit = React.useCallback(
    async (event: React.SyntheticEvent) => {
      const validationError = validateFormCallback()
      event.preventDefault()

      if (validationError) {
        return
      }

      setLoading(true)

      try {
        await changePassword(formValues.existingPassword, formValues.newPassword)
        setLoading(false)
        setErrorType(null)
        setIsPasswordChanged(true)
        setFormValues({
          existingPassword: '',
          newPassword: '',
          newPasswordConfirm: '',
        })
      } catch (err) {
        setLoading(false)
        if (!(err instanceof Error)) {
          setErrorType(UpdatePasswordErrorType.Unknown)
          return
        }
        if (err.name === 'NoSession') {
          setErrorType(UpdatePasswordErrorType.Unknown)
        }
        if (err.name === 'NotAuthorizedException') {
          setErrorType(UpdatePasswordErrorType.IncorrectExistingPassword)
        }
        setErrorType(UpdatePasswordErrorType.Unknown)
      }
    },
    [formValues, changePassword, validateFormCallback],
  )

  return (
    <div className="flex flex-col gap-3">
      <h2 className="text-lg font-semibold">Change password</h2>
      <form
        onSubmit={onSubmit}
        onChange={() => {
          setErrorType(null)
          setIsPasswordChanged(false)
        }}
        className={clsx('space-y-3', 'flex flex-col', errorType && 'text-copy-alert')}
      >
        {errorType && (
          <div className="text-xs font-semibold">{getErrorTextFromErrorType(errorType)}</div>
        )}
        {isPasswordChanged && (
          <div className="text-xs font-semibold">Your password was successfully changed</div>
        )}
        <div className="w-[240px]">
          <label htmlFor="existing-password-input" className="text-base font-semibold">
            Old password
          </label>
          <Input
            id="existing-password-input"
            name="existingPassword"
            type="password"
            value={formValues.existingPassword}
            onChange={onFormFieldChange}
            hasError={!!errorType}
          />
        </div>
        <div className="w-[240px]">
          <label htmlFor="new-password-input" className="text-base font-semibold">
            New password
          </label>
          <Input
            id="new-password-input"
            name="newPassword"
            type="password"
            value={formValues.newPassword}
            autoComplete="new-password"
            onChange={onFormFieldChange}
            hasError={!!errorType}
          />
        </div>
        <div className="w-[240px]">
          <label htmlFor="new-password-confirm-input" className="text-base font-semibold">
            Verify new password
          </label>
          <Input
            id="new-password-confirm-input"
            name="newPasswordConfirm"
            type="password"
            value={formValues.newPasswordConfirm}
            autoComplete="new-password"
            onChange={onFormFieldChange}
            hasError={!!errorType}
          />
        </div>
        <div>
          <Button
            variant="secondary"
            disabled={
              !!(
                errorType ||
                !formValues.newPassword ||
                !formValues.newPasswordConfirm ||
                !formValues.existingPassword
              )
            }
          >
            {loading ? 'Changing…' : 'Change password'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default UpdatePassword

const getErrorTextFromErrorType = (errorType: UpdatePasswordErrorType): string => {
  switch (errorType) {
    case UpdatePasswordErrorType.IncorrectExistingPassword:
      return 'Password is invalid.'
    case UpdatePasswordErrorType.MatchingExistingAndNewPassword:
      return 'New and old password cannot match.'
    case UpdatePasswordErrorType.MissingInfo:
      return 'All fields must be completed.'
    case UpdatePasswordErrorType.NonMatchingNewPasswords:
      return 'Passwords do not match.'
    case UpdatePasswordErrorType.Unknown:
    default:
      return 'We ran into an issue, please refresh and try again.'
  }
}
