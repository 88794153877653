import { useEffect } from 'react'

import { extensionClientStateVar } from '../../shared/apollo/apolloLocalState'
import {
  EXTENSION_ID_REGISTRATION_NAME,
  EXTENSION_ID_VARIABLE_NAME,
  EXTENSION_VERSION_VARIABLE_NAME,
} from '../../util/extensionId'
import { syncCognitoTokens } from '../../util/helpers'

export function useRegisterExtension() {
  useEffect(() => {
    const registeredExtensionId = window[EXTENSION_ID_VARIABLE_NAME]
    if (typeof registeredExtensionId === 'string') {
      const extensionVersion = window[EXTENSION_VERSION_VARIABLE_NAME]
      extensionClientStateVar({
        extensionRuntimeId: registeredExtensionId,
        extensionVersion,
        isExtensionInstalled: true,
      })
      // always sync the latest cognito token state whenever we register the extension
      syncCognitoTokens(registeredExtensionId)
    }

    window[EXTENSION_ID_REGISTRATION_NAME] = (id: string, version?: string) => {
      extensionClientStateVar({
        extensionRuntimeId: id,
        extensionVersion: version,
        isExtensionInstalled: true,
      })
      // always sync the latest cognito token state whenever we register the extension
      syncCognitoTokens(id)
      if (!window[EXTENSION_ID_VARIABLE_NAME]) {
        window[EXTENSION_ID_VARIABLE_NAME] = id
      }
      if (!window[EXTENSION_VERSION_VARIABLE_NAME] && version) {
        window[EXTENSION_VERSION_VARIABLE_NAME] = version
      }
    }
  }, [])
}
