import { usePopperTooltip } from 'react-popper-tooltip'

type SnapshotLiveBadgeProps = {
  tooltipText: string
}

export const SnapshotLiveBadge = ({ tooltipText }: SnapshotLiveBadgeProps): JSX.Element => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      delayShow: 300,
      placement: 'bottom',
    })

  return (
    <div className="bg-copy-alert text-copy-active flex h-4 cursor-default select-none items-center rounded-sm px-1">
      <div ref={setTriggerRef} className="text-xxs font-semibold uppercase">
        Live
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container w-[200px] text-center',
          })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {tooltipText}
        </div>
      )}
    </div>
  )
}
