import { findOrThrow } from '@plusdocs/utils/common/arrays'
import * as React from 'react'
import { useMemo } from 'react'
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Loader } from 'react-feather'

import { SnapshotViewerFragment, SnapshotViewerFragmentDoc } from '@/generated/graphql'
import { apolloCache } from '@/shared/apollo/apolloClient'
import SidebarDivider from '@/shared/components/SidebarDivider'
import {
  ObjectContent,
  ObjectPosition,
  PageObject,
  SnapshotPageObject,
} from '@/shared/types/document'
import { relativeToScaled, scaledToRelative } from '@/util/transformPositions'
import { useAppDispatch, useAppSelector } from '@/web/hooks'
import { selectCanvas, selectSelectedObjects } from '@/web/store/appState'
import {
  selectCurrentPage,
  selectDocument,
  selectObjectsOfTypeOnPage,
  updateDescription,
  updateObjectContent,
  updateObjectOrder,
  updateObjectPosition,
  updateTitle,
} from '@/web/store/document'
import { refreshSnapshot, selectAllStatuses, SnapshotState } from '@/web/store/snapshots'

import { Button, Input, Label, OwnerAttribution, TextArea } from '../../../shared/components'
import Objects from '../Page/objects'
import { OverlaySettings } from '../SnapshotSidebarSettings'

function SidebarPageSettings(): JSX.Element {
  const doc = useAppSelector(selectDocument)
  const dispatch = useAppDispatch()
  const statuses = useAppSelector(selectAllStatuses)
  const currentPage = useAppSelector(selectCurrentPage)
  const snapshotObjects = useAppSelector(
    (state) => selectObjectsOfTypeOnPage(state, 'Snapshot') as SnapshotPageObject[],
  )

  const areAnySnapshotsRefreshing = React.useMemo(() => statuses.includes('refreshing'), [statuses])

  const onClickRefreshAllSnapshots = React.useCallback(() => {
    const uniqueSnapshotIds = Array.from(new Set(snapshotObjects.map((obj) => obj.content.id)))

    uniqueSnapshotIds.forEach((snapshotId) => {
      dispatch(refreshSnapshot(snapshotId))
    })
  }, [dispatch, snapshotObjects])

  const onChangeTitle = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTitle(value))
  }
  const onChangeDescription = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateDescription(value))
  }

  const getCalculatedSnapshotCheckboxValue = (
    property: 'title' | 'timestamp' | 'domain' | 'schedule',
  ) => {
    const values = snapshotObjects.map((obj) => obj.content[property])
    return values.reduce<boolean | 'indeterminate'>((acc, curr) => {
      return acc === curr ? acc : 'indeterminate'
    }, values[0])
  }
  const setCalculatedSnapshotCheckboxValue = (
    property: 'title' | 'timestamp' | 'domain' | 'schedule',
  ) => {
    return () => {
      const editableObjects = snapshotObjects.filter((object) => {
        const data = apolloCache.readFragment<SnapshotViewerFragment>({
          fragment: SnapshotViewerFragmentDoc,
          id: `Snapshot:${object.content.id}`,
        })

        return data?.viewer?.canEdit
      })
      const checkedObjects = editableObjects.filter((object) => object.content[property] === true)
      const areAllCheckedOrUnchecked =
        checkedObjects.length === 0 || checkedObjects.length === editableObjects.length

      for (const object of editableObjects) {
        void dispatch(
          updateObjectContent({
            content: {
              [property]: areAllCheckedOrUnchecked ? !object.content[property] : true,
            },
            objectId: object.id,
            pageId: currentPage.id,
          }),
        )
      }
    }
  }

  return (
    <>
      <div>
        <Button
          variant="primary"
          className="w-full"
          onClick={onClickRefreshAllSnapshots}
          disabled={areAnySnapshotsRefreshing}
        >
          {areAnySnapshotsRefreshing ? (
            <div className="flex items-center space-x-2">
              <Loader size={16} className="motion-safe:animate-spin-slow" />
              <span>Refreshing…</span>
            </div>
          ) : (
            'Refresh all Snapshots'
          )}
        </Button>
      </div>
      <SidebarDivider />
      {doc.createdBy && (
        <OwnerAttribution
          userName={doc.createdBy.name}
          userImageUrl={doc.createdBy.image}
          userEmail={doc.createdBy.email}
        />
      )}
      <div>
        <Label htmlFor="sidebar-title-input" className="text-base font-semibold">
          Page title
        </Label>
        <Input
          id="sidebar-title-input"
          placeholder="Untitled page"
          value={doc.name ?? ''}
          onChange={onChangeTitle}
          maxLength={100}
        />
      </div>
      <div>
        <Label htmlFor="sidebar-description-input" className="mb-1 text-base font-semibold">
          Page description
        </Label>
        <TextArea
          id="sidebar-description-input"
          placeholder="Add a description"
          rows={3}
          value={doc.description ?? ''}
          onChange={onChangeDescription}
        />
      </div>
      <SidebarDivider />
      <OverlaySettings
        getCheckboxValue={getCalculatedSnapshotCheckboxValue}
        setCheckboxValue={setCalculatedSnapshotCheckboxValue}
        disabled={snapshotObjects.length === 0}
      />
    </>
  )
}

const SidebarObjectOrderSettings = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const currentPage = useAppSelector(selectCurrentPage)
  const selectedObjects = useAppSelector(selectSelectedObjects)

  const moveObject = (command: 'back' | 'backward' | 'forward' | 'front') => () => {
    void dispatch(
      updateObjectOrder({
        command,
        objectIds: selectedObjects,
        pageId: currentPage.id,
      }),
    )
  }
  return (
    <div>
      <Label htmlFor="object-order-settings" className="mb-1 block text-base font-semibold">
        Canvas order
      </Label>
      <div id="object-order-settings" className="flex flex-1 space-x-1">
        <Button
          icon={<ChevronsLeft size={16} />}
          size="standard"
          variant="secondary"
          className="flex-1"
          onClick={moveObject('back')}
          tooltip="Move to back"
        />
        <Button
          icon={<ChevronLeft size={16} />}
          size="standard"
          variant="secondary"
          className="flex-1"
          onClick={moveObject('backward')}
          tooltip="Move backward"
        />
        <Button
          icon={<ChevronRight size={16} />}
          size="standard"
          variant="secondary"
          className="flex-1"
          onClick={moveObject('forward')}
          tooltip="Move forward"
        />
        <Button
          icon={<ChevronsRight size={16} />}
          size="standard"
          variant="secondary"
          className="flex-1"
          onClick={moveObject('front')}
          tooltip="Move to front"
        />
      </div>
    </div>
  )
}

export function EditorSidebar({
  snapshots,
}: {
  snapshots: SnapshotState['snapshots']
}): JSX.Element {
  const dispatch = useAppDispatch()
  const { scalingSide } = useAppSelector(selectCanvas)
  const currentPage = useAppSelector(selectCurrentPage)
  const currentPageObjects = currentPage.objects as PageObject[]
  const selectedObjects = useAppSelector(selectSelectedObjects)
  const selectedObjectTypes = useMemo(
    () => selectedObjects.map((id) => findOrThrow(currentPageObjects, (obj) => obj.id === id).type),
    [selectedObjects, currentPageObjects],
  )
  const allObjectsOfSameType = useMemo(
    () => selectedObjectTypes.every((type) => type === selectedObjectTypes[0]),
    [selectedObjectTypes],
  )
  let renderStrategy
  if (selectedObjects.length === 0) {
    renderStrategy = 'pageSettings'
  } else if (selectedObjects.length > 0 && allObjectsOfSameType) {
    renderStrategy = 'objectSettings'
  } else {
    renderStrategy = 'mixedObjectSettings'
  }
  const CurrentObject = allObjectsOfSameType ? Objects[selectedObjectTypes[0]] : null
  const ObjectSettings = CurrentObject?.renderSettings ?? null

  const getObjectPositionProperty = (property: keyof ObjectPosition): number[] => {
    const result = selectedObjects.map((id) => {
      const object = findOrThrow(currentPageObjects, (obj) => obj.id === id)
      return property === 'z' || property === 'r'
        ? object.position[property]
        : relativeToScaled(object.position[property], scalingSide)
    })
    return result
  }
  const setObjectPositionProperty = (property: keyof ObjectPosition, value: number): void => {
    selectedObjects.forEach((id) => {
      void dispatch(
        updateObjectPosition({
          objectId: id,
          pageId: currentPage.id,
          position: {
            [property]:
              property === 'z' || property === 'r' ? value : scaledToRelative(value, scalingSide),
          },
        }),
      )
    })
  }

  function getObjectContentProperty<TProp extends keyof ObjectContent>(
    property: TProp,
  ): ObjectContent[TProp][] {
    const result = selectedObjects.map((id) => {
      const object = findOrThrow(currentPageObjects, (obj) => obj.id === id)
      return object.content[property]
    })
    return result
  }

  function setObjectContentProperty<TProp extends keyof ObjectContent>(
    property: TProp,
    value: ObjectContent[TProp],
  ): void {
    const objectsFromIds = selectedObjects.map((id) =>
      findOrThrow(currentPageObjects, (obj) => obj.id === id),
    )

    /* Snapshot properties */
    const snapshotObjects = objectsFromIds.filter((object) => object.type === 'Snapshot')

    const editableSnapshotObjects = snapshotObjects.filter((object) => {
      const data = apolloCache.readFragment<SnapshotViewerFragment>({
        fragment: SnapshotViewerFragmentDoc,
        id: `Snapshot:${object.content.id}`,
      })

      return data?.viewer?.canEdit
    })
    const checkedObjects = editableSnapshotObjects.filter(
      (object) => object.content[property] === true,
    )
    const areAllCheckedOrUnchecked =
      checkedObjects.length === 0 || checkedObjects.length === editableSnapshotObjects.length

    for (const object of editableSnapshotObjects) {
      void dispatch(
        updateObjectContent({
          content: {
            [property]: areAllCheckedOrUnchecked ? !object.content[property] : true,
          },
          objectId: object.id,
          pageId: currentPage.id,
        }),
      )
    }
    /* End Snapshot properties */

    /* Text properties */
    const textObjects = objectsFromIds.filter((object) => object.type === 'Text')

    for (const object of textObjects) {
      void dispatch(
        updateObjectContent({
          content: {
            // objectPropertyValue will only be used if the object type is a snapshot, and in the context of Pages,
            // this will only ever be setting the boolean values for Snapshot overlays.
            [property]: value,
          },
          objectId: object.id,
          pageId: currentPage.id,
        }),
      )
    }
    /* End Text properties */
  }

  return (
    <div className="border-divider-light-gray bg-background-panel md:h-viewport-minus-header h-full w-full overflow-y-auto border-l py-3 px-3 leading-4 md:w-[248px]">
      <div className="flex flex-col space-y-3">
        {renderStrategy === 'pageSettings' ? <SidebarPageSettings /> : null}
        {renderStrategy === 'objectSettings' ? (
          <>
            {ObjectSettings !== null ? (
              <ObjectSettings
                getObjectPositionProperty={getObjectPositionProperty}
                setObjectPositionProperty={setObjectPositionProperty}
                getObjectContentProperty={getObjectContentProperty}
                setObjectContentProperty={setObjectContentProperty}
                snapshots={snapshots}
              />
            ) : null}
            <SidebarObjectOrderSettings />
          </>
        ) : null}
        {renderStrategy === 'mixedObjectSettings' ? <SidebarObjectOrderSettings /> : null}
      </div>
    </div>
  )
}
