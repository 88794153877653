import { buildTour, ComponentProps } from '@plusdocs/star-tours'
import clsx from 'clsx'
import * as React from 'react'
import { X } from 'react-feather'

import { Button } from '@/shared/components'
import CaptureFailedStepOne from '@/web/assets/capture-failed-1.png'
import CaptureFailedStepTwo from '@/web/assets/capture-failed-2.png'

export const BaseRepairTourStepComponent = (
  props: ComponentProps & {
    children: React.ReactNode
    title: string
    disableNext?: boolean
  },
) => {
  return (
    <div
      className="border-divider-light-gray bg-background-white shadow-soft flex flex-col gap-y-3 rounded border p-3"
      style={{ width: 400 }}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <span className="text-copy text-lg font-semibold">{props.title}</span>
        <Button variant="ghost" onClick={props.endTour}>
          <X size={16} />
        </Button>
      </div>
      {props.children}
      <div className="grid w-full grid-flow-col grid-cols-3">
        <div className="flex flex-row items-center justify-start">
          <Button
            variant="secondary"
            onClick={props.previousStep}
            disabled={!props.hasPreviousStep}
          >
            Previous
          </Button>
        </div>
        <div className="flex flex-row items-center justify-center">
          <div className="flex flex-row gap-x-2">
            {new Array(props.totalSteps).fill('').map((_, i) => (
              <div
                key={i}
                className={clsx(
                  'h-[6px] w-[6px] rounded-full',
                  props.currentStepIndex === i ? 'bg-divider-dark-blue' : 'bg-divider-light-blue',
                )}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center justify-end">
          <Button
            variant={props.currentStepIndex + 1 === props.totalSteps ? 'primary' : 'secondary'}
            onClick={props.nextStep}
            disabled={props.disableNext}
          >
            {props.currentStepIndex + 1 === props.totalSteps ? 'Done' : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  )
}

const FirstStep = (props: ComponentProps) => {
  return (
    <BaseRepairTourStepComponent {...props} title="We couldn&rsquo;t capture this Snapshot">
      <div className="flex flex-col items-center gap-y-3">
        <img
          src={CaptureFailedStepOne}
          alt="Broken Snapshot"
          style={{ height: 160, width: 368 }}
          className="rounded"
        />
        <div className="text-base">
          Something may have gone wrong in our automated capture process, or there may have been a
          loading issue with the site you wanted to capture.
        </div>
      </div>
    </BaseRepairTourStepComponent>
  )
}

const SecondStep = (props: ComponentProps) => {
  return (
    <BaseRepairTourStepComponent {...props} title="We couldn’t capture this Snapshot">
      <div className="flex flex-col items-center gap-y-3">
        <img
          src={CaptureFailedStepTwo}
          alt="Snapshot repair sidebar"
          style={{ height: 160, width: 368 }}
          className="rounded"
        />
        <div className="flex flex-col items-center gap-y-2">
          <div className="text-base">
            You can click &ldquo;retry capture&rdquo; in the sidebar. This will restart the capture
            process in the cloud (you won&rsquo;t need to redo anything in your browser).
          </div>
          <div className="text-copy-secondary text-base">
            If issues persist,{' '}
            <a href="https://www.plusdocs.com/contact-us" className="text-interactive-primary">
              let us know
            </a>{' '}
            &mdash; Plus doesn&rsquo;t work perfectly on all sites, and we&rsquo;re eager to hear
            about issues you encounter.
          </div>
        </div>
      </div>
    </BaseRepairTourStepComponent>
  )
}

export default buildTour([
  {
    anchor: {
      type: 'screen',
    },
    component: FirstStep,
  },
  {
    anchor: {
      type: 'screen',
    },
    component: SecondStep,
  },
])
