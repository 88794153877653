import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import * as React from 'react'

import { MenuItemTestId } from '@/shared/types/testIds'

export interface IPopoverMenuItem {
  description: string
  title: string
  callback: () => void
  styles?: string
  disabled?: boolean
  testId?: MenuItemTestId
}

export const DoubleRowPopoverMenuItem = ({
  item,
}: {
  readonly item: IPopoverMenuItem
}): JSX.Element => {
  return (
    <DropdownMenuItem
      className={clsx(
        'flex w-full flex-col',
        'cursor-pointer',
        'rounded-md',
        'p-2',
        'outline-none',
        'hover:bg-interactive-secondary-hover',
        'focus:border-divider-dark-gray focus-visible:bg-interactive-secondary-hover',
        'active:bg-interactive-secondary-active',
        item.styles,
        {
          'bg-interactive-secondary !text-copy-secondary active:!text-copy-secondary !cursor-not-allowed hover:!bg-transparent active:!bg-transparent':
            item.disabled,
        },
      )}
      onSelect={() => item.callback()}
      onClick={(e) => e.stopPropagation()}
      disabled={item.disabled}
      data-testid={item.testId}
    >
      <span className="pb-1 font-semibold">{item.title}</span>
      <span className="text-copy-secondary">{item.description}</span>
    </DropdownMenuItem>
  )
}

export const constructDoubleRowPopoverMenuClassName = (className?: string) =>
  clsx(
    'font-base border-divider-light-gray bg-background-white shadow-soft rounded-md border p-2 text-base outline-none',
    className,
  )

const DoubleRowPopoverMenu = ({
  className,
  disabled = false,
  items,
  Trigger,
}: {
  disabled?: boolean
  className?: string
  items: readonly IPopoverMenuItem[]
  Trigger: React.ComponentType<{ active: boolean; disabled: boolean }>
}): JSX.Element => {
  const [isPopoverOpen, toggleIsPopoverOpen] = React.useState(false)

  return (
    <DropdownMenu onOpenChange={toggleIsPopoverOpen} modal={false} open={isPopoverOpen}>
      <DropdownMenuTrigger asChild>
        <div
          className="w-full"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <Trigger active={isPopoverOpen} disabled={disabled} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent
          align="start"
          className={constructDoubleRowPopoverMenuClassName(className)}
        >
          {items.map((item) => (
            <DoubleRowPopoverMenuItem key={item.title} item={item} />
          ))}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  )
}

export default DoubleRowPopoverMenu
