import cx from 'clsx'
import { useLayoutEffect, useRef, useState } from 'react'
import * as React from 'react'

import Input from './Input'

// Reuses the existing Input element, but with two key changes:
// 1. Sets the border to transparent, so this looks like regular text at first
// 2. Dynamically sets the width based on the value text length, by creating a span with the same classes, and calculating its width, which we then use on the input
export default function EditableTextInput(
  props: React.InputHTMLAttributes<HTMLInputElement>,
): JSX.Element {
  const { className, ...rest } = props
  const sizerRef = useRef<HTMLSpanElement>(null)
  const [width, setWidth] = useState('')
  useLayoutEffect(() => {
    if (!sizerRef.current) {
      return
    }
    setWidth(sizerRef.current.offsetWidth + 4 + 'px')
  }, [sizerRef, props.value, setWidth])
  return (
    <>
      <span
        className={cx(
          'border-divider-light-gray block rounded px-3 py-2 text-base font-semibold', // input style classes
          'pointer-events-none invisible absolute top-[-9999px] left-[-9999px] whitespace-pre', // sizer specific classes
        )}
        ref={sizerRef}
      >
        {props.value}
      </span>
      <Input className={cx('truncate border-transparent', className)} style={{ width }} {...rest} />
    </>
  )
}
