import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import {
  SlackAccountStatus,
  useGetSlackAccountStatusQuery,
  useLinkAccountToSlackMutation,
} from '@/generated/graphql'
import { Button, Loader } from '@/shared/components'
import PlusLogoWithText from '@/shared/components/PlusLogoWithText'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { useViewer } from '@/web/hooks'

// eslint-disable-next-line complexity
function SlackAccountLink(): JSX.Element {
  const { loading: viewerLoading, viewer } = useViewer()
  const params = useSearchParams()

  const [errorMessage, setErrorMessage] = useState<string>()
  const [isSuccess, setIsSuccess] = useState(false)

  const slackUserId = params.get('slackid') ?? ''
  const nonce = params.get('nonce') ?? ''

  const {
    data,
    loading: dataLoading,
    error,
  } = useGetSlackAccountStatusQuery({
    skip: !slackUserId || !nonce,
    variables: {
      nonce,
      slackUserId: slackUserId,
    },
  })

  const [linkSlackAccount, { loading: mutationLoading }] = useLinkAccountToSlackMutation()

  // URL Sanitation effect
  useEffect(() => {
    if (!slackUserId || !nonce) {
      setErrorMessage('Something went wrong.')
    }
  }, [slackUserId, nonce])

  const linkAccountCallback = useCallback(async () => {
    await linkSlackAccount({
      variables: {
        input: {
          nonce,
          slackUserId: slackUserId,
        },
      },
    }).then((response) => {
      if (response.data?.linkSlackAccount?.success) {
        setIsSuccess(true)
      } else {
        setErrorMessage(`We were not able to link your account.`)
      }
    })
    return
  }, [linkSlackAccount, nonce, slackUserId])

  return (
    <>
      <Helmet title={'Link Slack Account'} />
      <div className="m-auto flex h-screen max-w-[240px] flex-col items-center justify-center gap-0 text-center">
        {dataLoading || viewerLoading ? (
          <div className="m-auto">
            <Loader />
          </div>
        ) : (
          <>
            <PlusLogoWithText />
            {data?.viewer?.slackAccountStatus === SlackAccountStatus.Linked || isSuccess ? (
              <div className="my-6">
                <h1 className="text-base font-semibold">Success! Your account has been linked.</h1>
              </div>
            ) : (
              <div className="my-6">
                {error || errorMessage ? (
                  <div className="text-copy-alert text-base font-semibold">
                    {errorMessage || 'Something went wrong.'}
                    <br /> Please try again.
                  </div>
                ) : (
                  <>
                    <div className="text-copy-secondary text-base font-semibold">
                      {viewer?.user?.email}
                    </div>
                    <div className="text-copy text-base font-semibold">
                      You are about to connect Slack to your Plus account.{' '}
                    </div>
                  </>
                )}
              </div>
            )}
            {error ||
            errorMessage ||
            isSuccess ||
            data?.viewer?.slackAccountStatus === SlackAccountStatus.Linked ? (
              <div className="mb-4 flex gap-1">
                <a href="slack://" rel="noreferrer" target="_blank">
                  <Button variant="secondary">Open Slack</Button>
                </a>
              </div>
            ) : (
              <div className="mb-4 flex gap-1">
                <Button disabled={mutationLoading} onClick={linkAccountCallback}>
                  {mutationLoading ? 'Loading...' : 'Link your Slack account'}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default SlackAccountLink
