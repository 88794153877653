import { useReactiveVar } from '@apollo/client'

import { Maybe } from '@/generated/graphql'
import { extensionClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Button } from '@/shared/components'
import { generateNamespacedMessage, generateNamespacedName } from '@/util/extension'
import OnboardingCaptureDemo from '@/web/assets/onboarding-capture-demo.gif'
import OnboardingExtension from '@/web/assets/onboarding-extension.png'
import AsanaLogo from '@/web/assets/OnboardingLogos/asana.png'
import CalendarLogo from '@/web/assets/OnboardingLogos/calendar.png'
import GALogo from '@/web/assets/OnboardingLogos/ga.svg'
import GFLogo from '@/web/assets/OnboardingLogos/gf.png'
import GithubLogo from '@/web/assets/OnboardingLogos/github.png'
import GNLogo from '@/web/assets/OnboardingLogos/gn.svg'
import MixpanelLogo from '@/web/assets/OnboardingLogos/mixpanel.svg'
import SheetsLogo from '@/web/assets/OnboardingLogos/sheets.png'
import { useUserAgent } from '@/web/hooks/useUserAgent'

const SNAPSHOT_DESTINATIONS = [
  {
    image: CalendarLogo,
    title: 'Google Calendar',
    url: 'https://calendar.google.com/calendar/u/0/r/week',
  },
  {
    image: SheetsLogo,
    title: 'Google Sheets',
    url: 'https://docs.google.com/spreadsheets',
  },
  {
    image: MixpanelLogo,
    title: 'Mixpanel',
    url: 'https://mixpanel.com/',
  },
  {
    image: GALogo,
    title: 'Google Analytics',
    url: 'https://analytics.google.com/analytics/web',
  },
  {
    image: AsanaLogo,
    title: 'Asana',
    url: 'https://app.asana.com/0/home',
  },
  {
    image: GithubLogo,
    title: 'GitHub',
    url: 'https://github.com/',
  },
  {
    image: GFLogo,
    title: 'Google Finance',
    url: 'https://www.google.com/finance/quote/.INX:INDEXSP?hl=en',
  },
  // {
  //   image: FredLogo,
  //   title: 'Fred: CPI',
  //   url: 'https://fred.stlouisfed.org/graph/?g=8dGq',
  // },
  {
    image: GNLogo,
    title: 'Google News',
    url: 'https://news.google.com/foryou',
  },
  // {
  //   image: GNLogo,
  //   title: 'Weather',
  //   url: 'https://www.msn.com/en-us/weather/forecast/in-Seattle,WA',
  // },
]

const openOnboardingTab = (extensionRuntimeId: Maybe<string> | undefined, url: string) => {
  if (!extensionRuntimeId) return

  const port = chrome.runtime.connect(extensionRuntimeId, {
    name: generateNamespacedName('external-port'),
  })

  port.postMessage(generateNamespacedMessage('open-onboarding-tab', { url }))
  port.disconnect()
}

export const TakeSnapshotStep = () => {
  const { extensionRuntimeId } = useReactiveVar(extensionClientStateVar)
  const destinationsList = SNAPSHOT_DESTINATIONS.map((destination) => {
    return (
      <Button
        variant="secondary"
        className="!h-auto w-full !justify-start"
        key={destination.url}
        onClick={() => openOnboardingTab(extensionRuntimeId, destination.url)}
      >
        <img src={destination.image} className="mr-3 h-6 w-6 object-contain" />
        {destination.title}
      </Button>
    )
  })
  return (
    <div className="flex flex-col items-center gap-y-4">
      <div className="bg-base-highlightA shadow-dark-hard w-full rounded p-4">
        <img
          src={OnboardingCaptureDemo}
          alt="Animated gif of a user capturing a Snapshot with Plus"
          className="shadow-soft rounded"
        />
      </div>
      <div className="text-base">
        Choose a tool you check frequently from the list below. We’ll help you capture your first
        Snapshot of it.
      </div>
      <div className="grid w-full grid-cols-2 gap-3">
        {destinationsList}
        <Button
          variant="ghost"
          className="col-span-2 w-full"
          onClick={() =>
            openOnboardingTab(
              extensionRuntimeId,
              'https://www.google.com/finance/quote/.INX:INDEXSP?hl=en',
            )
          }
        >
          I don’t use any of these tools
        </Button>
      </div>
    </div>
  )
}

export const ChromeExtensionStep = () => {
  const ua = useUserAgent()
  const isChromiumLike = ua.engine.name === 'Blink'

  return (
    <div className="flex flex-col items-center gap-y-3">
      <img
        src={OnboardingExtension}
        alt="Screenshot of the Plus extension listing on the Google Chrome Web Store"
        style={{ height: 255, width: 456 }}
        className="shadow-dark-hard rounded"
      />
      <div className="self-start text-base">
        To capture Snapshots with Plus, install our Google Chrome extension.
      </div>
      <a
        href="https://chrome.google.com/webstore/detail/plus/bnebanooamokkihfjepphafoekheipfh"
        className="block w-full"
      >
        <Button className="h-12 w-full !p-6" disabled={!isChromiumLike}>
          {isChromiumLike ? 'Install the Chrome extension' : 'Switch to Chrome to install'}
        </Button>
      </a>
    </div>
  )
}
