import { Transition } from '@headlessui/react'

import { Loader } from '../../shared/components/Loader'

export function FullPageLoader({ loading }: { loading: boolean }): JSX.Element {
  return (
    <Transition
      show={loading}
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-background-canvas absolute inset-0 z-50 flex flex-col items-center overflow-hidden">
        <div className="flex h-full items-center">
          <Loader />
        </div>
      </div>
    </Transition>
  )
}
