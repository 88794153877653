import clsx from 'clsx'
import * as React from 'react'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { useAuth } from '@/shared/auth/useAuth'
import { Button, GoogleLogo, Loader, PlusLogo } from '@/shared/components'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { useViewer } from '@/web/hooks'

const LoginGoogle = (): JSX.Element => {
  const { finishLoginWithOAuth, logout, isLoggedIn, startLoginWithOAuth } = useAuth()
  const { reloadViewer } = useViewer()
  const history = useHistory()
  const location = useLocation<{
    from: { pathname: string; search: string; state: undefined | Record<string, unknown> }
    showResendConfirmation?: boolean
  }>()
  const [hasError, setHasError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const { from } = {
    from: { pathname: location.pathname || '/', search: location.search || '', state: {} },
  }
  const params = useSearchParams()
  const fromExtension = params.has('fromExtension')
  const logoutFromExtension = params.has('logoutFromExtension')

  const loginWithGoogle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.localStorage.setItem('location-state', JSON.stringify(from))
    startLoginWithOAuth()
  }

  const oAuthCode = params.get('code')
  const hasOAuthError = params.get('error')
  const oAuthErrorMessage = hasOAuthError
    ? params.get('error_description')?.replace('PreSignUp failed with error ', '')
    : ''
  const needsGoogleReauth = oAuthErrorMessage?.includes(
    'Already found an entry for username google_',
  )

  useEffect(() => {
    if (needsGoogleReauth) {
      // this only happens when linking an SSO account to a regular account
      // when we see this, we just want to do the redirect to the SSO page one more time, because the second time works
      // yes. this is the best solution. no, you cannot fix this on the backend. people have been asking AWS to fix this since 2017:
      // https://stackoverflow.com/questions/47815161/cognito-auth-flow-fails-with-already-found-an-entry-for-username-facebook-10155
      // and yet, they still haven't fixed it. sorry! thankfully, users would literally only see this once in their account's lifetime.
      return startLoginWithOAuth()
    }
    if (oAuthCode) {
      finishLoginWithOAuth(oAuthCode)
        .then(() => reloadViewer?.())
        .then((viewer) => {
          if (ReactGA.isInitialized) {
            const isSignup = typeof viewer?.data?.viewer.user?.id !== 'string'
            ReactGA.gtag('event', isSignup ? 'sign_up' : 'login', {
              method: 'Google',
            })
          }

          const localStorageLocationState = JSON.parse(
            window.localStorage.getItem('location-state') ?? '',
          ) ?? { state: {} }

          history.replace({
            ...from,
            ...localStorageLocationState,
            pathname: decodeURIComponent(params.get('route') || '') || from.pathname,
            state: {
              ...from.state,
              ...localStorageLocationState.state,
              fromExtension,
            },
          })
        })
        .catch((error: { message: string }) => {
          setHasError(true)
          setErrorMessage(error.message)
        })
    }
  }, [
    finishLoginWithOAuth,
    from,
    fromExtension,
    history,
    needsGoogleReauth,
    oAuthCode,
    reloadViewer,
    startLoginWithOAuth,
    params,
  ])

  if (logoutFromExtension) {
    logout().finally(() => {
      history.replace({
        pathname: from.pathname,
      })
    })
    return <></>
  } else if (isLoggedIn()) {
    return (
      <Redirect
        to={{
          ...from,
          state: {
            ...from.state,
            fromExtension,
          },
        }}
      />
    )
  }

  if (oAuthCode || needsGoogleReauth) {
    return (
      <div className="m-auto">
        <Loader />
      </div>
    )
  }

  return (
    <>
      <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
        <div className="flex w-full flex-col items-center justify-center gap-1">
          <PlusLogo width="48" height="48" />
          <h1 className="text-center text-xl font-semibold">Log in to Plus</h1>
        </div>
        <Button
          className="w-full gap-x-2"
          variant="secondary"
          onClick={loginWithGoogle}
          icon={<GoogleLogo />}
        >
          Continue with Google
        </Button>
      </div>
      {hasOAuthError && (
        <form
          onChange={() => setHasError(false)}
          className={clsx('space-y-3', 'flex flex-col p-6', hasError && 'text-copy-alert')}
        >
          {hasError && (
            <div className="text-copy-alert self-center text-base font-normal">{errorMessage}</div>
          )}

          {hasOAuthError && (
            <div className="text-copy-alert self-center text-base font-normal">
              {oAuthErrorMessage}
            </div>
          )}
        </form>
      )}
    </>
  )
}

export default LoginGoogle
