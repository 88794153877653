import * as React from 'react'

import Dialog from './Dialog'

type Props = {
  children: React.ReactNode
}

const Toast = (props: Props): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setIsOpen(false)
    }, 2000)
  })

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} isToast={true}>
      <div
        className="border-divider-light-gray bg-background-white my-2 mx-auto inline-flex transform justify-center overflow-hidden rounded border px-3 py-3 align-top shadow-xl outline-none transition-all"
        tabIndex={1}
      >
        {props.children}
      </div>
    </Dialog>
  )
}

export default Toast
