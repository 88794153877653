import { CheckCircle } from 'react-feather'

import { DocumentCardFragment, SnapshotFieldsFragment } from '@/generated/graphql'
import { ContentV2 } from '@/shared/types'
import { getSnapshotStatus } from '@/util/snapshotHelpers'

import Button from '../Button/Button'
import useCopyLink from './Actions/copyLink'
import useDelete from './Actions/delete'
import useMoveOrg from './Actions/moveFolder'
import useMoveTo from './Actions/moveto'
import useRefresh from './Actions/refresh'
import useRename from './Actions/rename'
import { useContentGrid } from './useContentGrid'

export const getItemCounts = (selectedItems: readonly ContentV2[]) =>
  selectedItems.reduce((previousValue, currentValue) => {
    if (currentValue.__typename === 'Folder') {
      return (
        previousValue +
        1 +
        currentValue.contentCounts.documents +
        currentValue.contentCounts.snapshots
      )
    } else {
      return previousValue + 1
    }
  }, 0)

export const BulkActionsToolbar = (): JSX.Element => {
  const context = useContentGrid()
  const moveToCallback = useMoveTo()
  const deleteCallback = useDelete()
  const refreshCallback = useRefresh()
  const renameCallback = useRename()
  const copyLinkCallback = useCopyLink()
  const moveOrgCallback = useMoveOrg()

  if (context.selectedItems.length > 0) {
    return (
      <div className="border-divider-light-gray bg-background-panel flex h-12 w-full flex-row items-center justify-between border-t">
        <div className="text-interactive-primary flex flex-row items-center justify-center pl-3 text-base font-bold tracking-tight ">
          <CheckCircle size={15} className="mr-1.5 capitalize" />
          {getItemCounts(context.selectedItems)} item
          {getItemCounts(context.selectedItems) > 1 ? 's' : ''}
        </div>
        <div className="flex flex-row items-center justify-center gap-2 pr-3">
          {context.selectedItems.filter((element) => element.__typename === 'Snapshot').length ===
            context.selectedItems.length && (
            <Button
              variant={'secondary'}
              className="my-2 h-max"
              disabled={
                context.selectedItems.filter(
                  (element) =>
                    element.__typename === 'Snapshot' &&
                    getSnapshotStatus(element).isCapturingOrRefreshingSnapshot,
                ).length === context.selectedItems.length
              }
              onClick={() =>
                refreshCallback(
                  context.selectedItems as SnapshotFieldsFragment[],
                  context.unselectAllItems,
                )
              }
            >
              Refresh
            </Button>
          )}
          {context.selectedItems.length === 1 &&
            context.selectedItems.filter(
              (element) => element.__typename === 'Snapshot' || element.__typename === 'Document',
            ).length === context.selectedItems.length && (
              <Button
                variant={'secondary'}
                className="my-2 h-max"
                onClick={() =>
                  copyLinkCallback(
                    context.selectedItems[0] as DocumentCardFragment | SnapshotFieldsFragment,
                    context.unselectAllItems,
                  )
                }
              >
                Copy link
              </Button>
            )}
          {!(context.selectedItems.filter((item) => item.__typename === 'Folder').length > 0) ? (
            <Button
              variant={'secondary'}
              className="my-2 h-max"
              onClick={() => moveToCallback(context.selectedItems, context.unselectAllItems)}
            >
              Move to...
            </Button>
          ) : (
            <Button
              variant={'secondary'}
              className="my-2 h-max"
              onClick={() =>
                moveOrgCallback(
                  context.selectedItems,
                  context.selectedItems[0].team ? 'personal' : 'team',
                  context.unselectAllItems,
                )
              }
            >
              Move to {context.selectedItems[0].team ? 'Personal' : 'Team'}
            </Button>
          )}
          {context.selectedItems.length === 1 && (
            <Button
              variant={'secondary'}
              className="my-2 h-max"
              onClick={() => renameCallback(context.selectedItems, context.unselectAllItems)}
            >
              Rename
            </Button>
          )}
          <Button
            variant={'secondary'}
            className="my-2 h-max"
            onClick={() => deleteCallback(context.selectedItems, context.unselectAllItems)}
            disabled={
              context.selectedItems.filter((element) => {
                if (element.__typename === 'Snapshot') {
                  if (getSnapshotStatus(element).isCapturingOrRefreshingSnapshot) {
                    return true
                  } else {
                    return false
                  }
                } else {
                  return false
                }
              }).length > 0
            }
          >
            Delete
          </Button>
          <Button
            variant={'secondary'}
            className="my-2 h-max"
            onClick={() => context.unselectAllItems()}
          >
            Deselect all
          </Button>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}
