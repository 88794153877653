import * as Slider from '@radix-ui/react-slider'
import { useCallback, useMemo, useRef, useState } from 'react'
import * as React from 'react'
import Cropper from 'react-easy-crop'
import { ZoomIn, ZoomOut } from 'react-feather'

import {
  UploadPurpose,
  useUploadOrganizationLogoMutation,
  useUploadUserAvatarMutation,
} from '@/generated/graphql'
import { Modal } from '@/shared/components'
import { showModal } from '@/util'

type Area = {
  width: number
  height: number
  x: number
  y: number
}

interface IProps {
  children: React.ReactNode
  onImagePicked: (image: Blob) => void
  imageWidth: number
  imageHeight: number
  shape?: 'round' | 'rect'
  wrapperClassName?: string
}

export const useImageUpload = (
  purpose: UploadPurpose.OrganizationLogo | UploadPurpose.UserAvatar,
): [
  imageUrl: string | undefined,
  setImage: (blob: Blob) => void,
  uploadImage: (id: string, newBlob?: Blob) => Promise<void>,
] => {
  const [uploadOrgImageMutation] = useUploadOrganizationLogoMutation()
  const [uploadUserImageMutation] = useUploadUserAvatarMutation()
  const [blob, setBlob] = useState<Blob>()
  const imageUrl = useMemo(() => (blob ? URL.createObjectURL(blob) : undefined), [blob])

  const uploadImage = useCallback(
    async (id: string, newBlob?: Blob) => {
      const createUploadMutation =
        purpose === UploadPurpose.OrganizationLogo
          ? uploadOrgImageMutation
          : uploadUserImageMutation

      const { data } = await createUploadMutation({
        variables: {
          id,
        },
      })

      if (data?.createUpload) {
        await fetch(data.createUpload, {
          body: newBlob ?? blob,
          method: 'PUT',
        })
      }
    },
    [blob, purpose, uploadOrgImageMutation, uploadUserImageMutation],
  )

  return [imageUrl, setBlob, uploadImage]
}

const createImageElement = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.src = url
  })

const getCroppedImage = async ({
  image,
  pixelCrop,
  width,
  height,
}: {
  image: string
  pixelCrop: Area
  width: number
  height: number
}): Promise<Blob | null> => {
  const imageEl = await createImageElement(image)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  canvas.width = imageEl.width
  canvas.height = imageEl.height
  ctx.fillStyle = '#fff'
  ctx.fillRect(0, 0, imageEl.width, imageEl.height)

  ctx.drawImage(imageEl, 0, 0)
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height)

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx.putImageData(data, 0, 0)

  return new Promise((resolve) => {
    canvas.toBlob(
      async (blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob)
          const resizedImageEl = await createImageElement(url)
          const scaledCanvas = document.createElement('canvas')
          const scaledCtx = scaledCanvas.getContext('2d')
          if (!scaledCtx) return null

          scaledCanvas.width = width
          scaledCanvas.height = height
          scaledCtx.drawImage(resizedImageEl, 0, 0, width, height)

          scaledCanvas.toBlob(
            (blob) => {
              resolve(blob)
              URL.revokeObjectURL(url)
            },
            'image/jpeg',
            0.9,
          )
        }
      },
      'image/webp',
      1,
    )
  })
}

const ImageCropModal = ({
  image,
  width,
  height,
  shape,
  onSubmit,
  onCancel,
}: {
  image: string
  width: number
  height: number
  shape: 'round' | 'rect'
  onSubmit: (img: Blob) => void
  onCancel: () => void
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [zoom, setZoom] = useState(1)

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  return (
    <Modal
      title="Crop image"
      primaryButtonText="Upload"
      secondaryButtonText="Cancel"
      onSubmit={async () => {
        if (!croppedAreaPixels) return

        const croppedImage = await getCroppedImage({
          height,
          image,
          pixelCrop: croppedAreaPixels,
          width,
        })

        if (croppedImage) {
          onSubmit(croppedImage)
        }
      }}
      onCancel={onCancel}
      variant="primary"
      content={
        <div className="flex flex-col gap-y-2">
          <div className="relative h-[256px] w-full">
            <div className="absolute top-0 bottom-0 left-0 right-0">
              <Cropper
                disableAutomaticStylesInjection
                image={image}
                crop={crop}
                zoom={zoom}
                minZoom={1}
                maxZoom={3}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                aspect={1 / 1}
                showGrid={false}
                cropShape={shape}
                onCropComplete={onCropComplete}
              />
            </div>
          </div>
          {/* @TODO – split this into Slider component in a design system */}
          <div className="flex flex-row gap-x-2">
            <ZoomOut className="text-copy" />
            <Slider.Root
              className="relative flex w-full items-center"
              min={1}
              max={3}
              step={0.01}
              value={[zoom]}
              onValueChange={(values) => setZoom(values[0])}
            >
              <Slider.Track className="bg-divider-light-gray relative h-1 flex-grow rounded-[2px]">
                <Slider.Range className="bg-divider-light-blue absolute h-full rounded-[2px]" />
              </Slider.Track>
              <Slider.Thumb className="border-divider-light-blue bg-background-white shadow-dark-hard block h-4 w-4 cursor-pointer rounded-full border outline-none" />
            </Slider.Root>
            <ZoomIn className="text-copy" />
          </div>
        </div>
      }
    />
  )
}

const ImagePicker = ({
  children,
  imageWidth,
  imageHeight,
  shape = 'rect',
  onImagePicked,
  wrapperClassName,
}: IProps): JSX.Element => {
  const filePickerRef = useRef<HTMLInputElement>(null)

  const onTriggerClicked = () => {
    filePickerRef.current?.click()
  }

  const onImageUploaded = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = ev.target.files?.[0]
    if (!fileUploaded) return

    const url = URL.createObjectURL(fileUploaded)

    showModal(
      <ImageCropModal
        image={url}
        width={imageWidth}
        height={imageHeight}
        shape={shape}
        onSubmit={(img) => {
          URL.revokeObjectURL(url)
          onImagePicked(img)
        }}
        onCancel={() => {
          URL.revokeObjectURL(url)
        }}
      />,
    )
  }

  return (
    <>
      <div className={wrapperClassName} onClick={onTriggerClicked}>
        {children}
      </div>
      <input
        type="file"
        ref={filePickerRef}
        className="hidden"
        onChange={onImageUploaded}
        multiple={false}
        accept="image/*"
      />
    </>
  )
}

export default ImagePicker
