import clsx from 'clsx'
import { useState } from 'react'
import { X } from 'react-feather'

import { Button } from '@/shared/components'

import { useUserAgent } from '../hooks/useUserAgent'

export const ExtensionBanner = (): JSX.Element => {
  const [isDismissed, dismiss] = useState(false)
  const ua = useUserAgent()
  const isChromiumLike = ua.engine.name === 'Blink'

  if (!isChromiumLike) {
    return (
      <div
        className={clsx(
          'border-divider-light-gray bg-interactive-secondary-active flex w-full flex-col content-end justify-between self-start border-b py-4 text-base font-semibold lg:py-2 lg:text-center',
          { hidden: isDismissed },
        )}
      >
        <div className="flex flex-row items-start justify-start px-4 lg:items-center lg:justify-center lg:px-4">
          <div className="flex w-full flex-col items-start justify-start gap-2 lg:flex-row lg:items-center lg:justify-center lg:gap-4">
            <div className="lg:block">Install our Google Chrome extension to capture Snapshots</div>
            <div className="flex flex-row gap-2 lg:flex-none">
              <a
                className="lg:block"
                href=""
                target="_blank"
                rel="noreferrer"
                onClick={() => dismiss(true)}
              >
                <Button
                  align="center"
                  variant="primary"
                  className="mt-0 inline-block border-none text-base"
                  disabled
                >
                  Switch to Chrome to install
                </Button>
              </a>
              <a className="block lg:hidden" onClick={() => dismiss(true)}>
                <Button align="center" variant="secondary" className="mt-0 inline-block text-base">
                  Close
                </Button>
              </a>
            </div>
          </div>
          <X className="hidden h-4 w-4 cursor-pointer lg:inline" onClick={() => dismiss(true)} />
        </div>
      </div>
    )
  } else {
    return (
      <div
        className={clsx(
          'border-divider-light-gray bg-interactive-secondary-active flex w-full flex-col content-end justify-between self-start border-b py-4 text-base font-semibold lg:py-2 lg:text-center',
          { hidden: isDismissed },
        )}
      >
        <div className="flex flex-row items-start justify-start px-4 lg:items-center lg:justify-center lg:px-4">
          <div className="flex w-full flex-col items-start justify-start gap-2 lg:flex-row lg:items-center lg:justify-center lg:gap-4">
            <div className="lg:block">
              Add our Chrome extension to take Snapshots of any website.
            </div>
            <div className="flex flex-row gap-2 lg:flex-none">
              <a
                className="lg:block"
                href="https://chrome.google.com/webstore/detail/plus/bnebanooamokkihfjepphafoekheipfh"
                target="_blank"
                rel="noreferrer"
                onClick={() => dismiss(true)}
              >
                <Button
                  align="center"
                  variant="primary"
                  className="mt-0 inline-block border-none text-base"
                >
                  Add to Chrome
                </Button>
              </a>
              <a className="block lg:hidden" onClick={() => dismiss(true)}>
                <Button align="center" variant="secondary" className="mt-0 inline-block text-base">
                  Close
                </Button>
              </a>
            </div>
          </div>
          <X className="hidden h-4 w-4 cursor-pointer lg:inline" onClick={() => dismiss(true)} />
        </div>
      </div>
    )
  }
}
