import { useMemo } from 'react'

import {
  closePopover,
  openPopover,
  selectIsPopoverOpen,
  setPopoverStatus,
  togglePopover,
} from '@/web/store/appState'

import { useAppDispatch, useAppSelector } from '.'

type IsOpen = boolean
export type Handlers = {
  openPopover: () => void
  closePopover: () => void
  togglePopover: () => void
  setPopoverStatus: (status: boolean) => void
}

const useControlledPopover = (popoverName: string): [IsOpen, Handlers] => {
  const dispatch = useAppDispatch()
  const isPopoverOpen = useAppSelector(selectIsPopoverOpen(popoverName))

  const handlers = useMemo(() => {
    return {
      closePopover: () => dispatch(closePopover(popoverName)),
      openPopover: () => dispatch(openPopover(popoverName)),
      setPopoverStatus: (status: boolean) => dispatch(setPopoverStatus([popoverName, status])),
      togglePopover: () => dispatch(togglePopover(popoverName)),
    }
  }, [dispatch, popoverName])

  return [isPopoverOpen, handlers]
}

export default useControlledPopover
