import { useReactiveVar } from '@apollo/client'
import { useStarTour } from '@plusdocs/star-tours'
import React from 'react'
import { useDrop } from 'react-dnd'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { FolderQuery, useCreateFolderMutation, useUpdateFolderMutation } from '@/generated/graphql'
import { extensionClientStateVar } from '@/shared/apollo/apolloLocalState'
import { DesktopDisplayOnly } from '@/shared/components'
import { useContentGrid } from '@/shared/components/ContentGrid/useContentGrid'
import Modal from '@/shared/components/Modal'
import { ContentV2 } from '@/shared/types'
import { generateNewPageUrl, showModal } from '@/util'
import { useViewer } from '@/web/hooks'
import useFolder from '@/web/hooks/useFolder'

import InviteTeammatesModal from '../InviteTeammatesModal'
import FeedContentFilter from './FeedContentFilter'
import FeedCreateMenu from './FeedCreateMenu'
import FeedHeaderView from './FeedHeaderView'
import { FeedSelectMenu } from './FeedSelectMenu'
import FeedSortMenu from './FeedSortMenu'
import { FeedUserAvatars } from './FeedUserAvatars'

interface IFeedHeaderContainerProps {
  hideFilter?: boolean
  hideSort?: boolean
  hideCreate?: boolean
  title: string
  isFeed?: boolean
  setIsFeed?: React.Dispatch<React.SetStateAction<boolean>>
}

const FeedHeaderContainer = ({
  hideFilter,
  hideSort,
  hideCreate,
  title,
  isFeed,
  setIsFeed,
}: IFeedHeaderContainerProps): JSX.Element => {
  const history = useHistory()
  const isGallery = useRouteMatch([
    `/:organizationSlug/gallery/:documentId`,
    `/gallery/:documentId`,
  ])
  const { activeOrganization } = useViewer()
  const { folderId, teamId } = useParams<{
    folderId?: string
    teamId?: string
  }>()
  const { folder } = useFolder(folderId)
  const createFolderCallback = useCreateFolderCallback()
  const renameFolderCallback = useRenameFolderCallback(folder)

  const { moveContentCallback } = useContentGrid()

  const { start } = useStarTour()
  const { isExtensionInstalled } = useReactiveVar(extensionClientStateVar)

  const [{ canDrop }, drop] = useDrop(
    () => ({
      accept: ['Document', 'Snapshot'],
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
      drop: (item: ContentV2) =>
        moveContentCallback(item, undefined, teamId, teamId ? 'Team' : 'Personal'),
    }),
    [teamId, moveContentCallback],
  )

  const showInviteModal = React.useCallback(() => {
    void showModal(<InviteTeammatesModal orgName={activeOrganization?.name ?? ''} />)
  }, [activeOrganization?.name])

  const getParentFolderLink = () => {
    if (isGallery) {
      return '/gallery'
    }

    if (!activeOrganization?.slug) {
      return ''
    }

    if (teamId) {
      return `/${activeOrganization.slug}/team/${teamId}`
    }

    return `/${activeOrganization.slug}`
  }

  const buttons = (
    <>
      {teamId && (
        <DesktopDisplayOnly>
          <FeedUserAvatars users={activeOrganization?.users.nodes} onClick={showInviteModal} />
        </DesktopDisplayOnly>
      )}
      {setIsFeed && <FeedSelectMenu isFeed={isFeed} setIsFeed={setIsFeed} />}
      {!hideFilter && <FeedContentFilter />}
      {!hideSort && <FeedSortMenu />}
      {!hideCreate && (
        <FeedCreateMenu
          inFolder={!!folder}
          onCreateFolder={createFolderCallback}
          onCreatePage={() => {
            history.push(generateNewPageUrl(activeOrganization?.slug || '', teamId, folderId))
          }}
          onCreateSnapshot={() => {
            if (isExtensionInstalled) {
              start('Welcome', 2)
            } else {
              start('Welcome', 1)
            }
          }}
        />
      )}
    </>
  )

  return (
    <FeedHeaderView
      buttonBarChildren={buttons}
      headingDropTarget={drop}
      headingHref={folder ? getParentFolderLink() : undefined}
      heading={title}
      isHeadingDropTargetHighlighted={canDrop}
      onSubheadingClick={renameFolderCallback}
      subheading={folder?.name}
    />
  )
}

export default FeedHeaderContainer

const useCreateFolderCallback = () => {
  const [createFolder] = useCreateFolderMutation()
  const { teamId } = useParams<{
    teamId?: string
  }>()

  return React.useCallback(() => {
    void showModal(
      <Modal
        onSubmit={(newName: string) => {
          void createFolder({
            variables: { input: { name: newName, teamId } },
          })
        }}
        primaryButtonText="Create"
        secondaryButtonText="Cancel"
        title={`Create folder`}
        useInput
        inputMaxLength={100}
        variant="primary"
      />,
    )
  }, [createFolder, teamId])
}

const useRenameFolderCallback = (folder?: FolderQuery['folder']) => {
  const [updateFolder] = useUpdateFolderMutation()

  return React.useCallback(() => {
    void showModal(
      <Modal
        defaultInputText={folder?.name}
        onSubmit={(newName: string) => {
          if (folder) {
            void updateFolder({
              variables: { id: folder.id, input: { name: newName } },
            })
          }
        }}
        primaryButtonText="Rename"
        secondaryButtonText="Cancel"
        title={`Rename Folder`}
        useInput
        inputMaxLength={100}
        variant="primary"
      />,
    )
  }, [folder, updateFolder])
}
