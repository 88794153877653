import { AlertCircle, File, Loader, RefreshCw } from 'react-feather'

import { ContentType } from '@/generated/graphql'
import SnapshotIcon from '@/shared/components/Icons/Snapshot'
import { CurrentSnapshotStatus } from '@/util/snapshotHelpers'

import { Connection } from '../Icons'

export const Trinket = ({
  type,
  currentSnapshotStatus,
}: {
  type: ContentType
  currentSnapshotStatus?: CurrentSnapshotStatus
}): JSX.Element => {
  switch (type) {
    case ContentType.Snapshot: {
      if (currentSnapshotStatus) {
        return <SnapshotTrinket currentSnapshotStatus={currentSnapshotStatus} />
      }
      return <></>
    }
    case ContentType.Document:
      return (
        <div className="absolute bottom-2 left-2 isolate">
          <div className="rounded-xs bg-background-pages flex h-6 w-6 items-center justify-center">
            <File className="text-copy-active h-4 w-4" />
          </div>
        </div>
      )

    case ContentType.Folder:
    default:
      return <></>
  }
}

const SnapshotTrinket = ({
  currentSnapshotStatus,
}: {
  currentSnapshotStatus: CurrentSnapshotStatus
}) => {
  const {
    isRefreshingSnapshot,
    isFirstCapture,
    snapshotSessionExpired,
    snapshotRefreshFailed,
    snapshotHasError,
    snapshotFirstCaptureFailed,
  } = currentSnapshotStatus

  if (isRefreshingSnapshot) {
    return (
      <div className="absolute bottom-2 left-2 isolate">
        <div className="rounded-xs bg-background-dark flex h-6 w-fit items-center justify-center gap-2 p-1">
          <RefreshCw className="animate-spin-slow text-copy-active h-4 w-4" />
          <div className="text-xxs text-copy-active">Refreshing</div>
        </div>
      </div>
    )
  }
  if (isFirstCapture) {
    return (
      <div className="absolute bottom-2 left-2 isolate">
        <div className="rounded-xs bg-background-dark flex h-6 w-fit items-center justify-center gap-2 p-1">
          <Loader className="animate-spin-slow text-copy-active h-4 w-4" />
          <div className="text-xxs text-copy-active">Capturing</div>
        </div>
      </div>
    )
  }
  if (snapshotSessionExpired) {
    return (
      <div className="absolute bottom-2 left-2 isolate">
        <div className="rounded-xs bg-interactive-destructive flex h-6 w-fit items-center justify-center gap-2 p-1">
          <Connection className="text-copy-active h-4 w-4" />
          <div className="text-xxs text-copy-active">Reconnect</div>
        </div>
      </div>
    )
  }
  if (snapshotHasError) {
    return (
      <div className="absolute bottom-2 left-2 isolate">
        <div className="rounded-xs bg-interactive-destructive flex h-6 w-fit items-center justify-center gap-2 p-1">
          <AlertCircle className="text-copy-active h-4 w-4" />
          <div className="text-xxs text-copy-active">
            {snapshotRefreshFailed || snapshotFirstCaptureFailed ? 'Capture Failed' : 'Review'}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="absolute bottom-2 left-2 isolate">
      <div className="rounded-xs bg-brand-bright flex h-6 w-6 items-center justify-center">
        <SnapshotIcon className="text-copy-active" />
      </div>
    </div>
  )
}
