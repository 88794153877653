import { useEffect } from 'react'

import {
  ContentAccessLevel,
  useGetPublicDocumentLazyQuery,
  usePagesEditorQueryLazyQuery,
} from '@/generated/graphql'
import { contentPrivacyStateVar } from '@/shared/apollo/apolloLocalState'

export const useDocument = (documentId: string, readOnly?: boolean) => {
  const [fetchDocument, { data, error, loading, refetch }] = usePagesEditorQueryLazyQuery()
  const [
    fetchPublicDocument,
    { data: publicData, error: publicError, loading: publicLoading, refetch: publicRefetch },
  ] = useGetPublicDocumentLazyQuery()
  const { accessLevel } = contentPrivacyStateVar()

  useEffect(() => {
    if (!accessLevel) return
    if (accessLevel === ContentAccessLevel.Public || readOnly) {
      fetchPublicDocument({ variables: { id: documentId } })
    } else {
      fetchDocument({ variables: { documentId } })
    }
  }, [accessLevel, fetchPublicDocument, fetchDocument, documentId, readOnly])

  const document = data?.document || publicData?.publicDocument?.document

  return {
    document,
    error: error || publicError,
    loading: !accessLevel || loading || publicLoading,
    refresh: accessLevel === ContentAccessLevel.Public ? publicRefetch : refetch,
  }
}

export default useDocument
