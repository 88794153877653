import type { FontSize, LineHeight } from '@/shared/types/document'

export const FONT_SIZES: { [key: string]: FontSize } = {
  LARGE: 64,
  MEDIUM: 36,
  SMALL: 24,
}

export const LINE_HEIGHTS: { [key: string]: LineHeight } = {
  LARGE: 1.15,
  MEDIUM: 1.15,
  SMALL: 1.5,
}
