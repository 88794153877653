import { kebabCase } from 'lodash-es'
import * as React from 'react'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { SignupSource, useCreateOrgMutation } from '@/generated/graphql'
import { activeOrganizationState } from '@/shared/apollo/apolloLocalState'
import { useAuth } from '@/shared/auth/useAuth'
import { Loader, PlusLogo } from '@/shared/components'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { capitalize } from '@/util'
import { useViewer } from '@/web/hooks'

type LoginPayload = {
  email: string
  password: string
}

declare global {
  interface Window {
    dataLayer: unknown[]
  }
}

// Route for users who are trying to install the google slides add-on
// This route captures their information in GA before sending them to the add-on install page
const Install = (): JSX.Element => {
  const [hasStartedOAuth, setHasStartedOAuth] = React.useState(false)
  const [hasSentEvent, setHasSentEvent] = React.useState(false)
  const [createOrgMutation] = useCreateOrgMutation()
  const { finishLoginWithOAuth, startLoginWithOAuth } = useAuth()
  const { reloadViewer, viewer } = useViewer()
  const history = useHistory()
  const location = useLocation<{
    from: { pathname: string; search: string; state: undefined | Record<string, unknown> }
    showResendConfirmation?: boolean
  }>()
  const [errorCode] = React.useState('')
  const [formValues] = React.useState<LoginPayload>({
    email: '',
    password: '',
  })
  const { from } = location.state || { from: { pathname: '/', search: '' } }
  const params = useSearchParams()
  const fromExtension = params.has('fromExtension')

  const oAuthCode = params.get('code')
  const hasOAuthError = params.get('error')
  const oAuthErrorMessage = hasOAuthError
    ? params.get('error_description')?.replace('PreSignUp failed with error ', '')
    : ''
  const needsGoogleReauth = oAuthErrorMessage?.includes(
    'Already found an entry for username google_',
  )

  useEffect(() => {
    if (needsGoogleReauth) {
      // this only happens when linking an SSO account to a regular account
      // when we see this, we just want to do the redirect to the SSO page one more time, because the second time works
      // yes. this is the best solution. no, you cannot fix this on the backend. people have been asking AWS to fix this since 2017:
      // https://stackoverflow.com/questions/47815161/cognito-auth-flow-fails-with-already-found-an-entry-for-username-facebook-10155
      // and yet, they still haven't fixed it. sorry! thankfully, users would literally only see this once in their account's lifetime.
      return startLoginWithOAuth()
    }

    if (hasOAuthError) {
      return history.push(
        `/preinstall?error=${hasOAuthError}&error_description=${oAuthErrorMessage}`,
      )
    }

    if (oAuthCode && !hasStartedOAuth) {
      setHasStartedOAuth(true)
      finishLoginWithOAuth(oAuthCode)
        .then(() => reloadViewer?.())
        .then((viewerData) => {
          if (!viewerData || !viewerData.data) {
            history.push('/start-trial')
          }

          if (viewerData?.data?.viewer.organizations.length === 0) {
            const gclid = JSON.parse(localStorage.getItem('gclid') || '{}')
            const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate

            const email = viewerData.data.viewer.email
            const localPart = email.split('@')[0]

            createOrgMutation({
              variables: {
                input: {
                  name: `${capitalize(localPart)}'s Organization`,
                  slug: `${kebabCase(localPart).slice(0, 35)}-${Math.random()
                    .toString(36)
                    .slice(4, 8)}`,
                  user: {
                    googleClickId: gclid && isGclidValid ? gclid.value : null,
                    name: email,
                    source: SignupSource.GoogleSlides,
                  },
                },
              },
            })
              .then(({ data }) => {
                // User switching to an org they are a member of but is not the active org
                activeOrganizationState({
                  id: data?.createOrganization.organization?.id || '',
                  slug: data?.createOrganization.organization?.slug || '',
                })

                if (!hasSentEvent) {
                  setHasSentEvent(true)
                  if (ReactGA.isInitialized) {
                    ReactGA.gtag('event', 'formSubmission', {
                      email: viewerData.data.viewer?.email,
                      method: 'Google',
                    })
                  }

                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: 'formSubmission',
                    formEmail: viewerData.data.viewer.email,
                    formType: 'add_on_form',
                  })
                }

                reloadViewer().finally(() => {
                  history.push('/start-trial')
                })
              })
              .catch(() => {
                history.push('/start-trial')
              })
          } else {
            if (!hasSentEvent) {
              setHasSentEvent(true)
              if (ReactGA.isInitialized) {
                ReactGA.gtag('event', 'formSubmission', {
                  email: viewerData?.data.viewer?.email,
                  method: 'Google',
                })
              }

              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'formSubmission',
                formEmail: viewerData?.data.viewer.email,
                formType: 'add_on_form',
              })
            }
            history.push('/start-trial')
          }
        })
        .catch(() => {
          history.push('/start-trial')
        })
    } else {
      if (!hasSentEvent) {
        setHasSentEvent(true)
        if (ReactGA.isInitialized) {
          ReactGA.gtag('event', 'formSubmission', {
            email: viewer?.email,
            method: 'Google',
          })
        }

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'formSubmission',
          formEmail: viewer?.email,
          formType: 'add_on_form',
        })
      }
    }
  }, [
    createOrgMutation,
    hasStartedOAuth,
    hasSentEvent,
    finishLoginWithOAuth,
    from,
    fromExtension,
    history,
    needsGoogleReauth,
    oAuthCode,
    reloadViewer,
    startLoginWithOAuth,
    setHasStartedOAuth,
    params,
    viewer,
    viewer?.user?.email,
  ])

  if (oAuthCode || needsGoogleReauth || (!viewer?.currentOrganization && !oAuthErrorMessage)) {
    return (
      <div className="bg-background-gridBackground flex h-screen w-screen items-center justify-center">
        <div className="m-auto">
          <Loader />
        </div>
      </div>
    )
  }

  if (errorCode === 'UserNotConfirmedException') {
    return (
      <div>
        <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <PlusLogo width="48" height="48" />
            <h1 className="text-center text-xl font-semibold">Verify account</h1>
          </div>
        </div>
        <div className="p-6 text-center text-base font-normal">
          We sent an email to {formValues.email} with a verification link. Click the button in that
          email to start using Plus.
          <div className="mt-3 font-normal">
            <Link
              className="text-interactive-primary hover:text-interactive-primary-hover flex justify-center text-base hover:underline"
              to="/login"
            >
              Back to login
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return <></>
}

export default Install
