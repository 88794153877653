import { useReactiveVar } from '@apollo/client'

import {
  ContentAccessLevel,
  useOnSnapshotUpdateSubscription,
  usePublicSnapshotDataQuery,
  useSnapshotQuery,
} from '@/generated/graphql'
import { contentPrivacyStateVar } from '@/shared/apollo/apolloLocalState'

export const useSnapshot = (snapshotId: string) => {
  const { accessLevel } = useReactiveVar(contentPrivacyStateVar)
  const { data, error, loading, refetch } = useSnapshotQuery({
    skip: accessLevel !== ContentAccessLevel.Private,
    variables: { id: snapshotId },
  })
  const {
    data: publicData,
    error: publicError,
    loading: publicLoading,
    refetch: publicRefetch,
  } = usePublicSnapshotDataQuery({
    skip: accessLevel !== ContentAccessLevel.Public,
    variables: { id: snapshotId },
  })

  const snapshot = data?.snapshot || publicData?.publicSnapshot?.snapshot

  useOnSnapshotUpdateSubscription({
    skip: snapshot?.urn === undefined || accessLevel !== ContentAccessLevel.Private,
    variables: {
      urn: snapshot?.urn ?? '',
    },
  })

  return {
    error: error || publicError,
    loading: !accessLevel || loading || publicLoading,
    refresh: accessLevel === ContentAccessLevel.Public ? publicRefetch : refetch,
    snapshot,
  }
}

export type IUseSnapshot = ReturnType<typeof useSnapshot>['snapshot']

export default useSnapshot
