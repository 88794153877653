import { ApolloQueryResult, NetworkStatus } from '@apollo/client'
import { throttle } from 'lodash-es'
import { useMemo } from 'react'

import {
  ContentType,
  SearchDocument,
  SearchFilter,
  SearchQuery,
  SearchQueryVariables,
  useContentGridSearchQuery,
  useOnContentCreatedSubscription,
  useOnContentDeletedSubscription,
  useOnContentUpdatedSubscription,
  useSearchQuery,
} from '@/generated/graphql'

interface UseSearch {
  counts?: SearchQuery['search']['counts']
  loading: boolean
  nodes: SearchQuery['search']['edges'][0]['node'][]
  refresh: () => Promise<ApolloQueryResult<SearchQuery>>
  loadMore: () => void
  loadingMore: boolean
  pageInfo?: SearchQuery['search']['pageInfo']
}

type ContentCountKey = 'snapshots' | 'documents' | 'folders'
const generateTypeCountKey = (type: string): ContentCountKey => {
  switch (type.toLowerCase()) {
    case 'document':
      return 'documents'
    case 'folder':
      return 'folders'
    case 'snapshot':
      return 'snapshots'
    default:
      throw new Error(`Unknown type when generating count key: ${type}`)
  }
}
type ContentCacheKey = 'Document' | 'Folder' | 'Snapshot'
const generateCacheKey = (type: ContentType): ContentCacheKey => {
  switch (type) {
    case ContentType.Document:
      return 'Document'
    case ContentType.Folder:
      return 'Folder'
    case ContentType.Snapshot:
      return 'Snapshot'
    default:
      throw new Error(`Unknown type when generating cache key: ${type}`)
  }
}

export const useSearchSubscriptions = (
  variables: SearchQueryVariables,
  urn?: string,
  shouldSkip?: boolean,
) => {
  const vars = useMemo(
    () => ({
      filter: variables.filter as SearchFilter,
      urn: urn ?? '',
    }),
    [variables.filter, urn],
  )

  useOnContentCreatedSubscription({
    onData({ data: subscriptionData, client }) {
      if (!subscriptionData.data?.onContentCreated) return
      client.cache.updateQuery<SearchQuery>(
        {
          query: SearchDocument,
          variables,
        },
        (data) => {
          if (!data || !subscriptionData.data?.onContentCreated) return data
          // don't update the query if the content already exists in the query (e.g. creating a new folder from the feed)
          if (
            data.search.edges.find(
              (edge) => edge.cursor === subscriptionData?.data?.onContentCreated?.cursor,
            )
          ) {
            return data
          }
          const countKey = generateTypeCountKey(
            subscriptionData.data.onContentCreated.node.__typename,
          )
          return {
            ...data,
            search: {
              ...data.search,
              counts: {
                ...data.search.counts,
                [countKey]: data.search.counts[countKey] + 1,
                total: data.search.counts.total + 1,
              },
              edges: [subscriptionData.data.onContentCreated, ...data.search.edges],
            },
          }
        },
      )
    },
    skip: shouldSkip || typeof urn === 'undefined',
    variables: vars,
  })

  // this doesn't need a custom handler since apollo will automatically cache the result via normalization
  useOnContentUpdatedSubscription({
    skip: shouldSkip || typeof urn === 'undefined',
    variables: vars,
  })

  useOnContentDeletedSubscription({
    onData({ data: subscriptionData, client }) {
      if (!subscriptionData.data?.onContentDeleted) return

      client.cache.updateQuery<SearchQuery>(
        {
          query: SearchDocument,
          variables: variables,
        },
        (data) => {
          if (!data || !subscriptionData.data?.onContentDeleted) return data
          const existingEdge = data.search.edges.find(
            (e) => e.node.id === subscriptionData.data?.onContentDeleted?.id,
          )
          const countKey = generateTypeCountKey(subscriptionData.data.onContentDeleted.contentType)
          const total = Math.max(0, data.search.counts.total - 1)
          // don't attempt to delete again if it's already gone on the client (i.e., this user deleted it)
          // we do need to update the counts though, since that isn't handled automatically
          if (!existingEdge) {
            return {
              ...data,
              search: {
                ...data.search,
                counts: {
                  ...data.search.counts,
                  [countKey]: Math.max(0, data.search.counts[countKey] - 1),
                  total,
                },
              },
            }
          }
          client.cache.evict({
            id: `${generateCacheKey(subscriptionData.data.onContentDeleted.contentType)}:${
              subscriptionData.data.onContentDeleted.id
            }`,
          })
          client.cache.evict({
            id: `SearchResultEdge:{"cursor":"${existingEdge.cursor}"}`,
          })
          const edges = data.search.edges.filter((e) => e.cursor !== existingEdge?.cursor)
          return {
            ...data,
            search: {
              ...data.search,
              counts: {
                ...data.search.counts,
                [countKey]: Math.max(0, data.search.counts[countKey] - 1),
                total,
              },
              edges,
              pageInfo: {
                ...data.search.pageInfo,
                endCursor:
                  data.search.pageInfo.endCursor === existingEdge?.cursor
                    ? edges[edges.length - 1]?.cursor ?? null
                    : data.search.pageInfo.endCursor,
              },
            },
          }
        },
      )
      client.cache.gc()
    },
    skip: shouldSkip || typeof urn === 'undefined',
    variables: vars,
  })
}

export const useContentGridSearch = (
  variables: SearchQueryVariables,
  urn?: string,
  shouldSkip?: boolean,
) => {
  const { error, data, refetch, networkStatus } = useContentGridSearchQuery({
    notifyOnNetworkStatusChange: true,
    skip: shouldSkip,
    variables: {
      contentIOwn: variables.filter?.contentIOwn,
      folders: variables.filter?.folders,
      sort: variables.sort,
      teams: variables.filter?.teams,
    },
  })

  if (error) throw error
  // set up subscription for each content type — needed so that each query in the content grid query document gets updated on changes
  const documentVariables = useMemo(
    () => ({
      ...variables,
      filter: { ...variables.filter, contentTypes: [ContentType.Document] },
    }),
    [variables],
  )
  useSearchSubscriptions(documentVariables, urn, shouldSkip)
  const folderVariables = useMemo(
    () => ({
      ...variables,
      filter: { ...variables.filter, contentTypes: [ContentType.Folder] },
    }),
    [variables],
  )
  useSearchSubscriptions(folderVariables, urn, shouldSkip)
  const snapshotVariables = useMemo(
    () => ({
      ...variables,
      filter: { ...variables.filter, contentTypes: [ContentType.Snapshot] },
    }),
    [variables],
  )
  useSearchSubscriptions(snapshotVariables, urn, shouldSkip)

  const documents = useMemo(
    () => data?.documents.edges.map((edge) => edge.node) ?? [],
    [data?.documents.edges],
  )
  const folders = useMemo(
    () => data?.folders.edges.map((edge) => edge.node) ?? [],
    [data?.folders.edges],
  )
  const snapshots = useMemo(
    () => data?.snapshots.edges.map((edge) => edge.node) ?? [],
    [data?.snapshots.edges],
  )
  return {
    counts: {
      documents: data?.documents.counts.documents,
      folders: data?.folders.counts.folders,
      snapshots: data?.snapshots.counts.snapshots,
    },
    loading:
      networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables ||
      networkStatus === NetworkStatus.refetch,
    refresh: refetch,
    results: {
      documents,
      folders,
      snapshots,
    },
  }
}

export const useSearch = (variables: SearchQueryVariables, urn?: string): UseSearch => {
  const { error, data, fetchMore, refetch, networkStatus } = useSearchQuery({
    notifyOnNetworkStatusChange: true,
    variables,
  })

  if (error) {
    throw error
  }

  useSearchSubscriptions(variables, urn)

  const pageInfo = data?.search?.pageInfo
  const nodes = useMemo(
    () => data?.search?.edges.map((edge) => edge.node) ?? [],
    [data?.search.edges],
  )
  const loadMore = useMemo(
    () =>
      throttle(() => {
        if (!pageInfo?.hasNextPage) return

        fetchMore({
          variables: {
            after: pageInfo?.endCursor,
          },
        })
      }, 1000),
    [fetchMore, pageInfo?.hasNextPage, pageInfo?.endCursor],
  )

  return {
    counts: data?.search?.counts,
    loadMore,
    loading:
      networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables ||
      networkStatus === NetworkStatus.refetch,
    loadingMore: networkStatus === NetworkStatus.fetchMore,
    nodes,
    pageInfo,
    refresh: refetch,
  }
}

export default useSearch
