import { DialogClose, DialogTitle } from '@radix-ui/react-dialog'
import { useState } from 'react'
import { X } from 'react-feather'

import { Button, Dialog } from '@/shared/components'

import SearchPane from './SearchPane'

interface SearchModalProps {
  modalChangedHandler?: (isOpen: boolean) => void
}

/**
 * React component for rendering the search experience within a modal/popup
 * @param props Props to render searchModal
 * @returns JSX Element which renders search experience inside a modal
 */
const SearchModal = (props: SearchModalProps): JSX.Element => {
  const { modalChangedHandler } = props

  const [isOpen, setIsOpen] = useState(true)

  const toggleModal = () => {
    setIsOpen(!isOpen)
    modalChangedHandler && modalChangedHandler(!isOpen)
  }

  return (
    <Dialog isOpen={isOpen} setIsOpen={toggleModal} showBackgroundOverlay={true}>
      <div className="border-base-black bg-background-white inline-block w-full max-w-[600px] transform rounded-md border p-3 text-left align-middle transition-all">
        <div className="flex h-full flex-col">
          <div className="mb-2 flex items-center justify-between">
            <DialogTitle className="text-copy text-lg font-semibold leading-8">Search</DialogTitle>
            <DialogClose asChild>
              <Button variant="ghost" onClick={toggleModal} tabIndex={-1}>
                <X size={16} />
              </Button>
            </DialogClose>
          </div>
          <SearchPane />
        </div>
      </div>
    </Dialog>
  )
}

export default SearchModal
