import { useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ContentAccessLevel, usePagesEditorCreateMutation } from '@/generated/graphql'
import { contentPrivacyStateVar } from '@/shared/apollo/apolloLocalState'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { isEmbed } from '@/util'

import { FullPageLoader } from '../components/FullPageLoader'
import generatePage from '../fixtures/DEFAULT_PAGE'
import Editor from './Editor'
import Share from './Share'

export const CreateNewPage = () => {
  const history = useHistory()
  const { organizationSlug } = useParams<{
    organizationSlug: string
  }>()
  const queryParams = useSearchParams()
  const folderId = queryParams.get('folderId')
  const teamId = queryParams.get('teamId')

  const [createPage] = usePagesEditorCreateMutation({
    onCompleted(data) {
      history.replace(`/${organizationSlug}/page/${data.createDocument.document?.id}`)
    },
    variables: {
      input: {
        description: '',
        folder: folderId || undefined,
        name: 'Untitled page',
        pages: generatePage(),
        teamId: teamId || undefined,
      },
    },
  })

  useEffect(() => {
    void createPage()
  }, [createPage])

  return <FullPageLoader loading />
}

const PageRouter = (): JSX.Element | null => {
  const { accessLevel } = useReactiveVar(contentPrivacyStateVar)
  const isEmbedded = isEmbed()

  if (accessLevel === ContentAccessLevel.Public || isEmbedded) return <Share />
  if (accessLevel === ContentAccessLevel.Private) return <Editor />
  return null
}

export default PageRouter
