import { User } from '@/generated/graphql'
import { UserImage } from '@/shared/components'

export const FeedUserAvatars = ({
  onClick,
  users,
}: {
  onClick: () => void
  users?: Partial<User>[]
}): JSX.Element => {
  if (!users || users.length === 0) return <></>

  return (
    <div className="flex flex-row" onClick={onClick}>
      {users.slice(0, 4).map((user) => (
        <UserImage
          key={user.id}
          userName={user.name}
          userImageUrl={user.image}
          userEmail={user.email}
          size="small"
          className="my-0 -ml-2 cursor-pointer"
        />
      ))}
      {users.length > 4 && (
        <div className="border-divider-light-gray bg-background-canvas text-copy-secondary relative my-0 -ml-2 flex h-[32px] w-[32px] cursor-pointer select-none items-center justify-center justify-items-center rounded-full border bg-contain text-base font-bold">
          +{users.length - 4}
        </div>
      )}
    </div>
  )
}
