import { useCallback } from 'react'

import type { FontSize } from '@/shared/types/document'
import { FONT_SIZES, LINE_HEIGHTS } from '@/web/constants'
import { useAppDispatch, useAppSelector } from '@/web/hooks'
import useControlledPopover from '@/web/hooks/useControlledPopover'
import { createObjects, selectCurrentPage } from '@/web/store/document'

import { Button } from '../../../shared/components'

const LINE_HEIGHTS_FOR_FONT_SIZE = {
  [FONT_SIZES.MEDIUM]: LINE_HEIGHTS.MEDIUM,
  [FONT_SIZES.SMALL]: LINE_HEIGHTS.SMALL,
}

const TextPopover = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const currentPage = useAppSelector(selectCurrentPage)
  const [, { closePopover }] = useControlledPopover('text')

  const addTextObjectToCanvas = useCallback(
    (fontSize: FontSize) => {
      const fontStyle = fontSize === FONT_SIZES.LARGE ? 'bold' : ''
      const lineHeight = LINE_HEIGHTS_FOR_FONT_SIZE[fontSize] ?? LINE_HEIGHTS.LARGE

      void dispatch(
        createObjects({
          objects: [
            {
              content: {
                fontFamily: 'Inter',
                fontSize,
                fontStyle,
                hasResized: false,
                horizontalAlign: 'left',
                lineHeight,
                text: '',
                textDecoration: '',
                verticalAlign: 'top',
              },
              pageId: currentPage.id,
              position: {
                h: fontSize * lineHeight,
                r: 0,
                w: 500,
                x: 10,
                y: 10,
              },
              type: 'Text',
            },
          ],
        }),
      )

      closePopover()
    },
    [dispatch, currentPage, closePopover],
  )

  return (
    <div className="bg-background-panel font-canvas-text shadow-soft ring-base-black flex w-32 flex-col rounded p-2 ring-1 ring-opacity-5">
      <Button
        variant="ghost"
        className="text-popover-large h-fit !justify-start"
        onClick={() => addTextObjectToCanvas(FONT_SIZES.LARGE)}
      >
        <div className="text-popover-large">Large</div>
      </Button>
      <Button
        variant="ghost"
        className="text-popover-medium !justify-start !font-normal"
        onClick={() => addTextObjectToCanvas(FONT_SIZES.MEDIUM)}
      >
        <div className="text-popover-medium">Medium</div>
      </Button>
      <Button
        variant="ghost"
        className="text-popover-small h-7 !justify-start !font-normal"
        onClick={() => addTextObjectToCanvas(FONT_SIZES.SMALL)}
      >
        <div className="text-popover-small">Small</div>
      </Button>
    </div>
  )
}

export default TextPopover
