import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'

import { useGalleryQuery, UserActivity } from '@/generated/graphql'
import { Button } from '@/shared/components'
import { ContentFeed } from '@/shared/components/ContentGrid/ContentFeed'
import { BaseGrid } from '@/shared/components/ContentGrid/ContentGrid'
import { ContentGridProvider } from '@/shared/components/ContentGrid/useContentGrid'
import { Loader } from '@/shared/components/Loader'
import { useTrackUserActivity } from '@/shared/hooks/useTrackUserActivity'
import { generateNewPageUrl } from '@/util'
import { noop } from '@/util/fp'
import { BasePage } from '@/web/BasePage'
import FeedHeaderContainer from '@/web/components/Feed/FeedHeaderContainer'
import { useViewer } from '@/web/hooks'

const CreatePageCTA = (): JSX.Element => {
  const history = useHistory()
  const { activeOrganization } = useViewer()

  return (
    <div className="bg-background-white border-divider-light-gray h-full w-full rounded border">
      <div className="flex h-full w-full flex-1 flex-row items-center justify-between p-3">
        <div className="w-full p-3 text-lg font-semibold">
          Want to create your own dashboards like these?
        </div>
        <Button
          className="mr-2 w-52"
          variant="secondary-dark"
          onClick={() => history.push(generateNewPageUrl(activeOrganization?.slug || ''))}
        >
          Create Page
        </Button>
      </div>
    </div>
  )
}

export const Gallery = (): JSX.Element => {
  const [isFeed, setIsFeed] = useState(true)
  const { data, loading } = useGalleryQuery()

  const nodes = data?.gallery.edges.map((edge) => edge.node) || []

  const trackUserActivity = useTrackUserActivity()
  useEffect(() => {
    trackUserActivity(UserActivity.GalleryViewed)
  }, [trackUserActivity])

  return (
    <ContentGridProvider itemsInView={nodes}>
      <BasePage>
        <Helmet title="Gallery" />
        <div className="bg-background-gridBackground relative flex h-full w-full grow flex-col content-start items-start lg:grow-0">
          <div className="absolute flex h-full w-full flex-col">
            <div className="flex w-full flex-col items-center justify-between">
              <FeedHeaderContainer
                title="Gallery"
                isFeed={isFeed}
                setIsFeed={setIsFeed}
                hideFilter={true}
                hideCreate={true}
                hideSort={true}
              />
            </div>
            <div className="flex h-full w-full flex-col items-center justify-between overflow-auto overscroll-contain scroll-smooth">
              <div className="h-full w-full lg:max-w-7xl">
                {loading && nodes.length === 0 ? (
                  <div className="flex h-full flex-row items-center">
                    <div className="m-auto">
                      <Loader />
                    </div>
                  </div>
                ) : isFeed ? (
                  <>
                    <div className="p-6 pt-0">
                      <CreatePageCTA />
                    </div>
                    <div>
                      <ContentFeed contents={[nodes]} onNodesLoaded={noop} isGallery />
                    </div>
                  </>
                ) : (
                  <BaseGrid contents={[nodes]} isGallery />
                )}
              </div>
            </div>
          </div>
        </div>
      </BasePage>
    </ContentGridProvider>
  )
}
