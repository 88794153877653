import { Popover, PopoverContent, PopoverPortal, PopoverTrigger } from '@radix-ui/react-popover'
import clsx from 'clsx'
import { useState } from 'react'
import { RefreshCw, Sliders } from 'react-feather'
import { Link } from 'react-router-dom'

import { SnapshotFieldsFragment, SnapshotStatus } from '@/generated/graphql'
import { Button, MobileDisplayOnly } from '@/shared/components'
import useRefreshSnapshot from '@/shared/hooks/useRefreshSnapshot'
import { SnapshotVersionHookReturnType } from '@/shared/hooks/useSnapshotVersion'
import useControlledPopover from '@/web/hooks/useControlledPopover'

import { ShareModalTrigger } from '../ShareModal'
import { SnapshotLiveBadge } from './SnapshotLiveBadge'
import { SnapshotMenu } from './SnapshotMenu'
import { SnapshotSidebar } from './SnapshotSidebar'

type MobileHeaderProps = {
  snapshotEditingAllowed: boolean
  deleteSnapshotCallback: () => void
  moveSnapshotCallback: () => void
  snapshot: SnapshotFieldsFragment
  snapshotVersion: SnapshotVersionHookReturnType['snapshotVersion']
}

export const MobileSnapshotHeader = ({
  snapshotEditingAllowed,
  deleteSnapshotCallback,
  moveSnapshotCallback,
  snapshot,
  snapshotVersion,
}: MobileHeaderProps): JSX.Element => {
  const [isMobileMenuOpen, mobileMenuHandlers] = useControlledPopover('mobileSnapshotMenu')

  const onClickRefreshSnapshot = useRefreshSnapshot(snapshot.id)
  const isCapturingOrRefreshingSnapshot =
    snapshot.status === SnapshotStatus.New ||
    snapshot.status === SnapshotStatus.Processing ||
    snapshot.status === SnapshotStatus.RefreshRequested

  const [isSidebarOpen, toggleSidebar] = useState(false)

  return (
    <MobileDisplayOnly className="flex w-full flex-col">
      <div className="border-divider-light-gray bg-background-panel flex h-12 w-full flex-row items-center justify-between space-x-2 border-b p-2">
        <div className="flex items-center space-x-2 text-base">
          <SnapshotMenu
            isMenuOpen={isMobileMenuOpen}
            menuPopoverHandlers={mobileMenuHandlers}
            deleteSnapshotCallback={deleteSnapshotCallback}
            moveSnapshotCallback={moveSnapshotCallback}
            snapshot={snapshot}
          />
          <div className="flex items-center space-x-2 px-0 py-2">
            <div className="flex items-center space-x-2">
              <span className="line-clamp-2 text-ellipsis text-base font-semibold">
                {snapshot?.name}
              </span>
              {snapshot?.refreshRate && !snapshotEditingAllowed && (
                <SnapshotLiveBadge tooltipText="This Snapshot updates automatically on a schedule set by the creator." />
              )}
            </div>
          </div>
        </div>

        <div
          className={clsx('flex items-center', snapshotEditingAllowed ? 'space-x-2' : 'space-x-1')}
        >
          {snapshotEditingAllowed && (
            <Button
              icon={
                <RefreshCw
                  size={16}
                  className={isCapturingOrRefreshingSnapshot ? 'animate-spin-slow' : ''}
                />
              }
              variant="ghost"
              aria-hidden="true"
              className={clsx('focus:outline-none', '!p-2', {
                '!bg-interactive-secondary-active': isCapturingOrRefreshingSnapshot,
                '!border-divider-light-blue': isCapturingOrRefreshingSnapshot,
                '!border-transparent': !isCapturingOrRefreshingSnapshot,
                '!text-interactive-primary': isCapturingOrRefreshingSnapshot,
              })}
              onClick={onClickRefreshSnapshot}
            ></Button>
          )}
          {snapshotEditingAllowed && (
            <Popover open={isSidebarOpen} onOpenChange={toggleSidebar}>
              <PopoverTrigger asChild>
                <Button
                  icon={<Sliders size={16} />}
                  variant="ghost"
                  aria-hidden="true"
                  className={clsx('focus:outline-none', {
                    '!bg-interactive-secondary-active': isSidebarOpen,
                    '!border-divider-light-blue': isSidebarOpen,
                    '!border-transparent': !isSidebarOpen,
                    '!text-interactive-primary': isSidebarOpen,
                  })}
                ></Button>
              </PopoverTrigger>
              <PopoverPortal>
                <PopoverContent
                  sideOffset={8}
                  className="h-viewport-minus-header bg-background-panel w-screen overflow-scroll overscroll-contain md:h-fit md:max-h-[800px] md:max-w-xs"
                >
                  <SnapshotSidebar snapshot={snapshot} />
                </PopoverContent>
              </PopoverPortal>
            </Popover>
          )}
          {!snapshotEditingAllowed && (
            <Link to="/register">
              <Button variant="primary">Sign up</Button>
            </Link>
          )}
          <ShareModalTrigger
            content={snapshot}
            versionInfo={
              snapshotVersion
                ? {
                    createdAt: snapshotVersion.createdAt,
                    id: snapshotVersion.id,
                  }
                : undefined
            }
          />
        </div>
      </div>
    </MobileDisplayOnly>
  )
}
