import { useEffect } from 'react'

import { useAuth } from '@/shared/auth/useAuth'
import { Loader } from '@/shared/components/Loader'
import { useSearchParams } from '@/shared/hooks/useSearchParams'

import { AuthRoutes } from './auth'

function getParam(p: string) {
  const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

function getExpiryRecord(value: string) {
  const expiryPeriod = 90 * 24 * 60 * 60 * 1000 // 90 day expiry in milliseconds

  const expiryDate = new Date().getTime() + expiryPeriod
  return {
    expiryDate: expiryDate,
    value: value,
  }
}

function addGclidToLocalStorage() {
  const gclidParam = getParam('gclid')
  let gclidRecord = null
  const gclsrcParam = getParam('gclsrc')
  const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1

  if (gclidParam && isGclsrcValid) {
    gclidRecord = getExpiryRecord(gclidParam)
    localStorage.setItem('gclid', JSON.stringify(gclidRecord))
  }
}

// Route for users who are trying to install the google slides add-on
// This route captures their information in GA before sending them to the add-on install page
const PreInstall = (): JSX.Element => {
  const { logout, startLoginWithOAuth } = useAuth()
  const params = useSearchParams()

  const maybeIdpSet = params.get('idp') as 'Google' | 'Microsoft' | null

  useEffect(() => {
    addGclidToLocalStorage()

    if (maybeIdpSet) {
      setTimeout(() => {
        void logout(false)
          .then(() => startLoginWithOAuth(true, maybeIdpSet))
          .catch(() => startLoginWithOAuth(true, maybeIdpSet))
      }, 100)
    }
  }, [logout, startLoginWithOAuth, maybeIdpSet])

  if (maybeIdpSet) {
    return (
      <div className="flex flex-row">
        <div className="bg-base-gray960 flex h-screen flex-1 flex-col items-center p-3 sm:justify-center sm:p-0">
          <div className="border-divider-light-gray bg-base-white shadow-soft w-full max-w-[400px] rounded border sm:w-72"></div>
          <div className="m-auto">
            <Loader />
          </div>
        </div>
      </div>
    )
  }

  return <AuthRoutes />
}

export default PreInstall
