import { buildTour, ComponentProps } from '@plusdocs/star-tours'

import LoggedOutStepOne from '@/web/assets/logged-out-1.png'
import LoggedOutStepTwo from '@/web/assets/logged-out-2.png'

import { BaseRepairTourStepComponent } from './CaptureFailed'

const FirstStep = (props: ComponentProps) => {
  const snapshotDomain = window.__SNAPSHOT_DOMAIN_NAME__

  return (
    <BaseRepairTourStepComponent {...props} title={`Disconnected from ${snapshotDomain}`}>
      <div className="flex flex-col items-center gap-y-3">
        <img
          src={LoggedOutStepOne}
          alt="Authentication icon"
          style={{ height: 160, width: 368 }}
          className="rounded"
        />
        <div className="flex flex-col items-center gap-y-2">
          <p className="text-base">
            It looks like Plus has been disconnected from {snapshotDomain}, preventing this Snapshot
            from refreshing.
          </p>
          <p className="text-base">
            This is normal, and can happen when you get logged out of the source website for a
            Snapshot.
          </p>
        </div>
      </div>
    </BaseRepairTourStepComponent>
  )
}

const SecondStep = (props: ComponentProps) => {
  const snapshotDomain = window.__SNAPSHOT_DOMAIN_NAME__

  return (
    <BaseRepairTourStepComponent {...props} title={`Reconnect to ${snapshotDomain}`}>
      <div className="flex flex-col items-center gap-y-3">
        <img
          src={LoggedOutStepTwo}
          alt="Snapshot logout sidebar"
          style={{ height: 160, width: 368 }}
          className="rounded"
        />
        <div className="flex flex-col items-center gap-y-2">
          <p className="text-base">
            Click &quot;Reconnect&quot; and follow the instructions on screen to repair this
            connection for all Snapshots that use it.
          </p>
          <p className="text-base">
            On rare occasions, Snapshots are incorrectly categorized as disconnected. If you think
            this is the case, click &quot;More options.&quot;
          </p>
          <p className="text-copy-secondary text-base">
            You can learn more about how connections work{' '}
            <a
              href="https://guide.plusdocs.com/snapshots-and-pages/snapshots/log-back-in"
              className="text-interactive-primary"
            >
              in our docs
            </a>
            .
          </p>
        </div>
      </div>
    </BaseRepairTourStepComponent>
  )
}

export default buildTour([
  {
    anchor: {
      type: 'screen',
    },
    component: FirstStep,
  },
  {
    anchor: {
      type: 'screen',
    },
    component: SecondStep,
  },
])
