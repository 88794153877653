import { useReactiveVar } from '@apollo/client'
import { useMemo } from 'react'
import * as React from 'react'
import { StatsigProvider as Provider, StatsigUser } from 'statsig-react'

import { ViewerNavDataQuery } from '@/generated/graphql'

import { extensionClientStateVar } from '../apollo/apolloLocalState'

const STATSIG_OPTIONS = {
  environment: { tier: import.meta.env.WEB_APP_MODE },
}
type Client = 'web-app' | 'extension' | 'google-slides'
interface StatsigUserWithCustomAttributes extends StatsigUser {
  readonly privateAttributes: {
    readonly email: string | undefined
  }
  readonly custom: {
    readonly client: Client
    readonly extensionVersion: string | undefined
    readonly organizationId: string | undefined
    readonly organizationSlug: string | undefined
  }
}
const useStatsigUser = (
  viewer: ViewerNavDataQuery['viewer'] | undefined,
  client: Client,
): StatsigUserWithCustomAttributes => {
  const { extensionVersion } = useReactiveVar(extensionClientStateVar)
  return useMemo(() => {
    return {
      custom: {
        client,
        extensionVersion: import.meta.env.EXT_VERSION ?? extensionVersion ?? undefined,
        organizationId: viewer?.currentOrganization?.id,
        organizationSlug: viewer?.currentOrganization?.slug,
      },
      privateAttributes: {
        email: viewer?.user?.email,
      },
      userID: viewer?.user?.id,
    }
  }, [viewer, client, extensionVersion])
}

export const StatsigProvider = ({
  client,
  viewer,
  children,
}: {
  client: Client
  viewer: ViewerNavDataQuery['viewer'] | undefined
  children: React.ReactNode
}): JSX.Element => {
  const user = useStatsigUser(viewer, client)

  return (
    <Provider
      sdkKey={import.meta.env.WEB_STATSIG_CLIENT_KEY}
      user={user}
      options={STATSIG_OPTIONS}
      mountKey={viewer?.user?.id ?? 'anonymous'}
    >
      {children}
    </Provider>
  )
}
