import { useCallback } from 'react'
import { CheckCircle } from 'react-feather'
import { useHistory } from 'react-router-dom'

import {
  ContentType,
  OrderDirection,
  SearchResultOrderField,
  SnapshotFieldsFragment,
  useDeleteSnapshotMutation,
  useSearchQuery,
} from '@/generated/graphql'
import { deleteFromCacheDuringMutation } from '@/shared/apollo/apolloClient'
import { Modal, Toast } from '@/shared/components'
import { useViewer } from '@/shared/hooks/useViewer'
import { showModal } from '@/util'

const generateBackToDashboardLink = (
  teamId?: string,
  folderId?: string,
  activeOrgSlug?: string,
) => {
  const teamPath = teamId ? `/team/${teamId}` : ''
  const folderPath = folderId ? `/folders/${folderId}` : ''

  if (!activeOrgSlug) {
    return '/'
  }

  return `/${activeOrgSlug}${teamPath}${folderPath}`
}

export const useSnapshotDelete = (snapshot: SnapshotFieldsFragment) => {
  const { activeOrganization } = useViewer()
  const activeOrgSlug = activeOrganization?.slug
  const currentSnapshotId = snapshot.id
  const folderId = snapshot.folder?.id
  const teamId = snapshot.team?.id
  const snapshotName = snapshot.name

  const { data } = useSearchQuery({
    fetchPolicy: 'cache-only',
    variables: {
      filter: {
        // TODO: Remove once graphQL teams-safety is added
        contentIOwn: teamId ? false : true,
        contentTypes: [ContentType.Snapshot],
        folders: folderId ? [folderId] : [],
        teams: teamId ? [teamId] : [],
      },
      sort: {
        direction: OrderDirection.Desc,
        field: SearchResultOrderField.CreatedAt,
      },
    },
  })

  const history = useHistory()
  const [deleteSnapshot] = useDeleteSnapshotMutation()

  return useCallback(() => {
    const nodes = data?.search.edges.map(({ node }) => node) ?? []
    void showModal(
      <Modal
        variant="destructive"
        title={`Delete "${snapshotName}"?`}
        content="The Snapshot will be permanently deleted."
        primaryButtonText="Delete"
        onSubmit={() => {
          if (nodes.length === 1) {
            const backLink = generateBackToDashboardLink(teamId, folderId, activeOrgSlug)

            history.replace(backLink)
          } else {
            const snapshotIndex = nodes.findIndex((snap) => snap.id === currentSnapshotId)
            const remainingNodes = nodes.filter((snap) => snap.id !== currentSnapshotId)
            const nextIndex =
              snapshotIndex < remainingNodes.length ? snapshotIndex : snapshotIndex - 1
            const nextSnapshot = remainingNodes[nextIndex]

            void showModal(
              <Toast>
                <CheckCircle size={15} className="text-interactive-primary mr-2 capitalize" />
                <span className="text-base">{`Deleted "${snapshotName}"`}</span>
              </Toast>,
            )

            setTimeout(() => {
              if (activeOrgSlug) {
                history.replace(`/${activeOrgSlug}/snapshot/${nextSnapshot.id}`)
              }
            }, 1000)
          }

          void deleteSnapshot({
            update: deleteFromCacheDuringMutation({
              __typename: 'Snapshot',
              id: currentSnapshotId,
            }),
            variables: {
              id: currentSnapshotId,
            },
          })
        }}
        secondaryButtonText="Cancel"
      />,
    )
  }, [
    activeOrgSlug,
    currentSnapshotId,
    data?.search.edges,
    deleteSnapshot,
    folderId,
    history,
    snapshotName,
    teamId,
  ])
}
