import clsx from 'clsx'
import { debounce } from 'lodash-es'
import { useEffect, useState } from 'react'
import { Check } from 'react-feather'

import {
  OrganizationImageFragmentDoc,
  UploadPurpose,
  useUpdateOrgMutation,
} from '@/generated/graphql'
import { apolloCache } from '@/shared/apollo/apolloClient'
import { Checkbox, Input, OrgImage } from '@/shared/components'
import ImagePicker, { useImageUpload } from '@/web/components/ImagePicker'
import { useViewer } from '@/web/hooks'

const EditOrganization = (): JSX.Element => {
  const { activeOrganization, viewer } = useViewer()
  const [updateOrg] = useUpdateOrgMutation()

  const [orgName, setOrgName] = useState<string>(activeOrganization?.name || '')
  useEffect(() => {
    if (activeOrganization?.name) {
      setOrgName(activeOrganization.name)
    }
  }, [activeOrganization?.name])
  const [showSaved, setShowSaved] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')
  const [joinByDomain, setJoinByDomain] = useState<boolean>(
    activeOrganization?.joinByDomain ?? false,
  )
  useEffect(() => {
    if (activeOrganization?.joinByDomain) {
      setJoinByDomain(activeOrganization.joinByDomain ?? false)
    }
  }, [activeOrganization?.joinByDomain])

  const persistNameChange = debounce(async (name: string) => {
    await updateOrg({
      variables: { id: activeOrganization?.id || '', input: { name } },
    })
    setShowSaved(true)
  }, 250)

  const onNameChange = (name: string) => {
    setShowSaved(false)
    setOrgName(name)
    setErrorMessage(undefined)
  }

  const [imageUrl, setImage, uploadImage] = useImageUpload(UploadPurpose.OrganizationLogo)

  const onImagePicked = async (blob: Blob) => {
    setImage(blob)
    if (activeOrganization?.id) {
      const url = URL.createObjectURL(blob)
      // we update the cache with the blob URL, since the API takes a few seconds to update the associated image after its uploaded
      // due to it listening for the s3 creation event, which takes a few seconds to fire. we want to be instantly updated!
      // the next time there's a viewer nav data query, it'll get the new URL, and it'll be all good
      apolloCache.writeFragment({
        data: {
          image: url,
        },
        fragment: OrganizationImageFragmentDoc,
        id: `Organization:${activeOrganization.id}`,
      })
      await uploadImage(activeOrganization.id, blob)
    }
  }

  return (
    <div className="border-divider-light-gray flex flex-col gap-3 border-b p-6">
      <h1 className="text-lg font-semibold">Edit organization</h1>
      {errorMessage && (
        <div className="mb-5 self-center">
          <h1 className="text-interactive-destructive m-auto">{errorMessage}</h1>
        </div>
      )}
      <div className="flex flex-col gap-2">
        <div className="text-xs font-semibold">Logo</div>
        <ImagePicker
          onImagePicked={onImagePicked}
          imageWidth={512}
          imageHeight={512}
          shape="rect"
          wrapperClassName="inline-block"
        >
          <OrgImage
            size="large"
            orgName={orgName}
            orgLogo={imageUrl ?? activeOrganization?.image}
            editable
          />
        </ImagePicker>
      </div>
      <div className="w-[240px]">
        <label
          htmlFor="name-input"
          className={clsx([
            errorMessage && 'text-interactive-destructive',
            'text-xs font-semibold',
          ])}
        >
          Name
        </label>
        <div className="flex w-80 flex-row items-center gap-2">
          <Input
            id="name-input"
            name="name"
            onChange={(x) => onNameChange(x.target.value)}
            onBlur={() => void persistNameChange(orgName)}
            placeholder="e.g., CarpCorp"
            value={orgName}
            hasError={!!errorMessage}
          />
          <div
            className={clsx(
              'text-interactive-primary flex flex-row text-base transition-opacity duration-150',
              showSaved ? 'opacity-100' : 'opacity-0',
            )}
          >
            <Check size={16} />
            &nbsp;
            <span>Name saved</span>
          </div>
        </div>
      </div>
      {viewer?.hasPublicEmailDomain && (
        <Checkbox
          className="cursor-pointer"
          checked={joinByDomain}
          onChange={(ev) => {
            setJoinByDomain(ev.target.checked)
            void updateOrg({
              variables: {
                id: activeOrganization?.id ?? '',
                input: { joinByDomain: ev.target.checked },
              },
            })
          }}
        >
          Allow users from @{viewer?.email.split('@')[1].toLowerCase()} to join automatically. If
          you’re on a paid plan, you will be billed for additional members when they join.
        </Checkbox>
      )}
    </div>
  )
}

export default EditOrganization
