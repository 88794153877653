import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@radix-ui/react-popover'
import * as React from 'react'
import { Edit, Menu, Search, Settings, Users, X } from 'react-feather'
import { Link, useParams, useRouteMatch } from 'react-router-dom'

import {
  ContentGridSearchDocument,
  SearchDocument,
  useMoveContentToTeamMutation,
} from '@/generated/graphql'
import { Button, PlusLogoWithText } from '@/shared/components'
import { showModal } from '@/util'
import { useViewer } from '@/web/hooks'
import { useContentDrop } from '@/web/hooks/useContentDrop'

import InviteTeammatesModal from './InviteTeammatesModal'
import MobileOrgNavMenu from './OrgNav/MobileOrgNavMenu'

enum WebAppRoutes {
  Personal = '/',
  Settings = '/settings',
  Team = '/team',
  Unknown = '',
}

interface IMobileHeaderProps {
  searchClickHandler: (showSearch: boolean) => void
}

const MobileHeader = (props: IMobileHeaderProps): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [isOrgSubMenuOpen, setIsOrgSubMenuOpen] = React.useState(false)

  const { searchClickHandler } = props

  const showSearchOnClickHandler = React.useCallback(() => {
    searchClickHandler(true)
  }, [searchClickHandler])

  const [currentActivePage, setCurrentActivePage] = React.useState<WebAppRoutes>(
    WebAppRoutes.Unknown,
  )

  const { activeOrganization } = useViewer()

  const [moveToTeam] = useMoveContentToTeamMutation({
    refetchQueries: [SearchDocument, ContentGridSearchDocument],
  })

  const showInviteModal = React.useCallback(() => {
    showModal(<InviteTeammatesModal orgName={activeOrganization?.name || ''} />)
  }, [activeOrganization])

  useCurrentRouteEffect(setCurrentActivePage)

  const [canDropPersonal, dropPersonal] = useContentDrop(
    (item) =>
      moveToTeam({
        variables: {
          id: item.id,
        },
      }),
    () => currentActivePage !== WebAppRoutes.Personal,
  )

  const [canDropTeam, dropTeam] = useContentDrop(
    (item) =>
      moveToTeam({
        variables: {
          id: item.id,
          teamId: activeOrganization?.teams.nodes[0].id,
        },
      }),
    () => currentActivePage !== WebAppRoutes.Team,
  )

  return (
    <div className="flex w-full items-center justify-between p-2">
      <Popover open={isMenuOpen} onOpenChange={setIsMenuOpen}>
        <PopoverTrigger asChild>
          <Button variant="ghost" icon={isMenuOpen ? <X size="16px" /> : <Menu size="16px" />} />
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent
            className="h-viewport-minus-header border-divider-light-gray bg-background-white w-screen border-t p-3"
            alignOffset={-16}
            side="bottom"
            sideOffset={8}
          >
            <MobileOrgNavMenu
              open={isOrgSubMenuOpen}
              onOpenChange={setIsOrgSubMenuOpen}
              onMenuOpenChange={setIsMenuOpen}
            />
            <PopoverClose asChild>
              <Button
                align="center"
                variant="primary"
                className="mt-3 inline-block w-full text-base"
                onClick={showInviteModal}
              >
                Invite teammates
              </Button>
            </PopoverClose>
            <Link ref={dropPersonal} to={`/${activeOrganization?.slug}${WebAppRoutes.Personal}`}>
              <PopoverClose asChild>
                <Button
                  align="left"
                  variant="ghost"
                  icon={<Edit size="16px" className="mr-2" />}
                  className="mt-3 inline-block w-full text-base"
                  active={currentActivePage === WebAppRoutes.Personal || canDropPersonal}
                >
                  Personal
                </Button>
              </PopoverClose>
            </Link>
            {/* Today only one team exists per org so we can just access the first element */}
            <Link
              ref={dropTeam}
              to={`/${activeOrganization?.slug}${WebAppRoutes.Team}/${activeOrganization?.teams.nodes[0].id}`}
            >
              <PopoverClose asChild>
                <Button
                  align="left"
                  variant="ghost"
                  icon={<Users size="16px" className="mr-2" />}
                  className="mt-3 inline-block w-full text-base"
                  active={currentActivePage === WebAppRoutes.Team || canDropTeam}
                >
                  Team
                </Button>
              </PopoverClose>
            </Link>
            <Link to={`/${activeOrganization?.slug}${WebAppRoutes.Settings}/personal`}>
              <PopoverClose asChild>
                <Button
                  align="left"
                  variant="ghost"
                  icon={<Settings size="16px" className="mr-2" />}
                  className="mt-3 inline-block w-full text-base"
                  active={currentActivePage === WebAppRoutes.Settings}
                >
                  Settings
                </Button>
              </PopoverClose>
            </Link>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
      <Link to={`/`}>
        <PlusLogoWithText width="74" height="24" />
      </Link>
      <Button variant="ghost" onClick={showSearchOnClickHandler} icon={<Search size="16px" />} />
    </div>
  )
}
export default MobileHeader

/**
 * Effect used to determine the current active page to set correct sidebar link to active
 * @param setCurrentActivePage setState method to update active page state
 */
const useCurrentRouteEffect = (setCurrentActivePage: React.Dispatch<WebAppRoutes>): void => {
  let matchedRoute = WebAppRoutes.Unknown
  const { path } = useRouteMatch()
  const { teamId } = useParams<{ teamId?: string }>()

  if (path.includes(WebAppRoutes.Settings)) {
    matchedRoute = WebAppRoutes.Settings
  } else if (teamId) {
    matchedRoute = WebAppRoutes.Team
  } else {
    matchedRoute = WebAppRoutes.Personal
  }

  React.useEffect(() => {
    setCurrentActivePage(matchedRoute)
  }, [matchedRoute, setCurrentActivePage])
}
