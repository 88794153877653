import { useEffect, useState } from 'react'
import WebFont from 'webfontloader'

const useLoadPagesFonts = (): boolean => {
  const [fontsLoading, setFontsLoading] = useState(true)
  useEffect(() => {
    if (!fontsLoading) return
    new Promise<void>((active) => {
      WebFont.load({
        active,
        google: {
          families: ['Lato:400,400i,700,700i:latin'],
        },
      })
    }).then(() => {
      setFontsLoading(false)
    })
  }, [fontsLoading, setFontsLoading])

  return fontsLoading
}

export default useLoadPagesFonts
