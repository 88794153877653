import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import { forwardRef } from 'react'
import { Loader, RefreshCw, XCircle } from 'react-feather'

import { SnapshotFieldsFragment } from '@/generated/graphql'
import SnapshotIcon from '@/shared/components/Icons/Snapshot'
import { getSnapshotStatus } from '@/util/snapshotHelpers'

import { Connection } from '../Icons'

export interface IThumbnailProps {
  readonly snapshot: SnapshotFieldsFragment
  readonly isSelected: boolean
  readonly onSelect: () => void
}

const tooltipStatus = (snapshot: SnapshotFieldsFragment) => {
  const {
    isRefreshingSnapshot,
    isFirstCapture,
    snapshotSessionExpired,
    snapshotRefreshFailed,
    snapshotHasError,
  } = getSnapshotStatus(snapshot)

  if (snapshotSessionExpired) {
    return 'Reconnect'
  } else if (isRefreshingSnapshot) {
    return 'Refreshing'
  } else if (isFirstCapture) {
    return 'Capturing'
  } else if (snapshotHasError) {
    return snapshotRefreshFailed ? 'Review' : 'Capture Failed'
  }
}

const ThumbnailImage = ({ snapshot, isSelected }: Partial<IThumbnailProps>) => {
  if (snapshot) {
    const {
      isRefreshingSnapshot,
      isFirstCapture,
      snapshotSessionExpired,
      snapshotRefreshFailed,
      snapshotHasError,
    } = getSnapshotStatus(snapshot)

    if (isRefreshingSnapshot) {
      return (
        <>
          <RefreshCw
            className={clsx(
              'animate-spin-slow absolute h-4 w-4 opacity-100',
              isSelected ? 'text-interactive-primary' : 'text-copy-secondary',
            )}
          />
          <img
            className={clsx('max-h-full max-w-full opacity-20')}
            src={snapshot.currentVersion?.image as string}
          />
        </>
      )
    } else if (isFirstCapture) {
      return (
        <Loader
          className={clsx(
            'animate-spin-slow h-4 w-4',
            isSelected ? 'text-interactive-primary' : 'text-copy-secondary',
          )}
        />
      )
    } else if (snapshotSessionExpired) {
      return (
        <>
          <Connection
            className={clsx(
              'absolute h-4 w-4 opacity-100',
              isSelected ? 'text-interactive-primary' : 'text-copy-secondary',
            )}
          />
          <img
            className={clsx('max-h-full max-w-full opacity-20')}
            src={snapshot.currentVersion?.image as string}
          />
        </>
      )
    } else if (snapshotHasError || snapshotRefreshFailed) {
      return (
        <>
          <XCircle
            className={clsx(
              'absolute h-4 w-4 opacity-100',
              isSelected ? 'text-interactive-primary' : 'text-copy-secondary',
            )}
          />
          <img
            className={clsx('max-h-full max-w-full opacity-20')}
            src={snapshot.currentVersion?.image as string}
          />
        </>
      )
    } else {
      return (
        <img
          className={clsx(
            'max-h-full max-w-full opacity-80 group-hover:opacity-100 group-focus-visible:opacity-100 group-active:opacity-100',
            {
              '!opacity-100': isSelected,
            },
          )}
          src={snapshot.currentVersion?.image as string}
        />
      )
    }
  } else {
    return (
      <SnapshotIcon
        className={clsx(
          'h-4 w-4 ',
          isSelected ? 'text-interactive-primary' : 'text-copy-secondary',
        )}
      />
    )
  }
}

export const LoadingThumbnail = () => (
  <div className="border-divider-light-gray bg-divider-light-gray relative flex h-12 w-12 animate-pulse items-center justify-between rounded border" />
)

export const Thumbnail = forwardRef<HTMLDivElement, IThumbnailProps>(
  ({ snapshot, isSelected, onSelect }: IThumbnailProps, ref): JSX.Element => {
    const status = tooltipStatus(snapshot)
    return (
      <TooltipProvider>
        <Tooltip delayDuration={300}>
          <TooltipTrigger asChild>
            <div
              ref={ref}
              id={snapshot.id}
              tabIndex={0}
              onClick={onSelect}
              className={clsx(
                'group',
                'relative flex h-12 w-12 items-center justify-between rounded border',
                'border-divider-light-gray active:border-divider-light-blue',
                'focus-visible:border-divider-dark-blue focus:outline-none focus-visible:outline-none',
                {
                  '!border-divider-light-blue !bg-interactive-selected focus-visible:!border-divider-dark-blue':
                    isSelected,
                },
                'bg-background-white hover:bg-background-canvas focus-visible:bg-background-canvas active:bg-background-canvas',
                'hover:cursor-pointer active:cursor-auto',
              )}
            >
              <div className="absolute inset-0 flex items-center justify-center p-[3px]">
                <ThumbnailImage snapshot={snapshot} isSelected={isSelected} />
              </div>
            </div>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent
              side="right"
              className="z-max bg-background-dark text-copy-active shadow-dark-hard flex max-w-xs flex-row items-center gap-2 rounded p-2 text-center text-xs font-normal"
            >
              {status ? `${status} · ${snapshot?.name}` : snapshot?.name}
              <TooltipArrow className="fill-background-dark" />
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </TooltipProvider>
    )
  },
)
