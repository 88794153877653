import { Button, PlusLogo } from './'

export default function MaintenanceScreen() {
  return (
    <div className="bg-base-gray960 flex h-screen flex-col items-center justify-center p-3 sm:p-0">
      <div className="border-divider-light-gray bg-base-white shadow-soft w-full max-w-[288px] rounded border">
        <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <PlusLogo width="48" height="48" />
            <h1 className="text-center text-xl font-semibold">Down for maintenance</h1>
          </div>
        </div>
        <div className="flex flex-col gap-y-3 p-6">
          <div className="text-center text-xs">
            Plus is currently down for maintenance. <br /> You can track our status at the link
            below.
          </div>
          <a href="https://status.plusdocs.com" target="_blank" rel="noreferrer">
            <Button className="w-full">View status</Button>
          </a>
        </div>
      </div>
    </div>
  )
}
