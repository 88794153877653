import * as React from 'react'
import { CheckCircle } from 'react-feather'

import {
  ContentGridSearchDocument,
  SearchDocument,
  SnapshotDocument,
  useMoveContentToTeamMutation,
  useMoveToFolderMutation,
} from '@/generated/graphql'
import { ContentV2 } from '@/shared/types'
import { showModal } from '@/util'
import { useKeyboardShortcuts } from '@/web/hooks'

import Toast from '../Toast'
import { getItemCounts } from './BulkActionsToolbar'

export interface IContentGridContext {
  readonly toggleItemSelect: (item: ContentV2) => void
  readonly unselectAllItems: () => void
  readonly isItemSelected: (item: ContentV2) => boolean
  readonly selectedItems: readonly ContentV2[]
  readonly itemsInView: readonly ContentV2[]
  readonly shiftAction: (itemsInView: readonly ContentV2[], item: ContentV2) => void
  readonly moveContentCallback: (
    item: ContentV2,
    folderId?: string,
    teamId?: string,
    folderName?: string,
  ) => Promise<void>
}

export const useContentGridProvider = (): IContentGridContext => {
  const [selectedItems, setSelectedItems] = React.useState([] as ContentV2[])
  const [lastSelected, setLastSelected] = React.useState({} as ContentV2)

  const [moveToFolder] = useMoveToFolderMutation({
    refetchQueries: [
      SearchDocument,
      ContentGridSearchDocument,
      SnapshotDocument,
      /* PagesEditorQueryDocument */ // uncomment PagesEditorQueryDocument when we move all of Pages into GQL
    ],
  })

  const [moveToTeam] = useMoveContentToTeamMutation({
    refetchQueries: [SearchDocument, ContentGridSearchDocument],
  })

  const isItemSelected = React.useCallback(
    (item: ContentV2) => {
      return !!selectedItems.find((selectedItem) => selectedItem.id === item.id)
    },
    [selectedItems],
  )

  const findItemIndex = React.useCallback(
    (item: ContentV2) => {
      return selectedItems.findIndex((selectedItem) => selectedItem.id === item.id)
    },
    [selectedItems],
  )

  const selectItem = React.useCallback(
    (item: ContentV2) => {
      const index = findItemIndex(item)
      if (index == -1) {
        setSelectedItems((items) => [...items, item])
        setLastSelected(item)
      }
    },
    [findItemIndex],
  )

  const unselectItem = React.useCallback(
    (item: ContentV2) => {
      setSelectedItems(selectedItems.filter((element) => element.id !== item.id))
    },
    [selectedItems],
  )

  const toggleItemSelect = React.useCallback(
    (item: ContentV2) => {
      const index = findItemIndex(item)
      if (index >= 0) {
        unselectItem(item)
      } else {
        selectItem(item)
      }
    },
    [findItemIndex, selectItem, unselectItem],
  )

  const unselectAllItems = React.useCallback(() => {
    setSelectedItems([])
    setLastSelected({} as ContentV2)
  }, [])

  const shiftAction = React.useCallback(
    // this is the action handler for shift-clicking a checkbox, for each shift click, we do an
    // exclusive select on the content cards that are in the range.
    (itemsInView: readonly ContentV2[], item: ContentV2) => {
      const lastSelectedIndex = itemsInView.findIndex((element) => element.id === lastSelected.id)
      const index = itemsInView.findIndex((element) => element.id === item.id)
      let newSelectedItems = []
      if (lastSelectedIndex !== -1) {
        if (lastSelectedIndex < index) {
          newSelectedItems = itemsInView.slice(lastSelectedIndex, index + 1)
        } else {
          newSelectedItems = itemsInView.slice(index, lastSelectedIndex + 1)
        }

        setSelectedItems(newSelectedItems)
      } else {
        selectItem(item)
      }
    },
    [lastSelected, selectItem],
  )

  const moveContentCallback = React.useCallback(
    async (item: ContentV2, folderId?: string, teamId?: string, folderName?: string) => {
      if (selectedItems.length > 0 && selectedItems.includes(item)) {
        selectedItems.forEach((element) => {
          if (teamId) {
            moveToTeam({
              variables: {
                id: element.id,
                teamId: teamId === '__drafts__' ? undefined : teamId,
              },
            })
          } else {
            moveToFolder({
              variables: { folderId: folderId, id: element.id },
            })
          }
        })
        showModal(
          <Toast>
            <CheckCircle size={15} className="text-interactive-primary mr-2 capitalize" />
            <span className="text-base">
              {getItemCounts(selectedItems)} items moved {folderName && `to ${folderName}`}
            </span>
          </Toast>,
        )
        unselectAllItems()
      } else {
        if (teamId) {
          await moveToTeam({
            variables: {
              id: item.id,
              teamId,
            },
          })
        } else {
          await moveToFolder({
            variables: { folderId: folderId, id: item.id },
          })
        }
        await showModal(
          <Toast>
            <CheckCircle size={15} className="text-interactive-primary mr-2 capitalize" />
            <span className="text-base">
              {item.name} moved {folderName && `to ${folderName}`}
            </span>
          </Toast>,
        )
      }
    },
    [moveToFolder, selectedItems, unselectAllItems],
  )

  useKeyboardShortcuts([
    {
      keys: ['Escape'],
      onEvent: unselectAllItems,
    },
  ])

  return {
    isItemSelected,
    itemsInView: [],
    moveContentCallback,
    selectedItems,
    shiftAction,
    toggleItemSelect,
    unselectAllItems,
  }
}

const contentGridContext = React.createContext<IContentGridContext>({
  isItemSelected: () => {
    return false
  },
  itemsInView: [],
  moveContentCallback: () => {
    return Promise.resolve()
  },
  selectedItems: [],
  shiftAction: () => {
    return
  },
  toggleItemSelect: (item: ContentV2) => {
    return item
  },
  unselectAllItems: () => {
    return
  },
})

export const ContentGridProvider = ({
  children,
  itemsInView,
}: {
  children: React.ReactNode
  itemsInView: readonly ContentV2[]
}) => {
  const provider = useContentGridProvider()
  return (
    <contentGridContext.Provider value={{ ...provider, itemsInView }}>
      {children}
    </contentGridContext.Provider>
  )
}

export const useContentGrid = () => React.useContext<IContentGridContext>(contentGridContext)
