import { buildTour, ComponentProps } from '@plusdocs/star-tours'

import ReviewStepOne from '@/web/assets/capture-failed-1.png'
import ReviewStepTwo from '@/web/assets/review-2.png'

import { BaseRepairTourStepComponent } from './CaptureFailed'

const FirstStep = (props: ComponentProps) => {
  return (
    <BaseRepairTourStepComponent {...props} title="Review this Snapshot">
      <div className="flex flex-col items-center gap-y-3">
        <img
          src={ReviewStepOne}
          alt="Broken Snapshot"
          style={{ height: 160, width: 368 }}
          className="rounded"
        />
        <p className="text-base">
          Our AI detected something unusual about this Snapshot. This can happen if a Snapshot
          appears blank, or if a significant visual change occurs between versions.
        </p>
      </div>
    </BaseRepairTourStepComponent>
  )
}

const SecondStep = (props: ComponentProps) => {
  return (
    <BaseRepairTourStepComponent {...props} title="Here&rsquo;s how to fix it">
      <div className="flex flex-col items-center gap-y-3">
        <img
          src={ReviewStepTwo}
          alt="Snapshot review sidebar"
          style={{ height: 160, width: 368 }}
          className="rounded"
        />
        <div className="flex flex-col items-center gap-y-2">
          <p className="text-base">
            If the Snapshot looks broken to you, click “It looks broken” in the sidebar.
            You&rsquo;ll be guided through the process of repairing the Snapshot, which usually
            takes less than a minute.
          </p>
        </div>
      </div>
    </BaseRepairTourStepComponent>
  )
}

export default buildTour([
  {
    anchor: {
      type: 'screen',
    },
    component: FirstStep,
  },
  {
    anchor: {
      type: 'screen',
    },
    component: SecondStep,
  },
])
