import { File } from 'react-feather'
import { useHistory } from 'react-router-dom'

import { generateNewPageUrl } from '@/util'
import { useViewer } from '@/web/hooks'

import Button from '../Button/Button'

export const CreatePageCTACard = () => {
  const history = useHistory()
  const { activeOrganization } = useViewer()

  return (
    <div className="border-divider-light-gray bg-background-white group flex h-full min-w-[8.25rem] max-w-sm select-none flex-col overflow-hidden rounded border outline-none">
      <div className="flex h-full w-full flex-col items-center justify-center gap-2 p-3">
        <File size={32} className="text-divider-dark-gray" />
        <div className="w-full p-3 text-center text-base font-semibold">
          Want to create your own dashboards like these?
        </div>
        <Button
          className="w-full"
          variant="secondary-dark"
          onClick={() => history.push(generateNewPageUrl(activeOrganization?.slug || ''))}
        >
          Create Page
        </Button>
      </div>
    </div>
  )
}
