import clsx from 'clsx'
import { createContext } from 'react'
import { ConnectDragSource, ConnectDropTarget } from 'react-dnd'
import { Link } from 'react-router-dom'

import { ContentV2 } from '@/shared/types'
import { getContentLinkUrl } from '@/util'

import { useContentGrid } from '../ContentGrid/useContentGrid'
import { IPopoverMenuItem } from '../PopoverMenu/PopoverMenu'
import { CardMetadata } from './CardMetadata'
import { DragAndDropUtils } from './ContentCardDataWrapper'
import { DescriptionArea } from './DescriptionArea'
import { ImagePreview } from './ImagePreview'

export interface IContentCardProps {
  content: ContentV2
  overflowItems?: IPopoverMenuItem[]
  selectable?: boolean
  isSelected?: boolean
  setIsChecked?: (item: ContentV2) => void
  disableOverflow?: boolean
  disableLink?: boolean
  noByline?: boolean
  noFolderLine?: boolean
  disableDragAndDrop?: boolean
  noLocationLine?: boolean
  dragAndDropUtils?: DragAndDropUtils
  customClickHandler?: () => void
  galleryTile?: boolean
  disableTrinket?: boolean
  className?: string
}

export const CardContext = createContext({
  noFolderLine: false,
  noLocationLine: false,
  selectable: false,
})

export const ContentCard = (props: IContentCardProps): JSX.Element => {
  const { isItemSelected } = useContentGrid()

  switch (props.content.__typename) {
    case 'Snapshot': {
      return (
        <CardContext.Provider
          value={{
            noFolderLine: !!props.noFolderLine,
            noLocationLine: !!props.noLocationLine,
            selectable: !!props.selectable,
          }}
        >
          <BaseCard
            content={props.content}
            isChecked={isItemSelected(props.content)}
            overflowItems={props.overflowItems}
            noByline={props.noByline}
            dragRef={props.dragAndDropUtils?.dragRef}
            isDragging={props.dragAndDropUtils?.isDragging}
            disableLink={props.disableLink}
            customClickHandler={props.customClickHandler}
            disableTrinket={props.disableTrinket}
            className={props.className}
          />
        </CardContext.Provider>
      )
    }
    case 'Folder': {
      return (
        <CardContext.Provider
          value={{
            noFolderLine: !!props.noFolderLine,
            noLocationLine: !!props.noLocationLine,
            selectable: !!props.selectable,
          }}
        >
          <BaseCard
            content={props.content}
            isChecked={isItemSelected(props.content)}
            isDragTarget={props.dragAndDropUtils?.canDrop}
            isHovering={props.dragAndDropUtils?.isHovering}
            overflowItems={props.overflowItems}
            dragRef={props.dragAndDropUtils?.dragRef}
            isDragging={props.dragAndDropUtils?.isDragging}
            dropRef={props.dragAndDropUtils?.dropRef}
            disableLink={props.disableLink}
            disableTrinket={props.disableTrinket}
            className={props.className}
          />
        </CardContext.Provider>
      )
    }
    case 'Document': {
      return (
        <CardContext.Provider
          value={{
            noFolderLine: !!props.noFolderLine,
            noLocationLine: !!props.noLocationLine,
            selectable: !!props.selectable,
          }}
        >
          <BaseCard
            content={props.content}
            isChecked={isItemSelected(props.content)}
            overflowItems={props.overflowItems}
            dragRef={props.dragAndDropUtils?.dragRef}
            isDragging={props.dragAndDropUtils?.isDragging}
            disableLink={props.disableLink}
            galleryTile={props.galleryTile}
            disableTrinket={props.disableTrinket}
            className={props.className}
          />
        </CardContext.Provider>
      )
    }
    default: {
      return <></>
    }
  }
}

// eslint-disable-next-line complexity
const BaseCard = ({
  content,
  isChecked,
  isDragTarget,
  overflowItems,
  noByline,
  dragRef,
  dropRef,
  isHovering,
  isDragging,
  disableLink,
  customClickHandler,
  galleryTile,
  disableTrinket,
  className,
}: {
  content: ContentV2
  isChecked?: boolean
  isDragTarget?: boolean
  overflowItems?: IPopoverMenuItem[]
  noByline?: boolean
  dragRef?: ConnectDragSource
  dropRef?: ConnectDropTarget
  isHovering?: boolean
  isDragging?: boolean
  disableLink?: boolean
  customClickHandler?: () => void
  galleryTile?: boolean
  disableTrinket?: boolean
  className?: string
}): JSX.Element => {
  const { shiftAction, itemsInView } = useContentGrid()

  const outerBorderColor = clsx({
    '!border-divider-light-blue': isChecked,
    'border-divider-light-blue hover:border-interactive-primary-active active:border-interactive-primary-active':
      isDragTarget && !isHovering,
    'border-divider-light-gray active:border-divider-light-blue': !isChecked && !isDragTarget,
    'border-interactive-primary-active': isDragTarget && isHovering,
  })

  const innerBorderColor = clsx({
    'border-divider-light-blue group-hover:border-interactive-primary-active group-active:border-interactive-primary-active':
      isDragTarget && !isHovering,
    'border-divider-light-gray group-active:border-interactive-selected-active':
      !isDragTarget && !isChecked,
    'border-interactive-primary-active': isDragTarget && isHovering,
    'border-interactive-selected-active': isChecked,
  })

  const backgroundColor = clsx({
    'bg-background-panel hover:bg-background-white focus-visible:bg-background-white active:bg-interactive-secondary-active':
      !isDragTarget && !isChecked,
    'bg-base-blue630': isDragTarget && isHovering,
    'bg-interactive-selected hover:bg-interactive-primary focus-visible:bg-interactive-primary active:bg-interactive-primary-active':
      isDragTarget && !isHovering,
    'bg-interactive-selected hover:bg-interactive-selected-hover focus-visible:bg-interactive-selected-hover active:bg-interactive-selected-active':
      isChecked,
  })

  const imageAreaBackgroundColor = clsx({
    'bg-background-canvas': !isDragTarget && content.__typename !== 'Folder',
    'bg-background-white': content.__typename === 'Folder',
    'bg-interactive-selected-hover': isDragTarget && isHovering,
    'bg-interactive-selected-hover group-hover:bg-interactive-selected group-active:bg-interactive-selected':
      isDragTarget && !isHovering,
  })

  const textPrimaryColor = clsx({
    'text-brand-bright group-hover:text-copy-active group-active:text-copy-active':
      isDragTarget && !isHovering,
    'text-copy-active': isDragTarget && isHovering,
  })

  const textSecondaryColor = clsx({
    'text-brand-bright group-hover:text-copy-active group-active:text-copy-active':
      isDragTarget && !isHovering,
    'text-copy-active': isDragTarget && isHovering,
    'text-copy-secondary': !isDragTarget,
  })

  const card = (
    <div
      className="h-full w-full text-left"
      onClick={(e) => {
        if (e.shiftKey) {
          e.stopPropagation()
          e.preventDefault()
          shiftAction(itemsInView, content)
        }
      }}
    >
      <ImagePreview
        content={content}
        innerBorderColor={innerBorderColor}
        imageAreaBackgroundColor={imageAreaBackgroundColor}
        checkboxBackgroundColor={backgroundColor}
        isDragTarget={!!isDragTarget}
        isChecked={isChecked}
        disableTrinket={disableTrinket}
      />
      <DescriptionArea
        content={content}
        innerBorderColor={innerBorderColor}
        textPrimaryColor={textPrimaryColor}
        textSecondaryColor={textSecondaryColor}
        overflowItems={overflowItems}
      />
      {!noByline && (
        <CardMetadata
          content={content}
          innerBorderColor={innerBorderColor}
          textPrimaryColor={textPrimaryColor}
          textSecondaryColor={textSecondaryColor}
          isGallery={galleryTile}
        />
      )}
    </div>
  )

  const dragDropRefFunction = (node: HTMLElement | null) => {
    if (dragRef && !dropRef) {
      return dragRef(node)
    }
    if (dropRef && !dragRef) {
      return dropRef(node)
    }
    if (dragRef && dropRef) {
      return dragRef(dropRef(node))
    }
  }

  if (disableLink) {
    return (
      <div
        ref={dragDropRefFunction}
        tabIndex={0}
        className={clsx(
          'group flex min-w-[8.25rem] max-w-sm',
          'select-none flex-col overflow-hidden rounded border outline-none',

          {
            '!cursor-grabbing hover:cursor-grabbing active:cursor-grabbing': isDragging,
            'hover:cursor-pointer active:cursor-auto': !isDragging,
          },
          outerBorderColor,
          backgroundColor,
          innerBorderColor,
          'hover:shadow-dark-hard focus-visible:border-interactive-primary-active focus-visible:shadow-bordershadow focus:outline-none focus-visible:outline-none',
          {
            'border-interactive-primary-active shadow-dark-hard  focus-visible:shadow-bordershadow':
              isDragTarget && isHovering,
          },
          className,
        )}
      >
        {card}
      </div>
    )
  } else if (customClickHandler) {
    return (
      <button
        ref={dragDropRefFunction}
        tabIndex={0}
        className={clsx(
          'h-full w-full',
          'group flex min-w-[8.25rem] max-w-sm',
          'select-none flex-col overflow-hidden rounded border outline-none',
          'hover:cursor-pointer active:cursor-auto',
          outerBorderColor,
          backgroundColor,
          innerBorderColor,
          'hover:shadow-dark-hard focus-visible:border-interactive-primary-active focus-visible:shadow-bordershadow focus:outline-none focus-visible:outline-none',
          {
            'border-interactive-primary-active shadow-dark-hard  focus-visible:shadow-bordershadow':
              isDragTarget && isHovering,
          },
          className,
        )}
        onClick={customClickHandler}
      >
        {card}
      </button>
    )
  } else if (galleryTile && content.__typename === 'Document') {
    return (
      <Link
        to={`gallery/${content.id}`}
        ref={dragDropRefFunction}
        className={clsx(
          'group flex min-w-[8.25rem] max-w-sm',
          'select-none flex-col overflow-hidden rounded border outline-none',
          'hover:cursor-pointer active:cursor-auto',
          outerBorderColor,
          backgroundColor,
          innerBorderColor,
          'hover:shadow-dark-hard focus-visible:border-interactive-primary-active focus-visible:shadow-bordershadow focus:outline-none focus-visible:outline-none',
          {
            'border-interactive-primary-active shadow-dark-hard  focus-visible:shadow-bordershadow':
              isDragTarget && isHovering,
          },
        )}
      >
        {card}
      </Link>
    )
  } else {
    return (
      <Link
        to={getContentLinkUrl(content)}
        ref={dragDropRefFunction}
        className={clsx(
          'group flex min-w-[8.25rem] max-w-sm',
          'select-none flex-col overflow-hidden rounded border outline-none',
          'hover:cursor-pointer active:cursor-auto',
          outerBorderColor,
          backgroundColor,
          innerBorderColor,
          'hover:shadow-dark-hard focus-visible:border-interactive-primary-active focus-visible:shadow-bordershadow focus:outline-none focus-visible:outline-none',
          {
            'border-interactive-primary-active shadow-dark-hard  focus-visible:shadow-bordershadow':
              isDragTarget && isHovering,
          },
          className,
        )}
      >
        {card}
      </Link>
    )
  }
}

export default ContentCard
