import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import { useState } from 'react'
import { Icon, MoreVertical } from 'react-feather'

import Button from '../Button/Button'

export interface IPopoverMenuItem {
  name: string
  callback: () => void
  styles?: string
  icon?: Icon
  disabled?: boolean
}

export const PopoverMenu = ({
  items,
  disabled,
  textSecondaryColor,
}: {
  items: IPopoverMenuItem[]
  disabled?: boolean
  textSecondaryColor?: string
}): JSX.Element => {
  const [isPopoverOpen, toggleIsPopoverOpen] = useState(false)

  return disabled ? (
    <Button
      aria-label="More actions"
      className="!cursor-not-allowed border-none bg-inherit hover:bg-inherit active:bg-inherit"
      variant="ghost"
      size="tiny"
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
      icon={
        <MoreVertical
          className="text-divider-light-gray hover:text-divider-light-gray active:text-divider-light-gray text-base"
          size={16}
        />
      }
    />
  ) : (
    <DropdownMenu onOpenChange={toggleIsPopoverOpen} modal={false}>
      <DropdownMenuTrigger
        asChild
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <Button
          aria-label="More actions"
          className="focus-within:ring-interactive-primary focus-visible:ring-interactive-primary border-none bg-inherit hover:bg-inherit focus-visible:ring-1 focus-visible:ring-offset-1 active:bg-inherit"
          variant="ghost"
          size="tiny"
          icon={
            <MoreVertical
              className={clsx('text-base', textSecondaryColor, {
                '!text-interactive-primary': isPopoverOpen,
                'visited:text-interactive-primary active:text-interactive-primary hover:text-inherit':
                  !isPopoverOpen,
              })}
              size={16}
            />
          }
        />
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent
          sideOffset={4}
          align="start"
          className="font-base border-divider-light-gray bg-background-white shadow-soft w-[160px] rounded-md border p-2 text-base outline-none"
        >
          {items.map((item) => {
            return (
              <DropdownMenuItem
                className={clsx(
                  'flex w-full gap-2',
                  'cursor-pointer',
                  'items-center',
                  'rounded-md',
                  'p-2',
                  'outline-none',
                  'hover:bg-interactive-secondary-hover',
                  'focus-visible:bg-interactive-secondary-hover',
                  item.styles,
                  {
                    '!text-copy-secondary active:!text-copy-secondary !cursor-not-allowed hover:!bg-transparent active:!bg-transparent':
                      item.disabled,
                  },
                )}
                key={item.name}
                onSelect={() => item.callback()}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                disabled={item.disabled}
              >
                {item.icon && <item.icon size={16} />}
                {item.name}
              </DropdownMenuItem>
            )
          })}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  )
}
