import { useMemo } from 'react'
import { ChevronLeft } from 'react-feather'
import { usePopperTooltip } from 'react-popper-tooltip'
import { Link, useRouteMatch } from 'react-router-dom'

import { Document } from '@/generated/graphql'
import { Button, MobileDisplayOnly } from '@/shared/components'
import PlusLogo from '@/shared/components/PlusLogo'
import { SnapshotPageObject } from '@/shared/types/document'
import { useAppSelector } from '@/web/hooks'
import { selectObjectsOfTypeOnPage } from '@/web/store/document'
import { selectSnapshots } from '@/web/store/snapshots'

import { ShareModalTrigger } from '../ShareModal'

const LiveBadge = () => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      delayShow: 300,
      placement: 'bottom',
    })

  return (
    <div className="bg-copy-alert text-copy-active flex cursor-default select-none items-center rounded-sm px-1">
      <div ref={setTriggerRef} className="text-xxs font-semibold uppercase">
        Live
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container w-[200px] text-center',
          })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          Snapshots on this Page update automatically on a schedule set by the Page creator.
        </div>
      )}
    </div>
  )
}

export function ShareHeader({ document }: { document: Document }): JSX.Element {
  const isGallery = useRouteMatch([
    `/:organizationSlug/gallery/:documentId`,
    `/gallery/:documentId`,
  ])
  const allSnapshots = useAppSelector(selectSnapshots)
  const snapshotObjectsOnPage = useAppSelector(
    (state) => selectObjectsOfTypeOnPage(state, 'Snapshot') as SnapshotPageObject[],
  )

  const areAnySnapshotsLive = useMemo(() => {
    return snapshotObjectsOnPage.some((object) => {
      return allSnapshots[object.content.id]?.refreshRate
    })
  }, [allSnapshots, snapshotObjectsOnPage])

  return (
    <div className="border-divider-light-gray bg-background-panel flex h-12 w-full items-center justify-between border-b p-3">
      <div className="flex h-full w-fit flex-col items-center">
        <div className="flex h-full w-fit flex-row items-start justify-center">
          <div className="flex h-full flex-row items-center text-base">
            {isGallery ? (
              <Link to={`/gallery`} className="w-full">
                <Button
                  variant={'ghost'}
                  icon={<ChevronLeft size={24} className="space-x-2 pr-2" />}
                >
                  Back to Gallery
                </Button>
              </Link>
            ) : (
              <a href="https://www.plusdocs.com/" target="_blank" rel="noreferrer">
                <PlusLogo width={'24'} height={'24'} />
              </a>
            )}
          </div>
          <div className="flex h-full w-fit flex-row items-center space-x-3 px-4 py-1">
            <div className="flex h-full w-full flex-col space-x-2">
              <span className="text-base font-semibold">{document.name}</span>
              {areAnySnapshotsLive ? <LiveBadge /> : null}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <MobileDisplayOnly>
          {!isGallery && (
            <Link to="/register?utm_source=plus&utm_medium=page&utm_campaign=signup_CTA">
              <Button variant="primary" className="whitespace-nowrap">
                Sign up
              </Button>
            </Link>
          )}
        </MobileDisplayOnly>
        <ShareModalTrigger content={document} />
      </div>
    </div>
  )
}
