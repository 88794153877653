import type Konva from 'konva'
import type { Box } from 'konva/lib/shapes/Transformer'
import rafSchd from 'raf-schd'
import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as React from 'react'
import { Transformer } from 'react-konva'

import { PageObject } from '@/shared/types/document'

import Objects from '../components/Page/objects'

export { useAppDispatch, useAppSelector } from './__DEPRECATED_reduxHooks'
export { useRegisterExtension } from './useRegisterExtension'
export { default as useScrollDetector } from './useScrollDetector'
export { useUserAgent } from './useUserAgent'
export { useAnalyticsIdentify } from '@/shared/hooks/useAnalyticsIdentify'
export { default as useKeyboardShortcuts } from '@/shared/hooks/useKeyboardShortcuts'
export * from '@/shared/hooks/useKeyboardShortcuts'
export { default as useOnClickOutside } from '@/shared/hooks/useOnClickOutside'
export { useViewer } from '@/shared/hooks/useViewer'

import basePalette from '@plusdocs/styles/palette.json'

const boundBoxFunc = (oldBox: Box, newBox: Box) => {
  if (newBox.width < 0 || newBox.height < 0) {
    return oldBox
  }

  return newBox
}

export interface TransformResult {
  pos: {
    x: number
    y: number
    w: number
    h: number
    r: number
  }
  node: Konva.Node
}

export const useTransformer = (
  layerRef: React.RefObject<Konva.Layer>,
  selectedObjects: string[],
  allObjects: PageObject[],
  transformCallback: (result: TransformResult) => void,
  options: Partial<Konva.TransformerConfig> = {},
): [React.ReactNode, (e: Konva.KonvaEventObject<Event>) => void] => {
  const transformRef = useRef<Konva.Transformer>(null)
  const currentObject =
    selectedObjects.length === 1 ? allObjects.find((obj) => obj.id === selectedObjects[0]) : null
  const currentObjectTransformOptions = currentObject
    ? Objects[currentObject.type].transformOptions
    : null
  const combinedOptions: Konva.TransformerConfig = {
    anchorCornerRadius: 6,

    anchorFill: basePalette.blue960,

    anchorSize: 12,

    anchorStroke: basePalette.primaryBlue,

    borderStroke: basePalette.primaryBlue,
    // this may be re-enabled later once we figure out a better rotation strategy
    // rotationSnaps: [0, 90, 180, 270],
    // rotationSnapTolerance: 3,
    // rotateAnchorOffset: 30,
    enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
    flipEnabled: false,
    keepRatio: selectedObjects.length > 1 ? true : false,
    rotateEnabled: false,
    ...currentObjectTransformOptions,
    ...options,
  }

  const transformer = (
    <Transformer ref={transformRef} boundBoxFunc={boundBoxFunc} {...combinedOptions} />
  )
  const onTransformEnd = useCallback(
    ({ target: node }: Konva.KonvaEventObject<Event>) => {
      const scaleX = node.scaleX()
      const scaleY = node.scaleY()

      node.scaleX(1)
      node.scaleY(1)
      node.width(node.width() * scaleX)
      node.height(node.height() * scaleY)

      transformCallback({
        node,
        pos: {
          h: node.height(),
          r: node.rotation(),
          w: node.width(),
          x: node.x(),
          y: node.y(),
        },
      })
      // we have to trigger a force update on the transform, as konva does not
      // seem to correctly update the transformer when props change on a group
      // forcing an update after the transform end gets it in place properly
      if (transformRef.current) {
        transformRef.current.forceUpdate()
      }
    },
    [transformRef, transformCallback],
  )

  useEffect(() => {
    if (transformRef.current && layerRef.current) {
      const nodes = layerRef.current.find(
        (node: Konva.Node) => selectedObjects.indexOf(node.id()) > -1,
      )
      transformRef.current.nodes(nodes)
      transformRef.current.getLayer()?.batchDraw()
    }
  }, [selectedObjects, transformRef, layerRef])

  return [transformer, onTransformEnd]
}

export const useResizeObserver = (
  target: RefObject<Element>,
  callback: (entry: ResizeObserverEntry) => void,
): void => {
  const currentTarget = target.current
  const memoizedCallback = useCallback(callback, [callback])
  const observer = useRef(
    new ResizeObserver(
      rafSchd((entries: ResizeObserverEntry[]) => {
        memoizedCallback(entries[0])
      }),
    ),
  )

  useLayoutEffect(() => {
    const currentObserver = observer.current
    if (!currentTarget) return
    currentObserver.observe(currentTarget)
    return () => {
      if (!currentTarget) return
      currentObserver.unobserve(currentTarget)
    }
  }, [currentTarget, observer])
}

export const useElementSizer = (
  target: RefObject<Element>,
  baseSize: { width: number; height: number } | undefined,
  padding: number,
): { width: number; height: number } => {
  const [elSize, setElSize] = useState(baseSize ?? { height: 0, width: 0 })
  useResizeObserver(target, (entry) => {
    setElSize({
      height: entry.contentRect.height,
      width: entry.contentRect.width,
    })
  })
  useLayoutEffect(() => {
    const bounding = target.current?.getBoundingClientRect()
    if (bounding) {
      setElSize({
        height: bounding.height,
        width: bounding.width,
      })
    }
  }, [target, setElSize])
  useEffect(() => {
    if (elSize.width === 0 && baseSize && baseSize.width !== 0) {
      setElSize(baseSize)
    }
  }, [elSize, baseSize])
  const resized = useMemo(() => {
    if (!baseSize || elSize.width === 0) return { height: 0, width: 0 }
    const scale = Math.min(
      1, // don't scale up larger than the baseSize
      (elSize.width - padding) / baseSize.width,
      (elSize.height - padding) / baseSize.height,
    )
    return {
      height: baseSize.height * scale,
      width: baseSize.width * scale,
    }
  }, [baseSize, elSize, padding])
  return resized
}
