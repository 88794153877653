import { parseDomain } from './parseDomain'
import { isValidString } from './string'

/**
 * A valid Urn looks something like:
 * urn:{provider}:{rootType}:{rootId}:{type}:{typeId}...
 * urn:{provider}:{rootType}:{rootId}:resource-level-2-type:resource-level-2-id:{type}:{typeId}...
 *
 * urn:plus:organization:82662ee4-0080-4960-8b8f-d7693e6ff3e4
 * urn:plus:snapshot:82662ee4-0080-4960-8b8f-d7693e6ff3e4
 */
export const isValidUrn = (urn: string): boolean => {
  return /^urn:[a-z]{3,}(:[-_.*/#0-9a-z]+)+$/i.test(urn)
}

export interface IUrn {
  readonly components: readonly string[]
  readonly id?: string
  readonly properties: Record<string, string | undefined>
  readonly provider: string
  readonly rootId?: string
  readonly rootType: string
  readonly type: string
}

export function parseUrn(urn: string): IUrn {
  const [, provider, ...components] = urn.split(':')
  const [rootType, rootId] = components

  const properties = components.reduce(
    (accumulator, part, index) =>
      index % 2 === 0
        ? {
            ...accumulator,
            [part]: components[index + 1],
          }
        : accumulator,
    {},
  )

  return {
    components,
    id:
      components.length <= 2
        ? rootId
        : components.length === 3
        ? undefined
        : components[components.length - 1],
    properties,
    provider,
    rootId,
    rootType,
    type:
      components.length <= 2
        ? rootType
        : components.length === 3
        ? components[2]
        : components[components.length - 2],
  }
}

const tryParseHierarchy = (
  urn: string,
  hierarchyKeys: readonly string[],
  provider = 'plus',
): readonly string[] | undefined => {
  if (!isValidUrn(urn)) {
    return undefined
  }

  const parsed = parseUrn(urn)
  if (parsed.provider !== provider || parsed.components.length / 2 !== hierarchyKeys.length) {
    return undefined
  }

  const hierachyValues = hierarchyKeys
    .map((key, index) => {
      if (parsed.components[index * 2] !== key) {
        return undefined
      }

      return parsed.components[index * 2 + 1]
    })
    .filter(isValidString)

  return hierachyValues.length === hierarchyKeys.length ? hierachyValues : undefined
}

const tryParseUrn = <T>(urn: string, parseMethod: (urn: string) => T): T | undefined => {
  try {
    return parseMethod(urn)
  } catch {
    return undefined
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Organization URNs

export interface IOrganizationUrn {
  readonly organizationId: string
}

export type OrganizationUrnString = `urn:plus:organization:${string}`

export const buildOrganizationUrn = ({ organizationId }: IOrganizationUrn): OrganizationUrnString =>
  `urn:plus:organization:${organizationId}`

export const parseOrganizationUrn = (urn: string): IOrganizationUrn => {
  const parsed = tryParseHierarchy(urn, ['organization'])
  if (parsed === undefined) {
    throw new Error(`Invalid organization URN: ${urn}`)
  }

  return { organizationId: parsed[0] }
}

export const tryParseOrganizationUrn = (urn: string): IOrganizationUrn | undefined =>
  tryParseUrn(urn, parseOrganizationUrn)

export const ensureOrganizationUrn = (urn: string): OrganizationUrnString =>
  buildOrganizationUrn(parseOrganizationUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Identity URNs

export interface IIdentityUrn {
  readonly organizationId: string
  readonly identityId: string
}

export type IdentityUrnString = `${OrganizationUrnString}:identity:${string}`

export const buildIdentityUrn = ({ organizationId, identityId }: IIdentityUrn): IdentityUrnString =>
  `${buildOrganizationUrn({ organizationId })}:identity:${identityId}`

export const parseIdentityUrn = (urn: string): IIdentityUrn => {
  const parsed = tryParseHierarchy(urn, ['organization', 'identity'])
  if (parsed === undefined) {
    throw new Error(`Invalid identity URN: ${urn}`)
  }

  return {
    identityId: parsed[1],
    organizationId: parsed[0],
  }
}

export const tryParseIdentityUrn = (urn: string): IIdentityUrn | undefined =>
  tryParseUrn(urn, parseIdentityUrn)

export const ensureIdentityUrn = (urn: string): IdentityUrnString =>
  buildIdentityUrn(parseIdentityUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Presentation URNs

export interface IPresentationUrn {
  readonly organizationId: string
  readonly presentationId: string
}

export type PresentationUrnString = `${OrganizationUrnString}:presentation:${string}`

export const buildPresentationUrn = ({
  organizationId,
  presentationId,
}: IPresentationUrn): PresentationUrnString =>
  `${buildOrganizationUrn({ organizationId })}:presentation:${presentationId}`

export const parsePresentationUrn = (urn: string): IPresentationUrn => {
  const parsed = tryParseHierarchy(urn, ['organization', 'presentation'])
  if (parsed === undefined) {
    throw new Error(`Invalid presentation URN: ${urn}`)
  }

  return {
    organizationId: parsed[0],
    presentationId: parsed[1],
  }
}

export const tryParsePresentationUrn = (urn: string): IPresentationUrn | undefined =>
  tryParseUrn(urn, parsePresentationUrn)

export const ensurePresentationUrn = (urn: string): PresentationUrnString =>
  buildPresentationUrn(parsePresentationUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Slide URNs

export interface ISlideUrn {
  readonly organizationId: string
  readonly slideId: string
}

export type SlideUrnString = `${OrganizationUrnString}:slide:${string}`

export const buildSlideUrn = ({ organizationId, slideId }: ISlideUrn): SlideUrnString =>
  `${buildOrganizationUrn({ organizationId })}:slide:${slideId}`

export const parseSlideUrn = (urn: string): ISlideUrn => {
  const parsed = tryParseHierarchy(urn, ['organization', 'slide'])
  if (parsed === undefined) {
    throw new Error(`Invalid slide URN: ${urn}`)
  }

  return {
    organizationId: parsed[0],
    slideId: parsed[1],
  }
}

export const tryParseSlideUrn = (urn: string): ISlideUrn | undefined =>
  tryParseUrn(urn, parseSlideUrn)

export const ensureSlideUrn = (urn: string): SlideUrnString => buildSlideUrn(parseSlideUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Text URNs

export interface ITextUrn {
  readonly organizationId: string
  readonly textId: string
}

export type TextUrnString = `${OrganizationUrnString}:text:${string}`

export const buildTextUrn = ({ organizationId, textId }: ITextUrn): TextUrnString =>
  `${buildOrganizationUrn({ organizationId })}:text:${textId}`

export const parseTextUrn = (urn: string): ITextUrn => {
  const parsed = tryParseHierarchy(urn, ['organization', 'text'])
  if (parsed === undefined) {
    throw new Error(`Invalid text URN: ${urn}`)
  }

  return {
    organizationId: parsed[0],
    textId: parsed[1],
  }
}

export const tryParseTextUrn = (urn: string): ITextUrn | undefined => tryParseUrn(urn, parseTextUrn)

export const ensureTextUrn = (urn: string): TextUrnString => buildTextUrn(parseTextUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Theme URNs

export interface IThemeUrn {
  readonly organizationId: string
  readonly themeId: string
}

export type ThemeUrnString = `${OrganizationUrnString}:theme:${string}`

export const buildThemeUrn = ({ organizationId, themeId }: IThemeUrn): ThemeUrnString =>
  `${buildOrganizationUrn({ organizationId })}:theme:${themeId}`

export const parseThemeUrn = (urn: string): IThemeUrn => {
  const parsed = tryParseHierarchy(urn, ['organization', 'theme'])
  if (parsed === undefined) {
    throw new Error(`Invalid theme URN: ${urn}`)
  }

  return {
    organizationId: parsed[0],
    themeId: parsed[1],
  }
}

export const tryParseThemeUrn = (urn: string): IThemeUrn | undefined =>
  tryParseUrn(urn, parseThemeUrn)

export const ensureThemeUrn = (urn: string): ThemeUrnString => buildThemeUrn(parseThemeUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Snapshot URNs

export interface ISnapshotUrn {
  readonly organizationId: string
  readonly snapshotId: string
  readonly versionId?: string
}

export interface ISnapshotVersionUrn extends ISnapshotUrn {
  readonly versionId: string
}

export type SnapshotNoVersionUrnString = `${OrganizationUrnString}:snapshot:${string}`
export type SnapshotVersionUrnString = `${SnapshotNoVersionUrnString}:version:${string}`

export type SnapshotUrnString = SnapshotNoVersionUrnString | SnapshotVersionUrnString

export const buildSnapshotUrn = ({
  organizationId,
  snapshotId,
  versionId,
}: ISnapshotUrn): SnapshotUrnString =>
  `${buildOrganizationUrn({ organizationId })}:snapshot:${snapshotId}${
    versionId ? ':version:' + versionId : ''
  }`

export const buildSnapshotVersionUrn = (urnInfo: ISnapshotVersionUrn): SnapshotVersionUrnString =>
  buildSnapshotUrn(urnInfo) as SnapshotVersionUrnString

export const parseSnapshotUrn = (urn: string): ISnapshotUrn => {
  const parsed =
    tryParseHierarchy(urn, ['organization', 'snapshot', 'version']) ??
    tryParseHierarchy(urn, ['organization', 'snapshot'])

  if (parsed === undefined) {
    throw new Error(`Invalid snapshot URN: ${urn}`)
  }

  return {
    organizationId: parsed[0],
    snapshotId: parsed[1],
    versionId: parsed[2],
  }
}

export const parseSnapshotVersionUrn = (urn: string): ISnapshotVersionUrn => {
  const parsed = tryParseHierarchy(urn, ['organization', 'snapshot', 'version'])
  if (parsed === undefined) {
    throw new Error(`Invalid snapshot version URN: ${urn}`)
  }

  return {
    organizationId: parsed[0],
    snapshotId: parsed[1],
    versionId: parsed[2],
  }
}

export const tryParseSnapshotUrn = (urn: string): ISnapshotUrn | undefined =>
  tryParseUrn(urn, parseSnapshotUrn)

export const tryParseSnapshotVersionUrn = (urn: string): ISnapshotVersionUrn | undefined =>
  tryParseUrn(urn, parseSnapshotVersionUrn)

export const ensureSnapshotUrn = (urn: string): SnapshotUrnString =>
  buildSnapshotUrn(parseSnapshotUrn(urn))

export const ensureSnapshotVersionUrn = (urn: string): SnapshotVersionUrnString =>
  buildSnapshotVersionUrn(parseSnapshotVersionUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Document URNs

export interface IDocumentUrn {
  readonly organizationId: string
  readonly documentId: string
}

export type DocumentUrnString = `${OrganizationUrnString}:document:${string}`

export const buildDocumentUrn = ({ organizationId, documentId }: IDocumentUrn): DocumentUrnString =>
  `${buildOrganizationUrn({ organizationId })}:document:${documentId}`

export const parseDocumentUrn = (urn: string): IDocumentUrn => {
  const parsed = tryParseHierarchy(urn, ['organization', 'document'])
  if (parsed === undefined) {
    throw new Error(`Invalid document URN: ${urn}`)
  }

  return { documentId: parsed[1], organizationId: parsed[0] }
}

export const tryParseDocumentUrn = (urn: string): IDocumentUrn | undefined =>
  tryParseUrn(urn, parseDocumentUrn)

export const ensureDocumentUrn = (urn: string): DocumentUrnString =>
  buildDocumentUrn(parseDocumentUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Folder URNs

export interface IFolderUrn {
  readonly organizationId: string
  readonly folderId: string
}

export type FolderUrnString = `${OrganizationUrnString}:folder:${string}`

export const buildFolderUrn = ({ organizationId, folderId }: IFolderUrn): FolderUrnString =>
  `${buildOrganizationUrn({ organizationId })}:folder:${folderId}`

export const parseFolderUrn = (urn: string): IFolderUrn => {
  const parsed = tryParseHierarchy(urn, ['organization', 'folder'])
  if (parsed === undefined) {
    throw new Error(`Invalid folder URN: ${urn}`)
  }

  return { folderId: parsed[1], organizationId: parsed[0] }
}

export const tryParseFolderUrn = (urn: string): IFolderUrn | undefined =>
  tryParseUrn(urn, parseFolderUrn)

export const ensureFolderUrn = (urn: string): FolderUrnString => buildFolderUrn(parseFolderUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Content URNs

export function buildContentUrn({
  documentId,
  folderId,
  organizationId,
  snapshotId,
}: {
  readonly organizationId: string
  readonly documentId?: string | null
  readonly folderId?: string | null
  readonly snapshotId?: string | null
}): SnapshotUrnString | DocumentUrnString | FolderUrnString {
  if (documentId) {
    return buildDocumentUrn({ documentId, organizationId })
  }

  if (folderId) {
    return buildFolderUrn({ folderId, organizationId })
  }

  if (snapshotId) {
    return buildSnapshotUrn({ organizationId, snapshotId })
  }

  throw new Error('Invalid Content object — one of documentId, folderId, or snapshotId required')
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Team URNs

export interface ITeamUrn {
  readonly organizationId: string
  readonly teamId: string
}

export type TeamUrnString = `${OrganizationUrnString}:team:${string}`

export const buildTeamUrn = ({ organizationId, teamId }: ITeamUrn): TeamUrnString =>
  `${buildOrganizationUrn({ organizationId })}:team:${teamId}`

export const parseTeamUrn = (urn: string): ITeamUrn => {
  const parsed = tryParseHierarchy(urn, ['organization', 'team'])
  if (parsed === undefined) {
    throw new Error(`Invalid team URN: ${urn}`)
  }

  return { organizationId: parsed[0], teamId: parsed[1] }
}

export const tryParseTeamUrn = (urn: string): ITeamUrn | undefined => tryParseUrn(urn, parseTeamUrn)

export const ensureTeamUrn = (urn: string): TeamUrnString => buildTeamUrn(parseTeamUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// User URNs

export interface IUserUrn {
  readonly organizationId: string
  readonly userId: string
}

export type UserUrnString = `${OrganizationUrnString}:user:${string}`

export const buildUserUrn = ({ organizationId, userId }: IUserUrn): UserUrnString =>
  `${buildOrganizationUrn({ organizationId })}:user:${userId}`

export const parseUserUrn = (urn: string): IUserUrn => {
  const parsed = tryParseHierarchy(urn, ['organization', 'user'])
  if (parsed === undefined) {
    throw new Error(`Invalid user URN: ${urn}`)
  }

  return { organizationId: parsed[0], userId: parsed[1] }
}

export const tryParseUserUrn = (urn: string): IUserUrn | undefined => tryParseUrn(urn, parseUserUrn)

export const ensureUserUrn = (urn: string): UserUrnString => buildUserUrn(parseUserUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// AuthN URNs

export interface IAuthNUrn {
  readonly userPoolId: string
  readonly actorId: string
}

export type AuthNUrnString = `urn:aws:cognito-idp:userpool/${string}:user:${string}`

export const buildAuthNUrn = ({ userPoolId, actorId }: IAuthNUrn): AuthNUrnString =>
  `urn:aws:cognito-idp:userpool/${userPoolId}:user:${actorId}`

export const parseAuthNUrn = (urn: string): IAuthNUrn => {
  const parsed = tryParseHierarchy(urn, ['cognito-idp', 'user'], 'aws')
  const userPoolId = parsed?.[0].slice(parsed[0].indexOf('/') + 1)

  if (parsed === undefined || !parsed[0].startsWith('userpool/') || !isValidString(userPoolId)) {
    throw new Error(`Invalid authN URN: ${urn}`)
  }

  return {
    actorId: parsed[1],
    userPoolId,
  }
}

export const tryParseAuthNUrn = (urn: string): IAuthNUrn | undefined =>
  tryParseUrn(urn, parseAuthNUrn)

export const ensureAuthNUrn = (urn: string): AuthNUrnString => buildAuthNUrn(parseAuthNUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Subscription URNs

export interface ISubscriptionUrn {
  readonly organizationId: string
  readonly parentContentId: string
  readonly subscriptionId: string
  readonly parentContentKind: 'snapshot' | 'document'
  readonly snapshotVersionId?: string
}

export type SubscriptionUrnString = `${
  | DocumentUrnString
  | SnapshotUrnString}:subscription:${string}`

export const buildSubscriptionUrn = ({
  organizationId,
  parentContentId,
  parentContentKind,
  snapshotVersionId,
  subscriptionId,
}: ISubscriptionUrn): SubscriptionUrnString => {
  if (parentContentKind === 'snapshot' && isValidString(snapshotVersionId)) {
    return `urn:plus:organization:${organizationId}:snapshot:${parentContentId}:version:${snapshotVersionId}:subscription:${subscriptionId}`
  }

  return `urn:plus:organization:${organizationId}:${parentContentKind}:${parentContentId}:subscription:${subscriptionId}`
}

export const parseSubscriptionUrn = (urn: string): ISubscriptionUrn => {
  const parsedSnapshot = tryParseHierarchy(urn, ['organization', 'snapshot', 'subscription'])
  const parsedSnapshotVersion = parsedSnapshot
    ? undefined
    : tryParseHierarchy(urn, ['organization', 'snapshot', 'version', 'subscription'])

  const parsedDocument =
    parsedSnapshot || parsedSnapshotVersion
      ? undefined
      : tryParseHierarchy(urn, ['organization', 'document', 'subscription'])

  const parsed = parsedSnapshot ?? parsedSnapshotVersion ?? parsedDocument
  if (parsed === undefined) {
    throw new Error(`Invalid subscription URN: ${urn}`)
  }

  return {
    organizationId: parsed[0],
    parentContentId: parsed[1],
    parentContentKind: parsedDocument ? 'document' : 'snapshot',
    snapshotVersionId: parsedSnapshotVersion ? parsed[2] : undefined,
    subscriptionId: parsed.at(-1) as string,
  }
}

export const tryParseSubscriptionUrn = (urn: string): ISubscriptionUrn | undefined =>
  tryParseUrn(urn, parseSubscriptionUrn)

export const ensureSubscriptionUrn = (urn: string): SubscriptionUrnString =>
  buildSubscriptionUrn(parseSubscriptionUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Slack Installation URNs

export interface ISlackInstallationUrn {
  readonly slackUserId: string
}

export type SlackInstallationUrnString = `urn:plus:slack:installation:${string}`

export const buildSlackInstallationUrn = ({
  slackUserId,
}: ISlackInstallationUrn): SlackInstallationUrnString => {
  return `urn:plus:slack:installation:${slackUserId}`
}

export const parseSlackInstallationUrn = (urn: string): ISlackInstallationUrn => {
  if (!isValidUrn(urn)) {
    throw new Error(`Invalid Slack installation URN: ${urn}`)
  }

  const parsed = parseUrn(urn)

  if (
    parsed.provider !== 'plus' ||
    parsed.components.length !== 3 ||
    parsed.components[0] !== 'slack' ||
    parsed.components[1] !== 'installation' ||
    !isValidString(parsed.components[2])
  ) {
    throw new Error(`Invalid Slack installation URN: ${urn}`)
  }

  return {
    slackUserId: parsed.components[2],
  }
}

export const tryParseSlackInstallationUrn = (urn: string): ISlackInstallationUrn | undefined =>
  tryParseUrn(urn, parseSlackInstallationUrn)

export const ensureSlackInstallationUrn = (urn: string): SlackInstallationUrnString =>
  buildSlackInstallationUrn(parseSlackInstallationUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TLD URNs

export interface ITldUrn {
  readonly tld: string
}

export type TldUrnString = `urn:plus:tld:${string}`

export const buildTldUrn = ({ tld }: ITldUrn): TldUrnString => {
  return `urn:plus:tld:${tld}`
}

export const parseTldUrn = (urn: string): ITldUrn => {
  if (!isValidUrn(urn)) {
    throw new Error(`Invalid TLD URN: ${urn}`)
  }

  const parsed = parseUrn(urn)

  if (
    parsed.provider !== 'plus' ||
    parsed.components.length !== 2 ||
    parsed.components[0] !== 'tld' ||
    !isValidString(parsed.components[1])
  ) {
    throw new Error(`Invalid TLD URN: ${urn}`)
  }

  return {
    tld: parsed.components[1],
  }
}

export const tryParseTldUrn = (urn: string): ITldUrn | undefined => tryParseUrn(urn, parseTldUrn)

export const ensureTldUrn = (urn: string): TldUrnString => buildTldUrn(parseTldUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Domain URNs

export interface IDomainUrn {
  readonly domain: string
}

export type DomainUrnString = `urn:plus:domain:${string}`

export const buildDomainUrn = ({ domain }: IDomainUrn): DomainUrnString => {
  return `urn:plus:domain:${parseDomain(domain).domain}`
}

export const parseDomainUrn = (urn: string): IDomainUrn => {
  if (!isValidUrn(urn)) {
    throw new Error(`Invalid Domain URN: ${urn}`)
  }

  const parsed = parseUrn(urn)

  if (
    parsed.provider !== 'plus' ||
    parsed.components.length !== 2 ||
    parsed.components[0] !== 'domain' ||
    !isValidString(parsed.components[1])
  ) {
    throw new Error(`Invalid Domain URN: ${urn}`)
  }

  return {
    domain: parsed.components[1],
  }
}

export const tryParseDomainUrn = (urn: string): IDomainUrn | undefined =>
  tryParseUrn(urn, parseDomainUrn)

export const ensureDomainUrn = (urn: string): DomainUrnString => buildDomainUrn(parseDomainUrn(urn))

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Settings URNs

export type SettingsUrnString =
  | UserUrnString
  | OrganizationUrnString
  | DomainUrnString
  | TldUrnString
  | `urn:plus:*`

export const ensureSettingsUrn = (urn: string): SettingsUrnString => {
  if (urn === `urn:plus:*`) {
    return urn
  }

  const parsed =
    tryParseUserUrn(urn) ??
    tryParseOrganizationUrn(urn) ??
    tryParseDomainUrn(urn) ??
    tryParseTldUrn(urn)

  if (parsed === undefined) {
    throw new Error(`Invalid Settings URN: ${urn}`)
  }

  return urn as SettingsUrnString
}
