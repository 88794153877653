/* eslint-disable complexity */
import { useReactiveVar } from '@apollo/client'
import { useStarTour } from '@plusdocs/star-tours'
import clsx from 'clsx'
import * as React from 'react'
import { Gift, Menu, Search, User, Users } from 'react-feather'
import { Link, useParams, useRouteMatch } from 'react-router-dom'

import {
  ContentType,
  OrderDirection,
  RepairType,
  SearchResultOrderField,
} from '@/generated/graphql'
import { extensionClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Button, DesktopDisplayOnly, Input, MobileDisplayOnly } from '@/shared/components'
import { useContentGrid } from '@/shared/components/ContentGrid/useContentGrid'
import { Snapshot as SnapshotIcon } from '@/shared/components/Icons'
import useKeyboardShortcuts from '@/shared/hooks/useKeyboardShortcuts'
import { useUserAgent, useViewer } from '@/web/hooks'
import { useContentDrop } from '@/web/hooks/useContentDrop'

import useCurrentOrganizationSnapshots from '../hooks/useCurrentOrganizationSnapshots'
import useSearch from '../hooks/useSearch'
import MobileHeader from './MobileHeader'
import OrgNavMenu from './OrgNav/OrgNavMenu'
import { SidebarNUX } from './Sidebar/NUX'
import { SnapshotLimit } from './Sidebar/SnapshotLimit'

enum WebAppRoutes {
  Connection = '/connection',
  Gallery = '/gallery',
  Personal = '/',
  Repair = '/snapshots/repair',
  Settings = '/settings',
  Team = '/team',
  Unknown = '',
}

interface ISideBarProps {
  searchClickHandler: (showSearch: boolean) => void
}

const SideBar = (props: ISideBarProps): JSX.Element => {
  const { searchClickHandler } = props
  const { moveContentCallback } = useContentGrid()

  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false)
  const toggleMenuCallback = React.useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen, setIsMenuOpen])
  const { folderId } = useParams<{
    folderId?: string
  }>()

  const showSearchOnClickHandler = React.useCallback(() => {
    searchClickHandler(true)
  }, [searchClickHandler])

  const [currentActivePage, setCurrentActivePage] = React.useState<WebAppRoutes>(
    WebAppRoutes.Unknown,
  )

  const { activeOrganization } = useViewer()

  useCurrentRouteEffect(setCurrentActivePage)

  useKeyboardShortcuts([
    {
      keys: ['Ctrl', 'k'],
      onEvent: showSearchOnClickHandler,
    },
  ])

  const [canDropPersonal, dropPersonal, isHoveringPersonal] = useContentDrop(
    (item) => moveContentCallback(item, undefined, '__drafts__', 'Personal'),
    (item) => !!folderId || item.team != null,
  )

  const [canDropTeam, dropTeam, isHoveringTeam] = useContentDrop(
    (item) => moveContentCallback(item, undefined, activeOrganization?.teams.nodes[0].id, 'Team'),
    (item) => !!folderId || item.team == null,
  )

  const { nodes: repairSnapshotNodes, totalCount } = useCurrentOrganizationSnapshots({
    // we want all logged out snapshots regardless of folder or team, so purposely leaving out `folders` and `teams`
    filter: {
      needsRepair: true,
      snapshotIOwn: true,
    },
    first: 50,
    sort: {
      direction: OrderDirection.Desc,
      field: SearchResultOrderField.CreatedAt,
    },
  })

  const repairCounts = repairSnapshotNodes.reduce(
    (accumulator, item) => ({
      ...accumulator,
      ...(item.suggestedRepairType === RepairType.Login
        ? { connections: { ...accumulator.connections, [item.identity?.id ?? 'deleted']: true } }
        : { review: accumulator.review + 1 }),
    }),
    { connections: {}, review: 0 },
  )
  const repairCount = Object.keys(repairCounts.connections).length + repairCounts.review

  const { counts: personalCount } = useSearch({
    filter: {
      contentIOwn: true,
      contentTypes: [ContentType.Document, ContentType.Snapshot],
      teams: [],
    },
    first: 1, // we don't need any of the actual results, so can just fetch 1 (API minimum) and look at counts
  })

  const { counts: teamCount } = useSearch({
    filter: {
      contentTypes: [ContentType.Document, ContentType.Snapshot],
      teams: activeOrganization?.teams.nodes[0] ? [activeOrganization.teams.nodes[0].id] : [],
    },
    first: 1,
  })

  const { start } = useStarTour()
  const ua = useUserAgent()
  const { isExtensionInstalled } = useReactiveVar(extensionClientStateVar)
  const isChromiumLike = ua.engine.name === 'Blink'

  return (
    <>
      <MobileDisplayOnly>
        <MobileHeader searchClickHandler={props.searchClickHandler} />
      </MobileDisplayOnly>
      <DesktopDisplayOnly>
        <div className="border-divider-light-gray lg:bg-background-panel absolute w-full lg:h-full lg:border-r">
          <div className="flex h-full flex-col">
            <div className="flex flex-row items-center justify-between lg:p-3 lg:pb-0">
              <Button
                variant="ghost"
                className="ml-1 lg:hidden"
                onClick={toggleMenuCallback}
                icon={<Menu size="18px" />}
              />

              <OrgNavMenu />

              <Button
                variant="ghost"
                className="lg:hidden"
                onClick={showSearchOnClickHandler}
                icon={<Search size="18px" />}
              />
            </div>

            <div
              className={clsx(
                !isMenuOpen && 'hidden',
                'p-3 lg:flex lg:grow lg:flex-col lg:overflow-y-auto lg:pt-0',
              )}
            >
              <Button
                align="center"
                variant="primary"
                className="mt-3 inline-block w-full text-base"
                onClick={() => {
                  if (isExtensionInstalled && isChromiumLike) {
                    start('Welcome', 2)
                  } else {
                    start('Welcome', 1)
                  }
                }}
              >
                Take a Snapshot
              </Button>

              <div className="hidden pt-3 lg:block">
                <Input
                  onClick={(e) => {
                    showSearchOnClickHandler()
                    e.currentTarget.blur()
                  }}
                  leadingIcon={<Search size="16px" className="text-copy-secondary" />}
                  readOnly
                  value={'Search'}
                  className="text-copy-secondary cursor-pointer text-base"
                  trailingIcon={
                    <div className="bg-interactive-secondary-hover text-copy-secondary select-none rounded-sm px-1 text-base">
                      {navigator.userAgent.includes('Macintosh') ? '⌘K' : 'Ctrl+K'}
                    </div>
                  }
                ></Input>
              </div>

              <div className="flex w-full flex-col gap-2 py-3">
                <Link
                  ref={dropPersonal}
                  tabIndex={-1}
                  to={`${WebAppRoutes.Personal}${activeOrganization?.slug}`}
                >
                  <Button
                    align="left"
                    variant={canDropPersonal && isHoveringPersonal ? 'primary' : 'ghost'}
                    className="focus:!border-divider-light-blue inline-block w-full justify-between text-base"
                    active={currentActivePage === WebAppRoutes.Personal || canDropPersonal}
                  >
                    <span>
                      <User size="16px" className="mr-2 inline" />
                      Personal
                    </span>
                    <div
                      className={clsx(
                        'px-1.25 flex h-4 w-max items-center justify-center rounded-full text-base font-normal',
                        currentActivePage === WebAppRoutes.Personal || canDropPersonal
                          ? 'bg-interactive-primary text-copy-active'
                          : 'bg-interactive-secondary-hover text-copy-secondary',
                      )}
                    >
                      {personalCount?.total || '0'}
                    </div>
                  </Button>
                </Link>

                {/* Today only one team exists per org so we can just access the first element */}
                <Link
                  ref={dropTeam}
                  tabIndex={-1}
                  to={`/${activeOrganization?.slug}${WebAppRoutes.Team}/${activeOrganization?.teams.nodes[0].id}`}
                >
                  <Button
                    align="left"
                    variant={canDropTeam && isHoveringTeam ? 'primary' : 'ghost'}
                    className="focus:!border-divider-light-blue inline-block w-full justify-between text-base"
                    active={currentActivePage === WebAppRoutes.Team || canDropTeam}
                  >
                    <span>
                      <Users size="16px" className="mr-2 inline" />
                      Team
                    </span>
                    <div
                      className={clsx(
                        'px-1.25 flex h-4 w-max items-center justify-center rounded-full text-base font-normal',
                        currentActivePage === WebAppRoutes.Team || canDropTeam
                          ? 'bg-interactive-primary text-copy-active'
                          : 'bg-interactive-secondary-hover text-copy-secondary',
                      )}
                    >
                      {teamCount?.total || '0'}
                    </div>
                  </Button>
                </Link>

                <Link tabIndex={-1} to={`/${activeOrganization?.slug}${WebAppRoutes.Gallery}`}>
                  <Button
                    align="left"
                    variant={'ghost'}
                    className="focus:!border-divider-light-blue inline-block w-full justify-between text-base"
                    active={currentActivePage === WebAppRoutes.Gallery}
                  >
                    <span>
                      <Gift size="16px" className="mr-2 inline" />
                      Gallery
                    </span>
                  </Button>
                </Link>

                {totalCount > 0 && (
                  <Link tabIndex={-1} to={`/${activeOrganization?.slug}${WebAppRoutes.Repair}`}>
                    <Button
                      align="left"
                      variant="ghost"
                      className="focus:!border-divider-light-blue inline-flex w-full justify-between text-base"
                      active={currentActivePage === WebAppRoutes.Repair}
                    >
                      <span>
                        <SnapshotIcon className="mr-2 inline" />
                        Repair
                      </span>
                      <div className="bg-interactive-destructive px-1.25 text-copy-active flex h-4 w-max items-center justify-center rounded-full text-base font-normal">
                        {repairCount}
                      </div>
                    </Button>
                  </Link>
                )}
              </div>

              <SidebarNUX />

              <div className="mt-auto hidden lg:flex">
                <SnapshotLimit />
              </div>
            </div>
          </div>
        </div>
      </DesktopDisplayOnly>
    </>
  )
}
export default SideBar

/**
 * Effect used to determine the current active page to set correct sidebar link to active
 * @param setCurrentActivePage setState method to update active page state
 */
const useCurrentRouteEffect = (setCurrentActivePage: React.Dispatch<WebAppRoutes>): void => {
  let matchedRoute = WebAppRoutes.Unknown
  const { path } = useRouteMatch()
  const { teamId } = useParams<{ teamId?: string }>()

  if (path.includes(WebAppRoutes.Settings)) {
    matchedRoute = WebAppRoutes.Settings
  } else if (path.includes(WebAppRoutes.Repair)) {
    matchedRoute = WebAppRoutes.Repair
  } else if (path.includes(WebAppRoutes.Gallery)) {
    matchedRoute = WebAppRoutes.Gallery
  } else if (path.includes(WebAppRoutes.Connection)) {
    // For v0 of the Connections feature, where we don't have a landing page for all connections, we don't want to show
    // that any page is active on the sidebar.
    matchedRoute = WebAppRoutes.Unknown
  } else if (teamId) {
    matchedRoute = WebAppRoutes.Team
  } else {
    matchedRoute = WebAppRoutes.Personal
  }

  React.useEffect(() => {
    setCurrentActivePage(matchedRoute)
  }, [matchedRoute, setCurrentActivePage])
}
