import { ApolloProvider } from '@apollo/client'
import { useCallback, useState } from 'react'
import { CheckCircle, X } from 'react-feather'
import { useParams } from 'react-router-dom'

import {
  ContentGridSearchDocument,
  DocumentCardFragment,
  SearchDocument,
  SnapshotDocument,
  SnapshotFieldsFragment,
  useMoveContentToTeamMutation,
  useMoveToFolderMutation,
} from '@/generated/graphql'
import { apolloClient } from '@/shared/apollo/apolloClient'
import { Button, Dialog, Modal, Toast } from '@/shared/components'
import useViewer from '@/shared/hooks/useViewer'
import { ContentV2 } from '@/shared/types'
import { showModal } from '@/util'
import { MoveToSearchPane } from '@/web/components/Search'

const useMoveTo = (): ((
  selectedContent: readonly ContentV2[],
  unselectAllItems?: () => void,
) => void) => {
  const { teamId } = useParams<{ teamId?: string }>()
  const { activeOrganization } = useViewer()
  const [moveToFolder] = useMoveToFolderMutation({
    refetchQueries: [
      SearchDocument,
      ContentGridSearchDocument,
      SnapshotDocument,
      /* PagesEditorQueryDocument */ // uncomment PagesEditorQueryDocument when we move all of Pages into GQL
    ],
  })
  const [moveToTeam] = useMoveContentToTeamMutation({
    refetchQueries: [
      SearchDocument,
      ContentGridSearchDocument,
      SnapshotDocument,
      /* PagesEditorQueryDocument */ // uncomment PagesEditorQueryDocument when we move all of Pages into GQL
    ],
  })

  const confirmationModalCallback = useCallback(
    async (callback: () => void, folder: ContentV2, selectedContent: readonly ContentV2[]) => {
      const type =
        selectedContent.filter((item) => item.__typename === 'Document').length ===
        selectedContent.length
          ? 'pages'
          : selectedContent.filter((item) => item.__typename === 'Snapshot').length ===
            selectedContent.length
          ? 'snapshots'
          : 'items'
      const originFolder = (selectedContent[0] as DocumentCardFragment | SnapshotFieldsFragment)
        .folder?.name
      const hasMultipleAuthors = () => {
        const snapshots = selectedContent.filter(
          (item) => item.__typename === 'Snapshot',
        ) as SnapshotFieldsFragment[]
        const snapshotsWithMultipleAuthors =
          snapshots.filter((item) => item.createdBy !== snapshots[0].createdBy).length > 0

        const documents = selectedContent.filter(
          (item) => item.__typename === 'Document',
        ) as DocumentCardFragment[]
        const documentsWithMultipleAuthors =
          documents.filter((item) => item.createdBy !== documents[0].createdBy).length > 0

        return snapshotsWithMultipleAuthors || documentsWithMultipleAuthors
      }
      const titleText = `Move ${selectedContent.length} ${type}?`
      const descriptionText = `This action will move ${selectedContent.length} ${type} ${
        selectedContent.filter((content) => content.__typename === 'Folder').length > 0
          ? ''
          : hasMultipleAuthors()
          ? 'created by multiple teammates'
          : 'created by you'
      } ${
        originFolder
          ? 'from the folder "' + originFolder + '"'
          : ' from the ' + (selectedContent[0].team ? 'folder "Team"' : 'folder "Personal"')
      } to the folder "${folder.name}". This action can not be automatically undone.`

      await showModal(
        <Modal
          variant="primary"
          title={titleText}
          content={descriptionText}
          primaryButtonText="Move"
          onSubmit={callback}
          secondaryButtonText="Cancel"
        />,
      )
    },
    [activeOrganization],
  )

  const addItemsToFolderCallback = useCallback(
    (folder: ContentV2, selectedContent: ContentV2[], unselectAllItems?: () => void) => {
      if (unselectAllItems) {
        unselectAllItems()
      }

      if (folder.id === 'personal') {
        for (const contentItem of selectedContent) {
          moveToTeam({
            variables: {
              id: contentItem.id,
            },
          })
        }
      } else if (folder.id === 'team') {
        for (const contentItem of selectedContent) {
          moveToTeam({
            variables: {
              id: contentItem.id,
              teamId: activeOrganization?.teams.nodes[0].id,
            },
          })
        }
      } else {
        for (const contentItem of selectedContent) {
          moveToFolder({
            variables: { folderId: folder.id, id: contentItem.id },
          })
        }
      }

      showModal(
        <Toast>
          <CheckCircle size={15} className="text-interactive-primary mr-2 capitalize" />
          <span className="text-base">
            {selectedContent.length > 1
              ? `Moved ${selectedContent.length} items to ${folder.name}`
              : `${selectedContent[0].name} moved to ${folder.name}`}
          </span>
        </Toast>,
      )
    },
    [activeOrganization, moveToFolder, moveToTeam],
  )

  const showMoveToFolderModalCallback = useCallback(
    (selectedContent: readonly ContentV2[], unselectAllItems?: () => void) => {
      showModal(
        <MoveToFolderModal
          confirmationModalCallback={confirmationModalCallback}
          addItemsToFolderCallback={addItemsToFolderCallback}
          selectedContent={selectedContent}
          itemCount={selectedContent.length}
          teamId={teamId}
          unselectAllItems={unselectAllItems}
        />,
      )
    },
    [addItemsToFolderCallback, teamId],
  )

  return showMoveToFolderModalCallback
}

interface MoveToFolderModalProps {
  addItemsToFolderCallback(
    folder: ContentV2,
    selectedContent: readonly ContentV2[],
    unselectAllItems?: () => void,
  ): void
  confirmationModalCallback: (
    callback: () => void,
    folder: ContentV2,
    selectedContent: readonly ContentV2[],
  ) => void
  selectedContent: readonly ContentV2[]
  itemCount: number
  teamId?: string
  unselectAllItems?: () => void
}

const MoveToFolderModal = (props: MoveToFolderModalProps) => {
  const {
    addItemsToFolderCallback,
    itemCount,
    teamId,
    unselectAllItems,
    confirmationModalCallback,
  } = props
  const [isOpen, setIsOpen] = useState(true)
  const title = `Move ${itemCount} item${itemCount > 1 ? 's' : ''}`

  const submitCallback = (folder: ContentV2) => {
    if (props.selectedContent.length > 1) {
      confirmationModalCallback(
        () => addItemsToFolderCallback(folder, props.selectedContent, unselectAllItems),
        folder,
        props.selectedContent,
      )
    } else {
      addItemsToFolderCallback(folder, props.selectedContent, unselectAllItems)
    }
    setIsOpen(false)
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Dialog isOpen={isOpen} setIsOpen={setIsOpen} showBackgroundOverlay={true}>
        <div className="border-base-black bg-background-white inline-block h-[600px] w-full max-w-[600px] transform rounded-md border text-left align-middle transition-all">
          <div className="flex h-full flex-col px-3">
            <div className="mb-2 flex items-center justify-between pt-3">
              <span className="text-copy text-lg font-semibold leading-8">{title}</span>
              <Button variant="ghost" tabIndex={-1} onClick={() => setIsOpen(false)}>
                <X size={16} />
              </Button>
            </div>
            <MoveToSearchPane
              onSubmit={submitCallback}
              teamId={teamId}
              currentFolderId={(props.selectedContent[0] as SnapshotFieldsFragment).folder?.id}
            />
          </div>
        </div>
      </Dialog>
    </ApolloProvider>
  )
}

export default useMoveTo
