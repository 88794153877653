import type Konva from 'konva'
import { useCallback, useMemo } from 'react'

import type { PageObject as IPageObject } from '@/shared/types/document'
import flushTextChanges from '@/util/flushTextChanges'
import { noop } from '@/util/fp'
import { relativeToScaled, scaledToRelative } from '@/util/transformPositions'
import type { AppState } from '@/web/store/appState'
import {
  toggleSelectedObjects,
  updateEditingState,
  updateSelectedObjects,
} from '@/web/store/appState'
import { updateObjectPosition } from '@/web/store/document'
import { SnapshotState } from '@/web/store/snapshots'
import type { AppDispatch } from '@/web/store/types'

import Objects from './objects'

interface ObjectProps {
  object: IPageObject
  pageId: string
  canvasSize: AppState['canvas']
  dispatch: AppDispatch
  selectedObjects: string[]
  editingState: AppState['editingState']
  onTransformEnd: (e: Konva.KonvaEventObject<Event>) => void
  snapshots: SnapshotState['snapshots']
  mode: 'editor' | 'share'
  slug?: string
}

const useScaledSize = (position: IPageObject['position'], scalingSide: number) => {
  const scaledSizes = useMemo(() => {
    return {
      height: relativeToScaled(position.h, scalingSide),
      width: relativeToScaled(position.w, scalingSide),
      x: relativeToScaled(position.x, scalingSide),
      y: relativeToScaled(position.y, scalingSide),
    }
  }, [position, scalingSide])

  return scaledSizes
}

export function PageObject({
  object,
  pageId,
  canvasSize,
  dispatch,
  selectedObjects,
  onTransformEnd,
  editingState,
  snapshots,
  mode,
  slug,
}: ObjectProps): JSX.Element {
  const CurrentObject = Objects[object.type]
  if (!CurrentObject) {
    throw new Error(`Unknown Object of type ${object.type}`)
  }

  const scaledSizes = useScaledSize(object.position, canvasSize.scalingSide)

  const onClick = useCallback(
    ({ evt }: Konva.KonvaEventObject<MouseEvent>) => {
      if (mode === 'editor') {
        if (evt.shiftKey) {
          dispatch(toggleSelectedObjects({ objectIds: [object.id] }))
        } else {
          dispatch(updateSelectedObjects([object.id]))
        }
      }
    },
    [dispatch, mode, object.id],
  )

  const onTransformStart = useCallback(() => {
    flushTextChanges({
      dispatch,
      pageId: pageId,
      pageObject: object,
      selectedObjects,
    })
    dispatch(updateEditingState('dragging'))
  }, [dispatch, selectedObjects, pageId, object])

  const onDragStart = useCallback(() => {
    flushTextChanges({
      dispatch,
      pageId: pageId,
      pageObject: object,
      selectedObjects,
    })
    dispatch(updateEditingState('dragging'))
    if (!selectedObjects.includes(object.id)) {
      dispatch(updateSelectedObjects([object.id]))
    }
  }, [selectedObjects, object, dispatch, pageId])

  const onDragEnd = useCallback(
    ({ target }: Konva.KonvaEventObject<MouseEvent>) => {
      dispatch(updateEditingState('none'))
      dispatch(
        updateObjectPosition({
          objectId: object.id,
          pageId,
          position: {
            x: scaledToRelative(target.x(), canvasSize.scalingSide),
            y: scaledToRelative(target.y(), canvasSize.scalingSide),
          },
        }),
      )
    },
    [dispatch, pageId, object.id, canvasSize.scalingSide],
  )

  const renderProps = {
    draggable: mode === 'editor',
    height: scaledSizes.height,
    id: object.id,
    name: 'page-object',
    onClick,
    onDragEnd: mode === 'editor' ? onDragEnd : noop,
    onDragStart: mode === 'editor' ? onDragStart : noop,
    onMouseEnter: () => {
      if (mode === 'editor') {
        document.body.classList.add('cursor-move')
      }
    },
    onMouseLeave: () => {
      if (mode === 'editor') {
        document.body.classList.remove('cursor-move')
      }
    },
    onTransformEnd: mode === 'editor' ? onTransformEnd : noop,
    onTransformStart: mode === 'editor' ? onTransformStart : noop,
    rotation: object.position.r,
    width: scaledSizes.width,
    x: scaledSizes.x,
    y: scaledSizes.y,
  }

  return (
    <CurrentObject.renderObject
      object={object}
      pageId={pageId}
      dispatch={dispatch}
      renderProps={renderProps}
      size={canvasSize}
      editingState={editingState}
      selected={selectedObjects.indexOf(object.id) > -1}
      snapshots={snapshots}
      mode={mode}
      slug={slug}
    />
  )
}

export function HTMLObject({
  object,
  pageId,
  canvasSize,
  dispatch,
  editingState,
  selectedObjects,
}: Omit<ObjectProps, 'onTransformEnd'>): JSX.Element | null {
  const CurrentObject = Objects[object.type]
  if (!CurrentObject) {
    throw new Error(`Unknown Object of type ${object.type}`)
  }
  if (!CurrentObject.renderHTML) {
    return null
  }

  return (
    <CurrentObject.renderHTML
      object={object}
      size={canvasSize}
      dispatch={dispatch}
      pageId={pageId}
      editingState={editingState}
      selected={selectedObjects.indexOf(object.id) > -1}
    />
  )
}
