import { kebabCase } from 'lodash-es'
import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  SignupSource,
  useCreateOrgMutation,
  useCreateThemeMutation,
  useGetThemesLazyQuery,
} from '@/generated/graphql'
import { useAuth } from '@/shared/auth/useAuth'
import { Button, PlusLogo } from '@/shared/components'
import { sanityClient } from '@/shared/sanity/client'
import { capitalize } from '@/util'
import PietroThumbsUp from '@/web/assets/pietro_thumbs_up.png'

import { FullPageLoader } from '../components/FullPageLoader'
import { useViewer } from '../hooks'

interface CMSTheme {
  name: string
  headerFont: string
  bodyFont: string
  backgroundColor: string
  fontColor: string
  colorOne: string
  colorTwo: string
  colorThree: string
  colorFour: string
  googleId?: string
  thumbnail?: string
}

const ImportTheme = (): JSX.Element => {
  const [importingTheme, setImportingTheme] = React.useState(false)
  const { loading: viewerLoading, reloadViewer, viewer } = useViewer()
  const [createOrgMutation, { loading }] = useCreateOrgMutation()
  const [getThemesQuery] = useGetThemesLazyQuery()
  const [createThemeMutation] = useCreateThemeMutation()
  const [hasError, setHasError] = React.useState(false)
  const history = useHistory()
  const { refreshToken } = useAuth()
  const { themeId } = useParams<{
    themeId?: string
  }>()

  React.useEffect(() => {
    if (!viewerLoading && viewer && viewer.organizations.length === 0) {
      const run = async () => {
        const email = viewer.email
        const localPart = email.split('@')[0]

        const { errors } = await createOrgMutation({
          variables: {
            input: {
              name: `${capitalize(localPart)}'s Organization`,
              slug: `${kebabCase(localPart).slice(0, 35)}-${Math.random()
                .toString(36)
                .slice(4, 8)}`,
              user: {
                name: email,
                source: SignupSource.ThemeLibrary,
              },
            },
          },
        })

        if (errors) {
          setHasError(true)
        }

        await refreshToken()
        await reloadViewer()
      }

      void run()
    } else if (!viewerLoading && viewer && viewer.organizations.length > 0) {
      const run = async () => {
        if (!themeId) {
          setHasError(true)
        }

        setImportingTheme(true)
        const cmsThemeData = await sanityClient.fetch<CMSTheme[]>(
          `*[_type == 'theme' && id.current == "${themeId as string}"] {
            name,
            headerFont,
            bodyFont,
            "backgroundColor": backgroundColor.hex,
            "fontColor": fontColor.hex,
            "colorOne": colorOne.hex,
            "colorTwo": colorTwo.hex,
            "colorThree": colorThree.hex,
            "colorFour": colorFour.hex,
            "googleId": presentationId,
            thumbnail
          }`,
        )

        if (!cmsThemeData[0]) {
          setHasError(true)
          return
        }

        const existingUserThemes = await getThemesQuery()

        // Check to make sure user hasn't already imported this theme before
        // We do this via a soft name check but that should be fine for now
        // If they have already imported just skip the import process and show success state
        if (
          existingUserThemes.data?._experimental.themes.find((x) => x.name === cmsThemeData[0].name)
        ) {
          setImportingTheme(false)
          return
        }

        const result = await createThemeMutation({
          variables: {
            input: {
              ...cmsThemeData[0],
              useBackgroundImages: !!cmsThemeData[0].googleId,
            },
          },
        })

        if (result.errors) {
          setHasError(true)
        } else {
          setImportingTheme(false)
        }
      }

      void run()
    }
  }, [
    createOrgMutation,
    createThemeMutation,
    getThemesQuery,
    refreshToken,
    reloadViewer,
    themeId,
    viewer,
    viewerLoading,
  ])

  if (hasError) {
    return (
      <div className="mt-[33vh] flex h-full w-full flex-col items-center justify-center p-2 text-center">
        <PlusLogo width="32" />
        <p className="text-l mt-3 font-semibold md:text-xl">
          We ran into an error and couldn&apos;t import your theme.
        </p>
        <Button onClick={() => history.push('/')}>Back to Plus</Button>
        <img className="absolute bottom-0 left-0" src={PietroThumbsUp} />
      </div>
    )
  }

  if (viewerLoading || loading || importingTheme) {
    return <FullPageLoader loading={true} />
  }

  return (
    <div className="flex flex-row">
      <div className="bg-base-gray960 flex h-screen flex-1 flex-col items-center p-3 sm:justify-center sm:p-0">
        <div className="border-divider-light-gray bg-base-white shadow-soft w-full max-w-[400px] rounded border sm:w-72">
          <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
            <div className="flex w-full flex-col items-center justify-center gap-1">
              <PlusLogo width="48" height="48" />
              <h1 className="text-center text-xl font-semibold">Theme imported!</h1>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center p-6 text-center">
            <div className="text-center text-base font-normal">
              You&apos;ve successfully imported a theme into{' '}
              <a
                href="https://workspace.google.com/marketplace/app/plus_ai_for_google_slides/214277172452"
                target="_blank"
                rel="noreferrer"
                className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
              >
                Plus AI for Google Slides
              </a>
              .
            </div>
            <div>
              <a
                href="https://guide.plusdocs.com/plus-ai/use-custom-themes"
                target="_blank"
                rel="noreferrer"
                className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
              >
                <Button variant="primary" className="my-2">
                  Show me how to use themes
                </Button>
              </a>
              <a
                href="https://workspace.google.com/marketplace/app/plus_ai_for_google_slides/214277172452"
                target="_blank"
                rel="noreferrer"
                className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
              >
                <Button variant="secondary">Get the Google Slides add-on</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImportTheme
