import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import { useState } from 'react'
import { Menu } from 'react-feather'
import { Link } from 'react-router-dom'

import { SnapshotFieldsFragment } from '@/generated/graphql'
import { Button, PlusLogo } from '@/shared/components'
import { getSnapshotStatus } from '@/util/snapshotHelpers'
import { useViewer } from '@/web/hooks'
import { Handlers } from '@/web/hooks/useControlledPopover'

type SnapshotMenuProps = {
  deleteSnapshotCallback: () => void
  moveSnapshotCallback: () => void
  isMenuOpen: boolean
  menuPopoverHandlers: Handlers
  snapshot?: SnapshotFieldsFragment
}

const generateBackToDashboardLink = (snapshot: SnapshotFieldsFragment, activeOrgSlug?: string) => {
  const folderPath = snapshot.folder && `/folders/${snapshot.folder.id}`
  const teamPath = snapshot.team && `/team/${snapshot.team.id}`

  if (!activeOrgSlug) {
    return '/'
  }

  return `/${activeOrgSlug}${teamPath || ''}${folderPath || ''}`
}

export const SnapshotMenu = ({
  deleteSnapshotCallback,
  moveSnapshotCallback,
  snapshot,
}: SnapshotMenuProps): JSX.Element => {
  const { activeOrganization } = useViewer()
  const [open, setOpen] = useState(false)

  if (snapshot && snapshot.viewer.canEdit) {
    const { snapshotCanBeDeleted } = getSnapshotStatus(snapshot)

    return (
      <DropdownMenu open={open} onOpenChange={setOpen} modal={false}>
        <DropdownMenuTrigger asChild>
          <Button
            icon={<Menu size={16} />}
            variant="ghost"
            aria-hidden="true"
            className={clsx('focus:outline-none', {
              '!bg-interactive-secondary-active': open,
              '!border-divider-light-blue': open,
              '!border-transparent': !open,
              '!text-interactive-primary': open,
              'focus:!border-divider-light-blue': open,
            })}
            active={open}
          />
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
          <DropdownMenuContent className="font-base border-divider-light-gray bg-background-white shadow-soft w-52 rounded-md border text-base outline-none">
            <DropdownMenuItem asChild>
              <div className="px-2 pt-2 outline-none">
                <Link
                  className="hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover flex cursor-pointer rounded-md p-2 outline-none"
                  to={generateBackToDashboardLink(snapshot, activeOrganization?.slug)}
                >
                  <span className="line-clamp-1 text-ellipsis">
                    Back to {snapshot.team ? 'Team' : 'Personal'}
                    {snapshot.folder && ' / ' + snapshot.folder.name}
                  </span>
                </Link>
              </div>
            </DropdownMenuItem>
            <div className="bg-divider-light-gray my-2 h-[1px] w-full"></div>
            <div className="px-2">
              <DropdownMenuItem
                className="hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover flex cursor-pointer rounded-md p-2 outline-none"
                onClick={moveSnapshotCallback}
              >
                Move Snapshot
              </DropdownMenuItem>
            </div>
            {snapshot.viewer.canDelete && (
              <div className="px-2 pb-2">
                <DropdownMenuItem
                  disabled={!snapshotCanBeDeleted}
                  className={clsx(
                    'flex cursor-pointer rounded-md p-2 outline-none disabled:cursor-not-allowed disabled:opacity-50',
                    {
                      'focus-visible:bg-interactive-secondary-hover': snapshotCanBeDeleted,
                      'hover:bg-interactive-secondary-hover': snapshotCanBeDeleted,
                    },
                  )}
                  onClick={!snapshotCanBeDeleted ? undefined : deleteSnapshotCallback}
                >
                  Delete Snapshot
                </DropdownMenuItem>
              </div>
            )}
          </DropdownMenuContent>
        </DropdownMenuPortal>
      </DropdownMenu>
    )
  }

  return (
    <a href="https://plusdocs.com">
      <Button
        icon={<PlusLogo height={'24'} width={'24'} />}
        variant="ghost"
        aria-hidden="true"
        className="h-full w-full p-1"
      />
    </a>
  )
}
