import {
  buildSnapshotUrn,
  SnapshotVersionUrnString,
  tryParseSnapshotVersionUrn,
} from '@plusdocs/utils/common/urns'
import { useCallback, useMemo } from 'react'

import { useReportSnapshotIssueMutation } from '@/generated/graphql'
import { showModal } from '@/util'

import { ReportSnapshotIssueModal } from '../components/SnapshotViewer/ReportSnapshotIssue'

export const useReportSnapshotIssueCallback = (urn: SnapshotVersionUrnString) => {
  const [reportIssue] = useReportSnapshotIssueMutation()
  const parsedUrn = useMemo(() => tryParseSnapshotVersionUrn(urn), [urn])

  const reportIssueCallback = useCallback(
    async (description?: string) => {
      await reportIssue({
        variables: {
          input: {
            description,
            version: urn,
          },
          urn: buildSnapshotUrn({
            organizationId: parsedUrn?.organizationId ?? '',
            snapshotId: parsedUrn?.snapshotId ?? '',
          }),
        },
      })
    },
    [parsedUrn, reportIssue, urn],
  )

  const showReportIssueModal = useCallback(() => {
    void showModal(<ReportSnapshotIssueModal reportIssueCallback={reportIssueCallback} />)
  }, [reportIssueCallback])

  return showReportIssueModal
}
