import basePalette from '@plusdocs/styles/palette.json'
import clsx from 'clsx'
import type { Property } from 'csstype'
import Konva from 'konva'
import { useCallback, useEffect, useRef } from 'react'
import * as React from 'react'
import { AlignCenter, AlignLeft, AlignRight, Bold, Italic, Underline } from 'react-feather'
import { Rect, Text } from 'react-konva'

import { ButtonGroup } from '@/shared/components'
import type {
  FontSize,
  HorizontalAlign,
  LineHeight,
  PageObject,
  SettingsProps,
  TextObjectContent,
  TextPageObject,
} from '@/shared/types/document'
import flushTextChanges from '@/util/flushTextChanges'
import { scaledToRelative } from '@/util/transformPositions'
// import AlignTop from '@/web/assets/icons/AlignTop';
// import AlignMiddle from '@/web/assets/icons/AlignMiddle';
// import AlignBottom from '@/web/assets/icons/AlignBottom';
import { FONT_SIZES, LINE_HEIGHTS } from '@/web/constants'
import { useAppSelector } from '@/web/hooks/__DEPRECATED_reduxHooks'
import { AppState, selectSelectedObjects, updateEditingState } from '@/web/store/appState'
import {
  updateObjectPosition,
  updateObjectTemp,
  // VerticalAlign,
} from '@/web/store/document'
import { AppDispatch } from '@/web/store/types'

import TextQuickSettings from './TextQuickSettings'
import { RendererConfig, RenderProps } from './types'

interface RenderState {
  x: number
  y: number
  textWidth: number
  rectWidth: number
  textHeight: number
  rectHeight: number
  rotation: number
  padding: number
  fontFamily: string
  fontSize: number
  fontStyle: string
  textDecoration: string
  lineHeight: number
  textAlign: string
  verticalAlign: string
  textArrLen: number
}

const CanvasText = (props: RenderProps): JSX.Element => {
  const object = props.object as TextPageObject
  const { renderProps, dispatch, pageId, size, editingState, selected, mode } = props

  const rectRef = useRef<Konva.Rect>(null)
  const textRef = useRef<Konva.Text>(null)
  const isPlaceholder = object.content.text === ''

  const selectedObjects = useAppSelector(selectSelectedObjects)

  const [renderState, setRenderState] = React.useState<RenderState>({} as RenderState)
  useEffect(() => {
    if (!rectRef.current || !textRef.current || editingState === 'editingText') return
    const { current: rect } = rectRef
    const { current: text } = textRef

    const cloned = text.clone({
      height: 'auto',
      listening: false,
      visible: false,
    }) as Konva.Text
    const textHeight = Math.ceil(cloned.height()) + cloned.lineHeight()
    cloned.remove()
    const newState = {
      fontFamily: text.fontFamily(),
      fontSize: text.fontSize(),
      fontStyle: text.fontStyle(),
      lineHeight: text.lineHeight(),
      padding: text.padding(),
      rectHeight: rect.height(),
      rectWidth: rect.width(),
      rotation: rect.rotation(),
      textAlign: text.align(),
      textArrLen: text.textArr.length,
      textDecoration: text.textDecoration(),
      textHeight,
      textWidth: text.getTextWidth() + text.padding() * 2,
      verticalAlign: text.verticalAlign(),
      x: rect.x(),
      y: rect.y(),
    }

    setRenderState(newState)
    if (mode === 'editor') {
      dispatch(
        updateObjectTemp({
          objectId: object.id,
          pageId,
          temp: newState,
        }),
      )
      if (text.height() !== newState.textHeight) {
        void dispatch(
          updateObjectPosition({
            objectId: object.id,
            pageId,
            position: {
              h: scaledToRelative(newState.textHeight, size.scalingSide),
            },
          }),
        )
      }
    }
  }, [
    dispatch,
    textRef,
    rectRef,
    size,
    object.id,
    object.content,
    object.position,
    pageId,
    mode,
    editingState,
  ])

  const updateTextPosition = useCallback(
    ({ target }: Konva.KonvaEventObject<Event>) => {
      if (!textRef.current) return
      const cloned = textRef.current.clone({
        height: 'auto',
        listening: false,
        visible: false,
      }) as Konva.Text
      const height = Math.ceil(cloned.height()) + cloned.lineHeight()
      cloned.remove()
      const newPosition = {
        rectHeight: height,
        rectWidth: Math.ceil(target.width() * target.scaleX()),
        rotation: target.rotation(),
        x: target.x(),
        y: target.y(),
      }
      target.height(height)
      setRenderState({ ...renderState, ...newPosition })
      dispatch(
        updateObjectTemp({
          objectId: object.id,
          pageId,
          temp: newPosition,
        }),
      )
    },
    [dispatch, object.id, pageId, renderState],
  )

  const shouldRenderSelectOutline = selected && selectedObjects.length > 1

  return (
    <>
      <Rect
        ref={rectRef}
        {...renderProps}
        perfectDrawEnabled={false}
        fill="transparent"
        dash={isPlaceholder && !shouldRenderSelectOutline ? [2, 2] : undefined}
        stroke={
          shouldRenderSelectOutline
            ? basePalette.primaryBlue
            : isPlaceholder
            ? basePalette.gray910
            : undefined
        }
        strokeWidth={shouldRenderSelectOutline ? 1 : 2}
        strokeScaleEnabled={false}
        onDragMove={updateTextPosition}
        onTransform={updateTextPosition}
        onDblClick={() => {
          if (selected && editingState === 'editingText') return
          dispatch(updateEditingState('editingText'))
          dispatch(
            updateObjectTemp({
              objectId: object.id,
              pageId: pageId,
              temp: {
                text: object.content.text,
              },
            }),
          )
        }}
      />
      <Text
        perfectDrawEnabled={false}
        x={renderState.x}
        y={renderState.y}
        listening={false}
        ref={textRef}
        width={renderState.rectWidth}
        height={renderState.rectHeight}
        rotation={renderState.rotation}
        text={!isPlaceholder ? object.content.text : 'Text'}
        fill={isPlaceholder ? basePalette.gray550 : basePalette.black}
        padding={8}
        fontSize={object.content.fontSize}
        fontStyle={object.content.fontStyle}
        fontFamily={object.content.fontFamily}
        textDecoration={object.content.textDecoration}
        lineHeight={object.content.lineHeight}
        align={object.content.horizontalAlign}
        verticalAlign={object.content.verticalAlign}
        visible={!selected || (selected && editingState !== 'editingText')}
      />
    </>
  )
}

const HTMLText = (props: {
  object: PageObject
  size: AppState['canvas']
  dispatch: AppDispatch
  pageId: string
  selected: boolean
  editingState: AppState['editingState']
}): JSX.Element | null => {
  const { size, dispatch, pageId, editingState, selected } = props
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (selected && editingState === 'editingText') {
      textareaRef.current?.focus()
      textareaRef.current?.setSelectionRange(
        Number.MAX_SAFE_INTEGER, // if people enter more text than this... we've got a problem
        Number.MAX_SAFE_INTEGER,
      )
    }
  }, [editingState, textareaRef, selected])

  const object = props.object as TextPageObject
  if (Object.keys(object.temp).length === 0) return null

  const rectWidth = object.temp.rectWidth as number
  const textWidth = object.temp.textWidth as number
  const rectHeight = object.temp.rectHeight as number
  const verticalAlign = object.temp.verticalAlign as string
  const textAlign = object.temp.textAlign as string
  const padding = object.temp.padding as number
  const textArrLen = object.temp.textArrLen as number
  const lineHeightPx = (object.temp.lineHeight as number) * (object.temp.fontSize as number)
  const calculatedXPadding =
    textAlign === 'center'
      ? (rectWidth - textWidth - padding * 2) / 2
      : textAlign === 'right'
      ? rectWidth - textWidth
      : 0
  const calculatedYPadding =
    verticalAlign === 'middle'
      ? (rectHeight - textArrLen * lineHeightPx - padding * 2) / 2
      : verticalAlign === 'bottom'
      ? rectHeight - textArrLen * lineHeightPx - padding * 2
      : 0
  const settingsPosition = new Konva.Transform()
    .translate((object.temp.x as number) * size.scale, (object.temp.y as number) * size.scale)
    .rotate(((object.temp.rotation as number) * Math.PI) / 180)
    .translate(0, -40)
    .getTranslation()
  // [padding adjustment, underline thickness, underline offset]
  const textTweaks: { [size: number]: number[] } = {
    [FONT_SIZES.LARGE]: [-4, 5, 8],
    [FONT_SIZES.MEDIUM]: [-2, 3, 5],
    [FONT_SIZES.SMALL]: [-1, 2, 3],
    // This is required so that old medium text elements don't crash the editor :(
    [48]: [-2, 3, 5],
  }
  return (
    <>
      <div className="absolute top-0 left-0">
        <textarea
          ref={textareaRef}
          className={clsx({
            'pointer-events-auto': selected && editingState === 'editingText',
          })}
          value={object.temp.text as string}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            boxShadow: 'none',
            display: 'block',
            fontFamily: object.temp.fontFamily as string,
            fontSize: `${(object.temp.fontSize as number) * size.scale}px`,
            fontStyle: (object.temp.fontStyle as string)
              .replace('bold', '')
              .replace('normal', '')
              .trim(),
            fontWeight: (object.temp.fontStyle as string).indexOf('bold') > -1 ? 700 : 400,
            height: `${
              (editingState === 'editingText'
                ? (object.temp.rectHeight as number)
                : (object.temp.textHeight as number)) * size.scale
            }px`,
            lineHeight: `${object.temp.lineHeight}`,
            marginLeft: editingState === 'editingText' ? 0 : `${calculatedXPadding * size.scale}px`,
            marginTop: editingState === 'editingText' ? 0 : `${calculatedYPadding * size.scale}px`,
            opacity: editingState === 'editingText' ? 1 : 0,
            outline: 'none',
            overflow: 'hidden',
            padding:
              editingState === 'editingText'
                ? `${
                    calculatedYPadding * size.scale +
                    padding * size.scale +
                    textTweaks[object.temp.fontSize as number][0] * size.scale
                  }px ${padding * size.scale}px`
                : `${padding * size.scale}px`,
            position: 'relative',
            resize: 'none',
            textAlign: object.temp.textAlign as Property.TextAlign,
            textDecoration: object.temp.textDecoration as Property.TextDecoration,
            textDecorationSkipInk: 'none',
            textDecorationThickness:
              textTweaks[object.temp.fontSize as number][1] * size.scale + 'px',
            textUnderlineOffset: textTweaks[object.temp.fontSize as number][2] * size.scale + 'px',
            transform: `translate(${(object.temp.x as number) * size.scale}px, ${
              (object.temp.y as number) * size.scale
            }px) rotate(${object.temp.rotation}deg)`,
            transformOrigin: 'top left',
            verticalAlign: object.temp.verticalAlign as string,
            width: `${
              (editingState === 'editingText'
                ? (object.temp.rectWidth as number)
                : (object.temp.textWidth as number)) * size.scale
            }px`,
            wordBreak: 'break-word',
          }}
          onChange={({ target }) => {
            target.style.height = `0px`
            target.style.height = `${Math.ceil(target.scrollHeight)}px`
            void dispatch(
              updateObjectPosition({
                objectId: object.id,
                pageId: pageId,
                position: {
                  h: scaledToRelative(
                    Math.ceil(target.scrollHeight / size.scale) +
                      (object.temp.lineHeight as number),
                    size.scalingSide,
                  ),
                },
              }),
            )
            dispatch(
              updateObjectTemp({
                objectId: object.id,
                pageId: pageId,
                temp: {
                  text: target.value,
                },
              }),
            )
          }}
          onBlur={() => {
            flushTextChanges({
              dispatch,
              pageId: pageId,
              pageObject: object,
              selectedObjects: [object.id],
            })
            dispatch(updateEditingState('none'))
          }}
          onKeyDown={(ev) => {
            if ((ev.ctrlKey || ev.metaKey) && ev.key === 'Enter') {
              flushTextChanges({
                dispatch,
                pageId: pageId,
                pageObject: object,
                selectedObjects: [object.id],
              })
              dispatch(updateEditingState('none'))
            }
          }}
        ></textarea>
      </div>
      <div className="absolute top-0 left-0">
        <div
          style={{
            transform: `translate(${settingsPosition.x}px, ${settingsPosition.y}px) rotate(${
              object.temp.rotation as number
            }deg)`,
          }}
          className="border-divider-light-gray bg-background-white shadow-soft pointer-events-auto relative flex h-8 rounded border p-1"
        >
          <TextQuickSettings dispatch={dispatch} pageId={pageId} object={object} />
        </div>
      </div>
    </>
  )
}

const TextStyleOptions = ({
  getValueForButtonGroup,
  toggleValueForButtonGroup,
}: {
  getValueForButtonGroup: <TProp extends 'fontStyle' | 'textDecoration'>(
    prop: TProp,
  ) => TextObjectContent[TProp] | 'Mixed'
  toggleValueForButtonGroup: <TProp extends 'fontStyle' | 'textDecoration'>(
    property: TProp,
    value: TextObjectContent[TProp],
  ) => () => void
}) => {
  const currentValue = React.useMemo(
    () => getValueForButtonGroup('fontStyle'),
    [getValueForButtonGroup],
  )

  const hasValue = React.useCallback(
    (value: string) => currentValue.indexOf(value) > -1,
    [currentValue],
  )

  const isUnderline = React.useMemo(
    () => getValueForButtonGroup('textDecoration').indexOf('underline') > -1,
    [getValueForButtonGroup],
  )

  return (
    <div>
      <label htmlFor="text-style-settings" className="mb-1 block text-base font-semibold">
        Style
      </label>
      <ButtonGroup
        id="text-style-settings"
        controlled
        sharedButtonProps={{ className: 'flex justify-center !py-2 !px-1' }}
      >
        <ButtonGroup.Button
          active={hasValue('bold')}
          onClick={toggleValueForButtonGroup('fontStyle', 'bold')}
          tooltip="Bold"
        >
          <Bold size={16} />
        </ButtonGroup.Button>
        <ButtonGroup.Button
          active={hasValue('italic')}
          onClick={toggleValueForButtonGroup('fontStyle', 'italic')}
          tooltip="Italic"
        >
          <Italic size={16} />
        </ButtonGroup.Button>
        <ButtonGroup.Button
          active={isUnderline}
          onClick={toggleValueForButtonGroup('textDecoration', 'underline')}
          tooltip="Underline"
        >
          <Underline size={16} />
        </ButtonGroup.Button>
      </ButtonGroup>
    </div>
  )
}

const TextSizeOptions = ({
  getValueForButtonGroup,
  setObjectContentProperty,
}: {
  getValueForButtonGroup: (prop: 'fontSize') => FontSize | 'Mixed'
  setObjectContentProperty: (
    property: 'fontSize' | 'lineHeight',
    value: FontSize | LineHeight,
  ) => void
}) => {
  const isCurrentValue = React.useCallback(
    (value: FontSize) => getValueForButtonGroup('fontSize') === value,
    [getValueForButtonGroup],
  )

  return (
    <div>
      <label htmlFor="text-size-settings" className="mb-1 block text-base font-semibold">
        Size
      </label>
      <ButtonGroup
        id="text-size-settings"
        controlled
        sharedButtonProps={{ className: '!font-semibold !py-2 !px-1' }}
      >
        <ButtonGroup.Button
          active={isCurrentValue(FONT_SIZES.SMALL)}
          onClick={() => {
            setObjectContentProperty('fontSize', FONT_SIZES.SMALL)
            setObjectContentProperty('lineHeight', LINE_HEIGHTS.SMALL)
          }}
        >
          Small
        </ButtonGroup.Button>
        <ButtonGroup.Button
          active={isCurrentValue(FONT_SIZES.MEDIUM)}
          onClick={() => {
            setObjectContentProperty('fontSize', FONT_SIZES.MEDIUM)
            setObjectContentProperty('lineHeight', LINE_HEIGHTS.MEDIUM)
          }}
        >
          Medium
        </ButtonGroup.Button>
        <ButtonGroup.Button
          active={isCurrentValue(FONT_SIZES.LARGE)}
          onClick={() => {
            setObjectContentProperty('fontSize', FONT_SIZES.LARGE)
            setObjectContentProperty('lineHeight', LINE_HEIGHTS.LARGE)
          }}
        >
          Large
        </ButtonGroup.Button>
      </ButtonGroup>
    </div>
  )
}

const TextHorizontalAlignmentOptions = ({
  getValueForButtonGroup,
  setObjectContentProperty,
}: {
  getValueForButtonGroup: (
    prop: 'horizontalAlign',
  ) => TextObjectContent['horizontalAlign'] | 'Mixed'
  setObjectContentProperty: (
    property: 'horizontalAlign',
    value: TextObjectContent['horizontalAlign'],
  ) => void
}) => {
  const isCurrentValue = React.useCallback(
    (value: HorizontalAlign) => getValueForButtonGroup('horizontalAlign') === value,
    [getValueForButtonGroup],
  )

  return (
    <div>
      <label
        htmlFor="text-horizontal-alignment-settings"
        className="mb-1 block text-base font-semibold"
      >
        Horizontal alignment
      </label>
      <ButtonGroup
        id="text-horizontal-alignment-settings"
        controlled
        sharedButtonProps={{ className: 'flex justify-center !py-2 !px-1' }}
      >
        <ButtonGroup.Button
          active={isCurrentValue('left')}
          onClick={() => setObjectContentProperty('horizontalAlign', 'left')}
          tooltip="Left"
        >
          <AlignLeft size={16} />
        </ButtonGroup.Button>
        <ButtonGroup.Button
          active={isCurrentValue('center')}
          onClick={() => setObjectContentProperty('horizontalAlign', 'center')}
          tooltip="Center"
        >
          <AlignCenter size={16} />
        </ButtonGroup.Button>
        <ButtonGroup.Button
          active={isCurrentValue('right')}
          onClick={() => setObjectContentProperty('horizontalAlign', 'right')}
          tooltip="Right"
        >
          <AlignRight size={16} />
        </ButtonGroup.Button>
      </ButtonGroup>
    </div>
  )
}

/*
const TextVerticalAlignmentOptions = ({
	getValueForButtonGroup,
	setObjectContentProperty,
}: {
	getValueForButtonGroup: <R>(prop: string) => R | 'Mixed';
	setObjectContentProperty: (property: string, value: unknown) => void;
}) => {
	const currentValue = React.useMemo(
		() => getValueForButtonGroup<VerticalAlign>('verticalAlign'),
		[getValueForButtonGroup],
	);

	const isCurrentValue = React.useCallback(
		(value: VerticalAlign) => currentValue === value,
		[currentValue],
	);

	return (
		<div>
			<label
				htmlFor="text-vertical-alignment-settings"
				className="block font-semibold text-base mb-1"
			>
				Vertical alignment
			</label>
			<ButtonGroup
				id="text-vertical-alignment-settings"
				controlled
				sharedButtonProps={{ className: 'flex justify-center !py-2 !px-1' }}
			>
				<ButtonGroup.Button
					active={isCurrentValue('top')}
					onClick={() => setObjectContentProperty('verticalAlign', 'top')}
					tooltip="Top"
				>
					<AlignTop />
				</ButtonGroup.Button>
				<ButtonGroup.Button
					active={isCurrentValue('middle')}
					onClick={() => setObjectContentProperty('verticalAlign', 'middle')}
					tooltip="Middle"
				>
					<AlignMiddle />
				</ButtonGroup.Button>
				<ButtonGroup.Button
					active={isCurrentValue('bottom')}
					onClick={() => setObjectContentProperty('verticalAlign', 'bottom')}
					tooltip="Bottom"
				>
					<AlignBottom />
				</ButtonGroup.Button>
			</ButtonGroup>
		</div>
	);
};
*/

const TextSettings = ({
  getObjectContentProperty,
  setObjectContentProperty,
}: SettingsProps<TextObjectContent>): JSX.Element => {
  function getValueForButtonGroup<TKey extends keyof TextObjectContent>(
    property: TKey,
  ): TextObjectContent[TKey] | 'Mixed' {
    const values = getObjectContentProperty(property)
    const allValuesEqual = values.every((v) => v === values[0])
    if (allValuesEqual) {
      return values[0]
    } else {
      return 'Mixed'
    }
  }
  const toggleValueForButtonGroup =
    <TProp extends 'fontStyle' | 'textDecoration'>(
      property: TProp,
      value: TextObjectContent[TProp],
    ) =>
    () => {
      const previousValue = getValueForButtonGroup(property)
      if (previousValue === 'Mixed') {
        setObjectContentProperty(property, value)
        return
      }
      const values = previousValue.split(' ')
      if (values.indexOf(value) > -1) {
        values.splice(values.indexOf(value), 1)
      } else {
        values.push(value)
      }
      setObjectContentProperty(property, values.join(' ').trim())
    }

  return (
    <>
      <div className="space-y-3">
        <TextSizeOptions
          getValueForButtonGroup={getValueForButtonGroup}
          setObjectContentProperty={setObjectContentProperty}
        />
        <TextStyleOptions
          getValueForButtonGroup={getValueForButtonGroup}
          toggleValueForButtonGroup={toggleValueForButtonGroup}
        />
        <TextHorizontalAlignmentOptions
          getValueForButtonGroup={getValueForButtonGroup}
          setObjectContentProperty={setObjectContentProperty}
        />
        {/* <TextVerticalAlignmentOptions
					getValueForButtonGroup={getValueForButtonGroup}
					setObjectContentProperty={setObjectContentProperty}
				/> */}
      </div>
    </>
  )
}

const config: RendererConfig = {
  name: 'Text',
  renderHTML: HTMLText,
  renderObject: CanvasText,
  renderSettings: TextSettings,
  transformOptions: {
    enabledAnchors: ['middle-left', 'middle-right'],
  },
}
export default config
