import * as React from 'react'
import { Grid } from 'react-feather'

import { ButtonGroup } from '@/shared/components'
import { GridSmall } from '@/shared/components/Icons'

export const FeedSelectMenu = ({
  isFeed,
  setIsFeed,
}: {
  isFeed: boolean | undefined
  setIsFeed: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <ButtonGroup
      sharedButtonProps={{ className: 'h-8 w-16 flex items-center justify-center ' }}
      className={'w-fit flex-none'}
    >
      <ButtonGroup.Button active={!isFeed} onClick={() => setIsFeed(true)}>
        <Grid size={16} />
      </ButtonGroup.Button>
      <ButtonGroup.Button active={!isFeed} onClick={() => setIsFeed(false)}>
        <GridSmall size={16} />
      </ButtonGroup.Button>
    </ButtonGroup>
  )
}
