import clsx from 'clsx'

type Props = {
  readonly className?: string
}

const Sort = (props: Props): JSX.Element => {
  return (
    <svg
      className={clsx('fill-current', props.className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.56242 12.6163L5.37055 11.8081C5.6143 11.5641 6.01055 11.5641 6.2543 11.8081C6.49836 12.0522 6.49836 12.4478 6.2543 12.6919L4.3793 14.5669C4.25742 14.6891 4.09742 14.75 3.93742 14.75C3.77742 14.75 3.61742 14.6891 3.49555 14.5669L1.62055 12.6919C1.37648 12.4478 1.37648 12.0522 1.62055 11.8081C1.8643 11.5641 2.26055 11.5641 2.5043 11.8081L3.31242 12.6163V1.625C3.31242 1.28 3.59242 1 3.93742 1C4.28242 1 4.56242 1.28 4.56242 1.625V12.6163Z" />
      <path d="M7.6875 9.125H10.8125C11.1575 9.125 11.4375 9.405 11.4375 9.75C11.4375 10.095 11.1575 10.375 10.8125 10.375H7.6875C7.3425 10.375 7.0625 10.095 7.0625 9.75C7.0625 9.405 7.3425 9.125 7.6875 9.125Z" />
      <path d="M12.0625 6H7.6875C7.3425 6 7.0625 6.28 7.0625 6.625C7.0625 6.97 7.3425 7.25 7.6875 7.25H12.0625C12.4075 7.25 12.6875 6.97 12.6875 6.625C12.6875 6.28 12.4075 6 12.0625 6Z" />
      <path d="M7.6875 2.875H13.9375C14.2825 2.875 14.5625 3.155 14.5625 3.5C14.5625 3.845 14.2825 4.125 13.9375 4.125H7.6875C7.3425 4.125 7.0625 3.845 7.0625 3.5C7.0625 3.155 7.3425 2.875 7.6875 2.875Z" />
    </svg>
  )
}

export default Sort
