import { ApolloProvider } from '@apollo/client'
import { DialogClose, DialogDescription, DialogTitle } from '@radix-ui/react-dialog'
import { useState } from 'react'
import { X } from 'react-feather'

import { apolloClient } from '@/shared/apollo/apolloClient'
import { Button, Dialog } from '@/shared/components'
import { ViewerProvider } from '@/shared/providers/ViewerProvider'

import InviteTeammates from './InviteTeammates'

const InviteTeammatesModal = (props: { orgName: string }) => {
  const [isOpen, setIsOpen] = useState(true)
  const { orgName } = props

  return (
    <ApolloProvider client={apolloClient}>
      <ViewerProvider>
        <Dialog isOpen={isOpen} setIsOpen={setIsOpen} showBackgroundOverlay={true}>
          <div className="max-w-screen bg-background-white relative inset-0 inline-block max-h-full w-[600px] items-center justify-center rounded-md border border-black bg-white text-left align-middle opacity-100 shadow-lg">
            <div className="flex h-full flex-col p-3">
              <div className="flex h-max flex-row items-start justify-between">
                <div>
                  <DialogTitle className="text-xl font-semibold">Invite to {orgName}</DialogTitle>
                  <DialogDescription className="text-copy-secondary pt-1 pb-3 text-base">
                    Members will be able to create, edit, and view documents in Team
                  </DialogDescription>
                </div>
                <div className="h-full">
                  <DialogClose asChild onClick={() => setIsOpen(false)}>
                    <Button variant="ghost" tabIndex={-1} onClick={() => setIsOpen(false)}>
                      <X size={16} />
                    </Button>
                  </DialogClose>
                </div>
              </div>
              <InviteTeammates isModal />
            </div>
          </div>
        </Dialog>
      </ViewerProvider>
    </ApolloProvider>
  )
}

export default InviteTeammatesModal
