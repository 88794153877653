import { useReactiveVar } from '@apollo/client'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogPortal,
  DialogTrigger,
} from '@radix-ui/react-dialog'
import cx from 'clsx'
import { useMemo } from 'react'
import { Check, ChevronLeft, ChevronRight } from 'react-feather'
import { Link } from 'react-router-dom'

import { ViewerNavDataQuery } from '@/generated/graphql'
import { activeOrganizationState } from '@/shared/apollo/apolloLocalState'
import { useAuth } from '@/shared/auth/useAuth'
import { Button, OrgImage } from '@/shared/components'
import { useViewer } from '@/web/hooks'

const MobileOrgNavMenu = ({
  open,
  onMenuOpenChange,
  onOpenChange,
}: {
  open: boolean
  // Handler for parent mobile nav menu
  onMenuOpenChange: (open: boolean) => void
  // Handler for org sub menu
  onOpenChange: (open: boolean) => void
}): JSX.Element => {
  const { logout } = useAuth()
  const { viewer } = useViewer()
  const activeOrgState = useReactiveVar(activeOrganizationState)
  const invitations = viewer?.invitations
  const currentOrganization = viewer?.currentOrganization
  const organizations = viewer?.organizations

  const activeOrg = useMemo(
    () =>
      (organizations ?? []).find((x) => x?.slug === activeOrgState?.slug) || currentOrganization,
    [activeOrgState?.slug, currentOrganization, organizations],
  )

  // This is a theoretical error case we should never hit
  if (!activeOrg || !organizations || !invitations) {
    return <></>
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger className="p-2" asChild>
        <div className="flex w-full flex-row justify-between gap-0 text-base">
          <OrgImage size="small" orgName={activeOrg.name} orgLogo={activeOrg.image} />
          <div className="ml-2 flex-1 truncate">
            <span className="text-primary  font-semibold">{activeOrg?.name}</span>
            <br />
            <span className="text-copy-secondary">
              {activeOrg?.users.totalCount} member
              {(activeOrg?.users.totalCount || 1) > 1 ? 's' : ''}
            </span>
          </div>
          <ChevronRight size={16} className="m-auto" />
        </div>
      </DialogTrigger>
      <DialogPortal>
        <DialogContent className="h-viewport-minus-header bg-background-white fixed top-0 left-0 right-0 w-screen p-3">
          <div className="mb-1 flex w-full items-center justify-between p-1">
            <DialogClose asChild>
              <Button variant="ghost" className="ml-1" icon={<ChevronLeft size="18px" />} />
            </DialogClose>
          </div>
          {organizations.map((org) =>
            orgContextMobileDropdownItem(org, activeOrg.id, onMenuOpenChange),
          )}

          {invitations.length > 0 && (
            <Link to="/invitations">
              <div className="mt-2 flex w-full flex-row justify-between p-2 text-base font-semibold">
                <div>View organization invites</div>
                <div className="bg-interactive-destructive text-copy-active flex h-4 w-4 items-center justify-center rounded-full text-base font-semibold">
                  {invitations.length}
                </div>
              </div>
            </Link>
          )}
          <div className="p-2">
            <DialogClose>
              <span onClick={() => logout()} className="text-base font-semibold">
                Sign out
              </span>
            </DialogClose>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

const orgContextMobileDropdownItem = (
  org: ViewerNavDataQuery['viewer']['organizations'][0],
  activeOrgId: string,
  closeMenuCallback: (open: boolean) => void,
) => (
  <Link to={`/${org?.slug}`} key={org?.id}>
    <DialogClose asChild onClick={() => closeMenuCallback(false)}>
      <div
        className={cx([
          org?.id === activeOrgId &&
            '!border-divider-light-blue border-divider-light-blue !bg-interactive-secondary-active !text-interactive-primary border',
          'my-2 rounded-md p-2 text-base',
        ])}
      >
        <div className="flex w-full flex-row justify-between gap-0">
          <OrgImage
            active={org?.id === activeOrgId}
            size="small"
            orgName={org?.name || ''}
            orgLogo={org?.image}
          />
          <div className="ml-2 flex-1 truncate">
            <span className="text-primary font-semibold">{org?.name}</span>
            <br />
            <span
              className={cx(
                org?.id === activeOrgId && '!text-interactive-primary',
                'text-copy-secondary',
              )}
            >
              {org?.users.totalCount} member
              {(org?.users.totalCount || 0) > 1 ? 's' : ''}
            </span>
          </div>
          <Check size={16} className={cx(['m-auto', org?.id !== activeOrgId && 'hidden'])} />
        </div>
      </div>
    </DialogClose>
  </Link>
)

export default MobileOrgNavMenu
