import clsx from 'clsx'
import * as React from 'react'

import { Button, PlusLogo } from '@/shared/components'

import { UpgradeScreenOne, UpgradeScreenThree, UpgradeScreenTwo } from './UpgradeSuccess'

const PostInstall = (): JSX.Element => {
  const [activeStep, setActiveStep] = React.useState(0)

  return (
    <div className="bg-background-gridBackground flex h-screen w-screen items-center justify-center">
      <div
        className="border-divider-light-gray bg-background-white shadow-soft flex flex-col rounded border sm:hidden"
        style={{ width: 480 }}
      >
        <div className="border-divider-light-gray flex w-full flex-col items-center justify-center border-b px-6 pt-6 pb-4">
          <PlusLogo width="48px" height="48px" />
          <h1 className="text-copy my-1 text-xl font-semibold">Installation instructions</h1>
        </div>
        <div className="m-6 flex flex-col gap-2 text-base">
          <span>
            Unfortunately, Google Slides and Docs do not support add-ons on mobile devices.
          </span>
          <span>
            To get started, install our add-on for Google Slides and Docs on a desktop computer
          </span>
        </div>
        <div className="px-6 pb-6 pt-2">
          <a href="https://guide.plusdocs.com/plus-ai/installing-plus-ai" className=" w-full">
            <Button variant="primary" className="h-[48px] w-full">
              Show me how
            </Button>
          </a>
        </div>
      </div>
      <div
        className="border-divider-light-gray bg-background-white shadow-soft hidden flex-col rounded border sm:flex"
        style={{ width: 480 }}
      >
        <div className="border-divider-light-gray flex w-full flex-col items-center justify-center border-b px-6 pt-6 pb-4">
          <PlusLogo width="48px" height="48px" />
          <h1 className="text-copy my-1 text-xl font-semibold">Welcome to Plus</h1>
          <span className="text-base">
            {activeStep === 0 && 'Installation instructions'}
            {activeStep === 1 && 'Install and launch the Google Workspance add-on'}
            {activeStep === 2 && 'Install the PowerPoint add-in'}
          </span>
        </div>
        <div className="mx-6 mt-6">
          {activeStep === 0 && <UpgradeScreenOne setStep={setActiveStep} />}
          {activeStep === 1 && <UpgradeScreenTwo />}
          {activeStep === 2 && <UpgradeScreenThree />}
        </div>

        <div className="grid w-full grid-flow-col grid-cols-3 p-6">
          <div className="flex flex-row items-center justify-start">
            <Button
              variant="secondary"
              onClick={() => setActiveStep(activeStep - 1)}
              disabled={activeStep === 0}
            >
              Previous
            </Button>
          </div>
          <div className="flex flex-row items-center justify-center">
            <div className="flex flex-row gap-x-2">
              {new Array(3).fill('').map((_, i) => (
                <div
                  key={i}
                  className={clsx(
                    'h-[6px] w-[6px] rounded-full',
                    activeStep === i ? 'bg-divider-dark-blue' : 'bg-divider-light-blue',
                  )}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-row items-center justify-end">
            <Button
              variant="secondary"
              onClick={() => setActiveStep(activeStep + 1)}
              disabled={activeStep === 2}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostInstall
