/* eslint-disable complexity */
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import { CheckCircle, ChevronLeft, ChevronRight, Clock } from 'react-feather'
import { useHistory, useLocation } from 'react-router-dom'

import { PublicSnapshotVersion, SnapshotVersion, UserActivity } from '@/generated/graphql'
import { Button, Toast } from '@/shared/components'
import { CopyText } from '@/shared/components/Icons'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { IUseSnapshot } from '@/shared/hooks/useSnapshot'
import useSnapshotVersions, { SnapshotVersions } from '@/shared/hooks/useSnapshotVersions'
import { copyTextToClipboard, showModal } from '@/util'

import { useTrackUserActivity } from '../hooks/useTrackUserActivity'
import SnapshotVersionDropdown, { SnapshotVersionPaginationParams } from './SnapshotVersionDropdown'
type DisplayType = 'regular' | 'compact' | 'embed'

interface IProps {
  snapshot?: NonNullable<IUseSnapshot>
  snapshotVersionId: string | null | undefined
  onChangeSnapshotVersion: (
    snapshotVersionId: string | null,
    query?: SnapshotVersionPaginationParams,
  ) => void
  className?: string
  showMostRecentOption?: boolean
  navigateOnPageChange?: boolean
  displayType?: DisplayType
  isFeedView?: boolean
}

interface IInnerProps extends IProps {
  snapshot: NonNullable<IUseSnapshot>
}

const CopyTextButton = ({
  displayType,
  version,
}: {
  displayType: DisplayType
  version?:
    | (Partial<SnapshotVersion> & { text?: string | null | undefined; urn: string })
    | Partial<PublicSnapshotVersion>
}) => {
  const trackUserActivity = useTrackUserActivity()

  const handleCopyText = useCallback(() => {
    if (version?.__typename !== 'SnapshotVersion') {
      return
    }

    const { extractedText } = version

    if (extractedText) {
      copyTextToClipboard(extractedText).catch(() => null)
      trackUserActivity(UserActivity.CopyVersionTextClicked, version.urn)
      void showModal(
        <Toast>
          <CheckCircle size={16} className="text-interactive-primary mr-2" />
          <span className="text-base">Text copied!</span>
        </Toast>,
      )
    }
  }, [version, trackUserActivity])

  if (displayType === 'embed') {
    return <></>
  }

  return (
    <Button
      size="standard"
      variant="secondary"
      onClick={handleCopyText}
      tooltip={
        <>
          Copy the text in this <br />
          Snapshot to your clipboard
        </>
      }
      tooltipPlacement="top"
      disabled={version?.__typename !== 'SnapshotVersion' || !version.extractedText}
    >
      <CopyText />
    </Button>
  )
}

const SnapshotVersionNavigator = ({
  className,
  currentSnapshotVersions,
  displayType,
  versionIdMatch,
  onChangeSnapshotVersion,
  originalSnapshot,
  showMostRecentOption,
}: {
  className?: string
  currentSnapshotVersions: SnapshotVersions
  displayType: DisplayType
  versionIdMatch: string | undefined | null
  onChangeSnapshotVersion: IProps['onChangeSnapshotVersion']
  originalSnapshot: NonNullable<IUseSnapshot>
  showMostRecentOption?: boolean
}) => {
  const {
    hasNextPage,
    hasPreviousPage,
    loadMore: loadMorePages,
    snapshot,
  } = currentSnapshotVersions

  const versionEdges = snapshot?.allVersions.edges
  const selectedIndex = useMemo(
    () => versionEdges?.findIndex((v) => v.node.id === versionIdMatch) ?? -1,
    [versionIdMatch, versionEdges],
  )

  const handleClickOlderSnapshotVersion = useCallback(async () => {
    if (selectedIndex === -1 || !versionEdges) return
    const index = selectedIndex + 1
    if (index > versionEdges.length - 1) {
      const newResults = await loadMorePages('after')
      const id = newResults?.edges[0].cursor
      if (!id) return
      return onChangeSnapshotVersion(id)
    }

    const versionNodeId = versionEdges?.[index].node.id

    if (versionNodeId) {
      onChangeSnapshotVersion(versionNodeId)
    }
  }, [loadMorePages, onChangeSnapshotVersion, selectedIndex, versionEdges])

  const handleClickNewerSnapshotVersion = useCallback(async () => {
    if (selectedIndex === -1 || !versionEdges) return
    const index = selectedIndex - 1
    if (index < 0) {
      const newResults = await loadMorePages('before')
      const id = newResults?.edges[newResults?.edges.length - 1].cursor
      if (!id) return
      return onChangeSnapshotVersion(id)
    }
    const versionNodeId = versionEdges?.[index].node.id
    if (versionNodeId) {
      onChangeSnapshotVersion(versionNodeId)
    }
  }, [loadMorePages, onChangeSnapshotVersion, selectedIndex, versionEdges])

  const versionNode = versionEdges?.[selectedIndex]?.node

  return (
    <div
      className={clsx(displayType !== 'embed' && 'bg-background-panel flex h-12 w-full', className)}
    >
      <div
        className={clsx(displayType !== 'embed' && 'border-divider-light-gray w-full border-t p-2')}
      >
        <div
          className={clsx(
            displayType === 'embed'
              ? 'flex min-w-[68px] items-center'
              : 'flex w-full justify-center gap-2',
          )}
        >
          <Button
            icon={<ChevronLeft size={16} />}
            size={displayType === 'embed' ? 'small' : 'standard'}
            variant={displayType === 'embed' ? 'ghost' : 'secondary'}
            onClick={handleClickOlderSnapshotVersion}
            tooltip={displayType === 'embed' ? '' : 'Older snapshot version'}
            tooltipPlacement="left"
            disabled={
              versionIdMatch === null ||
              (!hasNextPage && selectedIndex === (versionEdges ?? []).length - 1)
            }
            className={clsx(displayType === 'embed' ? 'p-1' : '')}
          />
          <Button
            icon={<ChevronRight size={16} />}
            size={displayType === 'embed' ? 'small' : 'standard'}
            variant={displayType === 'embed' ? 'ghost' : 'secondary'}
            onClick={handleClickNewerSnapshotVersion}
            tooltip={displayType === 'embed' ? '' : 'Newer snapshot version'}
            tooltipPlacement="right"
            disabled={versionIdMatch === null || (!hasPreviousPage && selectedIndex === 0)}
            className={clsx(displayType === 'embed' ? 'p-1' : '')}
          />
          <SnapshotVersionDropdown
            capturedSnapshotVersions={currentSnapshotVersions}
            originalSnapshot={originalSnapshot}
            snapshotVersionId={versionIdMatch}
            onSnapshotVersionChange={onChangeSnapshotVersion}
            showMostRecentOption={showMostRecentOption}
            displayType={displayType}
          />

          <CopyTextButton displayType={displayType} version={versionNode} />
        </div>
      </div>
    </div>
  )
}

const LoadingPlaceholder = (
  <div className="border-divider-light-gray bg-background-panel h-12 w-full shrink-0 border-t"></div>
)

const EmbedLoadingPlaceholder = (
  <div className="flex min-w-[68px] items-center">
    <Button
      icon={<ChevronLeft size={16} />}
      size={'small'}
      variant={'ghost'}
      disabled={true}
      className="p-1"
    />
    <div className="bg-background-panel m-auto rounded border border-transparent py-1">
      <Clock size={16} className="mx-1 text-center" />
    </div>
    <Button
      icon={<ChevronRight size={16} />}
      size={'small'}
      variant={'ghost'}
      disabled={true}
      className="p-1"
    />
  </div>
)

const SnapshotVersionNavigationBarInner = ({
  displayType = 'regular',
  snapshot: originalSnapshot,
  snapshotVersionId,
  onChangeSnapshotVersion,
  showMostRecentOption,
  className,
  navigateOnPageChange,
}: IInnerProps): JSX.Element => {
  const location = useLocation()
  const history = useHistory()
  const params = useSearchParams()
  const currentSnapshotVersions = useSnapshotVersions(
    originalSnapshot,
    params.get('before') ?? undefined,
    params.get('after') ?? undefined,
  )

  const { snapshot, loadMore, startCursor, endCursor } = currentSnapshotVersions

  const versionIdMatch = showMostRecentOption
    ? snapshotVersionId
    : snapshotVersionId || snapshot?.currentVersion?.id

  const loadMorePages = useCallback(
    async (direction: 'before' | 'after') => {
      if (navigateOnPageChange) {
        const searchParams = new URLSearchParams()
        searchParams.set(
          direction,
          direction === 'before' ? String(startCursor) : String(endCursor),
        )
        history.replace({
          pathname: location.pathname,
          search: searchParams.toString(),
        })
      }

      return await loadMore(direction)
    },
    [endCursor, history, loadMore, location.pathname, navigateOnPageChange, startCursor],
  )

  return (
    <SnapshotVersionNavigator
      className={className}
      displayType={displayType}
      versionIdMatch={versionIdMatch}
      onChangeSnapshotVersion={onChangeSnapshotVersion}
      originalSnapshot={originalSnapshot}
      showMostRecentOption={showMostRecentOption}
      currentSnapshotVersions={{
        ...currentSnapshotVersions,
        loadMore: loadMorePages,
      }}
    />
  )
}

const SnapshotVersionNavigationBar = (props: IProps): JSX.Element => {
  if (!props.snapshot) {
    if (props.displayType === 'embed') {
      return EmbedLoadingPlaceholder
    } else {
      return LoadingPlaceholder
    }
  }

  return <SnapshotVersionNavigationBarInner snapshot={props.snapshot} {...props} />
}

export default SnapshotVersionNavigationBar
