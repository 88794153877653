import { useHistory } from 'react-router-dom'

import {
  CaptureStatus,
  ConnectionStatus,
  RepairType,
  SnapshotFieldsFragment,
} from '@/generated/graphql'
import { UserImage } from '@/shared/components'
import { Connection } from '@/shared/components/Icons'
import { IdentityWithSnapshotFields } from '@/shared/hooks/useIdentities'
import { getSnapshotStatus } from '@/util'

import Favicon from '../Favicon'
import ConnectionStatusPill from './ConnectionStatusPill'
import ReconnectButton from './ReconnectButton'

interface Props {
  readonly identities: readonly IdentityWithSnapshotFields[]
}

type DomainConnection = {
  readonly connectionStatus: ConnectionStatus
  readonly domainName: string
  readonly identityId: string
  readonly snapshotToReconnect: SnapshotFieldsFragment
  readonly total: number
  readonly totalPaused: number
  readonly user: SnapshotFieldsFragment['createdBy'] | undefined
}

// Use a stable sort for connection status. We just care that Disconnected statuses are before Deteriorating.
const compareConnectionStatus = (x: ConnectionStatus, y: ConnectionStatus) =>
  x === y
    ? 0
    : x === ConnectionStatus.Disconnected
    ? -1
    : y === ConnectionStatus.Disconnected
    ? 1
    : 0

const compareDomainNames = (x: string, y: string) => x.localeCompare(y)

// Sort first by connection status, then by domain name.
const compareDomainConnections = (x: DomainConnection, y: DomainConnection) => {
  const connectionComparison = compareConnectionStatus(x.connectionStatus, y.connectionStatus)
  return connectionComparison !== 0
    ? connectionComparison
    : compareDomainNames(x.domainName, y.domainName)
}

export default function ReconnectionsList({ identities }: Props): JSX.Element {
  const history = useHistory()

  if (identities.length === 0) {
    return <></>
  }

  const sortedDomainConnections = identities
    .filter((identity) =>
      [
        ConnectionStatus.Deteriorating,
        ConnectionStatus.Disconnected,
        ConnectionStatus.Reconnecting,
      ].includes(identity.connectionStatus),
    )
    .map((identity) => {
      const snapshots = identity.snapshots

      const domainConnection: DomainConnection = {
        connectionStatus: identity.connectionStatus,
        domainName: identity.domain,
        identityId: identity.id,
        snapshotToReconnect:
          snapshots.find(
            (snapshot) => snapshot.latestVersion?.status === CaptureStatus.ErrorSessionExpired,
          ) ??
          snapshots.find((snapshot) => snapshot.suggestedRepairType === RepairType.Login) ??
          snapshots[0],
        total: snapshots.length,
        totalPaused: snapshots.filter((snapshot) => getSnapshotStatus(snapshot).primaryRepairOption)
          .length,
        user: snapshots.at(0)?.createdBy,
      }

      return domainConnection
    })
    .sort(compareDomainConnections)

  return (
    <table className="border-divider-light-gray shadow-dark-hard bg-background-panel rounded border-b">
      <tbody>
        {sortedDomainConnections.map((domainConnection) => {
          const {
            connectionStatus,
            domainName,
            identityId,
            snapshotToReconnect,
            total,
            totalPaused,
            user,
          } = domainConnection

          return (
            <tr
              className="border-divider-light-gray hover:bg-interactive-secondary-hover  active:bg-interactive-secondary-active border-b hover:cursor-pointer"
              key={domainName}
              onClick={() =>
                history.push(`/${snapshotToReconnect.organization.slug}/connection/${identityId}`)
              }
            >
              <td className="p-2 lg:p-4">
                <div className="flex items-center gap-4">
                  <Favicon
                    size={24}
                    domainName={domainName}
                    defaultIcon={
                      <div className="bg-background-canvas flex h-6 w-6 items-center justify-center rounded-3xl">
                        <Connection size={16} />
                      </div>
                    }
                  />
                  <div className="truncate text-base font-semibold">{domainName}</div>
                </div>
              </td>
              <td className="p-2 lg:p-4">
                <ConnectionStatusPill status={connectionStatus} />
              </td>
              <td className="p-2 lg:p-4">
                <span className="line-clamp-1 text-base">
                  {totalPaused} of {total} Snapshots paused
                </span>
              </td>
              <td className="p-2 lg:p-4">
                <div className="flex flex-row items-center gap-1">
                  <UserImage
                    className="shrink-0"
                    size="tiny"
                    userEmail={user?.email}
                    userImageUrl={user?.image}
                    userName={user?.name}
                  />
                  <span className="line-clamp-1 text-base">{user?.name || 'No Owner'}</span>
                </div>
              </td>
              <td className="w-px p-2 text-right lg:p-4">
                {snapshotToReconnect && (
                  <ReconnectButton
                    connectionStatus={connectionStatus}
                    hideIcon={true}
                    snapshotToReconnect={snapshotToReconnect}
                  />
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
