import { useStarTour } from '@plusdocs/star-tours'
import { isValidString } from '@plusdocs/utils/common/string'
import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'

import { ContentType, SnapshotFieldsFragment } from '@/generated/graphql'
import {
  contentPrivacyStateVar,
  defaultContentPrivacyState,
} from '@/shared/apollo/apolloLocalState'
import { useAuth } from '@/shared/auth/useAuth'
import { DesktopDisplayOnly } from '@/shared/components'
import { SnapshotImageDisplay } from '@/shared/components/SnapshotImageDisplay'
import { SnapshotVersionPaginationParams } from '@/shared/components/SnapshotVersionDropdown'
import SnapshotVersionNavigationBar from '@/shared/components/SnapshotVersionNavigationBar'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { IUseSnapshot, useSnapshot } from '@/shared/hooks/useSnapshot'
import { useSnapshotVersion } from '@/shared/hooks/useSnapshotVersion'
import { useTrackContentView } from '@/shared/hooks/useTrackContentView'
import { isEmbed } from '@/util'
import { getSnapshotStatus } from '@/util/snapshotHelpers'
import { BatchSnapshotViewer, EmbedFooter, SnapshotHeader } from '@/web/components'
import { SnapshotSidebar } from '@/web/components/SnapshotViewer/SnapshotSidebar'
import NotFound from '@/web/routes/NotFound'

import EmbedHeader from '../components/EmbedHeader'
import SignupSidebar from '../components/SnapshotViewer/SignupSidebar'
import { AvailableTours } from '../fixtures/tours'

/**
 * Determines which version of the snapshot to view/edit according to the following algorithm:
 *
 * 1. The version in the URL, if specified.
 * 2. If the user is the owner and the latest version has an error, then display the error image so they can see it when
 *    repairing.
 * 3. Use the snapshot's current version, which defaults to the first non-error version (in the useSnapshot GraphQL
 *    query resolver).
 * 4. No version specified - just view the default version contained in the Snapshot.
 */
function determineSnapshotVersionIdToDisplay({
  latestSnapshot,
  snapshotCurrentVersionId,
  snapshotVersionIdFromQueryParameters,
}: {
  readonly latestSnapshot?: IUseSnapshot
  readonly snapshotCurrentVersionId: string | undefined
  readonly snapshotVersionIdFromQueryParameters: string | undefined
}): string | undefined {
  if (isValidString(snapshotVersionIdFromQueryParameters)) {
    return snapshotVersionIdFromQueryParameters
  }

  if (!latestSnapshot) {
    return snapshotCurrentVersionId
  }
  const isOwner = latestSnapshot.viewer.isOwner

  const { snapshotHasError, snapshotNeedsRepair, snapshotSessionExpired } = getSnapshotStatus(
    latestSnapshot as SnapshotFieldsFragment,
  )
  const isBroken = snapshotHasError || snapshotNeedsRepair || snapshotSessionExpired

  const latestVersionId = latestSnapshot.latestVersion?.id

  if (isOwner && isBroken && isValidString(latestVersionId)) {
    return latestVersionId
  }

  return snapshotCurrentVersionId
}

// eslint-disable-next-line complexity
const Snapshot = (): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const auth = useAuth()
  const routeMatch = useRouteMatch('/:organizationSlug/snapshot/:snapshotId')
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const { snapshotId, snapshotVersionId: snapshotVersionIdFromQueryParameters } = useParams<{
    snapshotId: string
    snapshotVersionId?: string
  }>()
  const params = useSearchParams()
  const isFirstTimeUser = params.has('isFtu')

  const { loading, snapshot, refresh } = useSnapshot(snapshotId)

  const snapshotVersionId = determineSnapshotVersionIdToDisplay({
    latestSnapshot: snapshot,
    snapshotCurrentVersionId: snapshot?.currentVersion?.id,
    snapshotVersionIdFromQueryParameters,
  })

  const { snapshotVersion } = useSnapshotVersion(snapshotVersionId)

  const { start } = useStarTour()

  useEffect(() => {
    if (isFirstTimeUser) {
      start(AvailableTours.SnapshotViewer)
      history.replace({ ...location, search: undefined })
    }
  }, [start, isFirstTimeUser, history, location])

  const isEmbedded = isEmbed()
  useTrackContentView(ContentType.Snapshot, snapshot?.urn)

  useEffect(() => {
    window.__PLUSDOCS_RELOAD_CURRENT_SNAPSHOT__ = () => {
      setTimeout(() => {
        refresh()
      }, 2000)
    }

    return () => {
      window.__PLUSDOCS_RELOAD_CURRENT_SNAPSHOT__ = null
    }
  }, [refresh])

  // reset content privacy state when you navigate away from the page, so it doesn't persist to another snapshot
  // See https://linear.app/plus/issue/PLUS-1759/viewers-get-sent-to-non-authenticated-snapshot-view-after-viewing-a
  useEffect(() => {
    return () => {
      contentPrivacyStateVar({
        ...defaultContentPrivacyState,
      })
    }
  }, [])

  const onChangeSnapshotVersion = useCallback(
    (id: string | null, query?: SnapshotVersionPaginationParams): void => {
      if (!routeMatch?.url) {
        return
      }

      const search = new URLSearchParams(window.location.search)
      if (query?.after) search.set('after', query.after)
      if (query?.before) search.set('before', query.before)
      if (id) {
        history.push(`${routeMatch.url}/version/${id}?${search.toString()}`)
      } else {
        history.push(`${routeMatch.url}?${search.toString()}`)
      }
    },
    [history, routeMatch?.url],
  )

  // this userCanEdit is to prevent components flashing in and out when switching between snapshots
  const [canUserEdit, setCanEdit] = React.useState(snapshot?.viewer.canEdit)

  React.useEffect(() => {
    if (!canUserEdit) {
      setCanEdit(snapshot?.viewer.canEdit)
    }
  }, [canUserEdit, snapshot?.viewer.canEdit])

  if (!loading && !snapshot) {
    return <NotFound />
  }

  return (
    <>
      <Helmet title={snapshot?.name} />
      {isEmbedded && (
        <EmbedHeader
          content={snapshot}
          snapshotVersion={snapshotVersion}
          snapshotVersionId={snapshotVersionId}
          onChangeSnapshotVersion={onChangeSnapshotVersion}
        />
      )}
      <div
        className={clsx('fixed flex w-full flex-col overflow-hidden', !isEmbedded && 'h-full')}
        style={
          isEmbedded
            ? {
                height: 'calc(100vh - 2.5rem)',
              }
            : undefined
        }
      >
        {!isEmbedded && (
          <SnapshotHeader
            snapshot={snapshot as SnapshotFieldsFragment}
            snapshotVersion={snapshotVersion}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            snapshotEditingAllowed={!!canUserEdit}
          />
        )}
        <div className={clsx('flex w-full grow flex-col', !isEmbedded && 'md:flex-row')}>
          <DesktopDisplayOnly>
            {!isEmbedded && canUserEdit && snapshot && (
              <BatchSnapshotViewer snapshot={snapshot as SnapshotFieldsFragment} />
            )}
          </DesktopDisplayOnly>
          <div className="bg-background-canvas flex h-full flex-1 flex-col">
            <div className="group h-full">
              <SnapshotImageDisplay
                snapshot={snapshot as SnapshotFieldsFragment}
                snapshotVersion={snapshotVersion}
              />
            </div>
            {!isEmbedded && (
              <SnapshotVersionNavigationBar
                displayType={'regular'}
                snapshot={snapshot as NonNullable<IUseSnapshot>}
                snapshotVersionId={snapshotVersionId}
                onChangeSnapshotVersion={onChangeSnapshotVersion}
                navigateOnPageChange
              />
            )}
          </div>
          <DesktopDisplayOnly>
            {!isEmbedded &&
              sidebarOpen &&
              auth.isLoggedIn() &&
              snapshot?.__typename !== 'PublicSnapshot' && (
                <SnapshotSidebar
                  snapshot={snapshot as SnapshotFieldsFragment}
                  snapshotVersionId={snapshotVersionId}
                />
              )}
            {!isEmbedded && sidebarOpen && !auth.isLoggedIn() && <SignupSidebar />}
          </DesktopDisplayOnly>
        </div>
        {isEmbedded && snapshot && <EmbedFooter content={snapshot as SnapshotFieldsFragment} />}
      </div>
    </>
  )
}

export default Snapshot
