import { useCallback } from 'react'

import {
  ContentType,
  useUpdateDocumentMutation,
  useUpdateFolderMutation,
  useUpdateSnapshotMutation,
} from '@/generated/graphql'
import { Modal } from '@/shared/components'
import { ContentV2 } from '@/shared/types'
import { capitalize, showModal } from '@/util'

const useRename = (): ((
  selectedContent: readonly ContentV2[],
  unselectAllItems?: () => void,
) => void) => {
  const renameContent = useRenameContent()

  const renameContentCallback = useCallback(
    async (selectedContent: readonly ContentV2[], unselectAllItems?: () => void) => {
      const contentItem = selectedContent[0]

      await showModal(
        <Modal
          defaultInputText={contentItem.name}
          onSubmit={(newName: string) => {
            renameContent(contentItem, newName)
            if (unselectAllItems) {
              unselectAllItems()
            }
          }}
          primaryButtonText="Rename"
          secondaryButtonText="Cancel"
          title={`Rename ${capitalize(contentItem.__typename.toLocaleLowerCase())}`}
          useInput
          variant="primary"
        />,
      )
    },
    [renameContent],
  )

  return renameContentCallback
}

const useRenameContent = () => {
  const [updateDocumentMutation] = useUpdateDocumentMutation()
  const [updateFolderMutation] = useUpdateFolderMutation()
  const [updateSnapshotMutation] = useUpdateSnapshotMutation()

  const renameContentCallback = async (contentItem: ContentV2, name: string) => {
    const variables = { id: contentItem.id, input: { name } }

    switch (contentItem.__typename.toUpperCase() as ContentType) {
      case ContentType.Document:
        await updateDocumentMutation({ variables })
        break
      case ContentType.Folder:
        await updateFolderMutation({ variables })
        break
      case ContentType.Snapshot:
        await updateSnapshotMutation({ variables })
        break
      default:
        break
    }
  }

  return renameContentCallback
}

export default useRename
