import * as PropTypes from 'prop-types'
import * as React from 'react'
import { useLocation } from 'react-router'

import { useContentGrid } from '@/shared/components/ContentGrid/useContentGrid'
import SearchModal from '@/web/components/Search/SearchModal'
import SideBar from '@/web/components/SideBar'

export const BasePage: React.FC<{ children?: React.ReactNode }> = (props): JSX.Element => {
  const { children } = props
  const [isSearchShowing, setShowSearch] = React.useState<boolean>()
  const location = useLocation()

  const { unselectAllItems } = useContentGrid()

  React.useEffect(() => {
    setShowSearch(false)
  }, [location])

  const setShowSearchCallback = React.useCallback(
    (showSearch: boolean) => {
      setShowSearch(showSearch)
    },
    [setShowSearch],
  )

  return (
    <div className="flex h-full w-full flex-col">
      <div className="fixed h-full w-full flex-col lg:flex lg:flex-row">
        <div className="bg-background-gridBackground sticky top-0 flex w-full flex-shrink flex-col self-start lg:h-full lg:w-64">
          <SideBar searchClickHandler={setShowSearchCallback}></SideBar>
        </div>
        <div
          className="flex h-full w-full flex-1 flex-col items-center"
          onClick={() => unselectAllItems()}
        >
          {children}
        </div>
      </div>
      {isSearchShowing && <SearchModal modalChangedHandler={setShowSearchCallback} />}
    </div>
  )
}

BasePage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
