import { useReactiveVar } from '@apollo/client'
import cx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { Check, ChevronDown, Globe, LogOut, Settings } from 'react-feather'
import { Link } from 'react-router-dom'

import { ViewerNavDataQuery } from '@/generated/graphql'
import { activeOrganizationState } from '@/shared/apollo/apolloLocalState'
import { useAuth } from '@/shared/auth/useAuth'
import { ContextMenuButton, ContextPopover, OrgImage } from '@/shared/components'
import OrgMenuDivider from '@/shared/components/OrgMenuDivider'
import { showModal } from '@/util/showModal'
import { useViewer } from '@/web/hooks'

import IntegrationsModal from '../IntegrationsModal'

const OrgNavMenu = (): JSX.Element => {
  const { logout } = useAuth()
  const { viewer } = useViewer()
  const activeOrgState = useReactiveVar(activeOrganizationState)
  const invitations = viewer?.invitations
  const currentOrganization = viewer?.currentOrganization
  const organizations = viewer?.organizations
  const [open, setOpen] = useState(false)

  const activeOrg = useMemo(
    () =>
      (organizations ?? []).find((x) => x?.slug === activeOrgState?.slug) || currentOrganization,
    [activeOrgState?.slug, currentOrganization, organizations],
  )

  const showIntegrationsModal = useCallback(() => {
    void showModal(<IntegrationsModal />)
    setOpen(false)
  }, [])

  // This is a theoretical error case we should never hit
  if (!activeOrg || !organizations || !invitations) {
    return <></>
  }

  return (
    <div className="w-full">
      <ContextPopover
        controlled
        open={open}
        setOpen={setOpen}
        placement="bottom-start"
        trigger={({ open: isOpen }) => (
          <ContextMenuButton
            key={activeOrg?.id}
            className={cx([
              'active:!border-divider-light-blue border border-transparent',
              isOpen &&
                '!border-divider-light-blue !bg-interactive-secondary-active !text-interactive-primary border',
            ])}
            onClick={() => {
              setOpen(!open)
            }}
          >
            <div className="flex w-full flex-row justify-between gap-0">
              <OrgImage size="small" orgName={activeOrg.name} orgLogo={activeOrg.image} />
              <div className="ml-2 flex-1 truncate">
                <span className="text-primary font-semibold">{activeOrg?.name}</span>
                <br />
                <span>
                  {activeOrg?.users.totalCount} member
                  {(activeOrg?.users.totalCount || 1) > 1 ? 's' : ''}
                </span>
              </div>
              <ChevronDown size={16} className="text-copy-secondary m-auto" />
            </div>
          </ContextMenuButton>
        )}
      >
        <div className="border-divider-light-gray bg-background-white shadow-soft box-content w-[223px] rounded border p-2">
          {organizations.map((org) => orgContextMenuItem(org, activeOrg.id))}
          <OrgMenuDivider />
          {invitations.length > 0 && (
            <Link to="/invitations">
              <ContextMenuButton className="flex w-full flex-row justify-between">
                <div>View organization invites</div>
                <div className="bg-interactive-destructive text-copy-active flex h-4 w-4 items-center justify-center rounded-full text-base font-semibold">
                  {invitations.length}
                </div>
              </ContextMenuButton>
            </Link>
          )}
          <Link to={`/${activeOrg?.slug}/settings/personal`}>
            <ContextMenuButton className="flex flex-row gap-x-2">
              <Settings size={16} className="text-copy-secondary" />
              Settings
            </ContextMenuButton>
          </Link>

          <ContextMenuButton onClick={showIntegrationsModal} className="flex flex-row gap-x-2">
            <Globe size={16} className="text-copy-secondary" />
            Integrations
          </ContextMenuButton>
          <OrgMenuDivider />
          <ContextMenuButton onClick={() => void logout()} className="flex flex-row gap-x-2">
            <LogOut size={16} className="text-copy-secondary" />
            Sign out
          </ContextMenuButton>
        </div>
      </ContextPopover>
    </div>
  )
}

const orgContextMenuItem = (
  org: ViewerNavDataQuery['viewer']['organizations'][0],
  activeOrgId: string,
) => (
  <Link to={`/${org?.slug}`} key={org?.id}>
    <ContextMenuButton
      className={cx([
        org?.id === activeOrgId &&
          '!border-divider-light-blue !bg-interactive-secondary-active !text-interactive-primary',
      ])}
    >
      <div className="flex w-full flex-row justify-between gap-0">
        <OrgImage
          active={org?.id === activeOrgId}
          size="small"
          orgName={org?.name || ''}
          orgLogo={org?.image}
        />
        <div className="ml-2 flex-1 truncate">
          <span className="text-primary font-semibold">{org?.name}</span>
          <br />
          <span
            className={cx(
              org?.id === activeOrgId && '!text-interactive-primary',
              'text-copy-secondary',
            )}
          >
            {org?.users.totalCount} member
            {(org?.users.totalCount || 0) > 1 ? 's' : ''}
          </span>
        </div>
        <Check size={16} className={cx(['m-auto', org?.id !== activeOrgId && 'hidden'])} />
      </div>
    </ContextMenuButton>
  </Link>
)

export default OrgNavMenu
