import clsx from 'clsx'
import { useCallback, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, useRouteMatch } from 'react-router-dom'

import { ContentType, Document, PageStructureV1 } from '@/generated/graphql'
import { DesktopDisplayOnly } from '@/shared/components'
import { useDocument } from '@/shared/hooks/useDocument'
import {
  generateViewPayload,
  useLazyTrackContentViews,
  useTrackContentView,
} from '@/shared/hooks/useTrackContentView'
import { isEmbed } from '@/util'
import { EmbedFooter } from '@/web/components/EmbedFooter'
import { FullPageLoader } from '@/web/components/FullPageLoader'
import { Canvas } from '@/web/components/Page'
import { Header } from '@/web/components/Share'
import { useAppDispatch, useAppSelector, useElementSizer } from '@/web/hooks'
import {
  initEditorAppThunk,
  matchSidebarState,
  selectCanvas,
  updateCanvasSize,
} from '@/web/store/appState'

import EmbedHeader from '../components/EmbedHeader'
import SignupSidebar from '../components/SnapshotViewer/SignupSidebar'
import useLoadPagesFonts from '../hooks/useLoadPagesFonts'
import NotFound from './NotFound'

function Share(): JSX.Element {
  const dispatch = useAppDispatch()
  const { documentId } = useParams<{ documentId: string }>()
  const canvasSize = useAppSelector(selectCanvas)
  const trackContentViews = useLazyTrackContentViews()

  const { document: plusDocument, loading, refresh } = useDocument(documentId, true)
  useEffect(() => {
    const refreshOnVisible = () => {
      if (!document.hidden) {
        refresh()
      }
    }
    document.addEventListener('visibilitychange', refreshOnVisible)
    return () => {
      document.removeEventListener('visibilitychange', refreshOnVisible)
    }
  }, [refresh])
  const fontsLoading = useLoadPagesFonts()
  useTrackContentView(ContentType.Document, plusDocument?.urn)
  const currentPage = plusDocument?.pages[0]
  const isEmbedded = isEmbed()
  const loaded = !loading && !fontsLoading
  const isSidebarOpen = useAppSelector(matchSidebarState('open'))
  const isGallery = useRouteMatch([`/:organizationSlug/gallery`, `/gallery`])

  const onCanvasResize = useCallback(
    ({ width, height }: { width: number; height: number }) => {
      if (!currentPage?.canvasSize) return
      dispatch(
        updateCanvasSize({
          base: {
            height: currentPage?.canvasSize?.height,
            width: currentPage?.canvasSize?.width,
          },
          canvas: { height, width },
        }),
      )
    },
    [dispatch, currentPage?.canvasSize],
  )

  const divRef = useRef<HTMLDivElement>(null)
  // 16px = 1rem (desired padding)
  // use 0 in preview mode, since we want full viewport rendering
  const pageSize = useElementSizer(
    divRef,
    currentPage
      ? {
          height: currentPage.canvasSize?.height,
          width: currentPage.canvasSize?.width,
        }
      : undefined,
    isEmbedded ? 8 : 16,
  )

  useEffect(() => {
    if ((plusDocument as Document)?.viewer?.canEdit) {
      dispatch(initEditorAppThunk({ documentId }))
    }

    const snapshotUrns = plusDocument?.pages.flatMap((page) =>
      page.snapshots.map((snapshot) => snapshot.urn),
    )
    if (snapshotUrns) {
      void trackContentViews(
        snapshotUrns?.map((urn) => generateViewPayload(ContentType.Snapshot, urn, true)),
      )
    }

    const interval = setInterval(() => {
      void refresh()
    }, 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [dispatch, documentId, isEmbedded, plusDocument, trackContentViews])

  if (!loading && !plusDocument) {
    return <NotFound />
  }

  return (
    <main className="bg-background-canvas text-copy h-screen max-h-screen overflow-hidden">
      <Helmet title={plusDocument?.name as string} />
      <div className={clsx(isEmbedded && 'flex h-full w-full flex-col justify-between')}>
        {plusDocument && (
          <>
            {isEmbedded ? (
              <EmbedHeader content={plusDocument as Document} />
            ) : (
              <Header document={plusDocument as Document} />
            )}
          </>
        )}
        <div className={clsx(isEmbedded && 'aspect-video h-full')}>
          <div
            className={clsx(
              'relative flex w-full items-center justify-center',
              !isEmbedded && 'h-viewport-minus-header',
              isEmbedded && 'group',
            )}
            style={
              isEmbedded
                ? {
                    height: 'calc(100vh - 5rem)',
                  }
                : undefined
            }
            ref={divRef}
          >
            <div
              className={clsx('bg-background-white shadow-canvas relative m-3 w-full')}
              style={pageSize}
            >
              {loaded && currentPage ? (
                <Canvas
                  page={currentPage as PageStructureV1}
                  size={canvasSize}
                  onResize={onCanvasResize}
                  readOnly
                  mode="share"
                />
              ) : null}
            </div>
            <DesktopDisplayOnly>
              {isSidebarOpen && !isGallery && !isEmbedded && <SignupSidebar />}
            </DesktopDisplayOnly>
          </div>
        </div>
        <FullPageLoader loading={!loaded} />
        {isEmbedded && plusDocument && <EmbedFooter content={plusDocument as Document} />}
      </div>
    </main>
  )
}

export default Share
