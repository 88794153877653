import { ApolloProvider } from '@apollo/client'
import { url } from 'aws-sdk/clients/finspace'
import { useState } from 'react'
import { X } from 'react-feather'

import { apolloClient } from '@/shared/apollo/apolloClient'
import { Button, Dialog } from '@/shared/components'
import { ViewerProvider } from '@/shared/providers/ViewerProvider'

import CanvaLogo from '../assets/IntegrationLogos/CanvaLogo.svg'
import CodaLogo from '../assets/IntegrationLogos/CodaLogo.svg'
import ConfluenceLogo from '../assets/IntegrationLogos/ConfluenceLogo.svg'
import GoogleDocsLogo from '../assets/IntegrationLogos/GoogleDocsLogo.svg'
import GoogleSlidesLogo from '../assets/IntegrationLogos/GoogleSlidesLogo.svg'
import NotionLogo from '../assets/IntegrationLogos/NotionLogo.svg'
import { SlackIntegrationCard } from './SlackIntegrationCard'

const IntegrationItem = (props: {
  image: string
  title: string
  description: string
  primaryActionLabel?: string
  primaryActionDestination?: url
  secondaryActionLabel?: string
  secondaryActionDestination?: url
}) => {
  return (
    <div className="border-divider-light-gray flex flex-col overflow-hidden rounded-md border">
      <div className="border-divider-light-gray bg-background-panel h-[128px] content-center border-b p-8">
        <div className="flex max-h-[100%] max-w-[100%]">
          <img src={props.image} />
        </div>
      </div>
      <div className="flex flex-grow flex-col p-3">
        <span className="mb-1 text-xs font-semibold">{props.title}</span>
        <p className="mb-3 flex-grow text-xs">{props.description}</p>
        <div className="flex gap-2">
          {props.primaryActionLabel && (
            <a href={props.primaryActionDestination} target="_blank" rel="noreferrer">
              <Button variant="primary">{props.primaryActionLabel}</Button>
            </a>
          )}
          {props.secondaryActionLabel && (
            <a href={props.secondaryActionDestination} target="_blank" rel="noreferrer">
              <Button variant="secondary">{props.secondaryActionLabel}</Button>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

const integrationsData = [
  {
    description:
      'Share, view, and refresh Snapshots directly in Notion. Embed metrics, data, your calendar, and more.',
    image: NotionLogo,
    primaryActionDestination:
      'https://www.notion.so/integrations/plus-8f25d2b0-81cf-46f9-a2ac-1bea17d57f19',
    primaryActionLabel: 'Add to Notion',
    secondaryActionDestination: 'https://guide.plusdocs.com/integrations/connect-to-notion',
    secondaryActionLabel: 'Learn more',
    title: 'Notion',
  },
  {
    description:
      'Import Plus Snapshots directly into Google Slides presentations. Refresh all your Snapshots with one click, saving time on manually updating recurring presentations.',
    image: GoogleSlidesLogo,
    primaryActionDestination: 'https://workspace.google.com/marketplace/app/plus/214277172452',
    primaryActionLabel: 'Install add-on',
    secondaryActionDestination: 'https://guide.plusdocs.com/integrations/google-slides',
    secondaryActionLabel: 'Learn more',
    title: 'Google Slides',
  },
  {
    description:
      'Add Plus Snapshots to your Google Docs. Refresh them all with one click to get the latest data.',
    image: GoogleDocsLogo,
    primaryActionDestination: 'https://workspace.google.com/marketplace/app/plus/214277172452',
    primaryActionLabel: 'Install add-on',
    secondaryActionDestination: 'https://guide.plusdocs.com/integrations/google-workspace',
    secondaryActionLabel: 'Learn more',
    title: 'Google Docs',
  },
  {
    description:
      'Automatically embed Plus content in Coda. No install required, simply paste a Plus link into Coda and expand it into an interactive embed.',
    image: CodaLogo,
    primaryActionDestination: undefined,
    primaryActionLabel: undefined,
    secondaryActionDestination: 'https://guide.plusdocs.com/integrations/embed-plus-in-other-apps',
    secondaryActionLabel: 'Learn more',
    title: 'Coda',
  },
  {
    description:
      'Automatically embed Plus content in Confluence. No install required, simply use the built-in iframe widget with any Plus URL.',
    image: ConfluenceLogo,
    primaryActionDestination: undefined,
    primaryActionLabel: undefined,
    secondaryActionDestination: 'https://guide.plusdocs.com/integrations/embed-plus-in-other-apps',
    secondaryActionLabel: 'Learn more',
    title: 'Confluence',
  },
  {
    description: "Use Canva's embed tool to embed Snapshots and Pages directly into your designs.",
    image: CanvaLogo,
    primaryActionDestination: undefined,
    primaryActionLabel: undefined,
    secondaryActionDestination: 'https://guide.plusdocs.com/integrations/embed-plus-in-other-apps',
    secondaryActionLabel: 'Learn more',
    title: 'Canva',
  },
]

const IntegrationsModal = () => {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <ApolloProvider client={apolloClient}>
      <ViewerProvider>
        <Dialog isOpen={isOpen} setIsOpen={setIsOpen} showBackgroundOverlay={true}>
          <div className="border-base-black bg-background-white inline-block h-[600px] w-full max-w-[600px] transform overflow-hidden rounded-md border text-left align-middle transition-all">
            <div className="flex h-full flex-col">
              <div className="bg-background-white sticky top-0 flex items-center justify-between p-3">
                <span className="text-xl font-semibold">Integrations</span>
                <Button variant="ghost" tabIndex={-1} onClick={() => setIsOpen(false)}>
                  <X size={16} />
                </Button>
              </div>

              <div className="flex-grow overflow-scroll px-3">
                <div className="grid grid-cols-2 gap-3 pb-3">
                  <SlackIntegrationCard />
                  {integrationsData.map((integration, i) => (
                    <IntegrationItem
                      key={i}
                      title={integration.title}
                      description={integration.description}
                      image={integration.image}
                      primaryActionLabel={integration.primaryActionLabel}
                      primaryActionDestination={integration.primaryActionDestination}
                      secondaryActionLabel={integration.secondaryActionLabel}
                      secondaryActionDestination={integration.secondaryActionDestination}
                    />
                  ))}
                  <div className="text-copy-secondary col-span-2 flex flex-col justify-center px-3 py-8">
                    <span className="text-center text-xs font-semibold">And more</span>
                    <p className="text-center text-xs">
                      Plus works automatically in any apps that support embeds!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </ViewerProvider>
    </ApolloProvider>
  )
}

export default IntegrationsModal
