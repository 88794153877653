import { FetchPolicy, useReactiveVar } from '@apollo/client'
import { useCallback } from 'react'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import {
  ContentAccessLevel,
  ContentAuthorizationCode,
  useDocumentContentAccessQuery,
  useSnapshotContentAccessQuery,
} from '@/generated/graphql'
import { contentPrivacyStateVar } from '@/shared/apollo/apolloLocalState'
import { PasswordPromptForContent } from '@/web/components/'
import { FullPageLoader } from '@/web/components/FullPageLoader'
import NotFound from '@/web/routes/NotFound'

const ContentPrivacyContainer: React.FC<{ children?: React.ReactNode }> = (props): JSX.Element => {
  const { documentId, snapshotId } = useParams<{
    documentId?: string
    snapshotId?: string
  }>()
  const contentPrivacyState = useReactiveVar(contentPrivacyStateVar)
  const [hasAttemptedPassword, setHasAttemptedPassword] = React.useState(false)

  const { contentAccessResponse, checkPublicAccess, loading } = usePublicContentAccessQuery({
    documentId,
    snapshotId,
  })

  const onSubmitPassword = () => {
    setHasAttemptedPassword(true)
    checkPublicAccess()
  }

  // First access attempt on mount
  React.useEffect(() => {
    if (!contentPrivacyState.id) {
      contentPrivacyStateVar({
        ...contentPrivacyState,
        id: documentId || snapshotId,
      })
    }
  }, [checkPublicAccess, contentPrivacyState, documentId, snapshotId])

  // Update access value to public once access is allowed
  React.useEffect(() => {
    if (
      contentAccessResponse.code === ContentAuthorizationCode.Allow &&
      !contentPrivacyState.accessLevel
    ) {
      contentPrivacyStateVar({
        ...contentPrivacyState,
        accessLevel: ContentAccessLevel.Public,
      })
    }
  }, [contentAccessResponse, contentPrivacyState])

  if (loading) {
    return (
      <div className="m-auto">
        <FullPageLoader loading={true} />
      </div>
    )
  }

  if (!loading && !contentAccessResponse.code) {
    return <NotFound />
  }

  if (contentAccessResponse.code === ContentAuthorizationCode.Forbidden) {
    return <NotFound />
  } else if (contentAccessResponse.code === ContentAuthorizationCode.Password) {
    return (
      <PasswordPromptForContent
        onSubmit={onSubmitPassword}
        message={hasAttemptedPassword ? 'Incorrect passcode.' : ''}
      />
    )
  } else {
    return <>{props.children}</>
  }
}

export default ContentPrivacyContainer

const usePublicContentAccessQuery = ({
  documentId,
  snapshotId,
}: {
  documentId: string | undefined
  snapshotId: string | undefined
}) => {
  const queryOptions = {
    fetchPolicy: 'network-only' as FetchPolicy,
    notifyOnNetworkStatusChange: true,
  }

  const {
    data: documentQueryData,
    loading: documentQueryLoading,
    refetch: documentQueryRefetch,
  } = useDocumentContentAccessQuery({
    ...queryOptions,
    skip: !documentId,
    variables: { id: documentId as string },
  })
  const {
    data: snapshotQueryData,
    loading: snapshotQueryLoading,
    refetch: snapshotQueryRefetch,
  } = useSnapshotContentAccessQuery({
    ...queryOptions,
    skip: !snapshotId,
    variables: { id: snapshotId as string },
  })

  const fetchContentCallback = useCallback(() => {
    if (documentId) {
      documentQueryRefetch()
    } else {
      snapshotQueryRefetch()
    }
  }, [documentId, documentQueryRefetch, snapshotQueryRefetch])

  const contentAccessResponse = {
    code: documentId
      ? documentQueryData?.publicDocument?.code
      : snapshotQueryData?.publicSnapshot?.code,
  }

  return {
    checkPublicAccess: fetchContentCallback,
    contentAccessResponse: contentAccessResponse,
    loading: documentQueryLoading || snapshotQueryLoading,
  }
}
