import clsx from 'clsx'
import { File } from 'react-feather'

import SnapshotIcon from '@/shared/components/Icons/Snapshot'
import { ContentV2 } from '@/shared/types'

import PlusLogo from '../PlusLogo'
import UserImage from '../UserImage'

export const CardMetadata = ({
  content,
  innerBorderColor,
  textPrimaryColor,
  textSecondaryColor,
  isGallery,
}: {
  content: ContentV2
  innerBorderColor: string
  textPrimaryColor: string
  textSecondaryColor: string
  isGallery?: boolean
}): JSX.Element => {
  if (content.__typename === 'Folder') {
    return (
      <div
        className={clsx(
          'border-t',
          'px-2 py-1.5',
          innerBorderColor,
          'text-xxs leading-4',
          'flex flex-row gap-3.5',
        )}
      >
        <div className="flex flex-row">
          <SnapshotIcon className={clsx('h-4', textSecondaryColor)} />
          <div
            className={clsx(
              'ml-1.5 truncate',
              {
                [textSecondaryColor]: content.contentCounts?.snapshots === undefined,
              },
              textPrimaryColor,
            )}
          >
            {content.contentCounts?.snapshots ?? 'Unknown'}
          </div>
        </div>
        <div className="flex flex-row">
          <File className={clsx('h-4', textSecondaryColor)} />
          <div
            className={clsx(
              'truncate',
              {
                [textSecondaryColor]: content.contentCounts?.documents === undefined,
              },
              textPrimaryColor,
            )}
          >
            {content.contentCounts?.documents ?? 'Unknown'}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className={clsx(
          'border-t',
          'px-2 py-1.5',
          innerBorderColor,
          {
            [textSecondaryColor]: !content.createdBy?.name,
          },
          textPrimaryColor,
          'text-xxs leading-4',
          'flex flex-row gap-2',
        )}
      >
        {isGallery ? (
          <>
            <PlusLogo className="h-[16px] w-[16px]" />
            <div className="line-clamp-1">Plus</div>
          </>
        ) : (
          <>
            <UserImage
              size="tiny"
              userName={content.createdBy?.name}
              userImageUrl={content.createdBy?.image}
              userEmail={content.createdBy?.email}
            />
            <div className="line-clamp-1">{content.createdBy?.name || 'No Owner'}</div>
          </>
        )}
      </div>
    )
  }
}
