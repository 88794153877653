import clsx from 'clsx'
import { ChevronDown } from 'react-feather'

import { Button, DesktopDisplayOnly } from '@/shared/components'
import DoubleRowPopoverMenu, {
  IPopoverMenuItem,
} from '@/shared/components/DoubleRowPopover/DoubleRowPopover'
import { ButtonTestId, MenuItemTestId } from '@/shared/types/testIds'

export const RepairButton = ({
  isDisabled,
  domainName,
  onClickHideThisMessage,
  onClickRetakeSnapshot,
  onClickReauthSnapshot,
  onClickReportSnapshotIssue,
  onClickSnapshotNotBroken,
  label = 'Repair',
}: {
  domainName: string
  isDisabled?: boolean
  label?: string
  onClickHideThisMessage?: () => void
  onClickReauthSnapshot?: () => void
  onClickRetakeSnapshot?: () => void
  onClickReportSnapshotIssue?: () => void
  onClickSnapshotNotBroken?: () => void
}): JSX.Element => {
  const repairOptions: IPopoverMenuItem[] = [
    ...(onClickReauthSnapshot
      ? [
          {
            callback: onClickReauthSnapshot,
            description: `Log in to ${domainName} and update the Snapshot.`,
            testId: MenuItemTestId.LogBackIn,
            title: 'Log back in',
          },
        ]
      : []),
    ...(onClickRetakeSnapshot
      ? [
          {
            callback: onClickRetakeSnapshot,
            description: 'Create a new version of this Snapshot.',
            testId: MenuItemTestId.RetakeSnapshot,
            title: 'Take a new Snapshot',
          },
        ]
      : []),
    ...(onClickSnapshotNotBroken
      ? [
          {
            callback: onClickSnapshotNotBroken,
            description: 'Permanently ignore these errors for this Snapshot.',
            testId: MenuItemTestId.SnapshotNotBroken,
            title: `This Snapshot isn't broken`,
          },
        ]
      : []),
    ...(onClickReportSnapshotIssue
      ? [
          {
            callback: onClickReportSnapshotIssue,
            description: "Let us know if something isn't working correctly.",
            testId: MenuItemTestId.ReportSnapshotIssue,
            title: 'Report Snapshot issue',
          },
        ]
      : []),
    ...(onClickHideThisMessage
      ? [
          {
            callback: onClickHideThisMessage,
            description: 'Temporarily hide this message.',
            testId: MenuItemTestId.HideThisMessage,
            title: 'Hide this message',
          },
        ]
      : []),
  ]

  return (
    <DesktopDisplayOnly>
      <div className="w-full">
        <DoubleRowPopoverMenu
          className="w-[223px]"
          disabled={isDisabled}
          Trigger={({ active, disabled }) => (
            <Button
              active={active}
              disabled={disabled}
              variant="secondary"
              className="w-full"
              testId={ButtonTestId.RepairSnapshot}
            >
              <div className="relative w-full">
                <span>{label}</span>
                <ChevronDown
                  className={clsx(
                    'absolute right-0 top-0 h-4 w-4',
                    active ? 'text-interactive-primary' : 'text-copy-secondary',
                  )}
                />
              </div>
            </Button>
          )}
          items={repairOptions}
        />
      </div>
    </DesktopDisplayOnly>
  )
}
