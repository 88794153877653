import clsx from 'clsx'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from '@/shared/auth/useAuth'
import { Button, Input, PlusLogo } from '@/shared/components'
import { useSearchParams } from '@/shared/hooks/useSearchParams'

import SendPasswordVerification from './SendPasswordVerification'

const BackToLoginLink = ({ className }: { className?: string }) => (
  <div className={className}>
    <Link
      className="text-interactive-primary hover:text-interactive-primary-hover flex justify-center text-base hover:underline"
      to="/login"
    >
      Log in
    </Link>
  </div>
)

type NewPasswordPayload = {
  newPassword: string
  newPasswordConfirm: string
}

enum UpdatePasswordErrorType {
  MissingInfo = 1,
  NonMatchingNewPasswords = 2,
  ResetPasswordFailed = 3,
  Unknown = 4,
}

const getErrorTextFromErrorType = (
  errorType: UpdatePasswordErrorType,
  customText: string | null,
): string => {
  switch (errorType) {
    case UpdatePasswordErrorType.MissingInfo:
      return 'All fields must be completed.'
    case UpdatePasswordErrorType.NonMatchingNewPasswords:
      return 'Passwords do not match.'
    case UpdatePasswordErrorType.ResetPasswordFailed:
      if (customText) {
        return customText
      }
      return 'We were unable to reset the password for this account.'
    case UpdatePasswordErrorType.Unknown:
    default:
      return 'We ran into an issue, please refresh and try again.'
  }
}

const ResetPassword = (): JSX.Element => {
  const params = useSearchParams()
  const { resetPassword } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const [newPasswordValues, setNewPasswordValues] = React.useState<NewPasswordPayload>({
    newPassword: '',
    newPasswordConfirm: '',
  })
  const [errorType, setErrorType] = React.useState<UpdatePasswordErrorType | null>(null)
  const [customErrorText, setCustomErrorText] = React.useState<string | null>(null)
  const [isPasswordChanged, setIsPasswordChanged] = React.useState<boolean>(false)

  const validateNewPasswordForm = (
    formValues: NewPasswordPayload,
  ): UpdatePasswordErrorType | undefined => {
    const { newPassword, newPasswordConfirm } = formValues
    if (Object.values(formValues).some((value) => !value)) {
      return UpdatePasswordErrorType.MissingInfo
    }

    if (newPassword !== newPasswordConfirm) {
      return UpdatePasswordErrorType.NonMatchingNewPasswords
    }

    return
  }
  const validateFormCallback = React.useCallback(() => {
    if (errorType) {
      return false
    }

    const validationError = validateNewPasswordForm(newPasswordValues)
    if (validationError) {
      setErrorType(validationError)
    }

    return validationError
  }, [errorType, newPasswordValues])

  const onSubmitNewPassword = React.useCallback(
    (event: React.SyntheticEvent) => {
      const validationError = validateFormCallback()
      event.preventDefault()

      if (validationError) {
        return
      }

      const email = params.get('email')
      const verificationCode = params.get('code')

      if (!email || !verificationCode) {
        setErrorType(UpdatePasswordErrorType.Unknown)
        setLoading(false)
        return
      }

      setLoading(true)

      resetPassword({
        email,
        newPassword: newPasswordValues.newPassword,
        verificationCode,
      })
        .then(() => {
          setErrorType(null)
          setCustomErrorText(null)
          setIsPasswordChanged(true)
        })
        .catch((e) => {
          setErrorType(UpdatePasswordErrorType.ResetPasswordFailed)
          setCustomErrorText(e.message)
          setLoading(false)
        })
    },
    [newPasswordValues, params, resetPassword, validateFormCallback],
  )

  const onNewPasswordFormFieldChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setNewPasswordValues((prev) => ({ ...prev, [name]: value }))
    },
    [],
  )

  if (!params.get('code') || !params.get('email')) {
    return <SendPasswordVerification />
  }

  if (isPasswordChanged) {
    return (
      <div>
        <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <PlusLogo width="48" height="48" />
            <h1 className="text-center text-xl font-semibold">Password changed</h1>
          </div>
        </div>
        <div className="p-6 text-center text-base font-semibold">
          Your password has been changed. Please log in with your new password.
          <BackToLoginLink className="mt-3 font-normal" />
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
        <div className="flex w-full flex-col items-center justify-center gap-1">
          <PlusLogo width="48" height="48" />
          <h1 className="text-center text-xl font-semibold">Change password</h1>
        </div>
      </div>

      <form
        onSubmit={onSubmitNewPassword}
        onChange={() => {
          setErrorType(null)
          setCustomErrorText(null)
        }}
        className={clsx('space-y-3', 'flex flex-col p-6', errorType && 'text-copy-alert')}
      >
        {errorType && (
          <div className="text-center text-base font-semibold">
            {getErrorTextFromErrorType(errorType, customErrorText)}
          </div>
        )}
        <div>
          <label htmlFor="new-password-input" className="text-base font-semibold">
            New password
          </label>
          <Input
            id="new-password-input"
            name="newPassword"
            type="password"
            value={newPasswordValues.newPassword}
            autoComplete="new-password"
            onChange={onNewPasswordFormFieldChange}
            hasError={!!errorType}
          />
        </div>
        <div>
          <label htmlFor="new-password-confirm-input" className="text-base font-semibold">
            Verify new password
          </label>
          <Input
            id="new-password-confirm-input"
            name="newPasswordConfirm"
            type="password"
            value={newPasswordValues.newPasswordConfirm}
            autoComplete="new-password"
            onChange={onNewPasswordFormFieldChange}
            hasError={!!errorType}
          />
        </div>
        <div>
          <Button
            className="w-full"
            variant="primary"
            disabled={
              !!(
                errorType ||
                !newPasswordValues.newPassword ||
                !newPasswordValues.newPasswordConfirm
              )
            }
          >
            {loading ? 'Changing…' : 'Change password'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ResetPassword
