import clsx from 'clsx'
import { useCallback, useEffect } from 'react'
import { Link, RefreshCw, Trash2 } from 'react-feather'
import { useInView } from 'react-intersection-observer'

import { ContentType, SnapshotFieldsFragment } from '@/generated/graphql'
import { LinkCTA, Loader } from '@/shared/components'
import { ContentCardDataWrapper } from '@/shared/components/ContentCard/ContentCardDataWrapper'
import { LoadingCard } from '@/shared/components/ContentCard/LoadingCard'
import useCopyLink from '@/shared/components/ContentGrid/Actions/copyLink'
import useDelete from '@/shared/components/ContentGrid/Actions/delete'
import useRefresh from '@/shared/components/ContentGrid/Actions/refresh'
import { useLazyTrackContentView } from '@/shared/hooks/useTrackContentView'
import { remainingItemsToLoad } from '@/util'
import { getSnapshotStatus } from '@/util/snapshotHelpers'
import { useAppDispatch, useAppSelector } from '@/web/hooks'
import { createObjects, selectCurrentPage } from '@/web/store/document'
import { updateSnapshot } from '@/web/store/snapshots'

type Props = {
  snapshots: SnapshotFieldsFragment[]
  searchQuery: string
  loading: boolean
  loadMore: () => void
  loadingMore: boolean
  counts: number
}

const SnapshotsView = ({
  snapshots,
  searchQuery,
  loading,
  loadMore,
  loadingMore,
  counts,
}: Props): JSX.Element => {
  const deleteCallback = useDelete()
  const refreshCallback = useRefresh()
  const copyLinkCallback = useCopyLink()

  const dispatch = useAppDispatch()
  const currentPage = useAppSelector(selectCurrentPage)
  const trackContentView = useLazyTrackContentView()

  const onPreviewClick = useCallback(
    (snapshot: SnapshotFieldsFragment) => {
      dispatch(updateSnapshot(snapshot))
      void dispatch(
        createObjects({
          objects: [
            {
              content: {
                domain: false,
                id: snapshot.id,
                schedule: false,
                timestamp: true,
                title: false,
                versionId: null,
              },
              pageId: currentPage.id,
              position: {
                h: snapshot.instructions.frame.frameHeight,
                r: 0,
                w: snapshot.instructions.frame.frameWidth,
                x: 'center',
                y: 'center',
              },
              type: 'Snapshot',
            },
          ],
        }),
      )
      void trackContentView(ContentType.Snapshot, snapshot.urn, true)
    },
    [currentPage.id, dispatch, trackContentView],
  )

  const { ref: inViewRef, inView } = useInView()

  useEffect(() => {
    if (inView && !loading) {
      loadMore()
    }
  }, [inView, loadMore, loading])

  if (loading && snapshots.length === 0) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <Loader />
      </div>
    )
  }

  return (
    <>
      {snapshots.length === 0 ? (
        <div className="flex h-full flex-col items-center justify-center">
          {searchQuery ? (
            <>
              <div className="text-copy break-all text-center text-base font-semibold">
                No Snapshots found that contain “{searchQuery}”
              </div>
              <div className="text-copy-secondary mt-1 text-base">Try a less specific query</div>
            </>
          ) : (
            <>
              <div className="text-copy break-all text-center text-base font-semibold">
                No Snapshots found
              </div>
              <div className="text-copy-secondary mt-1 text-base">
                <LinkCTA
                  href="https://chrome.google.com/webstore/detail/plus/bnebanooamokkihfjepphafoekheipfh"
                  target="_blank"
                  rel="noreferrer"
                >
                  Install our Plus extension
                </LinkCTA>{' '}
                to take Snapshots
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="grid grid-cols-3 gap-3">
            {snapshots.map((snapshot, i) => {
              const { isCapturingOrRefreshingSnapshot, snapshotCanBeDeleted } =
                getSnapshotStatus(snapshot)

              const card = (
                <ContentCardDataWrapper
                  key={snapshot.id}
                  customClickHandler={() => onPreviewClick(snapshot)}
                  disableDragAndDrop={true}
                  content={snapshot}
                  overflowItems={[
                    {
                      callback: () => {
                        refreshCallback([snapshot])
                      },
                      disabled: isCapturingOrRefreshingSnapshot,
                      icon: RefreshCw,
                      name: 'Refresh',
                    },
                    {
                      callback: () => {
                        copyLinkCallback(snapshot)
                      },
                      icon: Link,
                      name: 'Copy Link',
                    },
                    {
                      callback: () => {
                        deleteCallback([snapshot])
                      },
                      disabled: !snapshotCanBeDeleted,
                      icon: Trash2,
                      name: 'Delete',
                      styles: clsx([
                        'text-interactive-destructive',
                        'active:text-interactive-destructive',
                        'active:bg-interactive-destructive-light',
                      ]),
                    },
                  ]}
                />
              )

              if (snapshots.length - 5 === i) {
                return (
                  <div key={snapshot.id} ref={inViewRef}>
                    {card}
                  </div>
                )
              }

              return card
            })}
            {loadingMore &&
              [...Array(remainingItemsToLoad(50, snapshots.length, counts || 0))].map((_, i) => (
                <LoadingCard type={ContentType.Snapshot} key={i} />
              ))}
          </div>
        </>
      )}
    </>
  )
}

export default SnapshotsView
