import clsx from 'clsx'
import { Fragment, useEffect } from 'react'
import { Edit, Folder, Link as LinkIcon, RefreshCw, Trash2, Type, Users } from 'react-feather'
import { useHistory } from 'react-router-dom'

import { ContentType, DocumentCardFragment, SnapshotFieldsFragment } from '@/generated/graphql'
import { ContentV2 } from '@/shared/types'
import { getSnapshotStatus } from '@/util/snapshotHelpers'

import { ContentCardDataWrapper } from '../ContentCard/ContentCardDataWrapper'
import { CreatePageCTACard } from '../ContentCard/CreatePageCTACard'
import { LoadingCard } from '../ContentCard/LoadingCard'
import useCopyLink from './Actions/copyLink'
import useDelete from './Actions/delete'
import useMoveOrg from './Actions/moveFolder'
import useMoveTo from './Actions/moveto'
import useRefresh from './Actions/refresh'
import useRename from './Actions/rename'
import { SeeMoreCard } from './SeeMoreCard'
import { useContentGrid } from './useContentGrid'

interface IBaseGridProps {
  readonly contents: ReadonlyArray<readonly ContentV2[]>
  readonly counts?: {
    readonly folders: number | undefined
    readonly documents: number | undefined
    readonly snapshots: number | undefined
  }
  readonly disableSelection?: boolean
  readonly hideSectionHeaders?: boolean
  readonly isGallery?: boolean
  readonly inViewRef?: (node?: Element | null | undefined) => void
  readonly loadingCardsToShow?: number
}

export const BaseGrid = (props: IBaseGridProps): JSX.Element => {
  const { unselectAllItems } = useContentGrid()
  const history = useHistory()

  useEffect(() => {
    unselectAllItems()
  }, [unselectAllItems, history.location])

  const deleteCallback = useDelete()
  const refreshCallback = useRefresh()
  const renameCallback = useRename()
  const moveToCallback = useMoveTo()
  const moveOrgCallback = useMoveOrg()
  const copyLinkCallback = useCopyLink()

  const getHeader = (content: ContentV2) => {
    switch (content.__typename.toUpperCase() as ContentType) {
      case ContentType.Document:
        return 'Pages'
      case ContentType.Folder:
        return 'Folders'
      case ContentType.Snapshot:
        return 'Snapshots'
      default:
        return ''
    }
  }

  const getContentCards = (innerContents: readonly ContentV2[]) => {
    switch (innerContents[0].__typename.toUpperCase() as ContentType) {
      case ContentType.Document: {
        return innerContents.map((document, i) => {
          const card = (
            <ContentCardDataWrapper
              galleryTile={props.isGallery}
              key={document.id}
              content={document}
              selectable={!props.isGallery && !props.disableSelection}
              noLocationLine
              noByline={!!props.isGallery}
              noFolderLine={!!props.isGallery}
              disableDragAndDrop={!!props.isGallery}
              overflowItems={
                !props.isGallery
                  ? [
                      {
                        callback: () => copyLinkCallback(document as DocumentCardFragment),
                        icon: LinkIcon,
                        name: 'Copy Link',
                      },
                      {
                        callback: () => moveToCallback([document]),
                        icon: Folder,
                        name: 'Move To',
                      },
                      {
                        callback: () => renameCallback([document]),
                        icon: Type,
                        name: 'Rename',
                      },
                      {
                        callback: () => deleteCallback([document]),
                        icon: Trash2,
                        name: 'Delete',
                        styles: clsx([
                          'text-interactive-destructive',
                          'active:text-interactive-destructive',
                          'active:bg-interactive-destructive-light',
                        ]),
                      },
                    ]
                  : undefined
              }
            />
          )

          if (innerContents.length - 5 === i) {
            return (
              <div key={document.id} ref={props.inViewRef}>
                {card}
              </div>
            )
          }

          return card
        })
      }
      case ContentType.Folder: {
        return innerContents.map((folder, i) => {
          const card = (
            <ContentCardDataWrapper
              key={folder.id}
              content={folder}
              noLocationLine
              selectable={!props.disableSelection}
              overflowItems={[
                {
                  callback: () => renameCallback([folder]),
                  icon: Type,
                  name: 'Rename',
                },
                {
                  callback: () => moveOrgCallback([folder], folder.team ? 'personal' : 'team'),
                  icon: folder.team ? Edit : Users,
                  name: folder.team ? 'Move to Personal' : 'Move to Team',
                },
                {
                  callback: () => deleteCallback([folder]),
                  icon: Trash2,
                  name: 'Delete',
                  styles: clsx([
                    'text-interactive-destructive',
                    'active:text-interactive-destructive',
                    'active:bg-interactive-destructive-light',
                  ]),
                },
              ]}
            />
          )

          if (innerContents.length - 5 === i) {
            return (
              <div key={folder.id} ref={props.inViewRef}>
                {card}
              </div>
            )
          }

          return card
        })
      }
      case ContentType.Snapshot: {
        return innerContents.map((snapshot, i) => {
          const { isCapturingOrRefreshingSnapshot, snapshotCanBeDeleted } = getSnapshotStatus(
            snapshot as SnapshotFieldsFragment,
          )

          const card = (
            <ContentCardDataWrapper
              key={snapshot.id}
              content={snapshot}
              noLocationLine
              selectable={!props.disableSelection}
              overflowItems={[
                {
                  callback: () => refreshCallback([snapshot as SnapshotFieldsFragment]),
                  disabled: isCapturingOrRefreshingSnapshot,
                  icon: RefreshCw,
                  name: 'Refresh',
                },
                {
                  callback: () => copyLinkCallback(snapshot as SnapshotFieldsFragment),
                  icon: LinkIcon,
                  name: 'Copy Link',
                },
                {
                  callback: () => moveToCallback([snapshot]),
                  icon: Folder,
                  name: 'Move To',
                },
                {
                  callback: () => renameCallback([snapshot]),
                  icon: Type,
                  name: 'Rename',
                },
                {
                  callback: () => deleteCallback([snapshot]),
                  disabled: !snapshotCanBeDeleted,
                  icon: Trash2,
                  name: 'Delete',
                  styles: clsx([
                    'text-interactive-destructive',
                    'active:text-interactive-destructive',
                    'active:bg-interactive-destructive-light',
                  ]),
                },
              ]}
            />
          )

          if (innerContents.length - 5 === i) {
            return (
              <div key={snapshot.id} ref={props.inViewRef}>
                {card}
              </div>
            )
          }

          return card
        })
      }
      default:
        return <></>
    }
  }

  const getContentCount = (type: ContentType): number | undefined => {
    switch (type) {
      case ContentType.Document:
        return props.counts?.documents
      case ContentType.Folder:
        return props.counts?.folders
      case ContentType.Snapshot:
        return props.counts?.snapshots
      default:
        return undefined
    }
  }

  return (
    <div className="relative w-full">
      <div className="absolute flex w-full">
        <div className="flex w-full flex-col gap-10 pb-20 lg:pb-10">
          {props.contents.map((innerContents, i) => {
            // Note that innerContents will only ever be an array of one type of Content (Snapshot[], Document[] or Folder[])
            if (innerContents.length === 0) {
              return <Fragment key={i} />
            }
            const type = innerContents[0].__typename.toUpperCase() as ContentType

            return (
              <div className="flex flex-col gap-y-4 px-4 lg:px-6" key={type}>
                <div
                  className={clsx(
                    'text-base font-semibold lg:text-lg',
                    props.hideSectionHeaders && 'hidden',
                  )}
                >
                  {getHeader(innerContents[0])}
                </div>
                <div
                  className={clsx(
                    'grid grid-flow-row items-end gap-3',
                    'grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5',
                    'auto-rows-fr',
                  )}
                >
                  {props.isGallery && <CreatePageCTACard />}
                  {getContentCards(innerContents)}
                  {typeof props.loadingCardsToShow === 'number' &&
                    props.loadingCardsToShow > 0 &&
                    [...Array(props.loadingCardsToShow)].map((_, i) => (
                      <LoadingCard type={type} key={i} />
                    ))}
                  {props.counts && (getContentCount(type) || 0) > innerContents.length && (
                    <div className="h-full w-full" onClick={(e) => e.stopPropagation()}>
                      <SeeMoreCard count={getContentCount(type) ?? 0} type={type} />
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
