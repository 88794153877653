import { useCallback } from 'react'
import { Filter } from 'react-feather'

import { ContentType } from '@/generated/graphql'
import { searchClientStateVar } from '@/shared/apollo/apolloLocalState'
import { SelectMenu } from '@/shared/components'
import { SelectOption } from '@/shared/components/Select'

export const FILTER_MODES: {
  label: string
  value: ContentType | null
}[] = [
  { label: 'Pages', value: ContentType.Document },
  { label: 'Snapshots', value: ContentType.Snapshot },
  { label: 'Folders', value: ContentType.Folder },
]

const DEFAULT_FILTER = { label: 'Everything', value: null }

const SearchFilter = (): JSX.Element => {
  const { activeContentType } = searchClientStateVar()

  const onChangeHandler = useCallback((selectedOption: SelectOption) => {
    searchClientStateVar({
      ...searchClientStateVar(),
      activeContentType: selectedOption.value as ContentType,
    })
  }, [])

  return (
    <div className="w-fit">
      <SelectMenu
        onChange={onChangeHandler}
        defaultValue={DEFAULT_FILTER}
        value={FILTER_MODES.find((x) => x.value === activeContentType) || DEFAULT_FILTER}
        options={[DEFAULT_FILTER, ...FILTER_MODES]}
        modal={true}
        icon={<Filter size={16} />}
      />
    </div>
  )
}

export default SearchFilter
