import { captureException } from '@sentry/react'
import clsx from 'clsx'
import { capitalize, kebabCase } from 'lodash-es'
import { useEffect, useState } from 'react'
import * as React from 'react'
import { Check, Loader, X } from 'react-feather'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'

import {
  CreateOrganizationInput,
  IsSlugAvailableQueryResult,
  SlugAvailability,
  useCreateOrgMutation,
  useIsSlugAvailableQuery,
  ViewerNavDataQuery,
} from '@/generated/graphql'
import { activeOrganizationState } from '@/shared/apollo/apolloLocalState'
import { useAuth } from '@/shared/auth/useAuth'
import { Button, Input } from '@/shared/components'
import { useViewer } from '@/web/hooks'

const generateNormalizedSlug = (input: string) =>
  `${kebabCase(input).slice(0, 35)}-${Math.random().toString(36).slice(4, 8)}`

const generateDefaultInput = (viewer?: ViewerNavDataQuery['viewer']): CreateOrganizationInput => {
  if (!viewer) {
    return {
      name: '',
      slug: '',
      user: {
        name: '',
      },
    }
  }

  const localPart = viewer.email.split('@')[0]

  return {
    name: `${capitalize(localPart)}'s Organization`,
    slug: generateNormalizedSlug(localPart),
    user: {
      name: viewer.email,
    },
  }
}

const renderOrgSlugHint = (
  orgSlug: string,
  orgSlugQueryResult: IsSlugAvailableQueryResult,
): JSX.Element => {
  if (orgSlug === '') {
    return (
      <div className="text-copy-secondary text-xxs mt-1 flex flex-row">
        URL must be unique and 3-40 characters
      </div>
    )
  }

  if (orgSlugQueryResult.loading) {
    return (
      <div className="text-copy-secondary text-xxs mt-1 flex flex-row">
        <Loader size={12} className="animate-spin-slow" />
        &nbsp;
        <span>Checking URL…</span>
      </div>
    )
  }

  switch (orgSlugQueryResult.data?.isSlugAvailable) {
    default:
    case SlugAvailability.Available:
      return (
        <div className="text-interactive-primary text-xxs mt-1 flex flex-row">
          <Check size={12} />
          &nbsp;
          <span>URL is available</span>
        </div>
      )
    case SlugAvailability.InvalidFormat:
      return (
        <div className="text-interactive-destructive text-xxs mt-1 flex flex-row">
          <X size={12} />
          &nbsp;
          <span>URL must be unique and 3-40 characters</span>
        </div>
      )
    case SlugAvailability.NotAvailable:
      return (
        <div className="text-interactive-destructive text-xxs mt-1 flex flex-row">
          <X size={12} />
          &nbsp;
          <span>URL is taken</span>
        </div>
      )
  }
}

const OneStepJoin = () => {
  const { logout, refreshToken } = useAuth()
  const { reloadViewer, viewer } = useViewer()
  const history = useHistory()
  const [createOrgInput, setCreateOrgInput] = useState<CreateOrganizationInput>(
    generateDefaultInput(viewer),
  )
  const [orgSlugFocused, setOrgSlugFocused] = useState(false)
  const [modifiedSlugManually, setModifiedSlugManually] = useState(false)
  const isOrgSlugValidQuery = useIsSlugAvailableQuery({
    skip: createOrgInput.slug === '',
    variables: {
      slug: createOrgInput.slug,
    },
  })
  const [createOrgMutation, { loading }] = useCreateOrgMutation()

  useEffect(() => {
    if (
      viewer &&
      createOrgInput.name === '' &&
      createOrgInput.slug === '' &&
      createOrgInput.user.name === ''
    ) {
      setCreateOrgInput(generateDefaultInput(viewer))
    }
  }, [createOrgInput, viewer])
  const [errorMessage, setErrorMessage] = useState('')
  const hasError = !!errorMessage

  const updateOrgName = (updatedOrgName: string) => {
    setCreateOrgInput((value) => {
      const newValue = { ...value }
      newValue.name = updatedOrgName
      if (!modifiedSlugManually) {
        newValue.slug = updatedOrgName === '' ? '' : generateNormalizedSlug(updatedOrgName)
      }
      return newValue
    })
  }
  const updateOrgSlug = (updatedOrgSlug: string) => {
    setCreateOrgInput((value) => ({ ...value, slug: updatedOrgSlug }))
    if (!modifiedSlugManually) {
      setModifiedSlugManually(true)
    }
  }
  const onSubmitForm = (ev: React.SyntheticEvent) => {
    ev.preventDefault()

    const run = async () => {
      const { data, errors } = await createOrgMutation({
        variables: {
          input: {
            name: createOrgInput.name.trim(),
            slug: createOrgInput.slug.trim(),
            user: {
              name: createOrgInput.user.name.trim(),
            },
          },
        },
      })

      if (errors) {
        setErrorMessage(
          'An error occurred during account creation. Please try again, or reach out to support@plusdocs.com if this error does not go away.',
        )
        captureException({
          message: errors.map((err) => `${err.name}: ${err.message}`).join('\n'),
          name: 'CreateAccountError',
        })
        return
      }

      activeOrganizationState({
        id: data?.createOrganization.organization?.id ?? '',
        slug: data?.createOrganization.organization?.slug ?? '',
      })

      await refreshToken()
      await reloadViewer()

      history.replace(`/${data?.createOrganization.organization?.slug ?? ''}?isFtu`)
    }

    void run()
  }

  return (
    <div className="bg-base-gray960 flex h-screen flex-col items-center p-3 sm:justify-center sm:p-0">
      <Helmet title="Create account" />
      <div className="absolute top-3 left-3">
        <Button variant="ghost" onClick={() => void logout()}>
          Log out
        </Button>
      </div>
      <div className="border-divider-light-gray bg-base-white shadow-soft w-full max-w-[400px] rounded border sm:w-[375px]">
        <div className="border-divider-light-gray flex w-full max-w-[400px] flex-col items-center justify-center gap-3 border-b p-6">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <h1 className="text-center text-xl font-semibold">Create account</h1>
          </div>
        </div>
        <form
          className={clsx('flex flex-col gap-y-3 p-6', hasError && 'text-copy-alert')}
          onSubmit={onSubmitForm}
        >
          {hasError && (
            <div className="self-center">
              <h1 className="text-interactive-destructive text-center text-base font-semibold">
                {errorMessage}
              </h1>
            </div>
          )}
          <div className="w-full">
            <label
              htmlFor="user-name-input"
              className={clsx(
                'text-base font-semibold',
                hasError && 'text-interactive-destructive',
              )}
            >
              Your name
            </label>
            <Input
              className="mt-1"
              hasError={hasError}
              id="user-name-input"
              value={createOrgInput.user.name}
              placeholder="e.g., Pietro Crespi"
              autoComplete="name"
              onChange={(x) =>
                setCreateOrgInput((value) => ({ ...value, user: { name: x.target.value } }))
              }
              required
              maxLength={100}
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="org-name-input"
              className={clsx(
                'text-base font-semibold',
                hasError && 'text-interactive-destructive',
              )}
            >
              Organization name
            </label>
            <Input
              className="mt-1"
              hasError={hasError}
              id="org-name-input"
              value={createOrgInput.name}
              autoComplete="organization"
              onChange={(x) => updateOrgName(x.target.value)}
              required
              maxLength={100}
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="slug-input"
              className={clsx(
                'text-base font-semibold',
                hasError && 'text-interactive-destructive',
              )}
            >
              Organization URL
            </label>
            <div
              className={clsx(
                'border-divider-light-gray mt-1 flex w-full items-center rounded border py-2 px-3 text-base',
                'hover:bg-interactive-secondary-hover',
                'active:bg-interactive-secondary-active',
                orgSlugFocused && 'border-interactive-primary',
                'focus:ring-0',
                'focus:ring-offset-0',
                'disabled:opacity-50',
                'disabled:cursor-not-allowed',
                hasError && 'border-copy-alert focus:border-copy-alert',
              )}
            >
              <span className="text-copy-secondary select-none text-xs">app.plusdocs.com/</span>
              <input
                className="m-0 w-full appearance-none !border-none bg-transparent p-0 text-base !shadow-none !ring-0 focus:!border-none focus:!shadow-none focus:!ring-0"
                id="slug-input"
                name="slug"
                type="text"
                onChange={(x) =>
                  updateOrgSlug(
                    x.target.value
                      .trim()
                      .toLowerCase()
                      .replace(/[^a-z0-9-]+/gi, ''),
                  )
                }
                onFocus={() => setOrgSlugFocused(true)}
                onBlur={() => setOrgSlugFocused(false)}
                value={createOrgInput.slug}
                maxLength={40}
                required
              />
            </div>
            {renderOrgSlugHint(createOrgInput.slug, isOrgSlugValidQuery)}
          </div>
          <Button
            disabled={
              loading ||
              isOrgSlugValidQuery.data?.isSlugAvailable !== SlugAvailability.Available ||
              hasError ||
              createOrgInput.name === '' ||
              createOrgInput.slug === '' ||
              createOrgInput.user.name === ''
            }
          >
            Create account
          </Button>
        </form>
      </div>
    </div>
  )
}

export default OneStepJoin
