import * as React from 'react'

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement>

const LinkCTA = (props: Props): JSX.Element => {
  const Tag = props.href ? 'a' : 'span'

  return (
    <Tag className="text-interactive-primary cursor-pointer hover:underline" {...props}>
      {props.children}
    </Tag>
  )
}

export default LinkCTA
