import cx from 'clsx'
import * as React from 'react'
import { Search } from 'react-feather'

import {
  ContentType,
  OrderDirection,
  SearchQueryVariables,
  SearchResultOrderField,
  SnapshotFieldsFragment,
} from '@/generated/graphql'
import { useScrollDetector } from '@/web/hooks'
import useSearch from '@/web/hooks/useSearch'

import { Input } from '../../../shared/components'
import SnapshotsView from './SnapshotsView'

const SnapshotsPopover = (): JSX.Element => {
  const [setScrollRef, isScrolled] = useScrollDetector()
  const [searchQuery, setSearchQuery] = React.useState('')

  const searchInput: SearchQueryVariables = React.useMemo(
    () => ({
      filter: {
        contentTypes: [ContentType.Snapshot],
        query: searchQuery,
      },
      first: 15,
      sort: {
        direction: OrderDirection.Desc,
        field: SearchResultOrderField.CreatedAt,
      },
    }),
    [searchQuery],
  )

  const { loading, nodes, loadMore, loadingMore, counts } = useSearch(searchInput)

  const onSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value)

  return (
    <div className="shadow-soft ring-base-black mt-0.5 w-[600px] rounded ring-1 ring-opacity-5">
      <div className="bg-background-panel relative rounded">
        <div
          className={cx('relative flex space-x-2 p-3', {
            'shadow-dark-hard': isScrolled,
          })}
        >
          <div className="flex w-full space-x-2">
            <div className="flex flex-1">
              <Input
                leadingIcon={
                  <Search size={16} className="text-copy-secondary" aria-hidden="true" />
                }
                placeholder="Search all Snapshots"
                value={searchQuery}
                onChange={onSearchQueryChange}
              />
            </div>
          </div>
        </div>
        <div
          className="h-[400px] overflow-y-auto p-3 pt-0"
          ref={(ref: HTMLDivElement) => {
            setScrollRef(ref)
          }}
        >
          <SnapshotsView
            snapshots={nodes as SnapshotFieldsFragment[]}
            searchQuery={searchQuery}
            loading={loading}
            loadMore={loadMore}
            loadingMore={loadingMore}
            counts={counts?.snapshots || 0}
          />
        </div>
      </div>
    </div>
  )
}

export default SnapshotsPopover
