import { useReactiveVar } from '@apollo/client'
import * as React from 'react'
import { Search as SearchIcon } from 'react-feather'
import { useHistory } from 'react-router'

import { OrderDirection, SearchQueryVariables, SearchResultOrderField } from '@/generated/graphql'
import { searchClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Input, Loader } from '@/shared/components'
import { ContentV2 } from '@/shared/types'
import { getContentLinkUrl } from '@/util/helpers'
import { useSearch } from '@/web/hooks/useSearch'

import SearchFilter from './SearchFilter'
import { SearchList } from './SearchList'

/**
 * React component for handling overall search experience ()
 * @returns React Component that handles search functionality
 */
const SearchPane = (): JSX.Element => {
  const { activeContentType, searchQuery, sortField } = useReactiveVar(searchClientStateVar)

  const searchInput: SearchQueryVariables = React.useMemo(
    () => ({
      filter: {
        ...(activeContentType && { contentTypes: [activeContentType] }),
        query: searchQuery,
      },
      first: 50,
      sort: {
        direction: OrderDirection.Asc,
        field: sortField,
      },
    }),
    [activeContentType, searchQuery, sortField],
  )

  const { loading, nodes } = useSearch(searchInput)

  const updateSearchQueryCallback = React.useCallback(
    (onChangeEvent: React.ChangeEvent<HTMLInputElement>) => {
      searchClientStateVar({
        ...searchClientStateVar(),
        searchQuery: onChangeEvent.target.value,
      })
    },
    [],
  )

  const history = useHistory()

  const onItemSelectedCallback = (item: ContentV2) => {
    history.push(getContentLinkUrl(item))
  }

  const noMatchingResults = nodes.length === 0 && searchQuery

  // Effect to reset search state on close
  React.useEffect(
    () => () => {
      searchClientStateVar({
        activeContentType: null,
        searchQuery: '',
        sortField: SearchResultOrderField.CreatedAt,
      })
    },
    [],
  )

  return (
    <div className="flex h-[500px] flex-col ">
      <div className="mb-3 flex space-x-2">
        <Input
          aria-label="Search input"
          value={searchQuery}
          name="search"
          onChange={updateSearchQueryCallback}
          autoComplete="off"
          leadingIcon={<SearchIcon className="text-copy-secondary cursor-pointer" size={16} />}
        />
        <SearchFilter />
      </div>
      {loading ? (
        <div className="m-auto">
          <Loader />
        </div>
      ) : noMatchingResults ? (
        <div className="flex h-full flex-col items-center justify-center">
          <div className="text-copy break-all text-center text-base font-semibold">
            No results found that contain “{searchQuery}”
          </div>
          <div className="text-copy-secondary mt-1 text-base">Try a less specific query</div>
        </div>
      ) : null}

      {nodes.length > 0 ? (
        <>
          <div className="h-full overflow-y-auto">
            <SearchList
              searchQuery={searchQuery}
              searchResults={nodes}
              showContentText={true}
              onItemSelectedCallback={onItemSelectedCallback}
            />
          </div>
        </>
      ) : null}
    </div>
  )
}

export default SearchPane
