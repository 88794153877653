import { Link } from 'react-router-dom'

import { Button, PlusLogo } from '@/shared/components'
const SignupSidebar = (): JSX.Element => {
  return (
    <div className="border-divider-light-gray bg-background-panel md:h-viewport-minus-header  flex h-full w-full flex-col items-center justify-center overflow-y-auto py-3 px-3 leading-4 md:w-[248px] md:border-l">
      <div className="flex w-full flex-col items-center justify-center gap-1">
        <PlusLogo width="40" height="40" />
        <h1 className="text-center text-xl font-semibold">Sign up for Plus</h1>
        <p className="center text-primary mb-2 text-center text-base">
          Take live Snapshots of any app or website.
        </p>
        <Link
          className="w-full"
          to="/register?utm_source=plus&utm_medium=snapshot&utm_campaign=signup_CTA"
        >
          <Button variant="primary" className="w-full">
            Sign up for free
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default SignupSidebar
