import { ApolloProvider, gql, useMutation } from '@apollo/client'
import { useCallback, useState } from 'react'
import { Check, Mail, X } from 'react-feather'

import { Referral } from '@/generated/graphql'
import { apolloClient } from '@/shared/apollo/apolloClient'
import { Button, Dialog, TextArea } from '@/shared/components'

const CREATE_REFERRALS_MUTATION = gql`
  mutation ReferralsCreate($recipients: [EmailAddress!]!) {
    createReferrals(recipients: $recipients) {
      code
      success
      message
      referrals {
        id
        recipient
      }
    }
  }
`

export function ReferralsModal() {
  const [open, setOpen] = useState(true)
  const [emailsText, setEmailsText] = useState('')
  const [referrals, setReferrals] = useState<readonly Referral[]>([])

  const [createReferrals, { error: mutationError, loading: mutationLoading }] =
    useMutation(CREATE_REFERRALS_MUTATION)

  const handleSubmit = useCallback(() => {
    const recipients = emailsText
      .split(/[\s,]+/)
      .filter((item) => item)
      .filter((item) =>
        item.match(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
        ),
      )

    void createReferrals({
      variables: {
        recipients,
      },
    }).then((result) => {
      setReferrals(result.data.createReferrals.referrals)
      setEmailsText('')
    })
  }, [emailsText, createReferrals])

  return (
    <Dialog isOpen={open} setIsOpen={setOpen} showBackgroundOverlay>
      <div className="border-divider-light-gray bg-background-white mx-auto mt-3 inline-block w-full max-w-[480px] transform rounded-md border text-left align-top transition-all md:mt-12">
        <Button
          className="absolute right-1 top-1 h-8 w-8"
          variant="ghost"
          tabIndex={-1}
          onClick={() => setOpen(false)}
        >
          <X size={16} />
        </Button>

        <div className="flex h-full w-full flex-col gap-3 p-3">
          <span className="text-lg font-semibold">Refer your friends</span>

          <div className="flex flex-row gap-x-2">
            <div className="text-primary w-full">
              <div className="text-copy flex flex-row items-center gap-x-2 text-[12px]">
                <p>
                  Get <strong>one month free</strong> on the Slides plan for every 3 friends you
                  refer.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-x-2">
            <div className="text-primary w-full">
              <div className="text-base-highlightA flex flex-row items-center gap-x-2 text-[12px]">
                <Check size={14} className="text-base-highlightA" />
                Unlimited presentations
              </div>

              <div className="text-base-highlightA flex flex-row items-center gap-x-2 text-[12px]">
                <Check size={14} className="text-base-highlightA" />
                100+ slide layouts
              </div>

              <div className="text-base-highlightA flex flex-row items-center gap-x-2 text-[12px]">
                <Check size={14} className="text-base-highlightA" />
                Remix and rewrite with AI
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-x-2">
            <div className="w-full">
              <div className="w-full pb-1">
                <TextArea
                  className="h-[80px] w-full"
                  placeholder="Enter emails separated by comma or newline"
                  value={emailsText}
                  onChange={(event) => setEmailsText(event.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="flex w-full flex-row gap-x-2">
            <div className="flex w-full justify-end">
              <Button variant="primary" onClick={handleSubmit} disabled={mutationLoading}>
                {mutationLoading ? 'Inviting..' : 'Invite'}
              </Button>
            </div>
          </div>

          {mutationError && (
            <div className="flex flex-row gap-x-2">
              <div className="text-copy-alert w-full text-base">
                <p>
                  <strong>Error: {mutationError.name}</strong>
                  <br />
                  {mutationError.message}
                </p>
              </div>
            </div>
          )}

          <div className={'flex flex-col gap-1 overflow-y-auto'}>
            {referrals.map((referral) => (
              <div className="flex" key={referral.id}>
                <Mail className="m-1" />
                <div className="my-auto pl-3 text-xs">
                  <span className="font-semibold">{referral.recipient}</span>
                  <br />
                  <span className="text-copy-secondary">Referral sent</span>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-row gap-x-2">
            <div className="text-copy-secondary w-full text-base text-[11px]">
              <p>
                <strong>How it works</strong>
                <br />
                We&apos;ll email your friends a sign up link. After three sign ups, we&apos;ll email
                you a coupon for 1 month free on the Slides plan.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default function ReferralsModalContainer() {
  return (
    <ApolloProvider client={apolloClient}>
      <ReferralsModal />
    </ApolloProvider>
  )
}
