import { useReactiveVar } from '@apollo/client'
import { useCallback } from 'react'

import { SearchResultOrderField } from '@/generated/graphql'
import { feedClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Sort } from '@/shared/components'
import SelectMenu, { SelectMenuOption } from '@/shared/components/SelectMenu'

export const FILTER_MODES: {
  label: string
  value: SearchResultOrderField
}[] = [
  { label: 'Date created', value: SearchResultOrderField.CreatedAt },
  { label: 'Name', value: SearchResultOrderField.Name },
]

const FeedSortMenu = (): JSX.Element => {
  const { sortField } = useReactiveVar(feedClientStateVar)

  const onChangeHandler = useCallback((selectedOption: SelectMenuOption) => {
    feedClientStateVar({
      ...feedClientStateVar(),
      sortField: selectedOption.value as SearchResultOrderField,
    })
  }, [])

  return (
    <div className="w-fit">
      <SelectMenu
        icon={<Sort />}
        onChange={onChangeHandler}
        options={FILTER_MODES}
        selectedValueLabel={`Sort`}
        value={FILTER_MODES.find((x) => x.value === sortField) || FILTER_MODES[0]}
      />
    </div>
  )
}

export default FeedSortMenu
