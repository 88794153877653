import { useReactiveVar } from '@apollo/client'
import * as React from 'react'
import { FolderPlus, Search as SearchIcon } from 'react-feather'

import {
  ContentType,
  Folder,
  OrderDirection,
  SearchQueryVariables,
  SearchResultOrderField,
  useCreateFolderMutation,
} from '@/generated/graphql'
import { searchClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Button, Input, Loader } from '@/shared/components'
import { ContentV2 } from '@/shared/types'
import { useSearch } from '@/web/hooks/useSearch'

import { SearchList, SearchListInputItem } from './SearchList'
import SearchSort from './SearchSort'

interface MoveToSearchPaneProps {
  onSubmit: (folder: ContentV2) => void
  teamId?: string
  currentFolderId?: string
}

/**
 * React component for handling "move to folder" Search experience
 * @returns React Component that handles search functionality
 */
const MoveToSearchPane = (props: MoveToSearchPaneProps): JSX.Element => {
  const { onSubmit, teamId } = props

  const [createNewFolder] = useCreateFolderMutation()
  const { searchQuery, sortField } = useReactiveVar(searchClientStateVar)

  const searchInput: SearchQueryVariables = React.useMemo(
    () => ({
      filter: {
        contentTypes: [ContentType.Folder],
        query: searchQuery,
      },
      first: 50,
      sort: {
        direction:
          sortField === SearchResultOrderField.Name ? OrderDirection.Asc : OrderDirection.Desc,
        field: sortField,
      },
    }),
    [searchQuery, sortField],
  )

  const { loading, nodes } = useSearch(searchInput)

  const searchResultsWithHome: ContentV2[] = [
    {
      __typename: 'Folder',
      contentCount: 0,
      contentCounts: {
        __typename: 'FolderContentCounts',
        documents: 0,
        snapshots: 0,
      },
      id: 'personal',
      name: 'Personal',
      organization: {
        slug: 'personal',
      },
    },
    {
      __typename: 'Folder',
      contentCount: 0,
      contentCounts: {
        __typename: 'FolderContentCounts',
        documents: 0,
        snapshots: 0,
      },
      id: 'team',
      name: 'Team',
      organization: {
        slug: 'team',
      },
    },
    ...(nodes || []),
  ].filter((node) => {
    if (!props.currentFolderId && props.teamId && (node as Folder).id === 'team') {
      return false
    }

    if (
      (node as Folder).id === 'team' &&
      searchQuery !== '' &&
      !'team'.includes(searchQuery.toLowerCase())
    ) {
      return false
    }

    if (!props.currentFolderId && !props.teamId && (node as Folder).id === 'personal') {
      return false
    }

    if (
      (node as Folder).id === 'personal' &&
      searchQuery !== '' &&
      !'personal'.includes(searchQuery.toLowerCase())
    ) {
      return false
    }

    if ((node as Folder).id === props.currentFolderId) {
      return false
    }

    return true
  }) as ContentV2[]

  const updateSearchQueryCallback = React.useCallback(
    (onChangeEvent: React.ChangeEvent<HTMLInputElement>) => {
      searchClientStateVar({
        ...searchClientStateVar(),
        searchQuery: onChangeEvent.target.value,
      })
    },
    [],
  )

  const [showNewFolderInput, setShowNewFolderInput] = React.useState<boolean>(false)

  const [selectedFolder, setSelectedFolder] = React.useState<ContentV2 | null>(null)

  const selectFolderCallback = React.useCallback(
    (folder: ContentV2) => {
      setSelectedFolder(folder)
    },
    [setSelectedFolder],
  )

  const submitOnClick = () => {
    onSubmit(selectedFolder as ContentV2)
  }

  const createNewFolderCallback = async (folderName: string) => {
    if (folderName !== '') {
      await createNewFolder({
        variables: {
          input: {
            name: folderName,
            ...(teamId && { teamId }),
          },
        },
      })
      setShowNewFolderInput(false)
    }
  }

  const searchContainsTeamOrDraft = () => {
    if ('team'.includes(searchQuery.toLowerCase())) {
      return true
    }

    if ('personal'.includes(searchQuery.toLowerCase())) {
      return true
    }

    return false
  }

  const noMatchingResults = nodes.length === 0 && searchQuery !== '' && !searchContainsTeamOrDraft()

  // Effect to reset search state on close
  React.useEffect(
    () => () => {
      searchClientStateVar({
        activeContentType: null,
        searchQuery: '',
        sortField: SearchResultOrderField.CreatedAt,
      })
    },
    [],
  )

  return (
    <>
      <div className="mb-3 flex space-x-2" data-automation-id="search-input">
        <Input
          aria-label="Search input"
          value={searchQuery}
          name="search"
          onChange={updateSearchQueryCallback}
          autoComplete="off"
          leadingIcon={<SearchIcon className="text-copy-secondary cursor-pointer" size={16} />}
        />
        <div data-automation-id="filter-dropdown">
          <SearchSort />
        </div>
      </div>
      {noMatchingResults ? (
        <div
          data-automation-id="search-empty-state"
          className="flex h-full flex-col items-center justify-center"
        >
          <div className="text-copy break-all text-center text-base font-semibold">
            No results found that contain “{searchQuery}”
          </div>
          <div className="text-copy-secondary mt-1 text-base">Try a less specific query</div>
        </div>
      ) : null}

      {noMatchingResults ? null : (
        <div className="h-[500px] overflow-y-auto">
          {showNewFolderInput && <SearchListInputItem onSubmitHandler={createNewFolderCallback} />}
          {loading ? (
            <div className="m-auto">
              <Loader />
            </div>
          ) : (
            <div data-automation-id="search-results">
              <SearchList
                onItemSelectedCallback={selectFolderCallback}
                searchQuery={searchQuery}
                searchResults={searchResultsWithHome}
                selectedContentId={selectedFolder?.id}
              />
            </div>
          )}
        </div>
      )}
      <div className="flex w-full justify-between justify-self-end pt-1 pb-3">
        <Button
          icon={<FolderPlus size={16} />}
          variant="secondary"
          onClick={() => setShowNewFolderInput(true)}
        />
        <Button disabled={!selectedFolder} onClick={submitOnClick}>
          Move {selectedFolder ? `to ${selectedFolder.name}` : ''}
        </Button>
      </div>
    </>
  )
}

export default MoveToSearchPane
