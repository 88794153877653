import Button from '@plusdocs/google-apps-script-shared/client/components/Button'
import ButtonGroup from '@plusdocs/google-apps-script-shared/client/components/ButtonGroup'
import clsx from 'clsx'
import { reverse } from 'ramda'
import * as React from 'react'
import { Check } from 'react-feather'

import { PlusLogo } from '@/shared/components'

import {
  Plan,
  PlanFrequency,
  PlanTier,
  UpgradeSource,
  useCreateCheckoutLinkMutation,
} from '../../generated/graphql'
import { useViewer } from '../hooks'

const frequencyToHumanReadable = (frequency: PlanFrequency) => {
  switch (frequency) {
    case PlanFrequency.Monthly:
      return 'per month'
    case PlanFrequency.Yearly:
      return 'per month'
  }
}

const getTierText = (tier: PlanTier) => {
  if (tier === PlanTier.Slides) {
    return 'BASIC'
  }

  return tier
}

const getDiscountText = (planFrequency: PlanFrequency, tier: PlanTier) => {
  if (planFrequency === PlanFrequency.Monthly) {
    return
  }

  if (tier === PlanTier.Slides) {
    return '33% off'
  }

  if (tier === PlanTier.Pro) {
    return '20% off'
  }

  return
}

const TierBlob = ({
  currencySymbol,
  price,
  tier,
  frequency,
  isCurrentPlan,
  discountText,
}: {
  readonly currencySymbol: string
  readonly price: number
  readonly tier: PlanTier
  readonly frequency: PlanFrequency
  readonly isCurrentPlan: boolean
  readonly discountText?: string
}) => (
  <div
    className={clsx(
      'shadow-soft relative flex w-full flex-col items-center justify-center rounded py-6',
      {
        'bg-background-white': tier === PlanTier.Slides,
        'bg-base-highlightA': tier === PlanTier.Free,
        'from-base-highlightB to-base-highlightC bg-[linear-gradient(110.18deg,var(--tw-gradient-stops))]':
          tier !== PlanTier.Free && tier !== PlanTier.Slides,
      },
    )}
  >
    {isCurrentPlan && (
      <div className="bg-background-overlay text-copy-active absolute top-1 left-1 rounded-sm px-1 text-base">
        Current plan
      </div>
    )}
    {discountText && (
      <div className="bg-base-highlightC absolute top-1 right-1 rounded-sm px-1 text-base font-semibold">
        {discountText}
      </div>
    )}
    <div className={clsx('text-xl font-semibold', tier !== PlanTier.Slides && 'text-base-white')}>
      {currencySymbol}
      {frequency === PlanFrequency.Monthly ? price : price / 12}
    </div>
    <div className={clsx('text-base', tier !== PlanTier.Slides && 'text-base-white')}>
      per user, {frequencyToHumanReadable(frequency)}
    </div>

    <div className={clsx('text-base', tier !== PlanTier.Slides && 'text-base-white')}>
      {frequency === PlanFrequency.Yearly ? <span>billed annually</span> : <span>&nbsp;</span>}
    </div>
  </div>
)

// eslint-disable-next-line complexity
const NewPlanItem = ({
  currencySymbol,
  isSelectedPlan,
  loading,
  plan,
  price,
  tier,
  frequency,
  onSelect,
  onPlanCheckout,
}: {
  currencySymbol: string
  readonly loading: boolean
  readonly plan: Plan
  readonly price: number
  readonly tier: PlanTier
  readonly frequency: PlanFrequency
  readonly isSelectedPlan: boolean
  readonly onSelect: () => void
  readonly onPlanCheckout: (plan?: Plan) => void
}) => {
  return (
    <div
      className={clsx('w-full rounded p-1 sm:cursor-pointer', {
        'bg-background-white': !isSelectedPlan,
        'from-base-highlightB to-base-highlightC bg-[linear-gradient(110.18deg,var(--tw-gradient-stops))]':
          isSelectedPlan,
      })}
      onClick={onSelect}
    >
      <div className="bg-background-white flex h-full w-full flex-col gap-y-2 rounded p-4">
        <span className="text-copy title text-xl font-semibold capitalize">
          {getTierText(tier).toLowerCase()}&nbsp;
          <span className="text-copy-secondary text-base">
            {tier === PlanTier.Pro && '(Recommended)'}
          </span>
        </span>

        <span className="text-copy-secondary text-base">
          {tier === PlanTier.Free && 'Start generating presentations for free.'}
          {tier === PlanTier.Slides &&
            'Best for individuals creating basic slides and docs, with short, simple prompts.'}
          {tier === PlanTier.Pro &&
            'Try every Plus AI feature, including document upload and long prompts. Downgrade at anytime.'}
        </span>

        <TierBlob
          currencySymbol={currencySymbol}
          price={price}
          tier={tier}
          frequency={frequency}
          isCurrentPlan={false}
          discountText={getDiscountText(frequency, tier)}
        />

        <div className="mt-1 flex flex-col gap-y-1">
          <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
            {tier === PlanTier.Slides && (
              <>
                <Check size={14} className="text-copy-secondary" /> Unlimited presentations and docs
              </>
            )}
            {tier === PlanTier.Pro && (
              <>
                <Check size={14} className="text-copy-secondary" /> Everything in the Basic plan
              </>
            )}
          </div>
          <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
            {tier === PlanTier.Slides && (
              <>
                <Check size={14} className="text-copy-secondary" /> 100+ slide layouts
              </>
            )}
            {tier === PlanTier.Pro && (
              <>
                <Check size={14} className="text-copy-secondary" /> 100K character inputs
              </>
            )}
          </div>
          <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
            {tier === PlanTier.Slides && (
              <>
                <Check size={14} className="text-copy-secondary" /> Remix and rewrite
              </>
            )}
            {tier === PlanTier.Pro && (
              <>
                <Check size={14} className="text-copy-secondary" /> Document uploads
              </>
            )}
          </div>
          <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
            {tier === PlanTier.Pro && (
              <>
                <Check size={14} className="text-copy-secondary" /> Premium Templates
              </>
            )}
          </div>
          <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
            {tier === PlanTier.Pro && (
              <>
                <Check size={14} className="text-copy-secondary" /> Custom instructions and presets
              </>
            )}
          </div>
        </div>

        <div className="block sm:hidden">
          <Button
            className="w-full"
            variant={tier === PlanTier.Pro ? 'upgrade' : 'secondary'}
            onClick={() => onPlanCheckout(plan)}
          >
            {loading ? 'One moment...' : 'Start free trial'}
          </Button>
        </div>
      </div>
    </div>
  )
}

const TIERS = [PlanTier.Slides, PlanTier.Pro]

const NewPlanPicker = () => {
  const { viewer } = useViewer()
  const [planFrequency, setPlanFrequency] = React.useState<PlanFrequency>(PlanFrequency.Monthly)
  const [selectedPlanTier, setSelectedPlanTier] = React.useState(PlanTier.Pro)
  const plans = [...(viewer?.availablePlans ?? [])]
    .sort((a, b) => a.price - b.price)
    .filter((x) => x.frequency === planFrequency || x.id == 'free')

  const [createCheckoutLink] = useCreateCheckoutLinkMutation()
  const [paymentLoading, setPaymentLoading] = React.useState(false)

  const onPlanCheckout = (plan?: Plan) => {
    if (paymentLoading) {
      return
    }

    const selectedPlan =
      plan ||
      plans.find((x) => {
        return x.frequency === planFrequency && selectedPlanTier === x.tier
      })

    if (!selectedPlan) {
      return
    }

    setPaymentLoading(true)
    createCheckoutLink({
      variables: { priceId: selectedPlan.id, upgradeSource: UpgradeSource.WebappTrial },
    })
      .then((v) => {
        if (v.data?.createCheckoutLink) {
          window.location.href = v.data.createCheckoutLink
        }
        setPaymentLoading(false)
      })
      .catch(() => {
        // no-op
      })
  }

  const renderPlan = (p: Plan) => (
    <NewPlanItem
      key={p.id}
      currencySymbol={p.currencySymbol}
      price={p.price}
      tier={p.tier}
      plan={p}
      frequency={planFrequency}
      isSelectedPlan={selectedPlanTier === p.tier}
      onSelect={() => setSelectedPlanTier(p.tier)}
      onPlanCheckout={onPlanCheckout}
      loading={paymentLoading}
    />
  )

  return (
    <div className="bg-background-gridBackground flex h-screen w-screen items-center justify-center">
      <div className="m-auto flex w-[720px] flex-col">
        <div className="bg-background-white border-divider-light-gray flex h-full w-full flex-col rounded border-[1px] py-6">
          <div className="border-divider-light-gray mt-2 mb-1 flex w-full flex-col border-b pb-4 text-center">
            <PlusLogo width="48px" height="48px" className="mx-auto mb-1" />
            <span className="text-xl font-semibold">Start for free with a 7 day trial</span>
            <span className="text-copy-secondary mt-2 text-base sm:hidden">
              Change plans or cancel anytime.{' '}
              <a
                href="https://guide.plusdocs.com/plus-ai/plus-ai-pricing"
                target="_blank"
                rel="noreferrer"
                className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
              >
                Learn more
              </a>{' '}
              or{' '}
              <a
                href="https://www.plusdocs.com/contact-us"
                target="_blank"
                rel="noreferrer"
                className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
              >
                contact us.
              </a>
            </span>
          </div>
          <div className="mt-4 flex h-full flex-col px-6">
            <div className="mb-4 flex w-full flex-row justify-center text-base">
              <ButtonGroup
                className="w-full sm:max-w-[320px]"
                sharedButtonProps={{ className: 'py-2 flex justify-center w-full sm:w-[160px]' }}
              >
                <ButtonGroup.Button
                  active={planFrequency === PlanFrequency.Monthly}
                  onClick={() => setPlanFrequency(PlanFrequency.Monthly)}
                >
                  Monthly
                </ButtonGroup.Button>
                <ButtonGroup.Button
                  active={planFrequency === PlanFrequency.Yearly}
                  onClick={() => setPlanFrequency(PlanFrequency.Yearly)}
                >
                  Yearly
                </ButtonGroup.Button>
              </ButtonGroup>
            </div>
            <div className="flex w-full flex-col gap-4 sm:flex-row">
              {reverse(TIERS).map((tier) => (
                <div
                  className={clsx(
                    'border-divider-light-gray flex w-full flex-col rounded border-[1px]',
                  )}
                  key={tier}
                >
                  <div className={clsx('flex h-full w-full flex-row')}>
                    {plans?.filter((p) => p.tier === tier)?.map(renderPlan)}
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-3 flex w-full justify-center">
              <Button
                className="hidden h-[48px] w-[320px] text-lg sm:block"
                variant="upgrade"
                onClick={() => onPlanCheckout()}
              >
                {paymentLoading
                  ? 'One moment...'
                  : viewer?.currentOrganization?.isStripeCustomer
                  ? 'Get started'
                  : 'Start free trial'}
              </Button>
            </div>
            <div className="mt-2 flex w-full justify-center">
              <span className="text-copy-secondary hidden text-base sm:block">
                Change plans or cancel anytime.{' '}
                <a
                  href="https://guide.plusdocs.com/plus-ai/plus-ai-pricing"
                  target="_blank"
                  rel="noreferrer"
                  className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
                >
                  Learn more
                </a>{' '}
                or{' '}
                <a
                  href="https://www.plusdocs.com/contact-us"
                  target="_blank"
                  rel="noreferrer"
                  className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
                >
                  contact us.
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const TrialPlanPicker = () => {
  const [planChangeCount, setPlanChangeCount] = React.useState(0)
  const { viewer } = useViewer()
  const currentOrganization = viewer?.currentOrganization
  const [activePlan, setActivePlan] = React.useState<string>()

  // Effect to track when plans change to show upgrade complete UI
  React.useEffect(() => {
    if (currentOrganization?.currentPlan?.id && planChangeCount === 0) {
      setActivePlan(currentOrganization?.currentPlan?.id)
      setPlanChangeCount(1)
    } else if (planChangeCount > 0 && currentOrganization?.currentPlan?.id !== activePlan) {
      setPlanChangeCount(planChangeCount + 1)
    }
  }, [activePlan, currentOrganization, planChangeCount, setPlanChangeCount, setActivePlan])

  return <NewPlanPicker />
}
