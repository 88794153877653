import { useHistory } from 'react-router-dom'

import {
  InvitationsDocument,
  InvitationType,
  useDeclineInvitationMutation,
} from '@/generated/graphql'
import { Button, OrgImage } from '@/shared/components'

type OrgInvitationProps = {
  orgLogo?: string | null
  orgName: string
  orgMemberCount: number
  invitedBy: string
  invitationId: string
  invitationType: InvitationType
}

const OrgInvitation = ({
  orgLogo,
  orgName,
  orgMemberCount,
  invitedBy,
  invitationId,
  invitationType,
}: OrgInvitationProps): JSX.Element => {
  const [declineInvitation] = useDeclineInvitationMutation({
    refetchQueries: [InvitationsDocument],
    variables: { id: invitationId },
  })
  const history = useHistory()
  const membersString =
    orgMemberCount > 1 ? `${orgMemberCount} members` : `${orgMemberCount} member`

  return (
    <div className="border-divider-light-gray flex w-full max-w-[264px] flex-col items-center justify-center rounded-md border p-3 sm:w-[452px] sm:max-w-[452px] sm:flex-row">
      <OrgImage size="large" orgName={orgName} orgLogo={orgLogo} />
      <div className="my-2 ml-2 flex-grow text-center sm:my-0 sm:text-left">
        <div className="text-xs font-semibold">
          <span>{orgName}</span>
        </div>

        <div className="text-copy-secondary text-xs">
          <span>{membersString}</span>
        </div>

        <div className="text-copy-secondary  text-xs">
          <span>
            {invitationType === InvitationType.Organization
              ? `Open to anyone from @${invitedBy.split('@')[1].toLowerCase()}`
              : `Invited by ${invitedBy}`}
          </span>
        </div>
      </div>
      <div className="flex w-full flex-col-reverse items-center sm:block sm:w-auto sm:flex-row">
        {invitationType !== InvitationType.Organization && (
          <Button
            onClick={() => declineInvitation()}
            variant="ghost"
            className="mt-1 w-full max-w-[240px] sm:mt-0 sm:w-auto"
          >
            Ignore
          </Button>
        )}
        <Button
          className="w-full max-w-[240px] sm:ml-2 sm:w-auto"
          onClick={() =>
            history.push(
              invitationType === InvitationType.Organization
                ? `/${invitationId}/accept-invite`
                : `/accept-invite/${invitationId}`,
            )
          }
        >
          Join
        </Button>
      </div>
    </div>
  )
}

export default OrgInvitation
