import { url } from 'aws-sdk/clients/finspace'
import { useState } from 'react'

import { SlackAccountStatus } from '@/generated/graphql'
import { Button } from '@/shared/components'
import SidebarIntegrationRow from '@/web/assets/IntegrationLogos/SidebarIntegrationRow.svg'
import SlackIntegrationLogo from '@/web/assets/IntegrationLogos/SlackIntegrationLogo.svg'
import { useViewer } from '@/web/hooks'

type NewUserExperienceStep = {
  action: url
  actionTitle: string
  dismissed: boolean
  dismissAction: () => void
  title: string
  description: string
  image: string
}

export const SidebarNUX = () => {
  const { viewer } = useViewer()
  const [currentStep, setCurrentStep] = useState(0)

  const steps: NewUserExperienceStep[] = [
    {
      action: 'https://p1g394ch06.execute-api.us-east-2.amazonaws.com/slack/install',
      actionTitle: 'Connect to Slack',
      description:
        'Use our app for Slack to send rich previews and create subscriptions to Snapshots.',
      dismissAction: () => {
        window.localStorage.setItem(`slack-nux:${viewer?.user?.id || ''}`, 'dismissed')
      },
      dismissed:
        viewer?.slackAccountStatus === SlackAccountStatus.Linked ||
        window.localStorage.getItem(`slack-nux:${viewer?.user?.id || ''}`) === 'dismissed',
      image: SlackIntegrationLogo,
      title: 'Send Snapshots in Slack',
    },
    {
      action: 'https://guide.plusdocs.com/integrations/embed-plus-in-other-apps',
      actionTitle: 'Learn more about embeds',
      description: 'You can embed Snapshots in tools like Notion, Coda, and more.',
      dismissAction: () => {
        window.localStorage.setItem(`embed-nux:${viewer?.user?.id || ''}`, 'dismissed')
      },
      dismissed: window.localStorage.getItem(`embed-nux:${viewer?.user?.id || ''}`) === 'dismissed',
      image: SidebarIntegrationRow,
      title: 'Embed Snapshots in other tools',
    },
  ]

  if (steps[currentStep] && steps[currentStep].dismissed) {
    setCurrentStep(currentStep + 1)
  }

  return (
    <>
      {currentStep < steps.length && !steps[currentStep].dismissed && (
        <div className="border-divider-light-gray bg-background-white h-fit w-full flex-row items-center rounded border">
          <div className="flex w-full flex-col items-center gap-3 p-3 text-center ">
            <div className="flex flex-col gap-1 text-center">
              <img src={steps[currentStep].image} className="h-10" />
              <div className=" text-base font-semibold">{steps[currentStep].title}</div>
              <div className="text-copy-secondary text-base">{steps[currentStep].description}</div>
            </div>
            <div className="flex w-full flex-col gap-2">
              <a
                href={steps[currentStep].action}
                target="_blank"
                rel="noreferrer"
                className="w-full"
              >
                <Button variant="secondary-dark" className="w-full text-base font-semibold">
                  {steps[currentStep].actionTitle}
                </Button>
              </a>
              <Button
                variant="ghost"
                className="w-full"
                onClick={() => {
                  setCurrentStep(currentStep + 1)
                  steps[currentStep].dismissAction()
                }}
              >
                Hide message
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
