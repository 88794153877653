import { formatRelative } from 'date-fns'

import {
  CaptureStatus,
  RepairType,
  Snapshot,
  SnapshotFieldsFragment,
  SnapshotRefreshRate,
  SnapshotStatus,
  SnapshotVersion,
} from '@/generated/graphql'

export type PrimaryRepairOption = 'captureFailed' | 'login' | 'review'

export const getSnapshotOverlayText = (snapshot: Snapshot, version: SnapshotVersion): string => {
  const refreshRateToFriendly = {
    [SnapshotRefreshRate.Daily]: 'every day',
    [SnapshotRefreshRate.EveryFiveMinutes]: 'every 5 minutes',
    [SnapshotRefreshRate.Hourly]: 'every hour',
    [SnapshotRefreshRate.Manually]: 'manually',
    [SnapshotRefreshRate.QuarterHourly]: 'every 15 minutes',
    [SnapshotRefreshRate.Weekly]: 'every week',
    [SnapshotRefreshRate.EverySixHours]: 'every 6 hours',
  }

  return (
    snapshot &&
    version &&
    [
      snapshot.overlay.title ? snapshot.name : null,
      snapshot.overlay.timestamp
        ? `Updated ${formatRelative(new Date(version.createdAt), new Date())}`
        : null,
      snapshot.overlay.schedule ? `Updates ${refreshRateToFriendly[snapshot.refreshRate]}` : null,
      snapshot.overlay.domain ? new URL(snapshot.instructions.url).hostname : null,
    ]
      .filter((val) => val !== null)
      .join(' • ')
  )
}

export type CurrentSnapshotStatus = {
  hasCapturedSnapshot: boolean
  isCapturingOrRefreshingSnapshot: boolean
  isFirstCapture: boolean
  isRefreshingSnapshot: boolean
  snapshotCanBeDeleted: boolean
  snapshotHasError: boolean
  snapshotFirstCaptureFailed: boolean
  snapshotRefreshFailed: boolean
  snapshotSessionExpired: boolean
  snapshotNeedsRepair: boolean
  primaryRepairOption?: PrimaryRepairOption
}

const getPrimaryRepairOption = (
  status: CurrentSnapshotStatus,
  snapshot: Pick<
    SnapshotFieldsFragment,
    'currentVersion' | 'latestVersion' | 'status' | 'suggestedRepairType' | 'versions'
  >,
): PrimaryRepairOption | undefined => {
  if (status.snapshotSessionExpired || snapshot.suggestedRepairType === RepairType.Login) {
    return 'login'
  }

  if (status.snapshotRefreshFailed || status.snapshotFirstCaptureFailed) {
    return 'captureFailed'
  }

  if (snapshot.suggestedRepairType === RepairType.Recapture) {
    return 'review'
  }

  return undefined
}

export const getSnapshotStatus = (
  snapshot:
    | Pick<
        SnapshotFieldsFragment,
        'currentVersion' | 'latestVersion' | 'status' | 'suggestedRepairType' | 'versions'
      >
    | undefined,
): CurrentSnapshotStatus => {
  if (!snapshot) {
    return {
      hasCapturedSnapshot: false,
      isCapturingOrRefreshingSnapshot: false,
      isFirstCapture: false,
      isRefreshingSnapshot: false,
      primaryRepairOption: undefined,
      snapshotCanBeDeleted: false,
      snapshotFirstCaptureFailed: false,
      snapshotHasError: false,
      snapshotNeedsRepair: false,
      snapshotRefreshFailed: false,
      snapshotSessionExpired: false,
    }
  }

  const initialStatus = {
    hasCapturedSnapshot: snapshot.currentVersion?.status === CaptureStatus.Captured,
    isCapturingOrRefreshingSnapshot:
      snapshot.status === SnapshotStatus.New ||
      snapshot.status === SnapshotStatus.Processing ||
      snapshot.status === SnapshotStatus.RefreshRequested,
    isFirstCapture:
      snapshot.status === SnapshotStatus.New ||
      (snapshot.status === SnapshotStatus.Processing && snapshot.versions.totalCount <= 1),
    isRefreshingSnapshot:
      snapshot.status === SnapshotStatus.RefreshRequested ||
      (snapshot.status === SnapshotStatus.Processing && snapshot.versions.totalCount > 1),
    snapshotCanBeDeleted:
      snapshot.status !== SnapshotStatus.Processing &&
      snapshot.status !== SnapshotStatus.RefreshRequested,
    snapshotFirstCaptureFailed:
      snapshot.status === SnapshotStatus.Error && snapshot.currentVersion == null,
    snapshotHasError:
      snapshot.status === SnapshotStatus.Error ||
      snapshot.suggestedRepairType === RepairType.Recapture,
    snapshotNeedsRepair:
      snapshot.suggestedRepairType != null && snapshot.suggestedRepairType !== RepairType.None,
    snapshotRefreshFailed:
      snapshot.status === SnapshotStatus.Error && snapshot.currentVersion != null,
    snapshotSessionExpired:
      snapshot.suggestedRepairType === RepairType.Login ||
      snapshot.latestVersion?.status === CaptureStatus.ErrorSessionExpired,
  }

  return {
    ...initialStatus,
    primaryRepairOption: getPrimaryRepairOption(initialStatus, snapshot),
  }
}
