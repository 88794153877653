import clsx from 'clsx'

import { ContentType } from '@/generated/graphql'

export const LoadingCard = ({ type }: { type: ContentType }) => {
  return (
    <div className="border-divider-light-gray bg-background-panel group flex min-w-[8.25rem] max-w-sm select-none flex-col overflow-hidden rounded border outline-none">
      <div className="w-full text-left">
        <div
          className={clsx('border-divider-light-gray relative border-b', {
            'aspect-image-area-default min-h-[80px]': type !== ContentType.Folder,
            'h-full min-h-[80px]': type === ContentType.Folder,
          })}
        />
        <div>
          <div className="flex flex-col p-2">
            <div className="flex w-full flex-col gap-1">
              <div className="bg-divider-light-gray h-3.5 w-40 animate-pulse rounded-sm" />
              <div className="bg-divider-light-gray h-3.5 w-14 animate-pulse rounded-sm" />
              <div className="bg-divider-light-gray h-3.5 w-28 animate-pulse rounded-sm" />
            </div>
          </div>
        </div>
        {type === ContentType.Folder ? (
          <div className="border-divider-light-gray flex flex-row items-center gap-2 border-t px-2 py-1.5">
            <div className="bg-divider-light-gray h-4 w-6 animate-pulse rounded-lg" />
            <div className="bg-divider-light-gray h-4 w-6 animate-pulse rounded-lg" />
          </div>
        ) : (
          <div className="border-divider-light-gray flex flex-row items-center gap-2 border-t px-2 py-1.5">
            <div className="bg-divider-light-gray h-4 w-4 animate-pulse rounded-lg" />
            <div className="bg-divider-light-gray h-3.5 w-28 animate-pulse rounded-sm" />
          </div>
        )}
      </div>
    </div>
  )
}
