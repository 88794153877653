import { useCallback } from 'react'
import { Filter } from 'react-feather'
import { useHistory, useParams } from 'react-router-dom'

import { ContentType } from '@/generated/graphql'
import { getFeedFilterParams } from '@/shared/components/ContentGrid/SeeMoreCard'
import SelectMenu, { SelectMenuOption } from '@/shared/components/SelectMenu'
import { useFeedFilter } from '@/web/routes/feed/FeedFilterView'

const IN_FOLDER_FILTER_MODES: {
  label: string
  value: ContentType | null
}[] = [
  { label: 'Pages', value: ContentType.Document },
  { label: 'Snapshots', value: ContentType.Snapshot },
]

const DEFAULT_FILTER_MODES: {
  label: string
  value: ContentType | null
}[] = [{ label: 'Folders', value: ContentType.Folder }, ...IN_FOLDER_FILTER_MODES]

const DEFAULT_FILTER = { label: 'Filter', value: null }

const FeedContentFilter = (): JSX.Element => {
  const history = useHistory()

  const activeContentTypeFromURL = useFeedFilter()

  const activeContentType = activeContentTypeFromURL

  const { folderId } = useParams<{
    organizationId?: string
    folderId?: string
  }>()

  const onChangeHandler = useCallback(
    (selectedOption: SelectMenuOption) => {
      if (selectedOption.value) {
        const params = getFeedFilterParams(selectedOption.value as ContentType)
        history.replace({ ...location, search: params.toString() })
      } else {
        history.replace({ ...location, search: undefined })
      }
    },
    [history],
  )

  const activeFilterList = folderId ? IN_FOLDER_FILTER_MODES : DEFAULT_FILTER_MODES

  const activeFilter = activeFilterList.find((x) => x.value === activeContentType) || DEFAULT_FILTER

  return (
    <div className="w-fit">
      <SelectMenu
        defaultValue={DEFAULT_FILTER}
        icon={<Filter size={16} />}
        onChange={onChangeHandler}
        options={[...activeFilterList]}
        value={activeFilter}
      />
    </div>
  )
}

export default FeedContentFilter
