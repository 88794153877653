import { useCallback } from 'react'

import { SnapshotStatus, useCaptureSnapshotMutation } from '@/generated/graphql'

const useRefreshSnapshot = (id: string): (() => void) => {
  const [captureSnapshotMutation] = useCaptureSnapshotMutation()

  return useCallback(() => {
    captureSnapshotMutation({
      optimisticResponse: {
        captureSnapshot: {
          code: 'success',
          message: 'Snapshot capture initiated.',
          snapshot: {
            __typename: 'Snapshot',
            id,
            status: SnapshotStatus.RefreshRequested,
          },
          success: true,
        },
      },
      variables: { snapshotId: id },
    })
  }, [captureSnapshotMutation, id])
}

export default useRefreshSnapshot
