import { CheckCircle } from 'react-feather'

import {
  ContentGridSearchDocument,
  SearchDocument,
  useMoveContentToTeamMutation,
} from '@/generated/graphql'
import { ContentV2 } from '@/shared/types'
import { showModal } from '@/util'
import { useViewer } from '@/web/hooks'

import Modal from '../../Modal'
import Toast from '../../Toast'
import { getItemCounts } from '../BulkActionsToolbar'

const useMoveOrg = (): ((
  contents: readonly ContentV2[],
  space: 'personal' | 'team',
  unselectAllItems?: () => void,
) => Promise<void>) => {
  const { activeOrganization } = useViewer()
  const [moveToTeam] = useMoveContentToTeamMutation({
    refetchQueries: [SearchDocument, ContentGridSearchDocument],
  })
  const moveOrgCallback = async (
    contents: readonly ContentV2[],
    space: 'personal' | 'team',
    unselectAllItems?: () => void,
  ) => {
    const isOnlyFolders =
      contents.filter((element) => element.__typename === 'Folder').length === contents.length
    const itemCounts = isOnlyFolders ? contents.length : getItemCounts(contents)
    const moveModalText = `Move ${itemCounts} ${isOnlyFolders ? 'folder' : 'item'}${
      itemCounts > 1 ? 's' : ''
    }?`
    const moveModalContent = `This action will move ${itemCounts} ${
      isOnlyFolders ? 'folder' : 'item'
    }${itemCounts > 1 ? 's' : ''} to ${
      space === 'team' ? 'Team' : 'Personal'
    }. This action can not be automatically undone.`

    const performAction = () => {
      contents.forEach((content) => {
        moveToTeam({
          variables: {
            id: content.id,
            teamId: space === 'team' ? activeOrganization?.teams.nodes[0].id : undefined,
          },
        })
      })

      void showModal(
        <Toast>
          <CheckCircle size={15} className="text-interactive-primary mr-2" />
          <span className="text-base">
            Folder moved to {space === 'personal' ? 'Personal' : 'Team'}
          </span>
        </Toast>,
      )

      if (unselectAllItems) {
        unselectAllItems()
      }
    }

    if (contents.length > 1) {
      await showModal(
        <Modal
          variant="primary"
          title={moveModalText}
          content={moveModalContent}
          primaryButtonText="Move"
          onSubmit={performAction}
          secondaryButtonText="Cancel"
        />,
      )
    } else {
      performAction()
    }
  }

  return moveOrgCallback
}

export default useMoveOrg
