// import AlignMiddle from '@/web/assets/icons/AlignMiddle';
// import AlignTop from '@/web/assets/icons/AlignTop';
// import AlignBottom from '@/web/assets/icons/AlignBottom';
import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import { AlignCenter, AlignLeft, AlignRight, Bold, Check, Italic, Underline } from 'react-feather'

import { Button, ContextPopover } from '@/shared/components'
import type {
  FontSize,
  HorizontalAlign,
  // VerticalAlign,
  Page,
  TextPageObject,
} from '@/shared/types/document'
import { FONT_SIZES, LINE_HEIGHTS } from '@/web/constants'
import { updateObjectContent } from '@/web/store/document'
import { AppDispatch } from '@/web/store/types'

type Props = {
  pageId: Page['id']
  object: TextPageObject
  dispatch: AppDispatch
}

const fontSizeStrings = {
  [FONT_SIZES.LARGE]: 'Large',
  [FONT_SIZES.MEDIUM]: 'Medium',
  [FONT_SIZES.SMALL]: 'Small',
}
const nextHorizontal = (align: HorizontalAlign): HorizontalAlign => {
  switch (align) {
    case 'left':
      return 'center'
    case 'center':
      return 'right'
    case 'right':
      return 'left'
  }
}
// const nextVertical = (align: VerticalAlign): VerticalAlign => {
// 	switch (align) {
// 		case 'top':
// 			return 'middle';
// 		case 'middle':
// 			return 'bottom';
// 		case 'bottom':
// 			return 'top';
// 	}
// };

const TextQuickSettings = (props: Props): JSX.Element => {
  const { dispatch, pageId, object } = props

  const [isFontSizeSelectorOpen, setIsFontSizeSelectorOpen] = useState(false)

  const toggleTextProperty = useCallback(
    (property: string, value: string) => {
      const currentValue = object.temp[property] as string

      if (currentValue.includes(value)) {
        void dispatch(
          updateObjectContent({
            content: {
              [property]: currentValue.replace(value, '').trim(),
            },
            objectId: object.id,
            pageId: pageId,
          }),
        )
      } else {
        void dispatch(
          updateObjectContent({
            content: {
              [property]: currentValue.split(' ').concat(value).join(' '),
            },
            objectId: object.id,
            pageId: pageId,
          }),
        )
      }
    },
    [pageId, object, dispatch],
  )

  const setObjectProperty = useCallback(
    (property: string, value: string | number) => {
      void dispatch(
        updateObjectContent({
          content: {
            [property]: value,
          },
          objectId: object.id,
          pageId: pageId,
        }),
      )

      setIsFontSizeSelectorOpen(false)
    },
    [pageId, object, dispatch],
  )

  const fontStyle = object.temp.fontStyle as string
  const textDecoration = object.temp.textDecoration as string
  const horizontalAlign = object.temp.textAlign as HorizontalAlign
  // const verticalAlign = object.temp.verticalAlign as VerticalAlign;
  const fontSize = object.temp.fontSize as FontSize

  // To ensure no Pages are permanently broken from PLUS-2406, set horizontalAlign to "left" in case it is false
  useEffect(() => {
    if (typeof horizontalAlign !== 'string') {
      setObjectProperty('horizontalAlign', 'left')
    }
  }, [horizontalAlign, setObjectProperty])

  return (
    <div className="flex items-center">
      <ContextPopover
        placement="bottom"
        controlled
        open={isFontSizeSelectorOpen}
        setOpen={setIsFontSizeSelectorOpen}
        trigger={
          <Button
            variant="ghost"
            className="text-copy-secondary hover:text-copy p-1 !text-base !font-normal"
            onClick={() => setIsFontSizeSelectorOpen(!isFontSizeSelectorOpen)}
            tooltip={isFontSizeSelectorOpen ? undefined : `Text size: ${fontSizeStrings[fontSize]}`}
          >
            {fontSizeStrings[fontSize]}
          </Button>
        }
      >
        <div className="bg-background-panel font-canvas-text shadow-soft ring-base-black flex w-32 flex-col rounded p-2 ring-1 ring-opacity-5">
          <Button
            variant="ghost"
            className="text-popover-large h-fit justify-start"
            onClick={() => {
              setObjectProperty('fontSize', FONT_SIZES.LARGE)
              setObjectProperty('lineHeight', LINE_HEIGHTS.LARGE)
            }}
          >
            <div className="text-popover-large flex w-full items-center justify-between">
              <span>Large</span>
              {fontSize === FONT_SIZES.LARGE && <Check size={16} />}
            </div>
          </Button>
          <Button
            variant="ghost"
            className="text-popover-medium justify-start !font-normal"
            onClick={() => {
              setObjectProperty('fontSize', FONT_SIZES.MEDIUM)
              setObjectProperty('lineHeight', LINE_HEIGHTS.MEDIUM)
            }}
          >
            <div className="text-popover-medium flex w-full items-center justify-between">
              <span>Medium</span>
              {fontSize === FONT_SIZES.MEDIUM && <Check size={16} />}
            </div>
          </Button>
          <Button
            variant="ghost"
            className="text-popover-small h-7 justify-start !font-normal"
            onClick={() => {
              setObjectProperty('fontSize', FONT_SIZES.SMALL)
              setObjectProperty('lineHeight', LINE_HEIGHTS.SMALL)
            }}
          >
            <div className="text-popover-small flex w-full items-center justify-between">
              <span>Small</span>
              {fontSize === FONT_SIZES.SMALL && <Check size={16} />}
            </div>
          </Button>
        </div>
      </ContextPopover>
      <Button
        variant="ghost"
        className={clsx('text-copy-secondary hover:text-copy p-1', {
          '!text-interactive-primary': fontStyle.includes('bold'),
        })}
        onClick={() => toggleTextProperty('fontStyle', 'bold')}
        tooltip="Bold"
      >
        <Bold size={16} />
      </Button>
      <Button
        variant="ghost"
        className={clsx('text-copy-secondary hover:text-copy p-1', {
          '!text-interactive-primary': fontStyle.includes('italic'),
        })}
        onClick={() => toggleTextProperty('fontStyle', 'italic')}
        tooltip="Italic"
      >
        <Italic size={16} />
      </Button>
      <Button
        variant="ghost"
        className={clsx('text-copy-secondary hover:text-copy p-1', {
          '!text-interactive-primary': textDecoration.includes('underline'),
        })}
        onClick={() => toggleTextProperty('textDecoration', 'underline')}
        tooltip="Underline"
      >
        <Underline size={16} />
      </Button>
      {typeof horizontalAlign === 'string' && (
        <Button
          variant="ghost"
          className="text-copy-secondary hover:text-copy p-1"
          onClick={() => setObjectProperty('horizontalAlign', nextHorizontal(horizontalAlign))}
          tooltip={`Horizontal alignment: ${
            horizontalAlign.charAt(0).toUpperCase() + horizontalAlign.slice(1)
          }`}
        >
          {horizontalAlign === 'left' ? <AlignLeft size={16} /> : null}
          {horizontalAlign === 'center' ? <AlignCenter size={16} /> : null}
          {horizontalAlign === 'right' ? <AlignRight size={16} /> : null}
        </Button>
      )}
      {/* <Button
				variant="ghost"
				className="text-copy-secondary hover:text-copy p-1"
				onClick={() =>
					setObjectProperty('verticalAlign', nextVertical(verticalAlign))
				}
				tooltip={`Vertical alignment: ${
					verticalAlign.charAt(0).toUpperCase() + verticalAlign.slice(1)
				}`}
			>
				{verticalAlign === 'top' ? <AlignTop className="h-4 w-4" /> : null}
				{verticalAlign === 'middle' ? (
					<AlignMiddle className="h-4 w-4" />
				) : null}
				{verticalAlign === 'bottom' ? (
					<AlignBottom className="h-4 w-4" />
				) : null}
			</Button> */}
    </div>
  )
}

export default TextQuickSettings
