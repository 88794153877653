import { Listbox, Transition } from '@headlessui/react'
import cx from 'clsx'
import { Fragment } from 'react'
import { Check, ChevronDown } from 'react-feather'
import { usePopperTooltip } from 'react-popper-tooltip'

export type SelectOption = {
  label: string
  value: string | number | boolean | null
}

type Props = {
  tooltip?: string
  options: SelectOption[]
  value: SelectOption
  className?: string
  onChange: (option: SelectOption) => void
  selectedValueLabel?: (option: SelectOption) => string
}

const Select = (props: Props): JSX.Element => {
  const { className = '', options, value, onChange } = props

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      delayShow: 300,
      placement: 'top',
    })

  return (
    <div className="shadow-button-secondary relative flex flex-1 rounded-md" ref={setTriggerRef}>
      <Listbox value={value} onChange={onChange}>
        <div className="relative w-full">
          <Listbox.Button
            className={cx(
              'relative w-full py-2 pl-3 pr-10 text-left',
              'bg-interactive-secondary',
              'active:bg-interactive-secondary-active',
              'border-divider-light-gray block w-full rounded border text-base',
              'hover:bg-interactive-secondary-hover',
              'focus:outline-none',
              'focus:border-divider-light-blue',
              'disabled:opacity-50',
              'disabled:cursor-not-allowed',
              className,
            )}
          >
            <span className="block truncate">
              {typeof props.selectedValueLabel === 'function'
                ? props.selectedValueLabel(value)
                : value.label}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDown className="text-copy-secondary h-4 w-4" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="bg-background-white shadow-soft ring-interactive-primary absolute z-10 max-h-60 w-full overflow-auto rounded p-2 text-base ring-1 ring-opacity-5 focus:outline-none sm:text-base">
              {options.map((option) => (
                <Listbox.Option
                  key={option.label}
                  className={({ active, selected }) =>
                    cx('relative flex cursor-pointer justify-between rounded p-2', {
                      'bg-interactive-secondary-active': selected,
                      'bg-interactive-secondary-hover': active,
                      'text-interactive-primary': selected,
                    })
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span className={cx('block truncate')}>{option.label}</span>
                      {selected && <Check size={16} />}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {visible && props.tooltip && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {props.tooltip}
        </div>
      )}
    </div>
  )
}

export default Select
