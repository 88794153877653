import { useCallback } from 'react'

import { UserActivity, useTrackUserActivityMutation } from '@/generated/graphql'

export const useTrackUserActivity = () => {
  const [dispatch] = useTrackUserActivityMutation({
    errorPolicy: 'ignore',
  })

  return useCallback(
    (name: UserActivity, context?: string) => {
      void dispatch({
        variables: {
          activity: {
            context,
            name,
          },
        },
      })
    },
    [dispatch],
  )
}
