import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from '@/shared/auth/useAuth'
import { PlusLogo } from '@/shared/components'
import { useSearchParams } from '@/shared/hooks/useSearchParams'

const BackToLoginLink = ({ className }: { className?: string }) => (
  <div className={className}>
    <Link
      className="text-interactive-primary hover:text-interactive-primary-hover flex justify-center text-xs hover:underline"
      to="/login"
    >
      Log in
    </Link>
  </div>
)

enum ConfirmRegistrationErrorType {
  Unknown,
  NotAuthorized,
  CodeMismatch,
  ExpiredCode,
  MissingInfo,
}

const getErrorTextFromErrorType = (
  errorType: ConfirmRegistrationErrorType,
  customText: string | null,
): string => {
  switch (errorType) {
    case ConfirmRegistrationErrorType.NotAuthorized:
      return 'Unable to confirm this account. Try logging in.'
    case ConfirmRegistrationErrorType.ExpiredCode:
      return 'This confirmation code has expired. Please request a new one.'
    case ConfirmRegistrationErrorType.CodeMismatch:
    case ConfirmRegistrationErrorType.MissingInfo:
      return 'Something went wrong when confirming your account. Please request a new confirmation code.'
    case ConfirmRegistrationErrorType.Unknown:
    default:
      if (customText) {
        return customText
      }
      return 'Something went wrong when confirming your account. Please request a new confirmation code.'
  }
}

const shouldShowResendConfirmation = (errorType: ConfirmRegistrationErrorType | null): boolean => {
  if (errorType === null) {
    return false
  }
  switch (errorType) {
    case ConfirmRegistrationErrorType.NotAuthorized:
      return false
  }
  return true
}

const ConfirmRegistration = (): JSX.Element => {
  const params = useSearchParams()
  const { confirmRegistration, resendConfirmationEmail } = useAuth()
  const [errorType, setErrorType] = useState<ConfirmRegistrationErrorType | null>(null)
  const [customErrorText, setCustomErrorText] = useState<string | null>(null)
  const [loadingConfirm, setLoadingConfirm] = useState(true)
  const [isRegistrationConfirmed, setIsRegistrationConfirmed] = useState<boolean>(false)
  const [submittedResendConfirmation, setSubmittedResendConfirmation] = useState<boolean>(false)

  useEffect(() => {
    const email = params.get('email')
    const verificationCode = params.get('code')

    if (!email || !verificationCode) {
      setErrorType(ConfirmRegistrationErrorType.MissingInfo)
      return
    }

    confirmRegistration({ email, verificationCode })
      .then(() => {
        setLoadingConfirm(false)
        setErrorType(null)
        setCustomErrorText(null)
        setIsRegistrationConfirmed(true)
      })
      .catch((e) => {
        setLoadingConfirm(false)
        if (e.code === 'NotAuthorizedException') {
          setErrorType(ConfirmRegistrationErrorType.NotAuthorized)
        } else if (e.code === 'CodeMismatchException') {
          setErrorType(ConfirmRegistrationErrorType.CodeMismatch)
        } else if (e.code === 'ExpiredCodeException') {
          setErrorType(ConfirmRegistrationErrorType.ExpiredCode)
        } else {
          setErrorType(ConfirmRegistrationErrorType.Unknown)
        }
        setCustomErrorText(e.message)
      })
  }, [params, confirmRegistration])

  const requestNewCode = useCallback(async () => {
    const email = params.get('email') ?? ''
    await resendConfirmationEmail({ email })
    setSubmittedResendConfirmation(true)
  }, [params, resendConfirmationEmail])

  if (loadingConfirm) {
    return (
      <div>
        <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <PlusLogo width="48" height="48" />
            <h1 className="text-center text-xl font-semibold">Verify account</h1>
          </div>
        </div>
        <div className="p-6">
          <div className="text-center text-xs font-normal">Verifying account...</div>
        </div>
      </div>
    )
  }

  if (isRegistrationConfirmed) {
    return (
      <div>
        <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <PlusLogo width="48" height="48" />
            <h1 className="text-center text-xl font-semibold">Account verified</h1>
          </div>
        </div>
        <div className="p-6">
          <div className="text-center text-xs font-normal">
            Your email is verified! Log in to start using Plus.
            <BackToLoginLink className="mt-3 font-normal" />
          </div>
        </div>
      </div>
    )
  }

  if (submittedResendConfirmation) {
    return (
      <div>
        <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <PlusLogo width="48" height="48" />
            <h1 className="text-center text-xl font-semibold">Verify account</h1>
          </div>
        </div>
        <div className="p-6 text-center text-xs font-normal">
          We sent an email to {params.get('email')} with a verification link. Click the button in
          that email to start using Plus.
          <Link
            className="text-interactive-primary hover:text-interactive-primary-hover mt-3 flex justify-center text-center text-xs font-normal hover:underline"
            to="/login"
          >
            Log in
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
        <div className="flex w-full flex-col items-center justify-center gap-1">
          <PlusLogo width="48" height="48" />
          <h1 className="text-center text-xl font-semibold">Verify account</h1>
        </div>
      </div>
      <div className="p-6">
        {errorType !== null && (
          <div className="text-center text-xs font-normal">
            {getErrorTextFromErrorType(errorType, customErrorText)}
          </div>
        )}
        {shouldShowResendConfirmation(errorType) && (
          <div className="mt-3">
            <a
              className="text-interactive-primary hover:text-interactive-primary-hover flex justify-center text-center text-xs hover:underline"
              onClick={requestNewCode}
            >
              Request new confirmation code
            </a>
          </div>
        )}
        <BackToLoginLink className="mt-3" />
      </div>
    </div>
  )
}

export default ConfirmRegistration
