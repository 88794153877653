import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

import { useAuth } from '@/shared/auth/useAuth'

// Middleware for a route which requires used to be authenticated
export const AuthMiddlewareRoute = ({ component: Component, ...rest }: RouteProps): JSX.Element => {
  const auth = useAuth()
  const location = useLocation<{
    from: { pathname: string; search: string; state: undefined | Record<string, unknown> }
    showResendConfirmation?: boolean
  }>()

  if (!auth.isLoggedIn()) {
    return (
      <Redirect
        to={{ pathname: '/login', search: `route=${encodeURIComponent(location.pathname)}` }}
      />
    )
  }

  if (!Component) return <></>

  return <Route {...rest} render={(props) => <Component {...props} />} />
}
