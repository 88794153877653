import { useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useInView } from 'react-intersection-observer'

import {
  ConnectionStatus,
  CurrentOrganizationSnapshotsQueryVariables,
  OrderDirection,
  SearchResultOrderField,
  SnapshotFieldsFragment,
} from '@/generated/graphql'
import { feedClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Loader } from '@/shared/components'
import { BulkActionsToolbar } from '@/shared/components/ContentGrid/BulkActionsToolbar'
import { BaseGrid } from '@/shared/components/ContentGrid/ContentGrid'
import { ContentGridProvider } from '@/shared/components/ContentGrid/useContentGrid'
import { useIdentities } from '@/shared/hooks/useIdentities'
import { getSnapshotStatus } from '@/util/snapshotHelpers'
import { BasePage } from '@/web/BasePage'
import FeedHeaderContainer from '@/web/components/Feed/FeedHeaderContainer'

import LearnMoreAboutConnectionsLink from '../components/Connections/LearnMoreAboutConnectionsLink'
import ReconnectionsList from '../components/Connections/ReconnectionsList'
import useCurrentOrganizationSnapshots from '../hooks/useCurrentOrganizationSnapshots'

function ReconnectSection(): JSX.Element {
  const { identities } = useIdentities({
    filter: {
      connectionStatus: [
        ConnectionStatus.Deteriorating,
        ConnectionStatus.Disconnected,
        ConnectionStatus.Reconnecting,
      ],
    },
    first: 50,
  })

  if (identities.length === 0) {
    return <></>
  }

  return (
    <div className="flex flex-col justify-between gap-3 px-4 lg:px-6">
      <div className="flex flex-col gap-1">
        <div className="text-base font-semibold lg:text-lg">Reconnect</div>
        <div className="text-copy-secondary text-xs">
          Plus uses connections to log into websites and take Snapshots for you. If a connection
          expires, Plus can&lsquo;t take new Snapshots on that website until the connection is
          repaired. <LearnMoreAboutConnectionsLink learnMoreText="Learn more" />
        </div>
      </div>

      <ReconnectionsList identities={identities} />
    </div>
  )
}

function ReviewSection({
  snapshotsToReview,
}: {
  readonly snapshotsToReview: readonly SnapshotFieldsFragment[]
}): JSX.Element {
  if (snapshotsToReview.length === 0) {
    return <></>
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col justify-between gap-1 px-4 lg:px-6">
        <div className="text-base font-semibold lg:text-lg">Review</div>
        <div className="text-copy-secondary text-xs">
          Plus uses a machine learning model to determine when Snapshots are broken. However, it
          doesn&lsquo;t always get things right. Please review these Snapshots to see if anything is
          broken.
        </div>
      </div>
      <BaseGrid contents={[snapshotsToReview]} hideSectionHeaders={true} />
    </div>
  )
}

function RepairSnapshots(): JSX.Element {
  const { sortField } = useReactiveVar(feedClientStateVar)

  const queryInput: CurrentOrganizationSnapshotsQueryVariables = {
    filter: {
      needsRepair: true,
      snapshotIOwn: true,
    },
    first: 50,
    sort: {
      direction:
        sortField === SearchResultOrderField.Name ? OrderDirection.Asc : OrderDirection.Desc,
      field: sortField || SearchResultOrderField.CreatedAt,
    },
  }

  const { ref: inViewRef, inView } = useInView()
  const {
    loading: isLoading,
    loadingMore: isLoadingMore,
    loadMore,
    nodes: snapshots,
  } = useCurrentOrganizationSnapshots(queryInput)

  useEffect(() => {
    if (inView && !isLoading) {
      loadMore()
    }
  }, [inView, isLoading, loadMore])

  const snapshotsToReview = snapshots.filter(
    (snapshot) => !getSnapshotStatus(snapshot).snapshotSessionExpired,
  )

  return (
    <ContentGridProvider itemsInView={snapshots}>
      <BasePage>
        <Helmet title="Repair" />
        <div className="bg-background-gridBackground relative flex h-full w-full grow flex-col content-start items-start lg:grow-0">
          <div className="absolute flex h-full w-full flex-col">
            <div className="flex w-full flex-col items-center justify-between">
              <FeedHeaderContainer title="Repair" hideFilter={true} hideCreate={true} hideSort />
            </div>
            <div className="flex h-full w-full flex-col items-center justify-between overflow-auto overscroll-contain scroll-smooth">
              <div className="h-full w-full lg:max-w-7xl">
                {isLoading && snapshots.length === 0 ? (
                  <div className="flex h-full flex-row items-center">
                    <div className="m-auto">
                      <Loader />
                    </div>
                  </div>
                ) : (
                  <>
                    {snapshots.length !== 0 ? (
                      <div className="flex flex-col gap-8">
                        <ReconnectSection />
                        <ReviewSection snapshotsToReview={snapshotsToReview} />
                      </div>
                    ) : (
                      <div className="flex h-full flex-row items-center">
                        <div className="mx-auto mt-[30vh] text-center text-xs md:m-auto">
                          <span className="font-semibold">No Snapshots to repair</span>
                          <br />
                          <span className="text-copy-secondary">
                            All of your Snapshots are up to date.
                          </span>
                        </div>
                      </div>
                    )}
                    {isLoadingMore && <Loader />}
                  </>
                )}
              </div>
            </div>
            <div ref={inViewRef} />
            <BulkActionsToolbar />
          </div>
        </div>
      </BasePage>
    </ContentGridProvider>
  )
}

export default RepairSnapshots
