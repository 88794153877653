import type { PageObject } from '@/shared/types/document'

import { updateObjectContent } from '../web/store/document'
import { AppDispatch } from '../web/store/types'

export default function flushTextChanges({
  dispatch,
  selectedObjects,
  objects,
  pageObject,
  pageId,
}: {
  dispatch: AppDispatch
  selectedObjects: string[]
  objects?: PageObject[]
  pageObject?: PageObject
  pageId: string
}): void {
  if (selectedObjects.length === 1) {
    const object = objects ? objects.find((obj) => obj.id === selectedObjects[0]) : pageObject
    if (!object) return

    if (
      object.type === 'Text' &&
      typeof object.temp.text === 'string' &&
      object.content.text !== object.temp.text
    ) {
      void dispatch(
        updateObjectContent({
          content: {
            text: object.temp.text,
          },
          objectId: object.id,
          pageId,
        }),
      )
    }
  }
}
