import { useReactiveVar } from '@apollo/client'
import { useStarTour } from '@plusdocs/star-tours'
import {
  ScrollArea,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '@radix-ui/react-scroll-area'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { useGate } from 'statsig-react'

import { ContentType, FolderQuery, SearchQuery, UserActivity } from '@/generated/graphql'
import { extensionClientStateVar, feedClientStateVar } from '@/shared/apollo/apolloLocalState'
import { LinkCTA, Loader } from '@/shared/components'
import { BulkActionsToolbar } from '@/shared/components/ContentGrid/BulkActionsToolbar'
import { ContentFeed } from '@/shared/components/ContentGrid/ContentFeed'
import { BaseGrid } from '@/shared/components/ContentGrid/ContentGrid'
import { useContentGridData } from '@/shared/components/ContentGrid/ContentGridData'
import { ContentGridProvider } from '@/shared/components/ContentGrid/useContentGrid'
import { EmptyGridOnboarding } from '@/shared/components/Onboarding/EmptyGridOnboarding'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { useTrackUserActivity } from '@/shared/hooks/useTrackUserActivity'
import { ScreenSize } from '@/shared/types'
import { generateNewPageUrl, getContentTypeFriendlyText, getScreenSize, showModal } from '@/util'
import { BasePage } from '@/web/BasePage'
import FeedHeaderContainer from '@/web/components/Feed/FeedHeaderContainer'
import useFolder from '@/web/hooks/useFolder'

import { ExtensionBanner } from '../components/ExtensionBanner'
import ReferralsModal from '../components/ReferralsModal'
import { AvailableTours } from '../fixtures/tours'
import { useViewer } from '../hooks'
import { FeedFilterView, useFeedFilter } from './feed/FeedFilterView'

// eslint-disable-next-line complexity
function Feed(): JSX.Element {
  const [isFeed, setIsFeed] = React.useState(true)
  const { sortField } = useReactiveVar(feedClientStateVar)
  const { isExtensionInstalled } = useReactiveVar(extensionClientStateVar)
  const history = useHistory()
  const location = useLocation<{ fromExtension: boolean }>()
  const params = useSearchParams()
  const isFirstTimeUser = params.has('isFtu')
  const takeFirstSnapshot = params.has('takeFirstSnapshot')
  const showReferralsModal = params.has('refer')

  const { activeOrganization, organizationCount } = useViewer()
  const { value: useExtendedNux } = useGate('extended_first_snapshot_nux')

  const [screenSize, setScreenSize] = React.useState(getScreenSize())

  const onResize = React.useCallback(() => {
    const currentScreenSize = getScreenSize()
    setScreenSize(currentScreenSize)
  }, [])

  React.useLayoutEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])

  const contentLength = useMemo(() => {
    switch (screenSize) {
      case ScreenSize.xxl:
      case ScreenSize.xl:
        return 9
      case ScreenSize.lg:
      case ScreenSize.md:
        return 7
      case ScreenSize.sm:
        return 5
      case ScreenSize.xs:
      default:
        return 3
    }
  }, [screenSize])

  const snapshotContentLength = useMemo((): number => {
    switch (screenSize) {
      case ScreenSize.xxl:
      case ScreenSize.xl:
        return 14
      case ScreenSize.lg:
      case ScreenSize.md:
        return 11
      case ScreenSize.sm:
        return 8
      case ScreenSize.xs:
      default:
        return 5
    }
  }, [screenSize])

  const activeContentType = useFeedFilter()

  const { folderId, teamId } = useParams<{
    organizationSlug?: string
    folderId?: string
    teamId?: string
  }>()

  const { folder } = useFolder(folderId)

  const hasActiveContentType = !!activeContentType
  const {
    counts,
    documents,
    folders,
    snapshots,
    loading: isLoading,
    refresh,
  } = useContentGridData(
    teamId,
    folderId,
    sortField,
    activeOrganization?.urn,
    isFeed || hasActiveContentType,
  )

  const slicedFolders = folders.slice(0, contentLength)
  const slicedDocuments = documents.slice(0, contentLength)
  const slicedSnapshots = snapshots.slice(0, snapshotContentLength)

  // get new data whenever active org changes
  useEffect(() => {
    if (!hasActiveContentType) {
      void refresh()
    }
  }, [activeOrganization?.id, refresh, hasActiveContentType])

  const { start } = useStarTour()

  useEffect(() => {
    if (isFirstTimeUser) {
      history.replace({ ...location, search: undefined })
      if (organizationCount === 1) {
        start(AvailableTours.Welcome)
      }
    }
  }, [start, isFirstTimeUser, history, location, organizationCount])

  const trackUserActivity = useTrackUserActivity()

  useEffect(() => {
    if (location.state?.fromExtension) {
      history.replace({ ...location, state: null })
      start('Welcome', 2)
      trackUserActivity(UserActivity.ExtensionInstalled)
    }
  }, [location, history, start, trackUserActivity])

  useEffect(() => {
    if (takeFirstSnapshot) {
      history.replace({ ...location, search: undefined })
      start('Welcome', isExtensionInstalled ? 2 : 1)
    }
  }, [history, location, start, takeFirstSnapshot, isExtensionInstalled])

  useEffect(() => {
    if (showReferralsModal) {
      void showModal(<ReferralsModal />)
    }
  }, [history, location, showReferralsModal])

  const isEmpty = snapshots.length === 0 && folders.length === 0 && documents.length === 0

  const [filteredNodes, setFilteredNodes] = useState<SearchQuery['search']['edges'][0]['node'][]>(
    [],
  )

  const getContentsInView = () => {
    if (hasActiveContentType) {
      return filteredNodes
    } else {
      return [...slicedFolders, ...slicedDocuments, ...slicedSnapshots]
    }
  }

  return (
    <ContentGridProvider itemsInView={getContentsInView()}>
      <BasePage>
        <Helmet title={folderId && folder ? folder.name : 'Home'} />
        <div className="bg-background-gridBackground flex h-full w-full grow flex-col content-start items-start  lg:grow-0">
          {!isExtensionInstalled && <ExtensionBanner />}
          <div className="flex h-full w-full flex-col">
            <div className="flex w-full flex-col items-center justify-between">
              <FeedHeaderContainer
                isFeed={isFeed}
                setIsFeed={setIsFeed}
                title={teamId ? 'Team' : 'Personal'}
              />
            </div>
            <ScrollArea className="h-full w-full">
              <ScrollAreaViewport className="relative h-full w-full overscroll-contain">
                <div className="flex flex-col items-center">
                  <div className="flex h-full w-full flex-col items-center lg:max-w-7xl">
                    {isLoading && isEmpty ? (
                      <div className="absolute h-full w-full">
                        <div className="flex h-full flex-row items-center">
                          <div className="m-auto">
                            <Loader />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {isFeed ? (
                          <ContentFeed
                            folder={folder}
                            onNodesLoaded={setFilteredNodes}
                            contents={[slicedFolders, slicedDocuments, slicedSnapshots]}
                          />
                        ) : activeContentType ? (
                          <FeedFilterView folder={folder} onNodesLoaded={setFilteredNodes} />
                        ) : !isEmpty ? (
                          <BaseGrid
                            counts={counts}
                            contents={[slicedFolders, slicedDocuments, slicedSnapshots]}
                          />
                        ) : (
                          <div className="absolute h-full w-full">
                            <div className="flex h-full w-full items-center justify-center">
                              {!useExtendedNux || folderId || teamId ? (
                                <div className="text-center text-xs">
                                  {getFeedEmptyMessageText(
                                    activeContentType,
                                    folder,
                                    teamId,
                                    activeOrganization?.slug,
                                  )}
                                </div>
                              ) : (
                                <EmptyGridOnboarding />
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </ScrollAreaViewport>
              <ScrollAreaScrollbar orientation="vertical" className="p-1">
                <ScrollAreaThumb className="text-divider-dark-gray rounded border-2" />
              </ScrollAreaScrollbar>
            </ScrollArea>
            <BulkActionsToolbar />
          </div>
        </div>
      </BasePage>
    </ContentGridProvider>
  )
}

export const getFeedEmptyMessageText = (
  activeContentType?: ContentType | null,
  folder?: FolderQuery['folder'],
  teamId?: string,
  activeOrganizationSlug?: string,
): JSX.Element => {
  let description: JSX.Element

  const title = `There are no ${
    activeContentType ? getContentTypeFriendlyText(activeContentType) + 's' : 'Snapshots or Pages'
  } in this ${folder ? 'folder' : 'space'} yet.`

  if (activeContentType === ContentType.Document) {
    description = (
      <>
        Get started by{' '}
        <LinkCTA>
          <Link to={generateNewPageUrl(activeOrganizationSlug, teamId, folder?.id)}>
            creating a new Page
          </Link>
        </LinkCTA>
        .
      </>
    )
  } else {
    description = (
      <>
        Get started by taking some Snapshots using{' '}
        <LinkCTA
          href="https://chrome.google.com/webstore/detail/plus/bnebanooamokkihfjepphafoekheipfh"
          target="_blank"
          rel="noreferrer"
        >
          the Plus extension
        </LinkCTA>
        .
      </>
    )
  }

  return (
    <>
      <span className="font-semibold">{title}</span>
      <br />
      <span className="text-copy-secondary">{description}</span>
    </>
  )
}

export default Feed
