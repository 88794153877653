import { ApolloError, NetworkStatus, useReactiveVar } from '@apollo/client'
import { useCallback } from 'react'

import {
  ContentAccessLevel,
  PublicSnapshotVersionsQuery,
  SnapshotVersionsQuery,
  usePublicSnapshotVersionsQuery,
  useSnapshotVersionsQuery,
} from '@/generated/graphql'
import { contentPrivacyStateVar } from '@/shared/apollo/apolloLocalState'

import { IUseSnapshot } from './useSnapshot'

type SnapshotData = NonNullable<SnapshotVersionsQuery['snapshot']>
type PublicSnapshotData = NonNullable<
  NonNullable<PublicSnapshotVersionsQuery['publicSnapshot']>['snapshot']
>

export interface SnapshotVersions {
  readonly endCursor?: string | null
  readonly error?: ApolloError
  readonly hasNextPage: boolean
  readonly hasPreviousPage: boolean
  readonly loadMore: (
    direction: 'after' | 'before',
  ) => Promise<SnapshotData['allVersions'] | PublicSnapshotData['allVersions'] | undefined>
  readonly loading: boolean
  readonly loadingMore: boolean
  readonly snapshot?: SnapshotData | PublicSnapshotData | null
  readonly startCursor?: string | null
}

export const useSnapshotVersions = (
  snapshot: NonNullable<IUseSnapshot>,
  beforeCursor?: string | null,
  afterCursor?: string | null,
): SnapshotVersions => {
  const { accessLevel } = useReactiveVar(contentPrivacyStateVar)
  const isPublic = accessLevel === ContentAccessLevel.Public
  const { data, error, loading, fetchMore, networkStatus } = useSnapshotVersionsQuery({
    fetchPolicy: 'cache-first',
    skip: isPublic,
    variables: { after: afterCursor, before: beforeCursor, id: snapshot.id },
  })
  const {
    data: publicData,
    error: publicError,
    loading: publicLoading,
    networkStatus: publicNetworkStatus,
    fetchMore: publicFetchMore,
  } = usePublicSnapshotVersionsQuery({
    fetchPolicy: 'cache-first',
    skip: !isPublic,
    variables: { after: afterCursor, before: beforeCursor, id: snapshot.id },
  })
  const snapshotData = isPublic ? publicData?.publicSnapshot?.snapshot : data?.snapshot

  const pageInfo = snapshotData?.allVersions.pageInfo
  const loadMore = useCallback(
    async (direction: 'after' | 'before') => {
      const fetch = isPublic ? publicFetchMore : fetchMore
      const results = await fetch({
        variables: {
          id: snapshot.id,
          ...((direction === 'after'
            ? {
                after: pageInfo?.endCursor,
                before: undefined,
              }
            : {
                after: undefined,
                before: pageInfo?.startCursor,
              }) as { after: string | undefined; before: string | undefined }),
        },
      })

      return isPublic
        ? (results.data as PublicSnapshotVersionsQuery).publicSnapshot?.snapshot?.allVersions
        : results.data.snapshot?.allVersions
    },
    [fetchMore, isPublic, pageInfo?.endCursor, pageInfo?.startCursor, publicFetchMore, snapshot.id],
  )

  const result: SnapshotVersions = {
    endCursor: pageInfo?.endCursor,
    error: error || publicError,
    hasNextPage: pageInfo?.hasNextPage ?? false,
    hasPreviousPage: pageInfo?.hasPreviousPage ?? false,
    loadMore,
    loading: !accessLevel || loading || publicLoading,
    loadingMore:
      networkStatus === NetworkStatus.fetchMore || publicNetworkStatus === NetworkStatus.fetchMore,
    snapshot: snapshotData,
    startCursor: pageInfo?.startCursor,
  }

  return result
}

export default useSnapshotVersions
