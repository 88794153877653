import clsx from 'clsx'
import { IconProps } from 'react-feather'

const GridSmall = ({ className, size = 16 }: IconProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={clsx('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 2H2.66667C2.29848 2 2 2.29848 2 2.66667V4C2 4.36819 2.29848 4.66667 2.66667 4.66667H4C4.36819 4.66667 4.66667 4.36819 4.66667 4V2.66667C4.66667 2.29848 4.36819 2 4 2Z" />
      <path d="M4 6.66667H2.66667C2.29848 6.66667 2 6.96514 2 7.33333V8.66667C2 9.03486 2.29848 9.33333 2.66667 9.33333H4C4.36819 9.33333 4.66667 9.03486 4.66667 8.66667V7.33333C4.66667 6.96514 4.36819 6.66667 4 6.66667Z" />
      <path d="M4 11.3333H2.66667C2.29848 11.3333 2 11.6318 2 12V13.3333C2 13.7015 2.29848 14 2.66667 14H4C4.36819 14 4.66667 13.7015 4.66667 13.3333V12C4.66667 11.6318 4.36819 11.3333 4 11.3333Z" />
      <path d="M8.66667 2H7.33333C6.96514 2 6.66667 2.29848 6.66667 2.66667V4C6.66667 4.36819 6.96514 4.66667 7.33333 4.66667H8.66667C9.03486 4.66667 9.33333 4.36819 9.33333 4V2.66667C9.33333 2.29848 9.03486 2 8.66667 2Z" />
      <path d="M8.66667 6.66667H7.33333C6.96514 6.66667 6.66667 6.96514 6.66667 7.33333V8.66667C6.66667 9.03486 6.96514 9.33333 7.33333 9.33333H8.66667C9.03486 9.33333 9.33333 9.03486 9.33333 8.66667V7.33333C9.33333 6.96514 9.03486 6.66667 8.66667 6.66667Z" />
      <path d="M8.66667 11.3333H7.33333C6.96514 11.3333 6.66667 11.6318 6.66667 12V13.3333C6.66667 13.7015 6.96514 14 7.33333 14H8.66667C9.03486 14 9.33333 13.7015 9.33333 13.3333V12C9.33333 11.6318 9.03486 11.3333 8.66667 11.3333Z" />
      <path d="M13.3333 2H12C11.6318 2 11.3333 2.29848 11.3333 2.66667V4C11.3333 4.36819 11.6318 4.66667 12 4.66667H13.3333C13.7015 4.66667 14 4.36819 14 4V2.66667C14 2.29848 13.7015 2 13.3333 2Z" />
      <path d="M13.3333 6.66667H12C11.6318 6.66667 11.3333 6.96514 11.3333 7.33333V8.66667C11.3333 9.03486 11.6318 9.33333 12 9.33333H13.3333C13.7015 9.33333 14 9.03486 14 8.66667V7.33333C14 6.96514 13.7015 6.66667 13.3333 6.66667Z" />
      <path d="M13.3333 11.3333H12C11.6318 11.3333 11.3333 11.6318 11.3333 12V13.3333C11.3333 13.7015 11.6318 14 12 14H13.3333C13.7015 14 14 13.7015 14 13.3333V12C14 11.6318 13.7015 11.3333 13.3333 11.3333Z" />
    </svg>
  )
}

export default GridSmall
