import { useStarTour } from '@plusdocs/star-tours'
import {
  ScrollArea,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '@radix-ui/react-scroll-area'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { ExternalLink } from 'react-feather'
import { Helmet } from 'react-helmet-async'
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'

import {
  PlanFrequency,
  useCreateCheckoutLinkMutation,
  useCreatePaymentPortalLinkMutation,
  useDeleteOrgUserMutation,
} from '@/generated/graphql'
import { useAuth } from '@/shared/auth/useAuth'
import { Button, ButtonGroup, Loader, Modal } from '@/shared/components'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { showModal } from '@/util'
import { BasePage } from '@/web/BasePage'
import ChangePassword from '@/web/components/Auth/ChangePassword'
import InviteTeammates from '@/web/components/InviteTeammates'
import { PlanPicker } from '@/web/components/PlanPicker'
import { useViewer } from '@/web/hooks'

import EditOrganization from './EditOrganization'
import EditProfile from './EditProfile'

const showLeaveOrgModal = (onSubmitCallback: () => void, orgName: string) => {
  void showModal(
    <Modal
      variant="destructive"
      title={`Permanently leave ${orgName}?`}
      content={`Are you sure you want to leave this organization? You'll lose access to all Snapshots and Pages you've created. You won't be able to rejoin unless you're invited by an admin.`}
      primaryButtonText="Delete"
      onSubmit={() => onSubmitCallback()}
      secondaryButtonText="Cancel"
    />,
  )
}

const CustomPlanUpgrade = () => {
  const params = useSearchParams()
  const priceId = params.get('plan')
  const couponId = params.get('coupon')
  const [createCheckoutLink] = useCreateCheckoutLinkMutation()

  useEffect(() => {
    if (priceId) {
      void createCheckoutLink({
        variables: {
          couponId,
          priceId,
        },
      }).then((v) => {
        if (v.data?.createCheckoutLink) {
          window.location.href = v.data.createCheckoutLink
        }
      })
    }
  }, [couponId, createCheckoutLink, priceId])

  return (
    <div className="text-copy p-6 text-lg">Redirecting you to Stripe to upgrade your plan...</div>
  )
}

function Settings(): JSX.Element {
  const { activeOrganization, loading, viewer } = useViewer()
  const { account, refreshToken } = useAuth()
  const { path } = useRouteMatch()
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const [deleteUser] = useDeleteOrgUserMutation()
  const [createPaymentPortalLink] = useCreatePaymentPortalLinkMutation()
  const [planFrequency, setPlanFrequency] = useState<PlanFrequency>(PlanFrequency.Yearly)
  const [portalLoading, setPortalLoading] = useState(false)
  const orgOwner = activeOrganization?.users.nodes.find(
    (u) => u.id === activeOrganization.createdBy.id,
  )
  const isOrgOwner = orgOwner?.id === viewer?.user?.id

  const currentPlan = viewer?.currentOrganization?.currentPlan
  const isCustomPlan = typeof activeOrganization?.currentPlan?.id === 'string' && !currentPlan

  const leaveOrganizationCallback = async () => {
    if (!viewer?.user) {
      return
    }
    await deleteUser({ variables: { id: viewer.user.id } })
    await refreshToken()

    // Hard navigate after org delete to homepage to avoid any stale data issues
    window.location.href = '/'
  }

  const billedUserCount = currentPlan?.billedUsersCount || 1
  const { start } = useStarTour()
  const params = useSearchParams()
  const history = useHistory()
  const location = useLocation()
  const success = params.has('success')
  useEffect(() => {
    if (success) {
      history.replace({ ...location, search: undefined })
      start('Upgrade')
    }
  }, [history, location, start, success])

  return (
    <BasePage>
      <Helmet title="Settings" />
      {loading ? (
        <div className="m-auto">
          <Loader />
        </div>
      ) : (
        <ScrollArea className="bg-base-gray960 h-full w-full">
          <ScrollAreaViewport className="relative h-full w-full overscroll-contain">
            <div className="flex h-full w-full flex-col items-center overscroll-contain p-3 pb-24">
              <div className="border-divider-light-gray bg-base-white shadow-soft w-full max-w-[800px] rounded border">
                <div className="border-divider-light-gray flex flex-col gap-3 border-b p-6">
                  <h1 className="text-xl font-semibold">Settings</h1>
                  <div className="flex gap-1">
                    <Link to={`/${organizationSlug}/settings/personal`}>
                      <Button
                        active={path === '/:organizationSlug/settings/personal'}
                        variant="ghost"
                      >
                        Personal
                      </Button>
                    </Link>
                    <Link to={`/${organizationSlug}/settings/organization`}>
                      <Button
                        active={path === '/:organizationSlug/settings/organization'}
                        variant="ghost"
                      >
                        Organization
                      </Button>
                    </Link>
                    <Link to={`/${organizationSlug}/settings/billing`}>
                      <Button
                        active={path === '/:organizationSlug/settings/billing'}
                        variant="ghost"
                      >
                        Plans and billing
                      </Button>
                    </Link>
                  </div>
                </div>

                <Switch>
                  <Route
                    path="/:organizationSlug/settings/personal"
                    component={() => {
                      return (
                        <div className="flex flex-col overscroll-contain">
                          <EditProfile />

                          {account?.provider === 'cognito' && (
                            <div className="border-divider-light-gray border-t p-6">
                              <ChangePassword />
                            </div>
                          )}
                          {!activeOrganization?.viewer.isOwner && (
                            <div className="border-divider-light-gray flex flex-col gap-3 border-t p-6">
                              <h2 className="text-lg font-semibold">More settings</h2>
                              <div className="w-[240px]">
                                <Button
                                  variant="destructive"
                                  onClick={() =>
                                    showLeaveOrgModal(
                                      leaveOrganizationCallback,
                                      activeOrganization?.name || '',
                                    )
                                  }
                                >
                                  Leave {activeOrganization?.name}
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    }}
                  />
                  <Route
                    path="/:organizationSlug/settings/organization"
                    component={() => {
                      return (
                        <div className="flex flex-col overscroll-contain">
                          {activeOrganization?.viewer.isOwner && (
                            <>
                              <EditOrganization />
                            </>
                          )}
                          <div className="overscroll-contain p-6">
                            <h2 className="mb-5 text-lg font-semibold">Teammates</h2>
                            <InviteTeammates showRemovalButtons={true} />
                          </div>
                        </div>
                      )
                    }}
                  />

                  <Route
                    path="/:organizationSlug/settings/billing"
                    component={() => {
                      return (
                        <>
                          {viewer?.currentOrganization?.currentPlan?.id !== 'free' && (
                            <div className="border-divider-light-gray flex flex-col border-b p-6">
                              <h1 className="text-lg font-semibold">Current plan</h1>

                              <span className="text-copy-secondary mt-2 text-base">
                                {currentPlan?.name} x {billedUserCount} user
                                {billedUserCount > 1 ? 's' : ''}
                              </span>
                              <span className="text-copy-secondary mb-2 text-base">
                                {currentPlan?.currencySymbol}
                                {billedUserCount * (currentPlan?.price as number)} per{' '}
                                {currentPlan?.frequency === PlanFrequency.Monthly
                                  ? 'month'
                                  : 'year'}
                                , renews on{' '}
                                {format(new Date(currentPlan?.nextInvoice as string), `LLLL dd, y`)}
                              </span>

                              <div className="flex">
                                <Button
                                  variant="upgrade"
                                  className="bg-base-highlightB mr-2"
                                  disabled={!isOrgOwner}
                                  tooltip={
                                    !isOrgOwner
                                      ? 'Only your organizations owner can manage your plan. Ask them for help or contact us.'
                                      : ''
                                  }
                                  onClick={() => {
                                    setPortalLoading(true)
                                    void createPaymentPortalLink().then((v) => {
                                      if (v.data?.createPaymentPortalLink) {
                                        window.location.href = v.data.createPaymentPortalLink
                                        setPortalLoading(false)
                                      }
                                    })
                                  }}
                                >
                                  <ExternalLink size={14} className="mr-1" />{' '}
                                  {portalLoading ? 'One second...' : 'Manage plan'}
                                </Button>
                              </div>
                              <span className="text-copy-secondary mt-2 text-base">
                                Need help?{' '}
                                <a
                                  href="https://guide.plusdocs.com/help/contact-us"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
                                >
                                  Contact us
                                </a>
                              </span>
                            </div>
                          )}
                          <div className="border-divider-light-gray flex flex-col border-b p-6">
                            <h1 className="text-lg font-semibold">Plans and pricing</h1>
                            <div className="flex w-full flex-row pb-3">
                              <span className="text-copy-secondary place-self-end text-base">
                                Learn more about paid features in our help center for{' '}
                                <a
                                  href="https://guide.plusdocs.com/plus-ai/plus-ai-pricing"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
                                >
                                  Plus AI
                                </a>{' '}
                                and{' '}
                                <a
                                  href="https://guide.plusdocs.com/live-snapshots/free-and-paid-plans"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
                                >
                                  Live Snapshots
                                </a>
                              </span>
                              {activeOrganization?.viewer.isOwner && (
                                <div className="mr-2 ml-auto">
                                  <ButtonGroup
                                    controlled
                                    sharedButtonProps={{
                                      className: 'flex justify-center !py-2 !px-4',
                                    }}
                                  >
                                    <ButtonGroup.Button
                                      active={planFrequency == PlanFrequency.Yearly}
                                      onClick={() => setPlanFrequency(PlanFrequency.Yearly)}
                                    >
                                      Yearly
                                    </ButtonGroup.Button>
                                    <ButtonGroup.Button
                                      active={planFrequency == PlanFrequency.Monthly}
                                      onClick={() => setPlanFrequency(PlanFrequency.Monthly)}
                                    >
                                      Monthly
                                    </ButtonGroup.Button>
                                  </ButtonGroup>
                                </div>
                              )}
                            </div>
                            {activeOrganization?.viewer.isOwner ? (
                              <>
                                {isCustomPlan ? (
                                  <>
                                    <p className="text-copy text-base">
                                      You are currently subscribed to a custom plan. You can view
                                      and manage your subscription on our billing portal.
                                    </p>
                                    <Button
                                      onClick={() => {
                                        setPortalLoading(true)
                                        void createPaymentPortalLink().then((v) => {
                                          if (v.data?.createPaymentPortalLink) {
                                            window.location.href = v.data.createPaymentPortalLink
                                          }
                                        })
                                      }}
                                      className="w-fit"
                                      variant="secondary"
                                      disabled={portalLoading}
                                    >
                                      {portalLoading ? 'One second...' : 'Manage subscription'}
                                    </Button>
                                  </>
                                ) : (
                                  <div className="border-divider-light-gray rounded border">
                                    <PlanPicker planFrequency={planFrequency} />
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <p className="text-copy text-base">
                                  Only the owner of {activeOrganization?.name} can manage your
                                  subscription. To upgrade to a different plan, contact your
                                  organization owner:{' '}
                                  <a
                                    className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
                                    href={`mailto:${orgOwner?.email ?? ''}`}
                                  >
                                    {orgOwner?.name}
                                  </a>
                                  .
                                </p>
                              </>
                            )}
                          </div>
                        </>
                      )
                    }}
                  />
                  <Route path="/:organizationSlug/upgrade" component={CustomPlanUpgrade} />
                </Switch>
              </div>
            </div>
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical" className="p-1">
            <ScrollAreaThumb className="text-divider-dark-gray rounded border-2" />
          </ScrollAreaScrollbar>
        </ScrollArea>
      )}
    </BasePage>
  )
}

export default Settings
