import clsx from 'clsx'
import { useContext } from 'react'

import SnapshotIcon from '@/shared/components/Icons/Snapshot'
import { ContentV2, ContentV2ObjectMap } from '@/shared/types'
import { getSnapshotStatus } from '@/util/snapshotHelpers'

import Checkbox from '../Checkbox'
import { useContentGrid } from '../ContentGrid/useContentGrid'
import { DesktopDisplayOnly } from '../ResponsiveContainers'
import { CardContext } from './ContentCard'
import { Trinket } from './Trinket'

// eslint-disable-next-line complexity
export const ImagePreview = ({
  content,
  innerBorderColor,
  imageAreaBackgroundColor,
  checkboxBackgroundColor,
  isDragTarget,
  isChecked,
  disableTrinket,
}: {
  content: ContentV2
  innerBorderColor: string
  imageAreaBackgroundColor: string
  checkboxBackgroundColor: string
  isDragTarget: boolean
  isChecked?: boolean
  disableTrinket?: boolean
}): JSX.Element => {
  const { selectable } = useContext(CardContext)
  const { toggleItemSelect, shiftAction, itemsInView } = useContentGrid()

  const currentSnapshotStatus =
    content.__typename === 'Snapshot' ? getSnapshotStatus(content) : undefined

  return (
    <div
      className={clsx(
        'relative border-b',
        {
          'aspect-image-area-default min-h-[80px]': content.__typename !== 'Folder',
          'h-full min-h-[80px]': content.__typename === 'Folder',
        },
        innerBorderColor,
        imageAreaBackgroundColor,
      )}
    >
      <div className="absolute inset-0 flex items-center justify-center p-1">
        {content.__typename === 'Folder' ? (
          <div className="select-none text-4xl">📁</div>
        ) : content.__typename === 'Presentation' ? (
          <></>
        ) : content.__typename === 'Snapshot' && !currentSnapshotStatus?.hasCapturedSnapshot ? (
          <SnapshotIcon className="text-copy-secondary h-8 w-8" />
        ) : (
          <img
            draggable="false"
            className={clsx(
              'shadow-dark-hard max-h-full max-w-full group-hover:opacity-100 group-focus-visible:opacity-100 group-active:opacity-100',
              {
                'opacity-50':
                  (currentSnapshotStatus?.isCapturingOrRefreshingSnapshot ||
                    currentSnapshotStatus?.snapshotHasError ||
                    currentSnapshotStatus?.snapshotNeedsRepair ||
                    currentSnapshotStatus?.snapshotSessionExpired ||
                    currentSnapshotStatus?.snapshotRefreshFailed ||
                    currentSnapshotStatus?.snapshotFirstCaptureFailed) &&
                  content.__typename === 'Snapshot',
                'opacity-80':
                  (currentSnapshotStatus?.hasCapturedSnapshot &&
                    !(
                      currentSnapshotStatus?.isCapturingOrRefreshingSnapshot ||
                      currentSnapshotStatus?.snapshotHasError
                    )) ||
                  content.__typename === 'Document',
              },
            )}
            src={
              content.__typename === 'Snapshot'
                ? content.currentVersion?.image ?? undefined
                : 'image' in content
                ? content.image ?? undefined
                : undefined
            }
          />
        )}
      </div>
      {!disableTrinket && (
        <Trinket
          type={ContentV2ObjectMap[content.__typename]}
          currentSnapshotStatus={currentSnapshotStatus}
        />
      )}
      {!isDragTarget && selectable && !currentSnapshotStatus?.isFirstCapture && (
        <DesktopDisplayOnly>
          <div
            className={clsx('absolute top-2 right-2 isolate', {
              'opacity-0 focus-within:opacity-100 group-hover:opacity-100 group-focus-visible:opacity-100':
                !isChecked,
              'opacity-100': isChecked,
            })}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (e.shiftKey) {
                shiftAction(itemsInView, content)
              } else {
                toggleItemSelect(content)
              }
            }}
          >
            <div
              className={clsx('shadow-dark-hard flex h-6 w-6 items-center justify-center rounded', [
                checkboxBackgroundColor,
                {
                  'hover:bg-background-white active:bg-background-white': !isChecked,
                  'hover:bg-interactive-selected active:bg-interactive-selected': isChecked,
                },
              ])}
            >
              <Checkbox
                className="ml-2 h-4 w-4 cursor-pointer rounded-sm"
                checked={isChecked}
                onChange={(e) => {
                  e.stopPropagation()
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  if (e.shiftKey) {
                    shiftAction(itemsInView, content)
                  } else {
                    toggleItemSelect(content)
                  }
                }}
              />
            </div>
          </div>
        </DesktopDisplayOnly>
      )}
    </div>
  )
}
