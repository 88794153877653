import clsx from 'clsx'
import { useEffect, useMemo } from 'react'
import * as React from 'react'
import { Loader as LoaderIcon, XCircle } from 'react-feather'
import { Link } from 'react-router-dom'

import { SnapshotCaptureFrame, SnapshotFieldsFragment } from '@/generated/graphql'
import { Connection, Loader } from '@/shared/components'
import { SnapshotVersionHookReturnType } from '@/shared/hooks/useSnapshotVersion'
import { getContentLinkUrl, isEmbed } from '@/util'
import { getSnapshotStatus } from '@/util/snapshotHelpers'
import { useElementSizer, useUserAgent } from '@/web/hooks'

const SnapshotImagePlaceholder = (props: {
  isRefreshingSnapshot: boolean
  snapshotHasError: boolean
  snapshotSessionExpired: boolean
  snapshotNeedsRepair: boolean
}) => {
  const { isRefreshingSnapshot, snapshotHasError, snapshotNeedsRepair, snapshotSessionExpired } =
    props

  if (
    !isRefreshingSnapshot &&
    (snapshotHasError || snapshotSessionExpired || snapshotNeedsRepair)
  ) {
    const Icon = snapshotSessionExpired ? Connection : XCircle

    return (
      <>
        <Icon className="text-copy-alert h-6 w-6" />
        <span className="text-copy text-center font-semibold">
          {snapshotSessionExpired ? 'Reconnect' : 'Capture failed'}
        </span>
      </>
    )
  }

  return (
    <>
      <LoaderIcon className="animate-spin-slow text-copy h-6 w-6" />
      <span className="text-copy text-center font-semibold">Capturing Snapshot…</span>
    </>
  )
}

type Dimensions = {
  width: number
  height: number
}
// width and height are divided by two, because we take snapshots at 2x their size
// this catches the case where a snapshot ended up being smaller than the original frame width (https://linear.app/plus/issue/PLUS-953/web-app-snapshot-viewer-handling-changing-aspect-ratios-across)
const calculateFinalDimensions = (
  imageDimensions: Dimensions,
  frame?: SnapshotCaptureFrame,
): Dimensions => {
  const width =
    imageDimensions.width !== 0
      ? Math.floor(imageDimensions.width / 2)
      : Math.floor(frame?.frameWidth ?? 0)
  const height =
    imageDimensions.height !== 0
      ? Math.floor(imageDimensions.height / 2)
      : Math.floor(frame?.frameHeight ?? 0)

  return { height, width }
}

const SnapshotImageDisplayInner = ({
  className,
  isCapturePreview,
  snapshot,
  snapshotVersion,
  isFeedView,
}: {
  className?: string
  isCapturePreview?: boolean
  snapshot?: SnapshotFieldsFragment
  snapshotVersion?: SnapshotVersionHookReturnType['snapshotVersion']
  isFeedView?: boolean
}): JSX.Element => {
  const isEmbedded = isEmbed()
  const userAgent = useUserAgent()

  const isCurrentVersion = snapshot?.currentVersion?.id === snapshotVersion?.id
  const elRef = React.useRef<HTMLDivElement>(null)
  const imgRef = React.useRef<HTMLImageElement>(null)
  const [imageDimensions, setImageDimensions] = React.useState<Dimensions>({
    height: 0,
    width: 0,
  })

  const resetImageDimensions = React.useCallback(() => {
    setImageDimensions({ height: 0, width: 0 })
  }, [])

  const updateImageDimensions = React.useCallback(
    (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const target = ev.target as HTMLImageElement
      setImageDimensions({
        height: target.naturalHeight,
        width: target.naturalWidth,
      })
    },
    [],
  )

  const {
    isFirstCapture,
    snapshotHasError,
    snapshotNeedsRepair,
    snapshotSessionExpired,
    isRefreshingSnapshot,
  } = getSnapshotStatus(snapshot)

  const showPlaceholder =
    isFirstCapture || snapshotHasError || snapshotNeedsRepair || snapshotSessionExpired

  // reset previously stored image dimensions if snapshot is capturing or error, so we use stored instructions instead
  useEffect(() => {
    if (showPlaceholder && isCurrentVersion && imageDimensions.width !== 0) {
      resetImageDimensions()
    }
  }, [showPlaceholder, isCurrentVersion, imageDimensions, resetImageDimensions])

  const dimensions = useMemo(
    () => calculateFinalDimensions(imageDimensions, snapshot?.instructions.frame),
    [imageDimensions, snapshot?.instructions.frame],
  )

  // 56px = 3.5rem (height of versions footer)
  // 16px = 1rem (desired padding)
  // 56 + 16 = 72
  const snapshotSize = useElementSizer(elRef, dimensions, isEmbedded ? 8 : 16)

  const snapshotImage = (
    <img
      className={clsx('absolute', className, {
        'rendering-optimize-contrast': userAgent.engine.name === 'Blink',
        'select-none': isEmbedded,
      })}
      src={snapshotVersion?.image as string}
      ref={imgRef}
      // eslint-disable-next-line react/no-unknown-property
      onLoadStart={resetImageDimensions}
      onLoad={updateImageDimensions}
      style={snapshotSize}
    />
  )

  return (
    <div ref={elRef} className="flex h-full w-full grow items-center justify-center">
      {snapshot && snapshotVersion && snapshotVersion.image ? (
        <>
          {!isEmbedded && !isFeedView && !isCapturePreview && snapshot?.viewer.canEdit ? (
            <a
              href={snapshot?.instructions.url.toString()}
              target="_blank"
              rel="noreferrer"
              className="group/mask absolute"
              style={snapshotSize}
            >
              {snapshotImage}
            </a>
          ) : (
            snapshotImage
          )}
        </>
      ) : showPlaceholder ? (
        <div
          className="border-divider-light-gray bg-background-panel absolute flex flex-col items-center justify-center gap-y-2 border text-base"
          style={snapshotSize}
        >
          <SnapshotImagePlaceholder
            isRefreshingSnapshot={isRefreshingSnapshot}
            snapshotHasError={snapshotHasError}
            snapshotNeedsRepair={snapshotNeedsRepair}
            snapshotSessionExpired={snapshotSessionExpired}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export const SnapshotImageDisplay = ({
  className,
  snapshot,
  snapshotVersion,
  isCapturePreview,
  isFeedView,
}: {
  className?: string
  snapshot?: SnapshotFieldsFragment
  snapshotVersion?: SnapshotVersionHookReturnType['snapshotVersion']
  isCapturePreview?: boolean
  isFeedView?: boolean
}): JSX.Element => {
  const isEmbedded = isEmbed()

  if (isFeedView) {
    return (
      <Link
        to={getContentLinkUrl(snapshot as SnapshotFieldsFragment)}
        className="flex h-full w-full grow items-center justify-center"
      >
        <SnapshotImageDisplayInner
          className={className}
          isCapturePreview={isCapturePreview}
          snapshot={snapshot}
          snapshotVersion={snapshotVersion}
          isFeedView={isFeedView}
        />
      </Link>
    )
  }

  return (
    <>
      {isEmbedded && snapshot?.viewer.canEdit && !isCapturePreview ? (
        <a
          href={snapshot?.instructions.url.toString()}
          target="_top"
          rel="noreferrer"
          className="flex h-full w-full grow items-center justify-center"
        >
          <SnapshotImageDisplayInner
            className={className}
            isCapturePreview={isCapturePreview}
            snapshot={snapshot}
            snapshotVersion={snapshotVersion}
            isFeedView={isFeedView}
          />
        </a>
      ) : (
        <SnapshotImageDisplayInner
          className={className}
          isCapturePreview={isCapturePreview}
          snapshot={snapshot}
          snapshotVersion={snapshotVersion}
          isFeedView={isFeedView}
        />
      )}
    </>
  )
}
