import { useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { activeOrganizationState } from '@/shared/apollo/apolloLocalState'

import { FullPageLoader } from '../components/FullPageLoader'
import { useViewer } from '../hooks'

export const ActiveOrgHandler = (props: { children: JSX.Element }): JSX.Element => {
  const { organizationSlug } = useParams<{ organizationSlug?: string }>()
  const { viewer, loading } = useViewer()
  const activeOrgState = useReactiveVar(activeOrganizationState)

  useEffect(() => {
    if (organizationSlug && viewer) {
      // Current org is already correct
      if (activeOrgState?.slug === organizationSlug) {
        return
      }

      const orgFromUrl = viewer.organizations.find((x) => x?.slug === organizationSlug)

      // User is visiting an orgs page they don't belong to
      if (!orgFromUrl) {
        return
      }

      // User switching to an org they are a member of but is not the active org
      activeOrganizationState({
        id: orgFromUrl.id,
        slug: orgFromUrl.slug,
      })
    } else if (!organizationSlug && viewer) {
      // No org can be determined by url
      // If we already have an active org, keep it
      if (activeOrgState?.slug) {
        return
      } else {
        // Otherwise default to server provided active org
        activeOrganizationState({
          id: viewer?.currentOrganization?.id as string,
          slug: viewer?.currentOrganization?.slug as string,
        })
      }
    }
  }, [activeOrgState?.slug, organizationSlug, viewer])

  if (loading) {
    return (
      <div className="m-auto">
        <FullPageLoader loading={true} />
      </div>
    )
  }

  return props.children
}
