import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from '@radix-ui/react-dialog'
import * as React from 'react'

import Button from './Button/Button'
import Input from './Input'

type Variant = 'primary' | 'destructive'

type Props = {
  isOpen?: boolean
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  content?: React.ReactChild
  contentStyle?: React.CSSProperties
  defaultInputText?: string
  inputMaxLength?: number
  onSubmit: (inputValue: string) => void
  onCancel?: () => void
  primaryButtonText: React.ReactChild
  secondaryButtonText?: React.ReactChild
  title: React.ReactChild
  useInput?: boolean
  variant: Variant
}

const Modal = (props: Props): JSX.Element => {
  const {
    content,
    contentStyle,
    defaultInputText,
    inputMaxLength,
    primaryButtonText,
    onSubmit,
    onCancel,
    secondaryButtonText,
    title,
    useInput = false,
    variant,
    isOpen = true,
    setOpen,
  } = props

  const [isModalOpen, setIsModalOpen] = React.useState(isOpen)

  React.useEffect(() => {
    setIsModalOpen(isOpen)
  }, [isOpen])

  const [inputValue, setInputValue] = React.useState<string>(defaultInputText || '')

  const onCancelClicked = React.useCallback(() => {
    if (typeof onCancel === 'function') {
      onCancel()
      if (setOpen) {
        setOpen(false)
      }
    }
  }, [onCancel])

  const onCTAClicked = React.useCallback(() => {
    onSubmit(inputValue)
    if (setOpen) {
      setOpen(false)
    }
  }, [inputValue, onSubmit])

  const submitModalCallback = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onCTAClicked()
        if (setOpen) {
          setOpen(false)
        }
      }
    },
    [onCTAClicked],
  )

  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogPortal>
        <DialogContent className="fixed inset-0 z-10 flex h-full w-full items-start justify-center sm:items-center">
          <DialogOverlay
            className="bg-base-black absolute h-full w-full opacity-80"
            onClick={() => {
              if (setIsModalOpen) {
                setIsModalOpen(false)
              }
            }}
          />
          <div
            style={contentStyle}
            className="bg-background-white mx-3 mt-3 inline-block grow transform overflow-hidden rounded px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:mx-0 sm:w-full sm:max-w-sm sm:p-3"
          >
            <div>
              <div className="text-center">
                <DialogTitle asChild>
                  <h1 className="text-copy text-base font-semibold">{title}</h1>
                </DialogTitle>
                <DialogDescription asChild>
                  <div className="text-copy-secondary mt-1 text-base">{content}</div>
                </DialogDescription>
              </div>
            </div>
            <div className="mt-2 flex flex-col space-y-2">
              {useInput && (
                <Input
                  onKeyDown={submitModalCallback}
                  onChange={(e) => setInputValue(e.target.value)}
                  value={inputValue}
                  name="modal-input"
                  aria-label="Modal input"
                  maxLength={inputMaxLength}
                />
              )}
              <DialogClose asChild>
                <Button variant={variant} onClick={onCTAClicked} className="w-full">
                  {primaryButtonText}
                </Button>
              </DialogClose>
              {secondaryButtonText && (
                <DialogClose asChild>
                  <Button variant={'secondary'} className="w-full" onClick={onCancelClicked}>
                    {secondaryButtonText}
                  </Button>
                </DialogClose>
              )}
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

export default Modal
