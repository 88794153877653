import { offset, shift, useFloating } from '@floating-ui/react-dom'
import {
  AnimatePresence,
  HTMLMotionProps,
  motion,
  MotionStyle,
  TargetAndTransition,
} from 'framer-motion'
import React, { useCallback, useEffect, useState } from 'react'

import { TourStep, useStarTour } from './machine'

interface RendererProps {
  readonly backgroundOpacity?: number
  readonly gap?: number
}

const ANIMATION_DEFAULTS: HTMLMotionProps<'div'> = {
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  initial: { opacity: 0 },
  transition: { damping: 24, mass: 1, stiffness: 275, type: 'spring' },
}

const generateAnchorElementStyles = (step: TourStep | null | undefined) => {
  if (step?.anchor.type === 'element') {
    const useHighlighted = step.anchor.useHighlightedElement
    const selector = useHighlighted ? step.highlight?.selectorId : step.anchor.selectorId
    if (!selector) return null
    const el = document.querySelector(`[data-star-tours-id="${selector}"]`)
    if (el) {
      const rect = el.getBoundingClientRect()
      const styles = {
        height: rect.height + (step.highlight?.padding ? step.highlight?.padding * 2 : 0),
        width: rect.width + (step.highlight?.padding ? step.highlight?.padding * 2 : 0),
        x: rect.left - (step.highlight?.padding ?? 0),
        y: rect.top - (step.highlight?.padding ?? 0),
      }
      return styles
    }
  }

  return null
}

const generateStepAlignmentStyle = (
  step: TourStep,
  alignment: 'horizontal' | 'vertical',
): string | undefined => {
  return step.anchor.type === 'screen' ? step.anchor[`${alignment}Align`] ?? 'center' : undefined
}

const generateStepPositionStyles = (step: TourStep): MotionStyle | undefined =>
  step.anchor.type === 'element' ? { left: 0, position: 'fixed', top: 0 } : undefined

export const StarTourRenderer = ({ backgroundOpacity = 0.8, gap = 8 }: RendererProps) => {
  const {
    currentStep,
    currentStepIndex,
    activeTourSteps,
    next: _next,
    previous: _previous,
    stop,
    options,
  } = useStarTour()
  const {
    x,
    y,
    reference,
    floating,
    update: updateFloatingElement,
  } = useFloating({
    middleware: [offset(gap), shift({ padding: gap })],
    placement: 'left-start',
    strategy: 'fixed',
  })
  const [anchorElementStyles, setAnchorElementStyles] = useState<{
    readonly x: number
    readonly y: number
    readonly width: number
    readonly height: number
  } | null>(null)
  const animationProps =
    x != null && y != null && currentStep?.anchor.type === 'element' ? { x, y } : null
  const [animating, setAnimating] = useState(false)

  const next = useCallback(() => {
    const nextStep = _next()?.context.currentStep
    setAnchorElementStyles(generateAnchorElementStyles(nextStep))
    setAnimating(true)
  }, [_next])
  const previous = useCallback(() => {
    const previousStep = _previous()?.context.currentStep
    setAnchorElementStyles(generateAnchorElementStyles(previousStep))
  }, [_previous])
  const updateCurrentStepAnchorStyles = useCallback(() => {
    // if the current step isn't an element anchor, we lookahead to the next
    // this is because calcuating the styles takes at least a frame, due to it using getBoundingClientRect, which triggers a repaint
    // as such, if we don't try to calculate the next, then we get a frame or more of bad styles, since it won't have the latest styles
    // so by calcuating the next step in the background (if it exists), we can avoid this jump
    setAnchorElementStyles(
      generateAnchorElementStyles(
        currentStep?.anchor.type === 'element'
          ? currentStep
          : activeTourSteps?.[currentStepIndex + 1],
      ),
    )
  }, [currentStep, activeTourSteps, currentStepIndex])

  useEffect(() => {
    const mutation = new MutationObserver(updateCurrentStepAnchorStyles)
    window.addEventListener('resize', updateCurrentStepAnchorStyles)
    mutation.observe(document.body, {
      childList: true,
      subtree: true,
    })

    return () => {
      window.removeEventListener('resize', updateCurrentStepAnchorStyles)
      mutation.disconnect()
    }
  }, [updateCurrentStepAnchorStyles])

  useEffect(() => {
    updateCurrentStepAnchorStyles()
  }, [currentStep, updateCurrentStepAnchorStyles])

  useEffect(() => {
    updateFloatingElement()
  }, [updateFloatingElement, anchorElementStyles])

  return (
    <>
      <AnimatePresence>
        {currentStep && (
          <motion.div
            key="animated-bg"
            {...ANIMATION_DEFAULTS}
            style={{
              backgroundColor: `rgba(0, 0, 0, ${backgroundOpacity})`,
              height: '100vh',
              left: 0,
              mixBlendMode: 'hard-light',
              position: 'fixed',
              top: 0,
              width: '100vw',
              zIndex: Number.MAX_SAFE_INTEGER - 1,
            }}
          >
            <div
              ref={reference}
              style={{
                borderRadius: 6,
                height: anchorElementStyles?.height ?? 0,
                left: 0,
                position: 'fixed',
                top: 0,
                transform: `translate(${anchorElementStyles?.x ?? 0}px, ${
                  anchorElementStyles?.y ?? 0
                }px)`,
                visibility: 'hidden',
                width: anchorElementStyles?.width ?? 0,
              }}
            />
            <AnimatePresence>
              <motion.div
                {...ANIMATION_DEFAULTS}
                animate={{ opacity: currentStep.highlight ? 1 : 0 }}
                key={currentStepIndex}
                style={{
                  ...anchorElementStyles,
                  backgroundColor: 'rgba(128, 128, 128, 1)',
                  borderRadius: 6,
                  left: 0,
                  position: 'fixed',
                  top: 0,
                }}
              />
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {currentStep && (
          <div
            style={{
              alignItems: generateStepAlignmentStyle(currentStep, 'horizontal'),
              display: currentStep.anchor.type === 'screen' ? 'flex' : 'block',
              height: '100vh',
              justifyContent: generateStepAlignmentStyle(currentStep, 'vertical'),
              left: 0,
              position: 'fixed',
              top: 0,
              width: '100vw',
              zIndex: Number.MAX_SAFE_INTEGER,
            }}
          >
            <motion.div
              layout={options?.layout ?? true}
              onAnimationComplete={() => setAnimating(false)}
              layoutId="step-component"
              ref={floating}
              initial={ANIMATION_DEFAULTS.initial}
              animate={{
                ...(ANIMATION_DEFAULTS.animate as TargetAndTransition),
                ...animationProps,
              }}
              transition={options?.transition ?? ANIMATION_DEFAULTS.transition}
              style={generateStepPositionStyles(currentStep)}
            >
              <currentStep.component
                nextStep={next}
                hasNextStep={!!activeTourSteps?.[currentStepIndex + 1]}
                previousStep={previous}
                hasPreviousStep={!!activeTourSteps?.[currentStepIndex - 1]}
                endTour={stop}
                currentStep={currentStep}
                currentStepIndex={currentStepIndex}
                totalSteps={activeTourSteps?.length ?? 0}
                animating={animating}
              />
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}
