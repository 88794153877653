import { ConnectDropTarget, useDrop } from 'react-dnd'

import { ContentV2 } from '@/shared/types'

/**
 *
 * @param onDropCallback Callback to be called when content is dropped on the target
 * @param canDropCallback Validation callback used to determine if the item can be dropped onto the target
 * @returns
 */
export const useContentDrop = (
  onDropCallback: (item: ContentV2) => void,
  canDropCallback?: (item: ContentV2) => boolean,
): [boolean, ConnectDropTarget, boolean] => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ['Snapshot', 'Document', 'Folder'],
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
      ...(canDropCallback && { canDrop: canDropCallback }),
      drop: onDropCallback,
    }),
    [onDropCallback, canDropCallback],
  )

  return [canDrop, drop, isOver]
}
