import { useReactiveVar } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import * as React from 'react'
import { useInView } from 'react-intersection-observer'
import { useLocation, useParams } from 'react-router-dom'

import {
  ContentType,
  FolderQuery,
  OrderDirection,
  SearchQuery,
  SearchQueryVariables,
  SearchResultOrderField,
} from '@/generated/graphql'
import { feedClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Loader } from '@/shared/components'
import { BaseGrid } from '@/shared/components/ContentGrid/ContentGrid'
import useViewer from '@/shared/hooks/useViewer'
import { remainingItemsToLoad } from '@/util'
import { useSearch } from '@/web/hooks/useSearch'

import { getFeedEmptyMessageText } from '../FeedNext'

export const useFeedFilter = () => {
  const location = useLocation()
  const filter = React.useMemo(() => new URLSearchParams(location.search), [location.search]).get(
    'filter',
  )

  if (filter === 'snapshots') {
    return ContentType.Snapshot
  } else if (filter === 'folders') {
    return ContentType.Folder
  } else if (filter === 'pages') {
    return ContentType.Document
  } else {
    return
  }
}

export const FeedFilterView = React.memo(
  ({
    folder,
    onNodesLoaded,
  }: {
    folder: FolderQuery['folder']
    onNodesLoaded: (nodes: SearchQuery['search']['edges'][0]['node'][]) => void
  }): JSX.Element => {
    const { activeOrganization } = useViewer()
    const { sortField } = useReactiveVar(feedClientStateVar)

    const activeContentType = useFeedFilter()

    const { folderId, teamId } = useParams<{
      organizationSlug?: string
      folderId?: string
      teamId?: string
    }>()

    const searchInput: SearchQueryVariables = useMemo(
      () => ({
        filter: {
          // TODO: Remove once graphQL teams-safety is added
          contentIOwn: teamId ? false : true,
          folders: folderId ? [folderId] : [],
          teams: teamId ? [teamId] : [],
          ...(activeContentType && { contentTypes: [activeContentType] }),
        },
        first: 50,
        sort: {
          direction:
            sortField === SearchResultOrderField.Name ? OrderDirection.Asc : OrderDirection.Desc,
          field: sortField || SearchResultOrderField.CreatedAt,
        },
      }),
      [activeContentType, folderId, sortField, teamId],
    )

    const { ref: inViewRef, inView } = useInView()
    const { loading, nodes, loadMore, loadingMore, counts } = useSearch(
      searchInput,
      activeOrganization?.urn,
    )

    useEffect(() => {
      if (inView && !loadingMore) {
        loadMore()
      }
    }, [inView, loadMore, loadingMore])

    useEffect(() => {
      onNodesLoaded(nodes)
    }, [onNodesLoaded, nodes])

    if (!activeContentType) {
      return <></>
    }

    if (!loading && nodes.length === 0) {
      return (
        <div className="absolute h-full w-full">
          <div className="flex h-full w-full items-center justify-center">
            <div className="text-center text-xs">
              {getFeedEmptyMessageText(activeContentType, folder, teamId, activeOrganization?.slug)}
            </div>
          </div>
        </div>
      )
    }

    if (loading && nodes.length === 0) {
      return (
        <div className="absolute h-full w-full">
          <div className="flex h-full flex-row items-center">
            <div className="m-auto">
              <Loader />
            </div>
          </div>
        </div>
      )
    }

    return (
      <BaseGrid
        contents={[nodes]}
        inViewRef={inViewRef}
        loadingCardsToShow={
          (loadingMore || loading) && nodes.length !== 0
            ? remainingItemsToLoad(50, nodes.length, counts?.total ?? 0)
            : 0
        }
      />
    )
  },
)
FeedFilterView.displayName = 'FeedFilterView'
