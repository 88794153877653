import { useEffect } from 'react'
import useLatest from 'use-latest'

const useOnClickOutside = (
  targetElement: HTMLElement | null,
  ignoreElements: Array<HTMLElement | null>,
  callback: () => void,
): void => {
  const stableCallback = useLatest(callback)
  const stableIgnoreElements = useLatest(ignoreElements)

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element, a descendent element, or inside of ignoreElements
      if (
        !targetElement ||
        targetElement.contains(event.target as Node) ||
        stableIgnoreElements.current.some((ignoreElement) =>
          ignoreElement?.contains(event.target as Node),
        )
      ) {
        return
      }
      stableCallback.current()
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener, { passive: true })

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [targetElement, stableCallback, stableIgnoreElements])
}

export default useOnClickOutside
