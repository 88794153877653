export default function LearnMoreAboutConnectionsLink({
  learnMoreText,
}: {
  readonly learnMoreText: string
}): JSX.Element {
  return (
    <a
      href="https://guide.plusdocs.com/snapshots/connections"
      target="_blank"
      rel="noreferrer"
      className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active cursor-pointer"
    >
      {learnMoreText}
    </a>
  )
}
