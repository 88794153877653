import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

import { useAuth } from '@/shared/auth/useAuth'

// Middleware for a route which requires user to be authenticated via Google OAuth
export const GoogleAuthMiddlewareRoute = ({
  component: Component,
  ...rest
}: RouteProps): JSX.Element => {
  const auth = useAuth()
  const location = useLocation<{
    from: { pathname: string; search: string; state: undefined | Record<string, unknown> }
    showResendConfirmation?: boolean
  }>()

  if (!auth.isLoggedIn()) {
    return (
      <Redirect
        to={{ pathname: '/login-google', search: `route=${encodeURIComponent(location.pathname)}` }}
      />
    )
  }

  if (!Component) return <></>

  return <Route {...rest} render={(props) => <Component {...props} />} />
}
