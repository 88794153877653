import { ReactNode, useEffect, useState } from 'react'

// To generate new domain favicons, use this tool:
// https://dev.to/derlin/get-favicons-from-any-website-using-a-hidden-google-api-3p1e

export type FaviconSupportedSize = '16' | 16 | '24' | 24 | '32' | 32

export type FaviconImageModules = Record<string, () => Promise<string>>

// Use Vite's dynamic glob import to get an inventory of all of the favicons we have. Vite will pack these into bundles
// at build time. See https://vitejs.dev/guide/features.html#glob-import for more information.
const imageModules: FaviconImageModules = import.meta.glob<false, string, string>(
  '../assets/favicons/*/*.png',
  { import: 'default' },
)

const svgModules: FaviconImageModules = import.meta.glob<false, string, string>(
  '../assets/favicons/svg/*.svg',
  { import: 'default' },
)

const getImagePath = async ({
  domainName,
  imageModuleMap,
  size,
}: {
  readonly domainName: string
  readonly imageModuleMap: FaviconImageModules
  readonly size: FaviconSupportedSize
}): Promise<string | undefined> => {
  const fileName = domainName.replaceAll('.', '-')

  const importFunction: () => Promise<string> =
    imageModuleMap[`../assets/favicons/${size}/${fileName}.png`]

  if (importFunction) {
    try {
      const path = await importFunction()
      return path
    } catch {
      return undefined
    }
  }
}

const getSvgPath = async ({
  domainName,
  svgModuleMap,
}: {
  readonly domainName: string
  readonly svgModuleMap: FaviconImageModules
}) => {
  const fileName = domainName.replaceAll('.', '-')

  const importFunction: () => Promise<string> =
    svgModuleMap[`../assets/favicons/svg/${fileName}.svg`]

  if (importFunction) {
    try {
      const svg = await importFunction()
      return svg
    } catch {
      return undefined
    }
  }
}

export interface IFaviconProps {
  readonly defaultIcon: ReactNode
  readonly domainName: string
  readonly imageModulesForUnitTests?: FaviconImageModules
  readonly size: FaviconSupportedSize
  readonly svgModulesForUnitTests?: FaviconImageModules
}

// This is the order in which images are searched. The algorithm is essentially this: use the specific size first, then
// try a larger image (preference to an image that is double the size so scaling is better). There are some cases where
// upscaling will look terrible, but that's the fault of the web site for not giving better favicons. :)
const sizeSearchOrders: Record<'16' | '24' | '32', readonly FaviconSupportedSize[]> = {
  '16': [16, 32, 24],
  '24': [24, 32, 16],
  '32': [32, 24, 16],
}

const Favicon = ({
  defaultIcon,
  domainName,
  imageModulesForUnitTests,
  size,
  svgModulesForUnitTests,
}: IFaviconProps): JSX.Element => {
  const [svgPath, setSvgPath] = useState<string>()
  const [imagePath, setImagePath] = useState<string>()

  const lowerDomainName = domainName.toLowerCase()
  const imageSrc = svgPath ?? imagePath

  useEffect(() => {
    const run = async () => {
      const importedSvg = await getSvgPath({
        domainName: lowerDomainName,
        svgModuleMap: svgModulesForUnitTests ?? svgModules,
      })

      if (importedSvg) {
        setSvgPath(importedSvg)
        return
      }

      const importedPaths = await Promise.allSettled(
        sizeSearchOrders[size].map((iconSize) =>
          getImagePath({
            domainName: lowerDomainName,
            imageModuleMap: imageModulesForUnitTests ?? imageModules,
            size: iconSize,
          }),
        ),
      )

      const importedPath = (
        importedPaths.find((result) => result.status === 'fulfilled' && result.value) as
          | PromiseFulfilledResult<string>
          | undefined
      )?.value

      if (importedPath) {
        setImagePath(importedPath)
      }
    }

    void run()
  }, [imageModulesForUnitTests, lowerDomainName, size, svgModulesForUnitTests])

  if (!imageSrc) {
    return <>{defaultIcon}</>
  }

  return <img width={size} height={size} alt={`${lowerDomainName} icon`} src={imageSrc} />
}

export default Favicon
