import { useReactiveVar } from '@apollo/client'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import { Edit, File, Trash2, Type, Users } from 'react-feather'
import { useInView } from 'react-intersection-observer'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useGate } from 'statsig-react'

import {
  CaptureStatus,
  ContentAccessLevel,
  ContentType,
  Document,
  DocumentCardFragment,
  FolderQuery,
  OrderDirection,
  SearchQuery,
  SearchQueryVariables,
  SearchResultOrderField,
  SnapshotFieldsFragment,
} from '@/generated/graphql'
import {
  contentPrivacyStateVar,
  defaultContentPrivacyState,
  feedClientStateVar,
} from '@/shared/apollo/apolloLocalState'
import { Snapshot as SnapshotIcon } from '@/shared/components/Icons'
import useSnapshotVersion from '@/shared/hooks/useSnapshotVersion'
import { ContentV2, ContentV2ObjectMap, ScreenSize } from '@/shared/types'
import { getContentLinkUrl, getScreenSize, remainingItemsToLoad } from '@/util'
import { getSnapshotStatus } from '@/util/snapshotHelpers'
import { EmbedFooter } from '@/web/components'
import EmbedHeader from '@/web/components/EmbedHeader'
import { useViewer } from '@/web/hooks'
import useFolder from '@/web/hooks/useFolder'
import useSearch from '@/web/hooks/useSearch'
import { useFeedFilter } from '@/web/routes/feed/FeedFilterView'
import { getFeedEmptyMessageText } from '@/web/routes/FeedNext'

import { ContentCardDataWrapper } from '../ContentCard/ContentCardDataWrapper'
import { Loader } from '../Loader'
import { EmptyGridOnboarding } from '../Onboarding/EmptyGridOnboarding'
import { SnapshotImageDisplay } from '../SnapshotImageDisplay'
import useDelete from './Actions/delete'
import useMoveOrg from './Actions/moveFolder'
import useRename from './Actions/rename'
import { SeeMoreCard } from './SeeMoreCard'
import { useContentGrid } from './useContentGrid'

interface IBaseFeedProps {
  contents: Array<ContentV2[]>
  folder?: FolderQuery['folder']
  isGallery?: boolean
  onNodesLoaded: (nodes: SearchQuery['search']['edges'][0]['node'][]) => void
}

const ContentTypeTrinket = ({ type }: { type: ContentType }) => {
  return (
    <div
      className={clsx(
        'rounded-xs absolute bottom-2 left-2 z-50 flex h-6 w-6 items-center justify-center',
        type === 'DOCUMENT' ? 'bg-background-pages' : 'bg-brand-dark',
      )}
    >
      {type === 'DOCUMENT' ? (
        <File className="text-copy-active h-4 w-4" />
      ) : (
        <SnapshotIcon className="text-copy-active h-4 w-4" />
      )}
    </div>
  )
}

const LoadingItem = () => {
  return (
    <div className="h-full w-full">
      <div className="flex h-full flex-1 flex-col items-center">
        <div className="border-divider-light-gray bg-background-panel flex h-10 w-full items-center gap-1 rounded-t border p-2" />
        <div className="bg-background-canvas border-divider-light-gray flex w-full animate-pulse flex-col border-x">
          <div className="group aspect-video w-full" />
        </div>
        <div className="border-divider-light-gray bg-background-panel bottom-0 h-10 w-full rounded-b border p-2" />
      </div>
    </div>
  )
}

const DocumentFeedItem = ({ loadedDocument }: { loadedDocument: Document }) => {
  const document = loadedDocument
  const isGallery = useRouteMatch([`/:organizationSlug/gallery`, `/gallery`])

  return (
    <div className="h-full w-full">
      <div className="flex h-full flex-1 flex-col items-center">
        <EmbedHeader content={loadedDocument} isFeedView />
        <div className="bg-background-canvas border-divider-light-gray relative flex w-full flex-col border-x">
          {loadedDocument.__typename && (
            <ContentTypeTrinket type={ContentV2ObjectMap[loadedDocument.__typename]} />
          )}
          <Link to={getContentLinkUrl(document as DocumentCardFragment, undefined, !!isGallery)}>
            <div className="group aspect-video w-full p-1">
              <img src={document.image ?? ''} className="h-full w-full" />
            </div>
          </Link>
        </div>
        <EmbedFooter content={loadedDocument} isFeedView itemCanBeDeleted allowRename showPopover />
      </div>
    </div>
  )
}

const SnapshotFeedItem = ({
  snapshot,
  snapshotCanBeDeleted,
}: {
  snapshot: NonNullable<SnapshotFieldsFragment>
  snapshotCanBeDeleted: boolean
}) => {
  const [snapshotVersionId, setSnapshotVersionId] = useState(snapshot.latestVersion?.id ?? null)
  const snapshotVersion =
    useSnapshotVersion(
      snapshotVersionId ?? undefined,
      snapshot.latestVersion?.id === snapshotVersionId,
    ).snapshotVersion ?? snapshot.latestVersion

  // update to latest version whenever version changes
  useEffect(() => {
    if (snapshot?.latestVersion?.status === CaptureStatus.Captured) {
      setSnapshotVersionId(snapshot.latestVersion.id)
    }
  }, [snapshot.latestVersion])

  return (
    <div className="h-full w-full">
      <div className="flex h-full flex-1 flex-col items-center">
        <EmbedHeader
          content={snapshot}
          snapshotVersion={snapshotVersion}
          snapshotVersionId={snapshotVersionId}
          onChangeSnapshotVersion={setSnapshotVersionId}
          isFeedView
        />
        <div className="bg-background-canvas border-divider-light-gray relative flex w-full flex-col border-x">
          {snapshot.__typename && (
            <ContentTypeTrinket type={ContentV2ObjectMap[snapshot.__typename]} />
          )}
          <div className="group aspect-video w-full">
            <SnapshotImageDisplay
              isFeedView
              snapshot={snapshot}
              snapshotVersion={snapshotVersion}
            />
          </div>
        </div>
        <EmbedFooter
          content={snapshot}
          isFeedView
          itemCanBeDeleted={snapshotCanBeDeleted}
          snapshotVersionId={snapshotVersionId}
          allowRename
          showPopover
        />
      </div>
    </div>
  )
}

// eslint-disable-next-line complexity
export const ContentFeed = (props: IBaseFeedProps) => {
  const { onNodesLoaded } = props
  useEffect(() => {
    contentPrivacyStateVar({ accessLevel: ContentAccessLevel.Private })

    return () => {
      contentPrivacyStateVar({
        ...defaultContentPrivacyState,
      })
    }
  }, [])

  const { activeOrganization } = useViewer()
  const { unselectAllItems } = useContentGrid()
  const history = useHistory()
  const { folderId, teamId } = useParams<{
    organizationSlug?: string
    folderId?: string
    teamId?: string
  }>()
  const { sortField } = useReactiveVar(feedClientStateVar)
  const activeContentType = useFeedFilter()

  const [screenSize, setScreenSize] = useState(getScreenSize())
  const onResize = React.useCallback(() => {
    const currentScreenSize = getScreenSize()
    setScreenSize(currentScreenSize)
  }, [])
  React.useLayoutEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])
  const folderContentLength = useMemo(() => {
    switch (screenSize) {
      case ScreenSize.xxl:
      case ScreenSize.xl:
        return 4
      case ScreenSize.lg:
      case ScreenSize.md:
        return 4
      case ScreenSize.sm:
        return 1
      case ScreenSize.xs:
      default:
        return 1
    }
  }, [screenSize])

  const { folder } = useFolder(folderId)
  const { value: useExtendedNux } = useGate('extended_first_snapshot_nux')

  const searchInput: SearchQueryVariables = useMemo(
    () => ({
      fetchPolicy: 'cache-first',
      filter: {
        // TODO: Remove once graphQL teams-safety is added
        contentIOwn: teamId ? false : true,
        folders: folderId ? [folderId] : [],
        teams: teamId ? [teamId] : [],
        ...(activeContentType && { contentTypes: [activeContentType] }),
      },
      first: 50,
      sort: {
        direction:
          sortField === SearchResultOrderField.Name ? OrderDirection.Asc : OrderDirection.Desc,
        field: sortField || SearchResultOrderField.CreatedAt,
      },
    }),
    [activeContentType, folderId, sortField, teamId],
  )

  const { ref: inViewRef, inView } = useInView()
  const { loading, nodes, loadMore, refresh, loadingMore, counts } = useSearch(
    searchInput,
    activeOrganization?.urn,
  )

  // get new data whenever active org changes
  useEffect(() => {
    if (!activeContentType) {
      void refresh()
    }
  }, [activeOrganization?.id, refresh, activeContentType])

  React.useEffect(() => {
    unselectAllItems()
  }, [unselectAllItems, history.location])

  const deleteCallback = useDelete()
  const renameCallback = useRename()
  const moveOrgCallback = useMoveOrg()

  const folders = nodes.filter((node) => node.__typename === 'Folder')

  const getFolderCards = () => {
    return folders.map((_folder, i) => {
      if (i >= folderContentLength && !activeContentType) {
        return <React.Fragment key={i}></React.Fragment>
      }

      const card = (
        <ContentCardDataWrapper
          key={_folder.id}
          content={_folder}
          noLocationLine
          selectable
          overflowItems={[
            {
              callback: () => renameCallback([_folder]),
              icon: Type,
              name: 'Rename',
            },
            {
              callback: () => moveOrgCallback([_folder], _folder.team ? 'personal' : 'team'),
              icon: _folder.team ? Edit : Users,
              name: _folder.team ? 'Move to Personal' : 'Move to Team',
            },
            {
              callback: () => deleteCallback([_folder]),
              icon: Trash2,
              name: 'Delete',
              styles: clsx([
                'text-interactive-destructive',
                'active:text-interactive-destructive',
                'active:bg-interactive-destructive-light',
              ]),
            },
          ]}
        />
      )

      if (folders.length - 5 === i) {
        return (
          <div key={_folder.id} ref={inViewRef}>
            {card}
          </div>
        )
      }

      return card
    })
  }

  const getContentCards = () => {
    const contents = props.isGallery ? props.contents[0] : nodes
    if (!contents || contents.length === 0) {
      return <></>
    }

    return contents.map((item, i) => {
      if (item.__typename === 'Document') {
        const document = item

        if (!document) {
          return <React.Fragment key={i}></React.Fragment>
        }

        const card = <DocumentFeedItem key={document.id} loadedDocument={document as Document} />

        if (nodes.length - 5 === i) {
          return (
            <div key={document.id} ref={inViewRef}>
              {card}
            </div>
          )
        }

        return card
      }

      if (item.__typename === 'Snapshot') {
        const snapshot = item

        const { snapshotCanBeDeleted } = getSnapshotStatus(snapshot as SnapshotFieldsFragment)

        if (!snapshot) {
          return <React.Fragment key={i}></React.Fragment>
        }

        const card = (
          <SnapshotFeedItem
            key={snapshot.id}
            snapshot={snapshot}
            snapshotCanBeDeleted={snapshotCanBeDeleted}
          />
        )

        if (nodes.length - 5 === i) {
          return (
            <div key={snapshot.id} ref={inViewRef}>
              {card}
            </div>
          )
        }

        return card
      }
    })
  }

  useEffect(() => {
    if (inView && !loadingMore) {
      loadMore()
    }
  }, [inView, loadMore, loadingMore])

  useEffect(() => {
    onNodesLoaded(nodes)
  }, [onNodesLoaded, nodes])

  const loadingCardsToShow =
    (loadingMore || loading) && nodes.length !== 0
      ? remainingItemsToLoad(50, nodes.length, counts?.total ?? 0)
      : 0

  if (!loading && nodes.length === 0 && !props.isGallery) {
    return (
      <div className="absolute h-full w-full">
        <div className="flex h-full w-full items-center justify-center">
          {!useExtendedNux || folderId || teamId ? (
            <div className="text-center text-xs">
              {getFeedEmptyMessageText(activeContentType, folder, teamId, activeOrganization?.slug)}
            </div>
          ) : (
            <EmptyGridOnboarding />
          )}
        </div>
      </div>
    )
  }

  if (loading && nodes.length === 0) {
    return (
      <div className="absolute h-full w-full">
        <div className="flex h-full flex-row items-center">
          <div className="m-auto">
            <Loader />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="relative w-full">
      <div className="absolute flex w-full justify-center">
        <div className="flex w-full max-w-7xl flex-col gap-6 pb-20 lg:pb-10">
          {folders.length > 0 && !props.isGallery && (
            <div className="flex flex-col gap-y-4 px-4 lg:px-6">
              <div
                className={clsx(
                  'grid grid-flow-row items-end gap-3',
                  'grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5',
                  'auto-rows-fr',
                )}
              >
                {getFolderCards()}
                {counts?.folders &&
                  !activeContentType &&
                  folderContentLength <= counts?.folders && (
                    <SeeMoreCard count={counts?.folders} type={ContentType.Folder} />
                  )}
              </div>
            </div>
          )}
          <div className="flex flex-col px-4 lg:px-6">
            <div
              className={clsx(
                'grid grid-flow-row items-end gap-6',
                'grid-cols-1 md:grid-cols-2',
                'auto-rows-fr',
              )}
            >
              {getContentCards()}
              {(loading || loadingMore) && loadingCardsToShow > 0
                ? [...Array(loadingCardsToShow)].map((_, i) => <LoadingItem key={i} />)
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
