import clsx from 'clsx'
import escapeStringRegexp from 'escape-string-regexp'
import * as React from 'react'
import { Edit, Folder, Users } from 'react-feather'

import { Input } from '@/shared/components'
import { ContentV2 } from '@/shared/types'
import { useViewer } from '@/web/hooks'

type SearchListItemProps = {
  isSelected: boolean
  onClickHandler: (contentItem: ContentV2) => void
  result: ContentV2
  searchQuery: string
  showContentText?: boolean
}

export const getHighlightedText = (text: string, highlight: string) => {
  const parts = text.split(new RegExp(`(${escapeStringRegexp(highlight)})`, 'gi'))

  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          className={clsx(part.toLowerCase() === highlight.toLowerCase() && 'font-semibold')}
        >
          {part}
        </span>
      ))}
    </span>
  )
}

const getIcon = (result: ContentV2) => {
  switch (result.__typename) {
    case 'Folder': {
      return (
        <div className="flex h-8 w-8 items-center justify-center">
          {result.id === 'personal' && <Edit size={16} />}
          {result.id === 'team' && <Users size={16} />}
          {result.id !== 'team' && result.id !== 'personal' && <Folder size={16} />}
        </div>
      )
    }
    case 'Snapshot': {
      return (
        <img
          className="border-divider-light-gray h-8 w-8 rounded-[3px] border"
          src={result.currentVersion?.image ?? undefined}
        />
      )
    }
    case 'Document': {
      return (
        <div
          className="border-divider-light-gray flex h-8 w-8 items-center justify-center rounded-[3px] border"
          style={{
            backgroundImage: `url(${result.image ?? ''})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
      )
    }
  }
}

export const SearchListItem = (props: SearchListItemProps): JSX.Element => {
  const {
    isSelected,
    onClickHandler,
    result: { name },
    result,
    searchQuery,
  } = props

  const { activeOrganization } = useViewer()

  const onClickCallback = React.useCallback(() => {
    onClickHandler(result)
  }, [onClickHandler, result])

  return (
    <div
      className={clsx(
        'flex h-12 cursor-pointer items-center space-x-2 rounded-md p-2',
        'hover:bg-interactive-secondary-hover',
        isSelected && 'bg-interactive-secondary-active',
      )}
      onClick={onClickCallback}
    >
      {getIcon(result)}
      <div className="text-copy flex-1 truncate text-xs leading-4">
        {getHighlightedText(name, searchQuery)}
        <br />
        <span className="text-copy-secondary">
          {getSearchSubText(result, activeOrganization?.name)}
        </span>
      </div>
    </div>
  )
}

type SearchListInputItemProps = {
  onSubmitHandler: (result: string) => void
  showContentText?: boolean
}

export const SearchListInputItem = (props: SearchListInputItemProps): JSX.Element => {
  const { onSubmitHandler } = props

  const [inputValue, setInputValue] = React.useState<string>('')

  const onFocusOutCallback = () => {
    onSubmitHandler(inputValue)
  }

  const onEnterPressedCallback = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmitHandler(inputValue)
    }
  }

  return (
    <div
      className={clsx(
        'flex h-12 w-full cursor-pointer items-center space-x-2 p-2',
        'hover:bg-interactive-secondary-hover rounded',
      )}
    >
      <div className="flex h-8 w-8 items-center justify-center">
        <Folder size={16} />
      </div>
      <div className="text-copy-primary w-full flex-1 truncate text-lg">
        <Input
          autoFocus
          className="focus:bg-interactive-secondary-active"
          name="search-list-input"
          onBlur={onFocusOutCallback}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={onEnterPressedCallback}
          value={inputValue}
        />
      </div>
    </div>
  )
}

type SearchListProps = {
  onItemSelectedCallback: (contentItem: ContentV2) => void
  searchQuery: string
  searchResults: ContentV2[]
  selectedContentId?: string
  showContentText?: boolean
}

export const SearchList = (props: SearchListProps): JSX.Element => {
  const { onItemSelectedCallback, searchQuery, searchResults, selectedContentId, showContentText } =
    props

  return (
    <div className="h-full">
      {searchResults.map((result) => {
        return (
          <SearchListItem
            key={result.id}
            result={result}
            searchQuery={searchQuery}
            onClickHandler={onItemSelectedCallback}
            isSelected={selectedContentId === result.id}
            showContentText={showContentText}
          />
        )
      })}
    </div>
  )
}

const getSearchSubText = (contentItem: ContentV2, activeOrganizationName?: string): string => {
  let locationPrefix
  if (contentItem.id === 'team') {
    locationPrefix = `Visible to ${activeOrganizationName || 'your organization'}`
  } else if (contentItem.id === 'personal') {
    locationPrefix = 'Only visible to you'
  } else if (contentItem.team) {
    locationPrefix = 'Team'
  } else {
    locationPrefix = 'Personal'
  }

  const separator = contentItem.__typename !== 'Folder' && contentItem.folder ? '/' : '•'
  const documentCountText =
    contentItem.__typename === 'Folder' &&
    contentItem.id !== 'personal' &&
    contentItem.id !== 'team'
      ? `${contentItem.contentCount} Document${contentItem.contentCount === 1 ? '' : 's'}`
      : ''
  const descriptorSuffix =
    contentItem.__typename === 'Folder'
      ? documentCountText
      : contentItem.folder?.name ||
        (contentItem.__typename === 'Document' ? 'Page' : contentItem.__typename)

  return [locationPrefix, descriptorSuffix].filter((val) => !!val).join(` ${separator} `)
}
