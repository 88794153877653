import '@/shared/libraries/global-polyfill'
import '../shared/styles/base.css'
import '../shared/styles/react-easy-crop.css'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'

import App from './App'
import AppProviders from './AppProviders'

if (import.meta.env.MODE === 'production') {
  ReactGA.initialize('G-46QTRSY88D')
  Sentry.init({
    // @zach — disabling the crash modal until we can spend more time actually tuning it to not be so sensitive
    // beforeSend(event) {
    // 	// we don't show the report dialogue for debug level errors, so that we can log errors silently
    // 	if (event.exception && event.level !== 'debug' && !isEmbed()) {
    // 		Sentry.showReportDialog({
    // 			eventId: event.event_id,
    // 			user: {
    // 				email: event.user?.email,
    // 			},
    // 		});
    // 	}
    // 	return event;
    // },
    dsn: 'https://935d9d595ca44575952d994646ca675c@o863887.ingest.sentry.io/5822338',
    ignoreErrors: [
      'AbortError',
      'Received status code 403',
      'Organization context is required',
      'Unable to get ID token',
    ],
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    release: import.meta.env.WEB_COMMIT_SHA,
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0,
    tracesSampleRate: 0.2,
  })
}

const container = document.getElementById('root')
if (container) {
  const root = ReactDOM.createRoot(container)
  root.render(
    <StrictMode>
      <AppProviders>
        <App />
      </AppProviders>
    </StrictMode>,
  )
}
