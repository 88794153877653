import { useReactiveVar } from '@apollo/client'

import { extensionClientStateVar } from '@/shared/apollo/apolloLocalState'

import { ChromeExtensionStep, TakeSnapshotStep } from './OnboardingTourSteps'

export const EmptyGridOnboarding = () => {
  const { isExtensionInstalled } = useReactiveVar(extensionClientStateVar)

  if (isExtensionInstalled) {
    return (
      <div className="bg-background-white border-divider-light-gray flex w-full max-w-md flex-col items-center gap-y-4 rounded border p-3 text-left">
        <div className="w-full pt-1 text-sm font-semibold">{`Let's take your first Snapshot!`}</div>
        <TakeSnapshotStep />
      </div>
    )
  }

  return (
    <div className="bg-background-white border-divider-light-gray flex w-full max-w-md flex-col items-center gap-y-4 rounded border p-3 text-left">
      <div className="w-full pt-1 text-sm font-semibold">
        Install our Chrome extension to start using Plus
      </div>
      <ChromeExtensionStep />
    </div>
  )
}
