import clsx from 'clsx'
import * as React from 'react'

import { UserActivity } from '@/generated/graphql'
import { Button, PlusLogo } from '@/shared/components'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { useTrackUserActivity } from '@/shared/hooks/useTrackUserActivity'
import UpgradeImageTwo from '@/web/assets/upgrade-image-2.png'
import UpgradeImageThree from '@/web/assets/upgrade-image-3.png'
import { useViewer } from '@/web/hooks'

import GoogleSlidesLogo from '../assets/IntegrationLogos/GoogleSlidesLogo.svg'
import PowerPointLogo from '../assets/IntegrationLogos/PowerPointLogo.svg'

declare global {
  interface Window {
    rewardful: (x: unknown, y: unknown) => void
  }
}

export const UpgradeScreenOne = ({ setStep }: { setStep: (step: number) => void }) => {
  const trackUserActivity = useTrackUserActivity()

  const selectPowerPointCallback = () => {
    trackUserActivity(UserActivity.PlatformSelectedPowerpoint)
    setStep(2)
  }

  const selectGoogleSlidesCallback = () => {
    trackUserActivity(UserActivity.PlatformSelectedGoogleSlides)
  }

  return (
    <div className="flex flex-col items-center gap-y-3">
      <div className="flex w-full gap-x-3">
        <div className="border-divider-light-gray flex w-full flex-col overflow-hidden rounded-md border">
          <div className="border-divider-light-gray bg-background-panel h-[128px] content-center border-b p-8">
            <div className="flex max-h-[100%] justify-center">
              <img src={GoogleSlidesLogo} />
            </div>
          </div>
          <div className="flex flex-grow flex-col p-3">
            <span className="mb-3 text-xs font-semibold">Google Slides</span>
            <div className="flex flex-col gap-2">
              <a
                href={
                  'https://workspace.google.com/marketplace/app/plus_ai_for_google_slides_and_docs/214277172452'
                }
                target="_blank"
                rel="noreferrer"
                className="w-full"
              >
                <Button variant="primary" className="w-full" onClick={selectGoogleSlidesCallback}>
                  Install add-on
                </Button>
              </a>
              <a
                href={'https://guide.plusdocs.com/plus-ai/install-in-google-slides'}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="secondary" className="w-full">
                  Learn more
                </Button>
              </a>
            </div>
          </div>
        </div>
        <div className="border-divider-light-gray flex w-full flex-col overflow-hidden rounded-md border">
          <div className="border-divider-light-gray bg-background-panel h-[128px] content-center border-b p-8">
            <div className="flex max-h-[100%]">
              <img src={PowerPointLogo} />
            </div>
          </div>
          <div className="flex flex-grow flex-col p-3">
            <span className="mb-3 text-xs font-semibold">PowerPoint</span>
            <div className="flex flex-col gap-2">
              <Button variant="primary" onClick={selectPowerPointCallback}>
                Install add-in
              </Button>
              <a
                href={'https://guide.plusdocs.com/plus-ai/install-in-powerpoint'}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="secondary" className="w-full">
                  Learn more
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <span className="text-base">
        To get started, install the Plus AI extension for Google Slides or PowerPoint.
      </span>
    </div>
  )
}

export const UpgradeScreenTwo = () => (
  <div className="flex flex-col items-center gap-y-3">
    <img src={UpgradeImageTwo} style={{ height: 241, width: 444 }} className="rounded" />
    <div className="text-base">
      Launch the Plus add-on from Google Slides &gt; Extensions &gt; Plus AI &gt; New presentation.{' '}
      <a
        href="https://guide.plusdocs.com/plus-ai"
        target="_blank"
        rel="noreferrer"
        className="text-interactive-primary"
      >
        Learn more
      </a>
    </div>
    <a
      href={'https://workspace.google.com/marketplace/app/plus_ai_for_google_slides/214277172452'}
      className="w-full"
    >
      <Button variant="primary" className="h-[48px] w-full">
        Install the Google Workspace add-on
      </Button>
    </a>
  </div>
)

export const UpgradeScreenThree = () => (
  <div className="flex flex-col items-center gap-y-3">
    <img src={UpgradeImageThree} style={{ height: 241, width: 444 }} className="rounded" />
    <div className="text-base">
      Open PowerPoint. In the <b>Home</b> tab, open <b>Add-ins</b> and search for Plus AI for
      PowerPoint. Click Add to install the add-in.
    </div>
    <a href={'https://guide.plusdocs.com/plus-ai/install-in-powerpoint'} className="w-full">
      <Button variant="primary" className="h-[48px] w-full">
        Show me how
      </Button>
    </a>
  </div>
)

const UpgradeSuccess = (): JSX.Element => {
  const { viewer } = useViewer()
  const [hasHandledReferral, setHasHandledReferral] = React.useState(false)
  const [hasHandledTracking, setHasHandledTracking] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)

  const params = useSearchParams()

  const refId = params.get('refId')
  const source = params.get('source')

  React.useEffect(() => {
    if (refId && !hasHandledReferral) {
      window.rewardful('convert', { email: decodeURIComponent(refId) })

      setHasHandledReferral(true)
    }

    if (!hasHandledTracking && viewer) {
      window.dataLayer.push({
        email: viewer.email,
        event: 'trial_started',
        planFrequency: viewer.currentOrganization?.currentPlan?.frequency,
        planType: viewer.currentOrganization?.currentPlan?.id,
        value: viewer.currentOrganization?.currentPlan?.price,
      })

      setHasHandledTracking(true)
    }
  }, [hasHandledReferral, hasHandledTracking, refId, viewer])

  const sourceFriendlyName = source === 'ppt' ? 'PowerPoint' : 'Google Slides'

  if (source === 'web') {
    return (
      <div className="bg-background-gridBackground flex h-screen w-screen items-center justify-center">
        <div
          className="border-divider-light-gray bg-background-white shadow-soft flex flex-col rounded border sm:hidden"
          style={{ width: 480 }}
        >
          <div className="border-divider-light-gray flex w-full flex-col items-center justify-center border-b px-6 pt-6 pb-4">
            <PlusLogo width="48px" height="48px" />
            <h1 className="text-copy my-1 text-xl font-semibold">Upgrade successful</h1>
          </div>
          <div className="m-6 flex flex-col gap-2 text-base">
            <span>
              Unfortunately, Google Slides and Docs do not support add-ons on mobile devices.
            </span>
            <span>
              To get started, install our add-on for Google Slides and Docs on a desktop computer
            </span>
          </div>
          <div className="px-6 pb-6 pt-2">
            <a href="https://guide.plusdocs.com/plus-ai/installing-plus-ai" className=" w-full">
              <Button variant="primary" className="h-[48px] w-full">
                Show me how
              </Button>
            </a>
          </div>
        </div>
        <div
          className="border-divider-light-gray bg-background-white shadow-soft hidden flex-col rounded border sm:flex"
          style={{ width: 480 }}
        >
          <div className="border-divider-light-gray flex w-full flex-col items-center justify-center border-b px-6 pt-6 pb-4">
            <PlusLogo width="48px" height="48px" />
            <h1 className="text-copy my-1 text-xl font-semibold">Welcome to Plus</h1>
            <span className="text-base">
              {activeStep === 0 && "You've successfully upgraded!"}
              {activeStep === 1 && 'Install and launch the Google Workspance add-on'}
              {activeStep === 2 && 'Install the PowerPoint add-in'}
            </span>
          </div>
          <div className="mx-6 mt-6">
            {activeStep === 0 && <UpgradeScreenOne setStep={setActiveStep} />}
            {activeStep === 1 && <UpgradeScreenTwo />}
            {activeStep === 2 && <UpgradeScreenThree />}
          </div>

          <div className="grid w-full grid-flow-col grid-cols-3 p-6">
            <div className="flex flex-row items-center justify-start">
              <Button
                variant="secondary"
                onClick={() => setActiveStep(activeStep - 1)}
                disabled={activeStep === 0}
              >
                Previous
              </Button>
            </div>
            <div className="flex flex-row items-center justify-center">
              <div className="flex flex-row gap-x-2">
                {new Array(3).fill('').map((_, i) => (
                  <div
                    key={i}
                    className={clsx(
                      'h-[6px] w-[6px] rounded-full',
                      activeStep === i ? 'bg-divider-dark-blue' : 'bg-divider-light-blue',
                    )}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-row items-center justify-end">
              <Button
                variant="secondary"
                onClick={() => setActiveStep(activeStep + 1)}
                disabled={activeStep === 2}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="mt-[33vh] flex h-full w-full flex-col items-center justify-center p-2 text-center">
      <PlusLogo width="32" />
      <p className="text-l mt-3 font-semibold md:text-xl">You&apos;ve successfully upgraded!</p>
      <p className="mt-1 mb-3 text-base">
        Head back to {sourceFriendlyName} to create unlimited presentations.
      </p>
      <Button onClick={() => top && top.window.close()}>Back to {sourceFriendlyName}</Button>
    </div>
  )
}

export default UpgradeSuccess
