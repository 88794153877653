import { getLikelyIdentityHost } from '@plusdocs/utils/common/string'
import clsx from 'clsx'
import { useContext, useMemo } from 'react'

import { ContentV2 } from '@/shared/types'

import { useContentGrid } from '../ContentGrid/useContentGrid'
import { IPopoverMenuItem, PopoverMenu } from '../PopoverMenu/PopoverMenu'
import { CardContext } from './ContentCard'

export const DescriptionArea = ({
  content,
  innerBorderColor,
  textPrimaryColor,
  textSecondaryColor,
  overflowItems,
}: {
  content: ContentV2
  innerBorderColor: string
  textPrimaryColor: string
  textSecondaryColor: string
  overflowItems?: IPopoverMenuItem[]
}): JSX.Element => {
  const { noLocationLine, noFolderLine } = useContext(CardContext)
  const { selectedItems } = useContentGrid()
  const location =
    !noLocationLine && content.__typename !== 'Folder'
      ? [content.team ? 'Team' : 'Personal', content.folder && content.folder.name]
          .filter((str) => !!str)
          .join(' / ')
      : ''

  const subtext = useMemo(() => {
    switch (content.__typename) {
      case 'Document':
        return 'Page'
      case 'Folder':
        return 'Folder'
      case 'Snapshot':
        return typeof content.instructions.url === 'string'
          ? getLikelyIdentityHost(content.instructions.url)
          : 'Snapshot'
      default:
        return ''
    }
  }, [content])

  return (
    <div className={clsx('', innerBorderColor)}>
      <div className="flex flex-col p-2">
        <div className="flex flex-row items-start">
          <div className="flex w-full flex-col">
            {location && !noFolderLine && (
              <div
                className={clsx(
                  'mb-1 justify-end',
                  'pr-1 text-base leading-4',
                  textSecondaryColor,
                  'line-clamp-1',
                )}
              >
                {location}
              </div>
            )}
            <div className={clsx('mb-1 h-8 text-base leading-4', textPrimaryColor, 'line-clamp-2')}>
              {content.name}
            </div>
          </div>
          {overflowItems && (
            <PopoverMenu
              disabled={selectedItems.length > 0}
              items={overflowItems}
              textSecondaryColor={textSecondaryColor}
            />
          )}
        </div>
        <div
          className={clsx('justify-end text-base leading-4', textSecondaryColor, 'line-clamp-1')}
        >
          {subtext}
        </div>
      </div>
    </div>
  )
}
