import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Loader } from '@/shared/components'
import { TrialPlanPicker } from '@/web/components/TrialPlanPicker'
import { useViewer } from '@/web/hooks'

const StartTrial = (): JSX.Element => {
  const [showTrialPicker, setShowTrialPicker] = React.useState(false)
  const { reloadViewer, viewer } = useViewer()
  const history = useHistory()

  const [isHandlingViewer, setIsHandlingViewer] = React.useState(false)

  React.useEffect(() => {
    if (isHandlingViewer || !viewer) {
      return
    }
    setIsHandlingViewer(true)

    if (viewer?.currentOrganization?.id) {
      if (viewer?.currentOrganization?.id && !viewer.currentOrganization.isStripeCustomer) {
        setShowTrialPicker(true)
      } else {
        history.push('/postinstall')
      }
    } else {
      reloadViewer()
        .then((updatedViewer) => {
          const currentOrg = updatedViewer?.data.viewer.currentOrganization
          if (currentOrg?.id && !currentOrg.isStripeCustomer) {
            setShowTrialPicker(true)
          } else {
            history.push('/postinstall')
          }
        })
        .catch(() => {
          history.push('/postinstall')
        })
    }
  }, [
    history,
    setShowTrialPicker,
    reloadViewer,
    viewer,
    viewer?.currentOrganization,
    isHandlingViewer,
    setIsHandlingViewer,
  ])

  if (showTrialPicker) {
    return <TrialPlanPicker />
  }

  return (
    <div className="bg-background-gridBackground flex h-screen w-screen items-center justify-center">
      <div className="m-auto">
        <Loader />
      </div>
    </div>
  )
}

export default StartTrial
