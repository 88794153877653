// sanity.js
import { createClient } from '@sanity/client'
// Import using ESM URL imports in environments that supports it:
// import {createClient} from 'https://esm.sh/@sanity/client'

export const sanityClient = createClient({
  apiVersion: '2023-05-03',
  dataset: 'production',
  projectId: 'q92hqyrq',
  useCdn: true,
})
