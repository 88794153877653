import {
  ScrollArea,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '@radix-ui/react-scroll-area'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { useHistory, useRouteMatch } from 'react-router-dom'

import {
  ContentType,
  OrderDirection,
  SearchQueryVariables,
  SearchResultOrderField,
  SnapshotFieldsFragment,
} from '@/generated/graphql'
import { LoadingThumbnail, Thumbnail } from '@/shared/components/Thumbnail/Thumbnail'
import { remainingItemsToLoad } from '@/util'
import { useKeyboardShortcuts, useViewer } from '@/web/hooks'
import useSearch from '@/web/hooks/useSearch'

export interface IBatchSnapshotViewerProps {
  snapshot: SnapshotFieldsFragment
}

export const BatchSnapshotViewer = memo(({ snapshot }: IBatchSnapshotViewerProps) => {
  const { activeOrganization } = useViewer()

  const currentSnapshotId = snapshot.id
  const folder = snapshot.folder?.id
  const team = snapshot.team?.id

  const { ref: inViewRef, inView } = useInView()

  const searchInput: SearchQueryVariables = useMemo(
    () => ({
      filter: {
        // TODO: Remove once graphQL teams-safety is added
        contentIOwn: team ? false : true,
        contentTypes: [ContentType.Snapshot],
        folders: folder ? [folder] : [],
        teams: team ? [team] : [],
      },
      first: 50,
      sort: {
        direction: OrderDirection.Desc,
        field: SearchResultOrderField.CreatedAt,
      },
    }),
    [folder, team],
  )

  const { loading, nodes, loadMore, loadingMore, counts } = useSearch(
    searchInput,
    activeOrganization?.urn,
  )

  const history = useHistory()
  const routeMatch = useRouteMatch('/:organizationSlug/snapshot/')

  const onThumbnailSelect = useCallback(
    (id: string) => {
      history.push(`${routeMatch?.url}/${id}`)
    },
    [history, routeMatch?.url],
  )

  useEffect(() => {
    document.getElementById(currentSnapshotId)?.focus()
    document.getElementById(currentSnapshotId)?.blur()
  }, [currentSnapshotId])

  const moveDown = () => {
    const currentSnapshotIndex = nodes?.findIndex((node) => node.id === currentSnapshotId)
    if (nodes[currentSnapshotIndex + 1]) {
      onThumbnailSelect(nodes[currentSnapshotIndex + 1].id)
    }
  }

  const moveUp = useCallback(() => {
    const currentSnapshotIndex = nodes?.findIndex((node) => node.id === currentSnapshotId)
    if (nodes[currentSnapshotIndex - 1]) {
      onThumbnailSelect(nodes[currentSnapshotIndex - 1].id)
    }
  }, [currentSnapshotId, onThumbnailSelect, nodes])

  useKeyboardShortcuts(
    [
      {
        keys: ['J'],
        onEvent: moveDown,
      },
      {
        keys: ['ArrowDown'],
        onEvent: moveDown,
      },
      {
        keys: ['ArrowUp'],
        onEvent: moveUp,
      },
      {
        keys: ['K'],
        onEvent: moveUp,
      },
    ],
    true,
    [nodes, currentSnapshotId],
    'keydown',
  )

  useEffect(() => {
    if (inView && !loadingMore) {
      loadMore()
    }
  }, [inView, loadMore, loadingMore])

  const loadingCardsToShow = remainingItemsToLoad(50, nodes.length, counts?.snapshots ?? 0)

  return (
    <ScrollArea>
      <ScrollAreaViewport className="border-divider-light-gray bg-background-panel relative flex h-full w-[72px] flex-col items-center justify-between border-r">
        <div className="absolute grid grid-flow-row grid-cols-1 gap-2 p-3">
          {nodes.map((node, index) => (
            <Thumbnail
              key={node.id}
              ref={nodes.length - 5 === index ? inViewRef : undefined}
              snapshot={node as SnapshotFieldsFragment}
              isSelected={node.id === currentSnapshotId}
              onSelect={() => onThumbnailSelect(node.id)}
            />
          ))}
          {(loading || loadingMore) && loadingCardsToShow > 0
            ? [...Array(loadingCardsToShow)].map((_, i) => <LoadingThumbnail key={i} />)
            : null}
        </div>
      </ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="vertical" className="p-1">
        <ScrollAreaThumb className="text-divider-dark-gray rounded border-2" />
      </ScrollAreaScrollbar>
    </ScrollArea>
  )
})
BatchSnapshotViewer.displayName = 'BatchSnapshotViewer'
