import clsx from 'clsx'

type Props = {
  className?: string
  size?: number
}

const Snapshot = (props: Props): JSX.Element => {
  const size = props.size || 16

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 16 16`}
      fill="none"
      className={clsx('fill-current', props.className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.6667 4.66667C14.9554 4.66667 16 3.622 16 2.33333C16 1.04467 14.9554 0 13.6667 0C12.378 0 11.3334 1.04467 11.3334 2.33333C11.3334 3.622 12.378 4.66667 13.6667 4.66667Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33337 2.66667C2.96518 2.66667 2.66671 2.96514 2.66671 3.33333V7.72386L4.52864 5.86193C4.78899 5.60158 5.2111 5.60158 5.47145 5.86193L9.00004 9.39052L10.5286 7.86193C10.789 7.60158 11.2111 7.60158 11.4714 7.86193L13.3334 9.72386V6.66667C13.3334 6.29848 13.6319 6 14 6C14.3682 6 14.6667 6.29848 14.6667 6.66667V12.6667C14.6667 13.7712 13.7713 14.6667 12.6667 14.6667H3.33337C2.2288 14.6667 1.33337 13.7712 1.33337 12.6667V3.33333C1.33337 2.22876 2.2288 1.33333 3.33337 1.33333H9.33337C9.70156 1.33333 10 1.63181 10 2C10 2.36819 9.70156 2.66667 9.33337 2.66667H3.33337ZM2.66671 12.6667V9.60948L5.00004 7.27614L8.52864 10.8047C8.78899 11.0651 9.2111 11.0651 9.47145 10.8047L11 9.27614L13.3334 11.6095V12.6667C13.3334 13.0349 13.0349 13.3333 12.6667 13.3333H3.33337C2.96518 13.3333 2.66671 13.0349 2.66671 12.6667Z"
      />
    </svg>
  )
}

export default Snapshot
