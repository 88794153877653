import { ApolloClient } from '@apollo/client'
import clsx from 'clsx'
import { Settings } from 'react-feather'
import { Link } from 'react-router-dom'

import {
  ContentType,
  useOnContentCreatedSubscription,
  useOnContentDeletedSubscription,
  ViewerNavDataDocument,
  ViewerNavDataQuery,
} from '@/generated/graphql'
import { Button } from '@/shared/components'
import { showModal } from '@/util'
import { useViewer } from '@/web/hooks'

import ReferralsModal from '../ReferralsModal'

const setUpdatedUsage =
  (delta: number) =>
  ({ client }: { client: ApolloClient<object> }) =>
    client.cache.updateQuery<ViewerNavDataQuery>(
      {
        query: ViewerNavDataDocument,
      },
      (data) => {
        if (!data || !data.viewer.usage?.snapshots) return data

        return {
          ...data,
          viewer: {
            ...data.viewer,
            usage: {
              ...data.viewer.usage,
              snapshots: data.viewer.usage.snapshots + delta,
            },
          },
        }
      },
    )

const useUpdateUsageSubscription = (urn: string | undefined) => {
  // The API requires one of `contentIOwn`, `teams` or `folders` to be defined for content subscriptions
  // As such, we're creating two subscriptions for creation and deletion: One for content owned, and one for not.
  // Maybe the API just shouldn't have this restriction? (Could maybe make it okay if `contentTypes` is specified?)
  useOnContentCreatedSubscription({
    onData: setUpdatedUsage(1),
    skip: typeof urn !== 'string',
    variables: {
      filter: {
        contentIOwn: true,
        contentTypes: [ContentType.Snapshot],
      },
      urn: urn ?? '',
    },
  })

  useOnContentDeletedSubscription({
    onData: setUpdatedUsage(-1),
    skip: typeof urn !== 'string',
    variables: {
      filter: {
        contentIOwn: true,
        contentTypes: [ContentType.Snapshot],
      },
      urn: urn ?? '',
    },
  })
}

export const SnapshotLimit = () => {
  const { activeOrganization, viewer } = useViewer()

  const snapshots = viewer?.usage?.snapshots ?? 0
  const quota = viewer?.settings?.quotas?.snapshots ?? 5
  const usage = Math.min(snapshots / quota, 1)
  const showUpgradeMessage = quota - snapshots <= 5
  const orgOwner = activeOrganization?.users.nodes.find(
    (u) => u.id === activeOrganization.createdBy.id,
  )

  useUpdateUsageSubscription(activeOrganization?.urn)

  const showReferralsModal = () => {
    void showModal(<ReferralsModal />)
  }

  return (
    <div className="border-divider-light-gray bg-interactive-secondary w-full rounded border">
      <div className="bg-divider-light-gray h-1.5 rounded-t">
        <div
          className={clsx(
            'from-base-highlightB to-base-highlightC h-full rounded-tl bg-gradient-to-r',
            {
              'rounded-tr': usage === 1,
            },
          )}
          style={{ width: `${usage * 100}%` }}
        />
      </div>
      <div className="flex flex-col gap-y-2 p-3">
        <div className="text-copy flex flex-row items-center justify-between text-base font-semibold">
          <span>
            {snapshots} / {quota} Snapshots used
          </span>
          {activeOrganization?.viewer.isOwner && (
            <Link to={`/${activeOrganization?.slug ?? ''}/settings/organization`}>
              <Settings
                size={16}
                className="text-copy-secondary hover:text-interactive-dark-hover"
              />
            </Link>
          )}
        </div>
        {showUpgradeMessage && activeOrganization?.viewer.isOwner && (
          <>
            <div className="text-copy-secondary text-base">
              Upgrade to unlock more Snapshots and AI features.
            </div>
            <Link
              to={`/${activeOrganization?.slug ?? ''}/settings/organization`}
              className="w-full"
            >
              <Button className="w-full" variant="upgrade-gradient">
                Upgrade plan
              </Button>
            </Link>
          </>
        )}
        {showUpgradeMessage && !activeOrganization?.viewer.isOwner && (
          <div className="text-copy-secondary text-base">
            Contact your org owner (
            <a
              className="text-interactive-primary hover:text-interactive-primary-hover active:text-interactive-primary-active"
              href={`mailto:${orgOwner?.email ?? ''}`}
            >
              {orgOwner?.name}
            </a>
            ) about upgrading for more Snapshots.
          </div>
        )}

        <Button className="w-full" variant="secondary" onClick={showReferralsModal}>
          Refer a friend
        </Button>
      </div>
    </div>
  )
}
