import { useReactiveVar } from '@apollo/client'
import {
  ScrollArea,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '@radix-ui/react-scroll-area'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import {
  CaptureStatus,
  ConnectionStatus,
  IdentityQueryVariables,
  OrderDirection,
  SnapshotFieldsFragment,
} from '@/generated/graphql'
import { feedClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Connection as ConnectionIcon, Loader } from '@/shared/components'
import { BaseGrid } from '@/shared/components/ContentGrid/ContentGrid'
import { ContentGridProvider } from '@/shared/components/ContentGrid/useContentGrid'
import { useIdentity } from '@/shared/hooks/useIdentity'
import { getSnapshotStatus } from '@/util'
import { BasePage } from '@/web/BasePage'
import ConnectionStatusPill, {
  getConnectionStatusColorClassName,
  getConnectionStatusColors,
} from '@/web/components/Connections/ConnectionStatusPill'
import FeedHeaderView from '@/web/components/Feed/FeedHeaderView'
import FeedSortMenu from '@/web/components/Feed/FeedSortMenu'

import LearnMoreAboutConnectionsLink from '../components/Connections/LearnMoreAboutConnectionsLink'
import ReconnectButton from '../components/Connections/ReconnectButton'

const MoreInfoPanel = ({
  className,
  connectionStatus,
  domainName,
  snapshotToReconnect,
}: {
  readonly className?: string
  readonly connectionStatus: ConnectionStatus
  readonly domainName: string
  readonly snapshotToReconnect: SnapshotFieldsFragment
}): JSX.Element => {
  return (
    <div
      className={clsx(
        'border-divider-light-gray bg-background-white flex w-full gap-3 rounded-md border p-3 text-base',
        className,
      )}
    >
      <div
        className={clsx(
          'flex shrink-0 rounded-sm',
          getConnectionStatusColorClassName(connectionStatus),
        )}
        style={{ height: 92, width: 92 }}
      >
        <ConnectionIcon size={32} className="m-auto" />
      </div>
      <div className="flex flex-col justify-between gap-1">
        <div
          className={clsx(
            'font-semibold',
            connectionStatus === ConnectionStatus.Disconnected &&
              getConnectionStatusColors(connectionStatus).textColor,
          )}
        >
          Reconnect Plus to {domainName}
        </div>
        <div>
          Some or all of the Snapshots using this connection have been paused because the connection
          to {domainName} expired. More Snapshots will likely be paused as they refresh. Click
          “Reconnect” to fix this issue for all Snapshots using this connection.
        </div>
        <ReconnectButton
          className="mr-auto"
          connectionStatus={connectionStatus}
          snapshotToReconnect={snapshotToReconnect}
        />
      </div>
    </div>
  )
}

export const Connection = (): JSX.Element => {
  const { identityId } = useParams<{ readonly identityId: string }>()
  const { sortField } = useReactiveVar(feedClientStateVar)

  const queryInput: IdentityQueryVariables = useMemo(
    () => ({
      first: 50,
      id: identityId,
      sort: sortField ? { direction: OrderDirection.Asc, field: sortField } : undefined,
    }),
    [identityId, sortField],
  )

  const { identity, isLoading, snapshots } = useIdentity(queryInput)

  const firstSnapshot = snapshots.at(0)
  const firstLoggedOutSnapshot =
    snapshots.find(
      (snapshot) => snapshot.latestVersion?.status === CaptureStatus.ErrorSessionExpired,
    ) ?? snapshots.find((snapshot) => getSnapshotStatus(snapshot).snapshotSessionExpired)

  const snapshotToReconnect = firstLoggedOutSnapshot ?? firstSnapshot

  const connectionStatus = identity?.connectionStatus
  const isReconnectionNeeded =
    connectionStatus === ConnectionStatus.Deteriorating ||
    connectionStatus === ConnectionStatus.Disconnected

  const domainName = identity?.domain

  const domainNameSubheading = (
    <>
      {domainName && <span>{domainName}</span>}
      {connectionStatus && (
        <ConnectionStatusPill status={connectionStatus} className="ml-2 align-middle" />
      )}
    </>
  )

  const subtitle = (
    <div className="text-copy-secondary w-full text-xs">
      {domainName && (
        <span>
          Viewing all Snapshots associated with your connection to{' '}
          <span className="text-copy">{domainName}</span>.
        </span>
      )}{' '}
      <LearnMoreAboutConnectionsLink learnMoreText="Learn more about connections." />
    </div>
  )

  const headerButtons = (
    <>
      <FeedSortMenu />
      {connectionStatus && snapshotToReconnect && (
        <ReconnectButton
          connectionStatus={connectionStatus}
          snapshotToReconnect={snapshotToReconnect}
        />
      )}
    </>
  )

  return (
    <ContentGridProvider itemsInView={snapshots}>
      <BasePage>
        <Helmet title="Connections" />
        <div className="bg-background-gridBackground flex h-full w-full grow flex-col items-start lg:grow-0">
          <div className="flex h-full w-full flex-col gap-3">
            <div className="flex w-full flex-col items-center">
              <FeedHeaderView
                buttonBarChildren={headerButtons}
                explanatoryText={subtitle}
                heading="Connections"
                ignoreMarginBottom={true}
                subheading={domainNameSubheading}
              />
            </div>

            {isReconnectionNeeded && domainName && snapshotToReconnect && (
              <div className="flex w-full flex-col items-center">
                <div className="w-full px-4 lg:max-w-7xl lg:px-6">
                  <MoreInfoPanel
                    connectionStatus={connectionStatus}
                    domainName={domainName}
                    snapshotToReconnect={snapshotToReconnect}
                  />
                </div>
              </div>
            )}

            <ScrollArea className="h-full w-full">
              <ScrollAreaViewport className="relative h-full w-full overscroll-contain">
                <div className="flex flex-col items-center">
                  <div className="flex h-full w-full flex-col items-center lg:max-w-7xl">
                    {isLoading && snapshots.length === 0 ? (
                      <div className="absolute h-full w-full">
                        <div className="flex h-full flex-row items-center">
                          <div className="m-auto">
                            <Loader />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <BaseGrid contents={[snapshots]} disableSelection hideSectionHeaders />
                    )}
                  </div>
                </div>
              </ScrollAreaViewport>
              <ScrollAreaScrollbar orientation="vertical" className="p-1">
                <ScrollAreaThumb className="text-divider-dark-gray rounded border-2" />
              </ScrollAreaScrollbar>
            </ScrollArea>
          </div>
        </div>
      </BasePage>
    </ContentGridProvider>
  )
}
