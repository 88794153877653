import { nanoid } from '@reduxjs/toolkit'

import { PageStructureV1Input } from '@/generated/graphql'
import { FONT_SIZES, LINE_HEIGHTS } from '@/web/constants'

function generatePage(): PageStructureV1Input[] {
  const pageId = nanoid()
  const textObjectId = nanoid()
  return [
    {
      canvasSize: {
        height: 1080,
        width: 1920,
      },
      createdAt: new Date().toJSON(),
      id: pageId,
      index: 1,
      objects: [
        {
          content: {
            fontFamily: 'Inter',
            fontSize: FONT_SIZES.LARGE,
            fontStyle: 'bold',
            hasResized: true,
            horizontalAlign: 'left',
            lineHeight: LINE_HEIGHTS.LARGE,
            text: '',
            textDecoration: '',
            verticalAlign: 'top',
          },
          createdAt: new Date().toJSON(),
          id: textObjectId,
          position: {
            h: 0.0425,
            r: 0,
            w: 0.35,
            x: 0.00521,
            y: 0.00521,
            z: 0,
          },
          temp: {},
          type: 'Text',
          updatedAt: new Date().toJSON(),
        },
      ],
      updatedAt: new Date().toJSON(),
    },
  ]
}

export default generatePage
