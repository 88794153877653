// We want to try and guess the part of the hostname that shares an identity. Event
// if we checked against a list of tlds (huge) we wouldn't know which ones share
// session data across subdomains or across domains.

// Eg:
// - For google that's google.com instead of mail.google.com / docs.google.com / etc
// - Many sites have their main saas product on a different subdomain to
//   their marketing page
// - Most sites have a single level of subdomain. docs.google.com and not project.docs.google.com,
// so leaving the subdomain in the hostname is probably the best option if

// We're looking for a best guess. In v1 of identities users can disconnect if
// we're over-eager and reauth each snapshot independently, and in v2 they'll be
// able to customize each identity.
export const getLikelyIdentityHost = (url: string): string => {
  const host = new URL(url).host
  const parts = host.split('.')

  // Sites aren't likely to have authentication on x.www.site.com
  // but they are likely to share credentials across //domain.com and //www.domain.com
  if (parts[0] === 'www') {
    // remove the wwww. subdomain
    return parts.slice(1).join('.')
  }

  // common tlds for our user base that are likely to have shared session data
  // across subdomains
  if (['com', 'net', 'io', 'org'].includes(parts[parts.length - 1])) {
    // return whatever.com
    return parts.slice(-2).join('.')
  }

  // For other cases just return the full hostname that may include subdomains
  return parts.join('.')
}

export const idFromPk = (pk?: string): string => {
  return pk ? pk.split(':')[1] : ''
}

export const pkFromId = (pk: string, id?: string): string => {
  return id ? `${pk}:${id}` : ''
}

/**
 * Tests whether the specified string is 'valid', which means that it is not undefined, null, empty, or consists of
 * entirely whitespace.
 */
export const isValidString = (s: string | undefined | null): s is string => {
  return typeof s === 'string' && s.trim().length > 0
}

export const ensureLessThan128Chars = (s: string) =>
  s.length >= 128 ? s.substring(s.length - 128) : s

export const isLessThanGivenKiloBytes = (input: string, maxSizeInKB = 128) => {
  const byteSize = Buffer.byteLength(input, 'utf8') // bytes
  const sizeInKB = byteSize / 1024 // kilobytes

  return sizeInKB < maxSizeInKB
}
