import {
  Dialog as DialogRoot,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from '@radix-ui/react-dialog'
import cx from 'clsx'
import * as React from 'react'

type Props = {
  children: React.ReactNode
  isOpen: boolean
  setIsOpen(isOpen: boolean): void
  showBackgroundOverlay?: boolean
  isToast?: boolean
}

const Dialog = (props: Props): JSX.Element => {
  const { children, isOpen, setIsOpen, showBackgroundOverlay, isToast } = props

  return (
    <DialogRoot open={isOpen} onOpenChange={setIsOpen} modal={!isToast}>
      <DialogPortal>
        <DialogContent
          className={cx(
            'fixed inset-0 overflow-y-hidden',
            !showBackgroundOverlay && 'pointer-events-none',
          )}
        >
          {showBackgroundOverlay && (
            <DialogOverlay
              className="bg-background-overlay fixed inset-0 transition-opacity"
              onClick={() => setIsOpen(false)}
            />
          )}

          <div className="flex h-full items-center justify-center px-4 py-4 text-center sm:block">
            <span className="hidden sm:inline-block sm:h-full sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            {children}
          </div>
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  )
}

export default Dialog
