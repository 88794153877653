import { Redirect, Route, RouteProps, useHistory, useParams } from 'react-router-dom'

import { useAuth } from '@/shared/auth/useAuth'
import { FullPageLoader } from '@/web/components/FullPageLoader'
import NotFound from '@/web/routes/NotFound'

import { useViewer } from '../hooks'
import { ActiveOrgHandler } from './ActiveOrgHandler'

// Middleware for a route which requires use to be a member of an organization
export const OrgAuthMiddlewareRoute = ({
  component: Component,
  ...rest
}: RouteProps): JSX.Element => {
  if (!Component) return <></>

  return (
    <Route
      {...rest}
      render={(props) => (
        <ActiveOrgHandler>
          <OrgMemberMiddleware>
            <Component {...props} />
          </OrgMemberMiddleware>
        </ActiveOrgHandler>
      )}
    />
  )
}

const OrgMemberMiddleware = (props: { children: JSX.Element }): JSX.Element => {
  const history = useHistory()
  const { loading, viewer } = useViewer()
  const auth = useAuth()
  const { organizationSlug } = useParams<{ organizationSlug?: string }>()

  if (!auth.isLoggedIn()) {
    return <Redirect to={{ pathname: '/login', state: { from: history.location } }} />
  }

  if (!loading && viewer && viewer.organizations.length === 0) {
    if (viewer.invitations.length > 0) {
      return <Redirect to="/invitations" />
    } else {
      return <Redirect to="/onboarding" />
    }
  }

  if (loading || !viewer) {
    return (
      <div className="m-auto">
        <FullPageLoader loading={true} />
      </div>
    )
  }

  if (organizationSlug && !viewer?.organizations.find((x) => x?.slug === organizationSlug)) {
    return <NotFound />
  }

  return props.children
}
