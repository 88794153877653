import clsx from 'clsx'
import { Dispatch, SetStateAction } from 'react'
import { Link, Menu } from 'react-feather'

import { Snapshot, SnapshotFieldsFragment } from '@/generated/graphql'
import { Button, DesktopDisplayOnly, MobileDisplayOnly } from '@/shared/components'
import { SnapshotVersionHookReturnType } from '@/shared/hooks/useSnapshotVersion'
import { noop } from '@/util/fp'
import { DesktopSnapshotHeader } from '@/web/components/SnapshotViewer/DesktopSnapshotHeader'
import { MobileSnapshotHeader } from '@/web/components/SnapshotViewer/MobileSnapshotHeader'
import { useSnapshotDelete } from '@/web/hooks/useSnapshotDelete'

import { useMoveToFolderCallback } from '../Feed/actions'

// TODO-PLUS-2296: Remove this once we upgrade to the newest version of type-fest. This is copied from the
// implementation. https://github.com/sindresorhus/type-fest/blob/main/source/set-non-nullable.d.ts
type SetNonNullable<BaseType, Keys extends keyof BaseType = keyof BaseType> = {
  [Key in keyof BaseType]: Key extends Keys ? NonNullable<BaseType[Key]> : BaseType[Key]
}

type SnapshotHeaderProps = {
  readonly sidebarOpen: boolean
  readonly setSidebarOpen: Dispatch<SetStateAction<boolean>>
  readonly snapshotEditingAllowed: boolean
  readonly snapshot: SnapshotFieldsFragment | null | undefined
  readonly snapshotVersion: SnapshotVersionHookReturnType['snapshotVersion']
}

const LoadingPlaceholder = ({ isEditor }: { isEditor: boolean }) => (
  <>
    <MobileDisplayOnly className="flex w-full flex-col">
      <div className="border-divider-light-gray flex h-12 w-full flex-row items-center justify-between p-3 md:border-b" />
    </MobileDisplayOnly>
    <DesktopDisplayOnly>
      <div className="border-divider-light-gray bg-background-panel flex h-12 w-full items-center justify-between border-b p-3">
        <div className="mr-2 flex items-center gap-1 text-base">
          <Button icon={<Menu size={16} />} variant="ghost" aria-hidden="true" disabled />
        </div>

        <div className="flex flex-row" data-star-tours-id="share-button">
          <Button
            className={clsx('!p-0 text-base', {
              'border-r-interactive-dark-active !rounded-l !rounded-r-none border-r': isEditor,
            })}
            variant="secondary-dark"
            onClick={noop}
            disabled
          >
            <span className="p-2">{isEditor ? 'Share' : 'Copy link'}</span>
          </Button>
          {isEditor && (
            <Button
              className="!rounded-r !rounded-l-none border-l-0 !p-0 text-base active:!border-l-0"
              variant="secondary-dark"
              onClick={noop}
              disabled
            >
              <span className="p-2">
                <Link size={16} />
              </span>
            </Button>
          )}
        </div>
      </div>
    </DesktopDisplayOnly>
  </>
)

const LoadedSnapshotHeader = ({
  setSidebarOpen,
  sidebarOpen,
  snapshot,
  snapshotEditingAllowed,
  snapshotVersion,
}: SetNonNullable<SnapshotHeaderProps, 'snapshot'>): JSX.Element => {
  const toggleSidebar = () => {
    setSidebarOpen((value: boolean) => !value)
  }

  const deleteSnapshotCallback = useSnapshotDelete(snapshot)
  const moveSnapshotCallback = useMoveToFolderCallback([snapshot as unknown as Snapshot])

  return (
    <>
      <DesktopSnapshotHeader
        snapshot={snapshot}
        snapshotVersion={snapshotVersion}
        snapshotEditingAllowed={snapshotEditingAllowed}
        isSidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        deleteSnapshotCallback={deleteSnapshotCallback}
        moveSnapshotCallback={moveSnapshotCallback}
      />

      <MobileSnapshotHeader
        snapshot={snapshot}
        snapshotVersion={snapshotVersion}
        snapshotEditingAllowed={snapshotEditingAllowed}
        deleteSnapshotCallback={deleteSnapshotCallback}
        moveSnapshotCallback={moveSnapshotCallback}
      />
    </>
  )
}

export const SnapshotHeader = (props: SnapshotHeaderProps): JSX.Element => {
  if (!props.snapshot) {
    return <LoadingPlaceholder isEditor={props.snapshotEditingAllowed} />
  }

  return <LoadedSnapshotHeader {...props} snapshot={props.snapshot} />
}
