import { buildTour, ComponentProps } from '@plusdocs/star-tours'
import { capitalize } from 'lodash-es'
import { Check, RefreshCw, X } from 'react-feather'

import { PlanTier, SnapshotRefreshRate } from '@/generated/graphql'
import { Button, Snapshot } from '@/shared/components'
import RocketPlus from '@/web/assets/rocket_plus.png'
import { useViewer } from '@/web/hooks'

const BaseTourStepComponent = (
  props: ComponentProps & {
    children: React.ReactNode
    title: string
  },
) => {
  return (
    <div
      className="border-divider-light-gray bg-background-white shadow-soft flex flex-col gap-y-3 rounded border p-3"
      style={{ width: 480 }}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <h1 className="text-popover-small text-copy font-semibold">{props.title}</h1>
        <Button variant="ghost" onClick={props.endTour}>
          <X size={16} />
        </Button>
      </div>
      {props.children}
    </div>
  )
}

const UpgradedStep = (props: ComponentProps) => {
  const { activeOrganization, viewer } = useViewer()
  const currentPlan = viewer?.availablePlans.find(
    (p) => p.id === activeOrganization?.currentPlan?.id,
  )
  const tier = capitalize(currentPlan?.tier ?? PlanTier.Team)

  const fastestRefreshRate = viewer?.settings?.refreshRates.includes(
    SnapshotRefreshRate.QuarterHourly,
  )
    ? '15 minute'
    : viewer?.settings?.refreshRates.includes(SnapshotRefreshRate.Hourly)
    ? '1 hour'
    : '6 hours'

  const presentationLimit = viewer?.settings?.quotas?.presentations ?? 3
  const snapshotLimit = viewer?.settings?.quotas?.snapshots

  return (
    <BaseTourStepComponent {...props} title={`Welcome to Plus ${tier}!`}>
      <div className="flex flex-col items-center gap-y-3">
        <img src={RocketPlus} className="shadow-soft rounded" style={{ height: 227, width: 454 }} />
        <p className="text-copy text-base">
          Thank you for your purchase! As part of your {tier} subscription, you now have access to:
        </p>
        <div className="flex flex-row">
          <div className="flex w-full flex-col gap-y-1">
            <div className="text-copy flex flex-row gap-x-2 text-base font-semibold">
              <Check size={16} />
              {presentationLimit > 3 ? 'Unlimited' : presentationLimit} AI presentations
            </div>
            <p className="text-copy-secondary text-base">
              Generate {presentationLimit > 3 ? 'Unlimited' : presentationLimit} presentations using
              our presentation slides generator.
            </p>
          </div>
          <div className="flex w-full flex-col gap-y-1">
            <div className="text-copy flex flex-row gap-x-2 text-base font-semibold">
              <RefreshCw size={16} />
              {fastestRefreshRate} refresh
            </div>
            <p className="text-copy-secondary text-base">
              Expanded refresh options let you set any Snapshot to refresh as quickly as every{' '}
              {fastestRefreshRate}
            </p>
          </div>
          <div className="flex w-full flex-col gap-y-1">
            <div className="text-copy flex flex-row gap-x-2 text-base font-semibold">
              <Snapshot className="h-4 w-4" />
              {snapshotLimit} Snapshots
            </div>
            <p className="text-copy-secondary text-base">
              Capture up to {snapshotLimit} Snapshots across your organization.
            </p>
          </div>
        </div>
        <p className="text-copy text-base">
          You can always change or modify your subscription by returning to Settings. We hope you
          enjoy our paid features!
        </p>
        <Button className="w-full !p-6" onClick={props.endTour} variant="upgrade-gradient">
          Get started
        </Button>
      </div>
    </BaseTourStepComponent>
  )
}

export default buildTour([
  {
    anchor: { type: 'screen' },
    component: UpgradedStep,
  },
])
