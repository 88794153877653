import * as React from 'react'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { useAuth } from '@/shared/auth/useAuth'
import { Button, GoogleLogo, Loader, PlusLogo } from '@/shared/components'
import MicrosoftLogo from '@/shared/components/MicrosoftLogo'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { useViewer } from '@/web/hooks'

const LoginPreinstall = (): JSX.Element => {
  const {
    finishLoginWithOAuth,

    isLoggedIn,
    startLoginWithOAuth,
  } = useAuth()
  const { reloadViewer } = useViewer()
  const history = useHistory()
  const location = useLocation<{
    from: { pathname: string; search: string; state: undefined | Record<string, unknown> }
    showResendConfirmation?: boolean
  }>()
  const { from } = location.state || { from: { pathname: '/', search: '' } }
  const params = useSearchParams()

  const loginWithGoogle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.localStorage.setItem('location-state', JSON.stringify(from))
    startLoginWithOAuth(true, 'Google')
  }

  const loginWithMicrosoft = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.localStorage.setItem('location-state', JSON.stringify(from))
    startLoginWithOAuth(true, 'Microsoft')
  }

  const oAuthCode = params.get('code')
  const hasOAuthError = params.get('error')
  const oAuthErrorMessage = hasOAuthError
    ? params.get('error_description')?.replace('PreSignUp failed with error ', '')
    : ''
  const needsGoogleReauth = oAuthErrorMessage?.includes(
    'Already found an entry for username google_',
  )

  useEffect(() => {
    if (needsGoogleReauth) {
      // this only happens when linking an SSO account to a regular account
      // when we see this, we just want to do the redirect to the SSO page one more time, because the second time works
      // yes. this is the best solution. no, you cannot fix this on the backend. people have been asking AWS to fix this since 2017:
      // https://stackoverflow.com/questions/47815161/cognito-auth-flow-fails-with-already-found-an-entry-for-username-facebook-10155
      // and yet, they still haven't fixed it. sorry! thankfully, users would literally only see this once in their account's lifetime.
      return startLoginWithOAuth(true, 'Google')
    }
    if (oAuthCode) {
      finishLoginWithOAuth(oAuthCode)
        .then(() => reloadViewer?.())
        .then((viewer) => {
          if (ReactGA.isInitialized) {
            const isSignup = typeof viewer?.data?.viewer.user?.id !== 'string'
            ReactGA.gtag('event', isSignup ? 'sign_up' : 'login', {
              method: 'Google',
            })
          }

          const localStorageLocationState = JSON.parse(
            window.localStorage.getItem('location-state') ?? '',
          ) ?? { state: {} }

          const searchParams = new URLSearchParams(localStorageLocationState['search'])

          history.replace({
            ...from,
            ...localStorageLocationState,
            pathname: decodeURIComponent(searchParams.get('route') || '') || from.pathname,
            state: {
              ...from.state,
              ...localStorageLocationState.state,
            },
          })
        })
        .catch(() => {
          // no-op
        })
    }
  }, [
    finishLoginWithOAuth,
    from,

    history,
    needsGoogleReauth,
    oAuthCode,
    reloadViewer,
    startLoginWithOAuth,
    params,
  ])

  if (isLoggedIn()) {
    return (
      <Redirect
        to={{
          ...from,
          state: {
            ...from.state,
          },
        }}
      />
    )
  }

  if (oAuthCode || needsGoogleReauth) {
    return (
      <div className="m-auto">
        <Loader />
      </div>
    )
  }

  return (
    <>
      <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
        <div className="flex w-full flex-col items-center justify-center gap-1">
          <PlusLogo width="48" height="48" />
          <h1 className="text-center text-xl font-semibold">Welcome to Plus AI</h1>
        </div>
      </div>
      <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
        {hasOAuthError && (
          <div className="text-copy-alert w-full gap-x-2 self-center p-2 text-center text-xs font-bold">
            <p>{oAuthErrorMessage}</p>
          </div>
        )}

        <Button
          className="w-full gap-x-2"
          variant="secondary"
          onClick={loginWithGoogle}
          icon={<GoogleLogo />}
        >
          Sign in with Google
        </Button>
        <Button
          className="w-full gap-x-2"
          variant="secondary"
          onClick={loginWithMicrosoft}
          icon={<MicrosoftLogo />}
        >
          Sign in with Microsoft
        </Button>
        <Button
          variant="ghost"
          className="text-copy-secondary font-medium"
          onClick={() => history.push('/register')}
        >
          Sign up with email
        </Button>
      </div>
    </>
  )
}

export default LoginPreinstall
