import { findOrThrow } from '@plusdocs/utils/common/arrays'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Prompt, useHistory, useParams } from 'react-router-dom'

import {
  ContentAccessLevel,
  ContentType,
  Snapshot,
  useSnapshotLazyQuery,
} from '@/generated/graphql'
import { contentPrivacyStateVar } from '@/shared/apollo/apolloLocalState'
import { DesktopDisplayOnly } from '@/shared/components'
import SnapshotVersionNavigationBar from '@/shared/components/SnapshotVersionNavigationBar'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { useTrackContentView } from '@/shared/hooks/useTrackContentView'
import { ScreenSize } from '@/shared/types'
import type { ObjectPosition, PageObject } from '@/shared/types/document'
import { getScreenSize } from '@/util'
import flushTextChanges from '@/util/flushTextChanges'
import { scaledToRelative } from '@/util/transformPositions'
import { Header, Sidebar } from '@/web/components/Editor'
import { FullPageLoader } from '@/web/components/FullPageLoader'
import { Canvas } from '@/web/components/Page'
import {
  EDITABLE_TAGS,
  ShortcutEvent,
  useAppDispatch,
  useAppSelector,
  useElementSizer,
  useKeyboardShortcuts,
} from '@/web/hooks'
import {
  initEditorAppThunk,
  matchSidebarState,
  resetAppState,
  selectCanvas,
  selectLoading,
  selectSelectedObjects,
  updateCanvasSize,
  updateEditingState,
  updateSelectedObjects,
  updateSnapshotsInDocument,
} from '@/web/store/appState'
import {
  CreateObjectPayload,
  createObjects,
  removeMultiObjects,
  resetDocument,
  selectCurrentPage,
  selectDocument,
  updateObjectContent,
  updateObjectOrder,
  updateObjectPosition,
} from '@/web/store/document'
import { executeRedo, executeUndo } from '@/web/store/history'
import { selectSnapshots } from '@/web/store/snapshots'

function Editor(): JSX.Element {
  const dispatch = useAppDispatch()
  const { documentId, organizationSlug } = useParams<{
    documentId: string
    organizationSlug: string
  }>()
  const history = useHistory()
  const selectedObjects = useAppSelector(selectSelectedObjects)
  const loading = useAppSelector(selectLoading)
  const canvasSize = useAppSelector(selectCanvas)
  const sidebarOpen = useAppSelector(matchSidebarState('open'))
  const [screenSize, setScreenSize] = useState<ScreenSize>(getScreenSize())
  const readOnly =
    screenSize === ScreenSize.xs || screenSize === ScreenSize.sm || screenSize === ScreenSize.md

  const resizeHandler = useCallback(() => setScreenSize(getScreenSize()), [])
  useEffect(() => {
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [resizeHandler])

  const plusDocument = useAppSelector(selectDocument)
  const currentPage = useAppSelector(selectCurrentPage)
  const editorSnapshots = useAppSelector(selectSnapshots)
  const snapshots = useMemo(
    () => ({
      ...(editorSnapshots || {}),
      ...(currentPage.snapshots || []).reduce(
        (obj: { [id: string]: Snapshot }, snapshot: Snapshot) => {
          obj[snapshot.id] = snapshot
          return obj
        },
        {},
      ),
    }),
    [currentPage.snapshots, editorSnapshots],
  )

  const queryParams = useSearchParams()
  const folderId = queryParams.get('folderId')
  const teamId = queryParams.get('teamId')
  useTrackContentView(ContentType.Document, plusDocument.urn)

  // @TODO – remove when unifying editor and share routes, by making the one route use ContentPrivacyMiddlewareRoute
  useEffect(() => {
    if (!loading && plusDocument.id) {
      contentPrivacyStateVar({
        ...contentPrivacyStateVar(),
        accessLevel: ContentAccessLevel.Private,
        id: plusDocument.id,
      })
    }
  }, [loading, plusDocument])

  useEffect(() => {
    return () => {
      dispatch(resetAppState())
      dispatch(resetDocument())
    }
  }, [dispatch])

  // @TODO - probably turn this into its own hook (i.e. useAppInit)
  useEffect(() => {
    Promise.resolve().then(() => {
      dispatch(initEditorAppThunk({ documentId }))
    })

    const interval = setInterval(() => {
      dispatch(updateSnapshotsInDocument())
    }, 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [dispatch, documentId, folderId, organizationSlug, teamId])

  useEffect(() => {
    if (documentId !== plusDocument.id && !loading) {
      history.replace(`/${organizationSlug}/page/${plusDocument.id}`)
    }
  }, [organizationSlug, documentId, plusDocument.id, loading, history])

  const maybeSelectedSnapshotObject = React.useMemo(() => {
    if (selectedObjects.length === 1) {
      const selectedObjectId = selectedObjects[0]
      const object = findOrThrow(
        currentPage.objects as PageObject[],
        (o) => o.id === selectedObjectId,
      )
      if (object.type === 'Snapshot') {
        return object
      } else {
        return null
      }
    } else {
      return null
    }
  }, [selectedObjects, currentPage.objects])
  const [fetchSnapshot, { data }] = useSnapshotLazyQuery()
  useEffect(() => {
    if (maybeSelectedSnapshotObject) {
      fetchSnapshot({
        variables: {
          id: maybeSelectedSnapshotObject.content.id,
        },
      })
    }
  }, [maybeSelectedSnapshotObject, fetchSnapshot])
  const maybeSelectedSnapshot = React.useMemo(() => {
    if (data) {
      return data?.snapshot
    } else {
      return null
    }
  }, [data])

  const removeSelectedObjects = useCallback(() => {
    if (selectedObjects.length > 0) {
      dispatch(updateSelectedObjects([]))
      dispatch(
        removeMultiObjects({
          objectIds: selectedObjects,
          pageId: currentPage.id,
        }),
      )
    }
  }, [dispatch, currentPage.id, selectedObjects])

  const selectAllObjectsOnPage = () =>
    dispatch(updateSelectedObjects(currentPage.objects.map((o) => o.id)))

  const deselectAllObjects = () => dispatch(updateSelectedObjects([]))

  const updateSelectedObjectsOrder = React.useCallback(
    (command: 'back' | 'backward' | 'forward' | 'front') => {
      dispatch(
        updateObjectOrder({
          command,
          objectIds: selectedObjects,
          pageId: currentPage.id,
        }),
      )
    },
    [dispatch, selectedObjects, currentPage.id],
  )

  const onArrowKey = React.useCallback(
    (event: ShortcutEvent) => {
      if (event instanceof WheelEvent) {
        return
      }

      const baseOffset = event.shiftKey ? 10 : 1
      const scaledOffset = scaledToRelative(baseOffset, canvasSize.scalingSide)

      selectedObjects.forEach((selectedObjectId) => {
        const selectedObject = findOrThrow(
          currentPage.objects as PageObject[],
          (object) => object.id === selectedObjectId,
        )

        const updatedPosition = {
          x: selectedObject.position.x,
          y: selectedObject.position.y,
        }

        if (event.key === 'ArrowUp') {
          updatedPosition.y -= scaledOffset
        } else if (event.key === 'ArrowDown') {
          updatedPosition.y += scaledOffset
        } else if (event.key === 'ArrowLeft') {
          updatedPosition.x -= scaledOffset
        } else if (event.key === 'ArrowRight') {
          updatedPosition.x += scaledOffset
        }

        dispatch(
          updateObjectPosition({
            objectId: selectedObjectId,
            pageId: currentPage.id,
            position: updatedPosition,
          }),
        )
      })
    },
    [dispatch, selectedObjects, currentPage.id, canvasSize.scalingSide, currentPage.objects],
  )

  const [syncing, setSyncing] = useState(false)
  React.useEffect(() => {
    window.addEventListener('pages:sync-change', (e) => {
      setSyncing((e as CustomEvent).detail.syncing)
    })
  }, [])

  React.useEffect(() => {
    const listener = (event: ClipboardEvent) => {
      if (!event.clipboardData || EDITABLE_TAGS.includes(document.activeElement?.tagName ?? '')) {
        return
      }
      event.preventDefault()

      const copyData = selectedObjects.map((selectedObjectId) => {
        const selectedObject = findOrThrow(
          currentPage.objects as PageObject[],
          (object) => object.id === selectedObjectId,
        )

        return {
          content: selectedObject.content,
          position: selectedObject.position,
          type: selectedObject.type,
        }
      })

      event.clipboardData.setData(
        'application/vnd.plusdocs.object',
        JSON.stringify(copyData, null, 2),
      )

      if (event.type === 'cut') {
        removeSelectedObjects()
      }
    }

    window.document.addEventListener('copy', listener)
    window.document.addEventListener('cut', listener)

    return () => {
      window.document.removeEventListener('copy', listener)
      window.document.removeEventListener('cut', listener)
    }
  }, [selectedObjects, currentPage.objects, removeSelectedObjects])

  React.useEffect(() => {
    const listener = (event: ClipboardEvent) => {
      const { clipboardData } = event
      if (
        !clipboardData ||
        !clipboardData.types.includes('application/vnd.plusdocs.object') ||
        EDITABLE_TAGS.includes(document.activeElement?.tagName ?? '')
      ) {
        return
      }
      event.preventDefault()

      const objectsAsJson = clipboardData.getData('application/vnd.plusdocs.object')

      const pastedObjects = JSON.parse(objectsAsJson)

      if (!Array.isArray(pastedObjects)) {
        return
      }

      const existingPositions = Object.values(currentPage.objects).map((object) => object.position)

      const pastedObjectPayloads: Array<CreateObjectPayload> = []

      pastedObjects.forEach((object) => {
        let foundNextPosition = false
        const newPosition = {
          x: object.position.x,
          y: object.position.y,
        }
        while (!foundNextPosition) {
          const existingObjectAtPosition = existingPositions.find(
            (obj) => obj.x === newPosition.x && obj.y === newPosition.y,
          )

          if (existingObjectAtPosition) {
            newPosition.x += scaledToRelative(25, canvasSize.scalingSide)
            newPosition.y += scaledToRelative(25, canvasSize.scalingSide)
          } else {
            foundNextPosition = true
          }
        }

        existingPositions.push(newPosition as ObjectPosition)

        pastedObjectPayloads.push({
          pageId: currentPage.id,
          ...object,
          position: {
            ...object.position,
            ...newPosition,
          },
          shouldSkipScaling: true,
        })
      })

      dispatch(
        createObjects({
          objects: pastedObjectPayloads,
        }),
      )
    }

    window.document.addEventListener('paste', listener)

    return () => window.document.removeEventListener('paste', listener)
  }, [canvasSize.scalingSide, currentPage.id, currentPage.objects, dispatch, selectedObjects])

  useKeyboardShortcuts(
    [
      ...['Delete', 'Backspace'].map((key) => ({
        keys: [key],
        onEvent: removeSelectedObjects,
      })),
      {
        keys: ['Ctrl', 'A'],
        onEvent: selectAllObjectsOnPage,
      },
      {
        keys: ['Ctrl', 'Z'],
        onEvent: () => dispatch(executeUndo()),
      },
      {
        keys: ['Ctrl', 'Shift', 'Z'],
        onEvent: () => dispatch(executeRedo()),
      },
      {
        keys: ['Ctrl', 'Alt', 'BracketLeft'],
        onEvent: () => updateSelectedObjectsOrder('back'),
      },
      {
        keys: ['Ctrl', 'BracketLeft'],
        onEvent: () => updateSelectedObjectsOrder('backward'),
      },
      {
        keys: ['Ctrl', 'BracketRight'],
        onEvent: () => updateSelectedObjectsOrder('forward'),
      },
      {
        keys: ['Ctrl', 'Alt', 'BracketRight'],
        onEvent: () => updateSelectedObjectsOrder('front'),
      },
      {
        keys: ['Escape'],
        onEvent: deselectAllObjects,
      },
      {
        keys: ['Ctrl', 'S'],
        onEvent: () => window.dispatchEvent(new Event('pages:save')),
      },
      {
        keys: ['Ctrl', 'Alt', '0'],
        onEvent: () => {
          throw new Error('Forced crash for testing')
        },
      },
      ...['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].map((key) => ({
        keys: [key],
        onEvent: onArrowKey,
      })),
    ],
    true,
    [selectedObjects, currentPage.id, currentPage.objects, canvasSize.scale],
  )

  const onCanvasResize = useCallback(
    ({ width, height }: { width: number; height: number }) => {
      dispatch(
        updateCanvasSize({
          base: {
            height: currentPage.canvasSize?.height,
            width: currentPage.canvasSize?.width,
          },
          canvas: { height, width },
        }),
      )
    },
    [dispatch, currentPage.canvasSize],
  )

  const onClick = useCallback(
    ({ target, currentTarget }: React.MouseEvent) => {
      if (target === currentTarget) {
        flushTextChanges({
          dispatch,
          objects: currentPage.objects as PageObject[],
          pageId: currentPage.id,
          selectedObjects,
        })
        dispatch(updateEditingState('none'))
        dispatch(updateSelectedObjects([]))
      }
    },
    [dispatch, currentPage, selectedObjects],
  )

  const onChangeSnapshotVersion = useCallback(
    (id: string | null) => {
      if (!maybeSelectedSnapshotObject?.id) {
        return
      }

      dispatch(
        updateObjectContent({
          content: {
            versionId: id,
          },
          objectId: maybeSelectedSnapshotObject.id,
          pageId: currentPage.id,
        }),
      )
    },
    [currentPage, dispatch, maybeSelectedSnapshotObject],
  )

  const divRef = useRef<HTMLDivElement>(null)
  // 56px = 3.5rem (height of versions footer)
  // 16px = 1rem (desired padding)
  // 56 + 16 = 72
  const pageSize = useElementSizer(
    divRef,
    currentPage?.canvasSize
      ? {
          height: currentPage.canvasSize.height,
          width: currentPage.canvasSize.width,
        }
      : undefined,
    72,
  )

  return (
    <main className="text-copy fixed h-full max-h-full w-full md:overflow-hidden">
      <Prompt
        when={syncing}
        message="We're still saving your changes to this Page. If you leave, your changes may be lost. Do you still want to leave?"
      />
      <Helmet title={plusDocument?.name} />
      <Header documentTitle={plusDocument.name} isSyncing={syncing} />
      <div className="bg-background-canvas flex flex-col md:flex-row md:overflow-hidden">
        <div
          ref={divRef}
          className={clsx('h-viewport-minus-header flex grow flex-col')}
          onClick={onClick}
        >
          <div className="flex grow flex-col items-center justify-center">
            <div className="bg-background-white shadow-canvas absolute" style={pageSize}>
              {!loading ? (
                <Canvas
                  page={currentPage}
                  size={canvasSize}
                  onResize={onCanvasResize}
                  readOnly={readOnly}
                  mode="editor"
                />
              ) : null}
            </div>
          </div>
          <DesktopDisplayOnly>
            {maybeSelectedSnapshot && maybeSelectedSnapshotObject ? (
              <SnapshotVersionNavigationBar
                className="z-10 self-end"
                snapshot={maybeSelectedSnapshot}
                snapshotVersionId={maybeSelectedSnapshotObject.content.versionId}
                showMostRecentOption={true}
                onChangeSnapshotVersion={onChangeSnapshotVersion}
              />
            ) : (
              <div className="border-t-divider-light-gray bg-background-panel flex h-12 w-full items-center justify-center border-t">
                <span className="text-copy-secondary text-base">
                  {maybeSelectedSnapshotObject && !maybeSelectedSnapshot
                    ? 'This Snapshot is owned by another user. The selected version can not be changed.'
                    : selectedObjects.length > 1
                    ? 'Select a single Snapshot to view its version history.'
                    : 'Place and select a Snapshot to view its version history.'}
                </span>
              </div>
            )}
          </DesktopDisplayOnly>
        </div>
        <DesktopDisplayOnly>
          {sidebarOpen ? <Sidebar snapshots={snapshots} /> : null}
        </DesktopDisplayOnly>
      </div>
      <FullPageLoader loading={loading} />
    </main>
  )
}

export default Editor
