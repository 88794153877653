import { Helmet } from 'react-helmet-async'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useGate } from 'statsig-react'

import { useAuth } from '@/shared/auth/useAuth'
import MaintenanceScreen from '@/shared/components/MaintenanceScreen'
import { FullPageLoader } from '@/web/components/FullPageLoader'
import {
  AuthMiddlewareRoute,
  ContentPrivacyMiddlewareRoute,
  OrgAuthMiddlewareRoute,
} from '@/web/middleware'

import StartTrial from './components/StartTrial'
import { useAnalyticsIdentify, useRegisterExtension, useViewer } from './hooks'
import { GoogleAuthMiddlewareRoute } from './middleware/GoogleAuthMiddlewareRoute'
import { AuthRoutes } from './routes/auth'
import { Connection } from './routes/Connection'
import FeedNext from './routes/FeedNext'
import { Gallery } from './routes/Gallery/Gallery'
import ImportTheme from './routes/ImportTheme'
import AcceptInvitation from './routes/join/AcceptInvitation'
import InvitationList from './routes/join/InvitationList'
import OneStepJoin from './routes/join/OneStepJoin'
import NotFound from './routes/NotFound'
import PageRouter, { CreateNewPage } from './routes/PageRouter'
import PostInstall from './routes/PostInstall'
import PreInstall from './routes/PreInstall'
import RepairSnapshots from './routes/RepairSnapshots'
import { BillingPortal } from './routes/settings/BillingPortal'
import Settings from './routes/settings/Settings'
import Share from './routes/Share'
import SlackAccountLink from './routes/SlackAccountLink'
import Snapshot from './routes/Snapshot'
import UpgradeSuccess from './routes/UpgradeSuccess'

function App(): JSX.Element {
  const { value: isUnderMaintenance } = useGate('maintenance_mode')
  const auth = useAuth()
  const { viewer } = useViewer()
  useAnalyticsIdentify(viewer?.user, viewer?.currentOrganization)
  useRegisterExtension()

  if (isUnderMaintenance) {
    return (
      <>
        <Helmet defaultTitle="Down for maintenance | Plus" />
        <MaintenanceScreen />
      </>
    )
  }

  return (
    <>
      <Helmet titleTemplate="%s | Plus" defaultTitle="Plus" />
      {auth.loading ? (
        <div className="m-auto">
          <FullPageLoader loading={true} />
        </div>
      ) : (
        <BrowserRouter>
          <Switch>
            <OrgAuthMiddlewareRoute path="/" exact component={FeedNext} />
            <OrgAuthMiddlewareRoute path="/start-trial" exact component={StartTrial} />
            <Route
              path="/(install|login|login-google|register|reset-password|confirm-registration)"
              component={AuthRoutes}
            />
            <Route exact path="/preinstall" component={PreInstall} />
            <Route exact path="/upgrade-success" component={UpgradeSuccess} />
            <Route exact path="/postinstall" component={PostInstall} />
            <AuthMiddlewareRoute path="/slack-account-link" exact component={SlackAccountLink} />
            <AuthMiddlewareRoute path="/invitations" exact component={InvitationList} />
            <AuthMiddlewareRoute path="/onboarding" component={OneStepJoin} />
            <AuthMiddlewareRoute
              path={['/accept-invite/:invitationId', '/:organizationSlug/accept-invite']}
              component={AcceptInvitation}
            />
            <GoogleAuthMiddlewareRoute path="/import-theme/:themeId" component={ImportTheme} />
            <OrgAuthMiddlewareRoute
              exact
              path={[
                '/:organizationSlug/settings/organization',
                '/:organizationSlug/settings/personal',
                '/:organizationSlug/settings/billing',
                '/:organizationSlug/upgrade',
              ]}
              component={Settings}
            />
            <OrgAuthMiddlewareRoute exact path={['/billing-portal']} component={BillingPortal} />
            <OrgAuthMiddlewareRoute
              path={['/:organizationSlug/connection/:identityId']}
              component={Connection}
            />
            <OrgAuthMiddlewareRoute
              exact
              path={['/gallery', '/:organizationSlug/gallery']}
              component={Gallery}
            />
            <OrgAuthMiddlewareRoute path="/:organizationSlug/page/new" component={CreateNewPage} />
            <ContentPrivacyMiddlewareRoute
              path={'/:organizationSlug/page/:documentId'}
              component={PageRouter}
            />
            <ContentPrivacyMiddlewareRoute
              path={['/gallery/:documentId', '/:organizationSlug/gallery/:documentId']}
              component={Share}
            />
            <Redirect
              from="/:organizationSlug/pages/edit/:documentId"
              to="/:organizationSlug/page/:documentId"
            />
            <Redirect
              from="/:organizationSlug/pages/share/:documentId"
              to="/:organizationSlug/page/:documentId"
            />

            <ContentPrivacyMiddlewareRoute
              path={[
                '/:organizationSlug/snapshot/:snapshotId/version/:snapshotVersionId',
                '/:organizationSlug/snapshot/:snapshotId',
              ]}
              component={Snapshot}
            />
            <OrgAuthMiddlewareRoute
              path="/:organizationSlug/snapshots/repair"
              component={RepairSnapshots}
            />
            <OrgAuthMiddlewareRoute
              path={[
                '/:organizationSlug/team/:teamId/folders/:folderId',
                '/:organizationSlug/team/:teamId',
                '/:organizationSlug/folders/:folderId',
                '/:organizationSlug',
              ]}
              component={FeedNext}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </BrowserRouter>
      )}
    </>
  )
}

export default App
