// This imports have some issue that is unresolvable by eslint so the following ignore is required
// eslint-disable-next-line simple-import-sort/imports
import basePalette from '@plusdocs/styles/palette.json'
import clsx from 'clsx'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Sliders, Star } from 'react-feather'

import { SnapshotFieldsFragment } from '@/generated/graphql'
import { Button, DesktopDisplayOnly } from '@/shared/components'
import { SnapshotVersionHookReturnType } from '@/shared/hooks/useSnapshotVersion'
import useUpdateSnapshot from '@/shared/hooks/useUpdateSnapshot'
import {
  RuntimeMessage,
  extractNameFromMessage,
  generateNamespacedMessage,
  generateNamespacedName,
} from '@/util/extension'
import { extensionRuntimeId } from '@/util/extensionId'
import useControlledPopover from '@/web/hooks/useControlledPopover'

import { HeaderBreadcrumbs } from '../HeaderBreadcrumbs'
import { ShareModalTrigger } from '../ShareModal'
import { SnapshotLiveBadge } from './SnapshotLiveBadge'
import { SnapshotMenu } from './SnapshotMenu'

type DesktopHeaderProps = {
  deleteSnapshotCallback: () => void
  moveSnapshotCallback: () => void
  snapshotEditingAllowed: boolean
  isSidebarOpen: boolean
  toggleSidebar: () => void
  snapshot: SnapshotFieldsFragment
  snapshotVersion: SnapshotVersionHookReturnType['snapshotVersion']
}

export const DesktopSnapshotHeader = ({
  snapshotEditingAllowed,
  isSidebarOpen,
  toggleSidebar,
  deleteSnapshotCallback,
  moveSnapshotCallback,
  snapshot,
  snapshotVersion,
}: DesktopHeaderProps): JSX.Element => {
  const updateSnapshotMutation = useUpdateSnapshot(snapshot)
  const [isDesktopMenuOpen, desktopMenuHandlers] = useControlledPopover('desktopSnapshotMenu')
  const [name, setName] = useState<string>(snapshot.name)
  const [newTabEnabled, setNewTabEnabled] = useState(false)
  const [pinnedSnapshots, setPinnedSnapshots] = useState<string[]>([])
  const isPinned = pinnedSnapshots.includes(snapshot.id)

  const onChangeName = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setName(value ?? 'Untitled snapshot')
  }

  const onBlurName = () => {
    updateSnapshotMutation({ name: name ?? 'Untitled snapshot' })
  }

  useEffect(() => {
    setName(snapshot.name)
  }, [snapshot.name])

  useEffect(() => {
    if (!extensionRuntimeId) return

    const port = chrome.runtime.connect(extensionRuntimeId, {
      name: generateNamespacedName('external-port'),
    })

    port.postMessage(generateNamespacedMessage('get-new-tab-options'))
    const disconnectIfNoResponse = setTimeout(() => port.disconnect(), 10 * 1000) // 10 seconds
    port.onMessage.addListener((msg: RuntimeMessage) => {
      const eventName = extractNameFromMessage(msg)
      if (eventName === 'new-tab-options-response') {
        clearTimeout(disconnectIfNoResponse)
        setNewTabEnabled(true)
        setPinnedSnapshots(msg.data?.pinnedSnapshots as string[])
        port.disconnect()
      }
    })
  }, [])
  const togglePinnedSnapshot = () => {
    const port = chrome.runtime.connect(extensionRuntimeId, {
      name: generateNamespacedName('external-port'),
    })

    port.postMessage(generateNamespacedMessage('toggle-pinned-snapshot', { id: snapshot.id }))
    port.disconnect()

    const idx = pinnedSnapshots.indexOf(snapshot.id)
    const newPinnedSnapshots = [...pinnedSnapshots]
    if (idx > -1) {
      newPinnedSnapshots.splice(idx, 1)
    } else {
      newPinnedSnapshots.push(snapshot.id)
    }

    setPinnedSnapshots(newPinnedSnapshots)
  }

  if (!snapshot) {
    return <div />
  }

  return (
    <DesktopDisplayOnly>
      <div className="border-divider-light-gray bg-background-panel flex h-12 w-full items-center justify-between border-b p-3">
        <div
          className={clsx(
            'mr-2 flex items-center gap-1 text-base',
            snapshotEditingAllowed && 'grow overflow-hidden',
          )}
        >
          <SnapshotMenu
            deleteSnapshotCallback={deleteSnapshotCallback}
            moveSnapshotCallback={moveSnapshotCallback}
            isMenuOpen={isDesktopMenuOpen}
            menuPopoverHandlers={desktopMenuHandlers}
            snapshot={snapshot}
          />

          {snapshotEditingAllowed && (
            <HeaderBreadcrumbs
              content={snapshot}
              name={name}
              onChangeName={onChangeName}
              onBlurName={onBlurName}
            />
          )}
        </div>

        {!snapshotEditingAllowed && (
          <div className="flex items-center space-x-3 overflow-hidden px-4 py-2">
            <div className="flex space-x-2 overflow-hidden">
              <span className="truncate text-base font-semibold">{snapshot?.name}</span>
              {snapshot?.refreshRate && (
                <SnapshotLiveBadge tooltipText="This Snapshot updates automatically on a schedule set by the creator." />
              )}
            </div>
          </div>
        )}

        <div className="flex items-center space-x-2">
          {newTabEnabled && snapshotEditingAllowed && (
            <Button
              variant={isPinned ? 'ghost' : 'secondary'}
              onClick={togglePinnedSnapshot}
              className={clsx('focus:border-divider-light-gray focus:outline-none', {
                '!bg-interactive-secondary-active': isPinned,
                '!border-divider-light-blue': isPinned,
                '!text-interactive-primary': isPinned,
              })}
              data-star-tours-id="pin-new-tab"
            >
              <Star
                fill={isPinned ? basePalette.primaryBlue : 'transparent'}
                className="mr-1"
                size={16}
              />
              {isPinned ? 'Pinned to New Tab' : 'Pin to New Tab'}
            </Button>
          )}
          <Button
            icon={<Sliders size={16} />}
            variant="ghost"
            aria-hidden="true"
            className={clsx('focus:outline-none', {
              '!bg-interactive-secondary-active': isSidebarOpen,
              '!border-divider-light-blue': isSidebarOpen,
              '!border-transparent': !isSidebarOpen,
              '!text-interactive-primary': isSidebarOpen,
            })}
            onClick={toggleSidebar}
          ></Button>

          <ShareModalTrigger
            content={snapshot}
            versionInfo={
              snapshotVersion
                ? {
                    createdAt: snapshotVersion.createdAt,
                    id: snapshotVersion.id,
                  }
                : undefined
            }
          />
        </div>
      </div>
    </DesktopDisplayOnly>
  )
}
