import * as React from 'react'

import { SlackAccountStatus, useDisconnectFromSlackMutation } from '@/generated/graphql'
import { Button, Modal } from '@/shared/components'
import { showModal } from '@/util'

import SlackLogo from '../assets/IntegrationLogos/SlackLogo.svg'
import { useViewer } from '../hooks'

export const SlackIntegrationCard = () => {
  const { viewer, reloadViewer } = useViewer()

  const [disconnectSlackAccountMutation, { data }] = useDisconnectFromSlackMutation()

  const disconnectSlackAccountCallback = React.useCallback(() => {
    void showModal(
      <Modal
        variant="destructive"
        title="Disconnect Slack Account?"
        content="Are you sure you want to disconnect your account from Slack? You can always reconnect later, but any subscriptions you've created will be lost."
        primaryButtonText="Disconnect"
        onSubmit={() => void disconnectSlackAccountMutation()}
      />,
    )
  }, [disconnectSlackAccountMutation])

  React.useEffect(() => {
    void reloadViewer()
  }, [data, reloadViewer])

  return (
    <div className="border-divider-light-gray flex flex-col overflow-hidden rounded-md border">
      <div className="border-divider-light-gray bg-background-panel h-[128px] content-center border-b p-8">
        <div className="flex max-h-[100%] max-w-[100%]">
          <img src={SlackLogo} />
        </div>
      </div>
      <div className="flex flex-grow flex-col p-3">
        <span className="mb-1 text-xs font-semibold">Slack</span>
        <p className="mb-3 flex-grow text-xs">
          Easily share rich previews of Plus content in channels and DMs.
        </p>
        <div className="flex gap-2">
          {viewer?.slackAccountStatus === SlackAccountStatus.Linked ? (
            <Button variant="destructive" onClick={disconnectSlackAccountCallback}>
              Disconnect Account
            </Button>
          ) : (
            <a
              href={'https://p1g394ch06.execute-api.us-east-2.amazonaws.com/slack/install'}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="primary">Install App</Button>
            </a>
          )}

          <a
            href={'https://guide.plusdocs.com/integrations/slack-subscriptions'}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="secondary">Learn more</Button>
          </a>
        </div>
      </div>
    </div>
  )
}
