import { Popover as HeadlessPopover } from '@headlessui/react'
import * as PopperJS from '@popperjs/core'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'

import useOnClickOutside from '@/shared/hooks/useOnClickOutside'

type Props = {
  /**
   * trigger={<button />}
   * trigger={props => <button disabled={props.open} />}
   */
  trigger: JSX.Element | React.ComponentType<{ open: boolean }>
  children: React.ReactNode
  placement?: PopperJS.Placement

  /**
   * Used to create a larger mouse hitarea around the actual popover contents
   * to help caputre mouse events
   */
  extraHitarea?: number

  /**
   * Externally manage the open state of the popover
   */
  controlled?: boolean
  open?: boolean
  setOpen?: (open: boolean) => void
}

const ContextPopover = (props: Props): JSX.Element | null => {
  const { placement = 'right-start', extraHitarea = 0, controlled, setOpen } = props

  const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null)

  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null)

  useOnClickOutside(popperElement, [referenceElement], () => {
    if (controlled && props.open && setOpen) {
      setOpen(false)
    }
  })

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        enabled: true,
        name: 'offset',
        options: {
          offset: [0 - extraHitarea, 0 - extraHitarea],
        },
      },
    ],
    placement,
  })

  const portalDestination = React.useRef(document.getElementById('popover'))

  React.useEffect(() => {
    const captureAndStopEvent = (event: MouseEvent) => {
      event.stopPropagation()
      event.stopImmediatePropagation()
    }

    if (portalDestination.current) {
      const children = portalDestination.current.querySelectorAll(
        '[id*="headlessui-popover-panel"]',
      )

      /**
       * If we already have a parent popover mounted to the portal,
       * then we want to nest this popover underneath the deepest parent popover node
       * so we can properly handle event bubbling up a parent tree instead of
       * alongside children
       *
       * html
       * 	#app
       * 	#popover
       * 	  popover-1
       * 			popover-2 (mousedown bubbles up the popover-1 tree)
       *
       * instead of
       *
       * html
       * 	#app
       * 	#popover
       * 	 	popover-1
       * 		popover-2 (mousedown doesn't bubble up the popover-1 tree)
       */
      if (children.length > 0) {
        const newDestination = children[children.length - 1] as HTMLDivElement

        portalDestination.current = newDestination
      } else {
        /**
         * We reset the portalDestination to the main popover since these are stateful popovers
         */
        portalDestination.current = document.getElementById('popover')
      }
    }

    portalDestination.current?.addEventListener('mousedown', captureAndStopEvent)

    return () => {
      portalDestination.current?.removeEventListener('mousedown', captureAndStopEvent)
    }
  }, [])

  const portalDestinationNode = portalDestination.current

  if (!portalDestinationNode) {
    return null
  }

  const { trigger: Trigger } = props

  if (props.controlled) {
    return (
      <HeadlessPopover>
        <HeadlessPopover.Button
          as="div"
          ref={(ref: HTMLDivElement) => {
            setReferenceElement(ref)
          }}
        >
          {typeof Trigger === 'function' ? <Trigger open={Boolean(props.open)} /> : Trigger}
        </HeadlessPopover.Button>
        {props.open &&
          ReactDOM.createPortal(
            <HeadlessPopover.Panel
              ref={(_ref: HTMLDivElement) => {
                setPopperElement(_ref)
              }}
              style={styles.popper}
              {...attributes.popper}
              className="z-20"
              static
            >
              <div style={{ padding: `${extraHitarea}px` }}>{props.children}</div>
            </HeadlessPopover.Panel>,
            portalDestinationNode,
          )}
      </HeadlessPopover>
    )
  }

  return (
    <HeadlessPopover>
      {({ open }) => (
        <>
          <HeadlessPopover.Button
            as="div"
            ref={(ref: HTMLDivElement) => {
              setReferenceElement(ref)
            }}
          >
            {typeof Trigger === 'function' ? <Trigger open={open} /> : Trigger}
          </HeadlessPopover.Button>
          {open &&
            ReactDOM.createPortal(
              <HeadlessPopover.Panel
                ref={(ref: HTMLDivElement) => {
                  setPopperElement(ref)
                }}
                style={styles.popper}
                {...attributes.popper}
                className="z-20"
              >
                <div style={{ padding: `${extraHitarea}px` }}>{props.children}</div>
              </HeadlessPopover.Panel>,
              portalDestinationNode,
            )}
        </>
      )}
    </HeadlessPopover>
  )
}

export default ContextPopover
