import { cloneElement } from 'react'
import ReactDOM from 'react-dom/client'

type ModalResponse = {
  type: 'submit' | 'cancel'
}

const ModalWrapper = ({
  component,
  cleanupModalRootNode,
  resolve,
}: {
  component: JSX.Element
  cleanupModalRootNode: () => void
  resolve: (value: ModalResponse | PromiseLike<ModalResponse>) => void
}): JSX.Element => {
  return cloneElement(component, {
    onCancel: () => {
      if (typeof component.props.onCancel === 'function') {
        component.props.onCancel()
      }
      cleanupModalRootNode()
      resolve({ type: 'cancel' })
    },
    onSubmit: (x: string) => {
      component.props.onSubmit(x)
      cleanupModalRootNode()
      resolve({ type: 'submit' })
    },
  })
}

/**
 * This function lets us use a Modal in a declarative way, outside of the return/render function
 * of a component. It's wrapped in a Promise, so we can wait for the modal to submit/close before executing
 * any further code at the consuming location. It prevents us from having to create new state variables wherever
 * we use Modals, and then either conditionally render the modal vs the content we want to see.
 *
 * Example usage:
 	const onDelete = React.useCallback(() => {
		showModal(
			<Modal
				variant="destructive"
				title="Delete snapshot?"
				content="“Dan’s Cool Snapshot” will be permanently deleted."
				primaryButtonText="Delete"
				onSubmit={() => dispatch(deleteSnapshot(snapshot.id))}
			/>,
		).then(() => {
			// Modal was confirmed
		}).catch(() => {
			// Modal was cancelled
		});
	}, [dispatch, snapshot]);
 */
export const showModal = async (component: JSX.Element): Promise<ModalResponse> => {
  const appRootNode = document.getElementById('root')
  const modalRootNode = document.createElement('div')

  if (!appRootNode) {
    throw new Error('Could not find app root node to create modal')
  }

  appRootNode.appendChild(modalRootNode)

  const root = ReactDOM.createRoot(modalRootNode)

  const cleanupModalRootNode = () => {
    appRootNode?.removeChild(modalRootNode)
    root.unmount()
  }

  return new Promise((resolve) => {
    root.render(
      <ModalWrapper
        component={component}
        cleanupModalRootNode={cleanupModalRootNode}
        resolve={resolve}
      />,
    )
  })
}
