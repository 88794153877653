import clsx from 'clsx'
import * as React from 'react'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'

import { useAuth } from '@/shared/auth/useAuth'
import { Button, GoogleLogo, Input, Loader, PlusLogo } from '@/shared/components'
import MicrosoftLogo from '@/shared/components/MicrosoftLogo'
import { useSearchParams } from '@/shared/hooks/useSearchParams'
import { useViewer } from '@/web/hooks'

type LoginPayload = {
  email: string
  password: string
}

const validateForm = (formValues: LoginPayload) => {
  // make sure everything is filled out
  if (Object.values(formValues).some((value) => !value)) {
    return false
  }

  return true
}

const Login = (): JSX.Element => {
  const {
    finishLoginWithOAuth,
    login,
    logout,
    isLoggedIn,
    startLoginWithOAuth,
    resendConfirmationEmail,
  } = useAuth()
  const { reloadViewer } = useViewer()
  const history = useHistory()
  const location = useLocation<{
    from: { pathname: string; search: string; state: undefined | Record<string, unknown> }
    showResendConfirmation?: boolean
  }>()
  const [loading, setLoading] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [errorCode, setErrorCode] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const [formValues, setFormValues] = React.useState<LoginPayload>({
    email: '',
    password: '',
  })
  const { from } = location.state || { from: { pathname: '/', search: '' } }
  const { showResendConfirmation } = location.state || {}
  const params = useSearchParams()
  const fromExtension = params.has('fromExtension')
  const logoutFromExtension = params.has('logoutFromExtension')

  const onFormFieldChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormValues((prev) => ({ ...prev, [name]: value }))
  }, [])

  const isFormValid = React.useMemo(() => {
    if (hasError) {
      return false
    }

    return validateForm(formValues)
  }, [hasError, formValues])

  const loginWithGoogle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.localStorage.setItem('location-state', JSON.stringify(from))
    startLoginWithOAuth(false, 'Google')
  }

  const loginWithMicrosoft = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.localStorage.setItem('location-state', JSON.stringify(from))
    startLoginWithOAuth(false, 'Microsoft')
  }

  const onSubmit = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()

      if (!isFormValid) {
        // this shouldn't happen because the form is disabled in this state, but just in case
        return
      }

      setLoading(true)

      login({
        email: formValues.email,
        password: formValues.password,
      })
        .then(() => reloadViewer?.())
        .then(() => {
          if (ReactGA.isInitialized) {
            ReactGA.gtag('event', 'login', {
              method: 'Email',
            })
          }
          history.replace({
            ...from,
            pathname: decodeURIComponent(params.get('route') || '') || from.pathname,
            state: {
              ...from.state,
              fromExtension,
            },
          })
        })
        .catch((error: { code: string; message: string }) => {
          setHasError(true)
          setErrorCode(error.code)
          setErrorMessage(error.message)
          setLoading(false)

          if (error.code === 'UserNotConfirmedException') {
            resendConfirmationEmail({ email: formValues.email })
          }
        })
    },
    [
      isFormValid,
      login,
      formValues.email,
      formValues.password,
      reloadViewer,
      history,
      from,
      fromExtension,
      resendConfirmationEmail,
      params,
    ],
  )

  const oAuthCode = params.get('code')
  const hasOAuthError = params.get('error')
  const oAuthErrorMessage = hasOAuthError
    ? params.get('error_description')?.replace('PreSignUp failed with error ', '')
    : ''
  const needsGoogleReauth = oAuthErrorMessage?.includes(
    'Already found an entry for username google_',
  )

  useEffect(() => {
    if (needsGoogleReauth) {
      // this only happens when linking an SSO account to a regular account
      // when we see this, we just want to do the redirect to the SSO page one more time, because the second time works
      // yes. this is the best solution. no, you cannot fix this on the backend. people have been asking AWS to fix this since 2017:
      // https://stackoverflow.com/questions/47815161/cognito-auth-flow-fails-with-already-found-an-entry-for-username-facebook-10155
      // and yet, they still haven't fixed it. sorry! thankfully, users would literally only see this once in their account's lifetime.
      return startLoginWithOAuth()
    }
    if (oAuthCode) {
      finishLoginWithOAuth(oAuthCode)
        .then(() => reloadViewer?.())
        .then((viewer) => {
          if (ReactGA.isInitialized) {
            const isSignup = typeof viewer?.data?.viewer.user?.id !== 'string'
            ReactGA.gtag('event', isSignup ? 'sign_up' : 'login', {
              method: 'Google',
            })
          }

          const localStorageLocationState = JSON.parse(
            window.localStorage.getItem('location-state') ?? '',
          ) ?? { state: {} }

          const searchParams = new URLSearchParams(localStorageLocationState['search'])

          history.replace({
            ...from,
            ...localStorageLocationState,
            pathname: decodeURIComponent(searchParams.get('route') || '') || from.pathname,
            state: {
              ...from.state,
              ...localStorageLocationState.state,
              fromExtension,
            },
          })
        })
        .catch((error: { message: string }) => {
          setHasError(true)
          setErrorMessage(error.message)
          setLoading(false)
        })
    }
  }, [
    finishLoginWithOAuth,
    from,
    fromExtension,
    history,
    needsGoogleReauth,
    oAuthCode,
    reloadViewer,
    startLoginWithOAuth,
    params,
  ])

  if (logoutFromExtension) {
    logout().finally(() => {
      history.replace({
        pathname: from.pathname,
      })
    })
    return <></>
  } else if (isLoggedIn()) {
    return (
      <Redirect
        to={{
          ...from,
          state: {
            ...from.state,
            fromExtension,
          },
        }}
      />
    )
  }

  if (oAuthCode || needsGoogleReauth) {
    return (
      <div className="m-auto">
        <Loader />
      </div>
    )
  }

  if (errorCode === 'UserNotConfirmedException') {
    return (
      <div>
        <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <PlusLogo width="48" height="48" />
            <h1 className="text-center text-xl font-semibold">Verify account</h1>
          </div>
        </div>
        <div className="p-6 text-center text-base font-normal">
          We sent an email to {formValues.email} with a verification link. Click the button in that
          email to start using Plus.
          <div className="mt-3 font-normal">
            <Link
              className="text-interactive-primary hover:text-interactive-primary-hover flex justify-center text-base hover:underline"
              to="/login"
              onClick={() => {
                setHasError(false)
                setErrorCode('')
                setErrorMessage('')
                setFormValues({
                  email: '',
                  password: '',
                })
              }}
            >
              Back to login
            </Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
        <div className="flex w-full flex-col items-center justify-center gap-1">
          <PlusLogo width="48" height="48" />
          <h1 className="text-center text-xl font-semibold">Log in to Plus AI</h1>
        </div>
        <Button
          className="w-full gap-x-2"
          variant="secondary"
          onClick={loginWithGoogle}
          icon={<GoogleLogo />}
        >
          Sign in with Google
        </Button>
        <Button
          className="w-full gap-x-2"
          variant="secondary"
          onClick={loginWithMicrosoft}
          icon={<MicrosoftLogo />}
        >
          Sign in with Microsoft
        </Button>
      </div>
      <form
        onSubmit={onSubmit}
        onChange={() => setHasError(false)}
        className={clsx('space-y-3', 'flex flex-col p-6', hasError && 'text-copy-alert')}
      >
        {showResendConfirmation && (
          <div className="text-copy self-center text-center text-base">
            Enter your email and password to resend the confirmation email.
          </div>
        )}

        {hasError && (
          <div className="text-copy-alert self-center text-base font-normal">{errorMessage}</div>
        )}

        {hasOAuthError && (
          <div className="text-copy-alert self-center text-base font-normal">
            {oAuthErrorMessage}
          </div>
        )}

        <div>
          <label htmlFor="email-input" className="text-base font-semibold">
            Work email
          </label>
          <Input
            id="email-input"
            name="email"
            type="email"
            value={formValues.email}
            autoComplete="username"
            onChange={onFormFieldChange}
            hasError={hasError}
          />
        </div>
        <div>
          <label htmlFor="password-input" className="text-base font-semibold">
            Password
          </label>
          <Input
            id="password-input"
            name="password"
            type="password"
            value={formValues.password}
            autoComplete="current-password"
            onChange={onFormFieldChange}
            hasError={hasError}
          />
        </div>
        <div>
          <Button className="w-full" disabled={!isFormValid || loading}>
            {loading ? 'Logging in…' : 'Log in'}
          </Button>
        </div>
        <div className="flex flex-col items-center gap-3">
          <Link
            className="text-interactive-primary hover:text-interactive-primary-hover flex justify-center text-center text-base hover:underline"
            to="/reset-password"
          >
            Reset password
          </Link>
          <Link
            className="text-interactive-primary hover:text-interactive-primary-hover flex justify-center text-center text-base hover:underline"
            to="/register"
          >
            Sign up
          </Link>
        </div>
      </form>
    </>
  )
}

export default Login
