import { useReactiveVar } from '@apollo/client'
import { MouseEvent, useCallback } from 'react'

import { ConnectionStatus, SnapshotFieldsFragment } from '@/generated/graphql'
import { extensionClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Button, Connection as ConnectionIcon, InstallExtensionModal } from '@/shared/components'
import { createReauthHandler, showModal } from '@/util'

type Props = {
  readonly className?: string
  readonly connectionStatus: ConnectionStatus
  readonly hideIcon?: boolean
  readonly snapshotToReconnect: SnapshotFieldsFragment
}

export default function ReconnectButton({
  className,
  connectionStatus,
  hideIcon,
  snapshotToReconnect,
}: Props): JSX.Element {
  const { extensionRuntimeId, isExtensionInstalled } = useReactiveVar(extensionClientStateVar)

  const isReconnectionNeeded = [
    ConnectionStatus.Disconnected,
    ConnectionStatus.Deteriorating,
  ].includes(connectionStatus)

  const onReconnectClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation()

      if (!isExtensionInstalled) {
        void showModal(<InstallExtensionModal />)
        return
      }

      const handler = createReauthHandler({
        extensionRuntimeId,
        orgId: snapshotToReconnect.organization.id,
        orgSlug: snapshotToReconnect.organization.slug,
        snapshotId: snapshotToReconnect.id,
        snapshotImageUrl: snapshotToReconnect.currentVersion?.image ?? '',
        snapshotUrl: snapshotToReconnect.instructions.url,
      })

      handler()
    },
    [extensionRuntimeId, isExtensionInstalled, snapshotToReconnect],
  )

  return (
    <Button
      className={className}
      disabled={connectionStatus === ConnectionStatus.Reconnecting}
      icon={hideIcon ? undefined : <ConnectionIcon className="mr-2" />}
      onClick={onReconnectClick}
      variant={isReconnectionNeeded ? 'secondary-dark' : 'secondary'}
    >
      {connectionStatus === ConnectionStatus.Reconnecting ? 'Reconnecting…' : 'Reconnect'}
    </Button>
  )
}
