import { ApolloProvider } from '@apollo/client'
import basePalette from '@plusdocs/styles/palette.json'
import { findOrThrow } from '@plusdocs/utils/common/arrays'
import Konva from 'konva'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { Layer, Rect, Stage } from 'react-konva'
import { Provider, useStore } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router-dom'

import { PageStructureV1, Snapshot, SnapshotFieldsFragment } from '@/generated/graphql'
import { apolloClient } from '@/shared/apollo/apolloClient'
import { PageObject as PageObjectType } from '@/shared/types/document'
import flushTextChanges from '@/util/flushTextChanges'
import { scaledToRelativeObject } from '@/util/transformPositions'
import {
  TransformResult,
  useAppDispatch,
  useAppSelector,
  useResizeObserver,
  useTransformer,
} from '@/web/hooks'
import {
  AppState,
  selectEditingState,
  selectSelectedObjects,
  updateEditingState,
  updateSelectedObjects,
} from '@/web/store/appState'
import { updateObjectPosition } from '@/web/store/document'
import { selectSnapshots } from '@/web/store/snapshots'
import { RootState } from '@/web/store/types'

import { HTMLObject, PageObject } from './Object'

interface CanvasProps {
  className?: string
  size: AppState['canvas']
  onResize?: (size: { width: number; height: number }) => void
  readOnly?: boolean
  page: PageStructureV1
  mode: 'editor' | 'share'
}

const isEqualStringArray = (a: string[], b: string[]) =>
  a.length === b.length && a.every((v, i) => v === b[i])

function CanvasSelectBox() {
  const rectRef = useRef<Konva.Rect>(null)
  const dispatch = useAppDispatch()
  const store = useStore<RootState>()

  useEffect(() => {
    if (!rectRef.current) {
      return
    }
    const rect = rectRef.current
    const stage = rect.getStage()
    if (!stage) {
      return
    }
    const layer = stage.findOne<Konva.Layer>('#render-layer')
    if (!layer) {
      return
    }

    let x1: number, y1: number, x2: number, y2: number
    const mouseDown = (ev: Konva.KonvaEventObject<MouseEvent>) => {
      if (ev.target !== stage) {
        return
      }

      x1 = stage.getRelativePointerPosition()?.x ?? 0
      y1 = stage.getRelativePointerPosition()?.y ?? 0
      x2 = stage.getRelativePointerPosition()?.x ?? 0
      y2 = stage.getRelativePointerPosition()?.y ?? 0

      rect.visible(true)
      rect.width(0)
      rect.height(0)
      dispatch(updateEditingState('rectSelecting'))
    }
    const mouseMove = () => {
      if (!rect.visible()) {
        return
      }
      x2 = stage.getRelativePointerPosition()?.x ?? 0
      y2 = stage.getRelativePointerPosition()?.y ?? 0
      rect.setAttrs({
        height: Math.abs(y2 - y1),
        width: Math.abs(x2 - x1),
        x: Math.min(x1, x2),
        y: Math.min(y1, y2),
      })
      const objects = layer.find('.page-object')
      const box = rect.getClientRect()
      const selected = objects
        .filter((object) => Konva.Util.haveIntersection(box, object.getClientRect()))
        .map((node) => node.id())
        .sort()
      const currentlySelectedObjects = selectSelectedObjects(store.getState())
      if (!isEqualStringArray(selected, currentlySelectedObjects)) {
        dispatch(updateSelectedObjects(selected))
      }
    }
    const mouseUp = () => {
      // no nothing if we didn't start selection
      if (!rect.visible()) {
        return
      }
      rect.visible(false)
      dispatch(updateEditingState('none'))
    }
    stage.on('mousedown', mouseDown)
    stage.on('mousemove', mouseMove)
    stage.on('mouseup', mouseUp)

    return () => {
      stage.off('mousedown', mouseDown)
      stage.off('mousemove', mouseMove)
      stage.off('mouseup', mouseUp)
    }
  }, [rectRef, dispatch, store])

  return (
    <Rect
      listening={false}
      visible={false}
      ref={rectRef}
      fill={basePalette.transparentPrimaryBlue}
      stroke={basePalette.primaryBlue}
      strokeWidth={1}
      perfectDrawEnabled={false}
    />
  )
}

export function Canvas({
  className = '',
  size,
  page,
  onResize,
  readOnly = false,
  mode,
}: CanvasProps): JSX.Element {
  const store = useStore()
  const dispatch = useAppDispatch()
  const editingState = useAppSelector(selectEditingState)
  const editorSnapshots = useAppSelector(selectSnapshots)
  const snapshots = useMemo(
    () => ({
      ...(editorSnapshots || {}),
      ...(page.snapshots || []).reduce(
        (obj: { [id: string]: SnapshotFieldsFragment | Snapshot }, snapshot) => {
          obj[snapshot.id] = snapshot
          return obj
        },
        {},
      ),
    }),
    [page.snapshots, editorSnapshots],
  )
  const selectedObjects = useAppSelector(selectSelectedObjects)
  const { organizationSlug } = useParams<{ organizationSlug?: string }>()
  const isGallery = useRouteMatch([
    `/:organizationSlug/gallery/:documentId`,
    `/gallery/:documentId`,
  ])

  const slug = isGallery ? 'plus-gallery' : organizationSlug

  const sizedCanvasRef = useRef<HTMLDivElement>(null)
  const stageRef = useRef<Konva.Stage>(null)
  const layerRef = useRef<Konva.Layer>(null)
  const transformCallback = useCallback(
    (result: TransformResult) => {
      dispatch(updateEditingState('none'))
      dispatch(
        updateObjectPosition({
          objectId: result.node.id(),
          pageId: page.id,
          position: scaledToRelativeObject(result.pos, size.scalingSide),
        }),
      )
    },
    [page.id, size.scalingSide, dispatch],
  )

  const [transformer, onTransformEnd] = useTransformer(
    layerRef,
    selectedObjects,
    page.objects as PageObjectType[],
    transformCallback,
  )
  useLayoutEffect(() => {
    if (sizedCanvasRef.current) {
      onResize?.(sizedCanvasRef.current.getBoundingClientRect())
    }
  }, [sizedCanvasRef, onResize])
  useResizeObserver(sizedCanvasRef, (entry) => onResize?.(entry.contentRect))

  const onMouseDown = useCallback(
    (ev: Konva.KonvaEventObject<MouseEvent>) => {
      if (ev.target === stageRef.current) {
        flushTextChanges({
          dispatch,
          objects: page.objects as PageObjectType[],
          pageId: page.id,
          selectedObjects,
        })
        dispatch(updateSelectedObjects([]))
      }
    },
    [stageRef, dispatch, selectedObjects, page],
  )

  const sortedObjects = useMemo(
    () => [...(page.objects as PageObjectType[])].sort((a, b) => a.position.z - b.position.z),
    [page.objects],
  )

  return (
    <div className={className} ref={sizedCanvasRef}>
      <Stage
        ref={stageRef}
        onMouseDown={onMouseDown}
        width={size.width}
        height={size.height}
        scaleX={size.scale}
        scaleY={size.scale}
        id="page-editor-stage"
      >
        <ApolloProvider client={apolloClient}>
          <Provider store={store}>
            <Layer ref={layerRef} id="render-layer">
              {sortedObjects.map((object) => (
                <PageObject
                  key={object.id}
                  pageId={page.id}
                  object={object}
                  canvasSize={size}
                  dispatch={dispatch}
                  selectedObjects={selectedObjects}
                  onTransformEnd={onTransformEnd}
                  editingState={editingState}
                  snapshots={snapshots}
                  mode={mode}
                  slug={slug}
                />
              ))}
            </Layer>
            {!readOnly ? (
              <Layer id="overlay-layer">
                {transformer}
                <CanvasSelectBox />
              </Layer>
            ) : null}
          </Provider>
        </ApolloProvider>
      </Stage>
      {!readOnly &&
      selectedObjects.length === 1 &&
      editingState !== 'dragging' &&
      editingState !== 'rectSelecting' ? (
        <div className="pointer-events-none absolute inset-0">
          <HTMLObject
            pageId={page.id}
            object={findOrThrow(
              page.objects as PageObjectType[],
              (obj) => obj.id === selectedObjects[0],
            )}
            canvasSize={size}
            dispatch={dispatch}
            selectedObjects={selectedObjects}
            editingState={editingState}
            snapshots={snapshots}
            mode={mode}
          />
        </div>
      ) : null}
    </div>
  )
}
