import { useEffect, useState } from 'react'

const useScrollDetector = (
  { threshold } = { threshold: 0 },
): [(ref: HTMLDivElement) => void, boolean] => {
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null)
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setIsScrolled((scrollRef?.scrollTop ?? 0) > threshold)
    }

    scrollRef?.addEventListener('scroll', onScroll)

    return () => {
      scrollRef?.removeEventListener('scroll', onScroll)
    }
  }, [scrollRef, threshold])

  return [setScrollRef, isScrolled]
}

export default useScrollDetector
