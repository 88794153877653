import { useReactiveVar } from '@apollo/client'

import { SearchResultOrderField } from '@/generated/graphql'
import { searchClientStateVar } from '@/shared/apollo/apolloLocalState'
import { SelectMenu, Sort } from '@/shared/components'
import { SelectOption } from '@/shared/components/Select'

export const FILTER_MODES: {
  label: string
  value: SearchResultOrderField
}[] = [
  { label: 'Date created', value: SearchResultOrderField.CreatedAt },
  { label: 'Name', value: SearchResultOrderField.Name },
]

const SearchSort = (): JSX.Element => {
  const { sortField } = useReactiveVar(searchClientStateVar)

  const onChangeHandler = (selectedOption: SelectOption) => {
    searchClientStateVar({
      ...searchClientStateVar(),
      sortField: selectedOption.value as SearchResultOrderField,
    })
  }

  return (
    <SelectMenu
      icon={<Sort />}
      onChange={onChangeHandler}
      options={FILTER_MODES}
      selectedValueLabel={`Sort`}
      value={FILTER_MODES.find((x) => x.value === sortField) || FILTER_MODES[0]}
      modal={true}
    />
  )
}

export default SearchSort
