import clsx from 'clsx'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from '@/shared/auth/useAuth'
import { Button, Input, PlusLogo } from '@/shared/components'

const BackToLoginLink = ({ className }: { className?: string }) => (
  <div className={className}>
    <Link
      className="text-interactive-primary hover:text-interactive-primary-hover flex justify-center text-xs hover:underline"
      to="/login"
    >
      Log in
    </Link>
  </div>
)

type ResetPasswordPayload = {
  email: string
}

enum UpdatePasswordErrorType {
  MissingInfo = 1,
  NonMatchingNewPasswords = 2,
  ResetPasswordFailed = 3,
  Unknown = 4,
}

const validateForm = (formValues: ResetPasswordPayload) => {
  // make sure everything is filled out
  if (Object.values(formValues).some((value) => !value)) {
    return false
  }

  return true
}

const getErrorTextFromErrorType = (errorType: UpdatePasswordErrorType): string => {
  switch (errorType) {
    case UpdatePasswordErrorType.MissingInfo:
      return 'All fields must be completed'
    case UpdatePasswordErrorType.NonMatchingNewPasswords:
      return 'Passwords do not match'
    case UpdatePasswordErrorType.ResetPasswordFailed:
      return 'We were unable to reset the password for this account.'
    case UpdatePasswordErrorType.Unknown:
    default:
      return 'We ran into an issue, please refresh and try again.'
  }
}

const SendVerification = (): JSX.Element => {
  const { startResetPassword } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const [hasSubmitted, setHasSubmitted] = React.useState(false)
  const [formValues, setFormValues] = React.useState<ResetPasswordPayload>({
    email: '',
  })
  const [errorType, setErrorType] = React.useState<UpdatePasswordErrorType | null>(null)

  const onFormFieldChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormValues((prev) => ({ ...prev, [name]: value }))
  }, [])

  const isFormValid = React.useMemo(() => {
    return validateForm(formValues)
  }, [formValues])

  const onSubmit = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()

      if (!isFormValid) {
        // this shouldn't happen because the form is disabled in this state, but just in case
        return
      }

      setErrorType(null)
      setLoading(true)

      startResetPassword({ email: formValues.email })
        .catch(() => {
          setLoading(false)
          setErrorType(UpdatePasswordErrorType.Unknown)
        })
        .then(() => {
          setHasSubmitted(true)
        })
    },
    [isFormValid, formValues, startResetPassword],
  )

  if (hasSubmitted) {
    return (
      <div>
        <div className="border-divider-light-gray flex w-full max-w-[400px] flex-col items-center justify-center gap-3 border-b p-6">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <PlusLogo width="48" height="48" />
            <h1 className="text-center text-xl font-semibold">Reset password</h1>
          </div>
        </div>
        <div className="p-6 text-center text-xs font-normal">
          We sent an email to {formValues.email} with instructions on how to reset your password.
          <BackToLoginLink className="mt-3 font-normal" />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="border-divider-light-gray flex w-full flex-col items-center justify-center gap-3 border-b p-6">
        <div className="flex w-full flex-col items-center justify-center gap-1">
          <PlusLogo width="48" height="48" />
          <h1 className="text-center text-xl font-semibold">Reset password</h1>
        </div>
      </div>
      <form
        onSubmit={onSubmit}
        className={clsx('space-y-3', 'flex flex-col p-6', errorType && 'text-copy-alert')}
      >
        {errorType && (
          <div className="text-xs font-normal">{getErrorTextFromErrorType(errorType)}</div>
        )}
        <div>
          <label htmlFor="email-input" className="text-base font-semibold">
            Work email
          </label>
          <Input
            id="email-input"
            name="email"
            type="email"
            value={formValues.email}
            autoComplete="username"
            onChange={onFormFieldChange}
          />
        </div>
        <div className="mt-3">
          <Button className="w-full" disabled={!isFormValid || loading}>
            {loading ? 'Loading...' : 'Reset Password'}
          </Button>
        </div>
        <BackToLoginLink />
      </form>
    </div>
  )
}

export default SendVerification
