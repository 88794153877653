import Modal from './Modal'

const InstallExtensionModal = () => (
  <Modal
    variant="primary"
    title={'Install browser extension'}
    content={"To perform this action, you'll need to install the Plus browser extension."}
    primaryButtonText="Install browser extension"
    onSubmit={() =>
      window.open(
        'https://chrome.google.com/webstore/detail/plus/bnebanooamokkihfjepphafoekheipfh',
        '_blank',
      )
    }
    secondaryButtonText="Cancel"
  />
)
export default InstallExtensionModal
