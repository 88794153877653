import { NetworkStatus } from '@apollo/client'
import { useCallback } from 'react'

import {
  IdentitiesQuery,
  IdentitiesQueryVariables,
  Identity,
  SnapshotFieldsFragment,
  useIdentitiesQuery,
} from '@/generated/graphql'

import { useAuth } from '../auth/useAuth'

export type IdentityWithSnapshotFields = Omit<Identity, 'snapshots'> & {
  readonly snapshots: readonly SnapshotFieldsFragment[]
}

interface UseIdentitiesHookResults {
  readonly identities: readonly IdentityWithSnapshotFields[]
  readonly isLoading: boolean
  readonly isLoadingMore: boolean
  readonly loadMore: () => void
  readonly networkStatus: NetworkStatus
  readonly refresh: () => void
  readonly totalCount: number
}

export const useIdentities = (
  variables: IdentitiesQueryVariables,
  onCompleted?: (data: IdentitiesQuery) => void,
): UseIdentitiesHookResults => {
  const auth = useAuth()
  const { data, error, fetchMore, networkStatus, refetch } = useIdentitiesQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted,
    skip: auth.loading || !auth.isLoggedIn(),
    variables,
  })

  if (error) {
    throw error
  }

  const pageInfo = data?.identities.pageInfo
  const totalCount = data?.identities.totalCount ?? 0

  const loadMore = useCallback(() => {
    if (!pageInfo?.hasNextPage) {
      return
    }

    void fetchMore({
      variables: { ...variables, after: pageInfo.endCursor },
    })
  }, [fetchMore, pageInfo, variables])

  const refresh = useCallback(() => void refetch(), [refetch])

  return {
    identities:
      data?.identities.edges.map((edge) => ({
        ...edge.node,
        snapshots: edge.node.snapshots.edges.map((snapshotEdge) => ({ ...snapshotEdge.node })),
      })) ?? [],
    isLoading: [NetworkStatus.loading, NetworkStatus.setVariables, NetworkStatus.refetch].includes(
      networkStatus,
    ),
    isLoadingMore: networkStatus === NetworkStatus.fetchMore,
    loadMore,
    networkStatus,
    refresh,
    totalCount,
  }
}
