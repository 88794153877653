import { getLikelyIdentityHost } from '@plusdocs/utils/common/string'
import { Link } from 'react-router-dom'

import { SnapshotFieldsFragment } from '@/generated/graphql'
import { Button, Connection, Label } from '@/shared/components'
import { ButtonTestId } from '@/shared/types/testIds'

import ConnectionStatusPill from '../Connections/ConnectionStatusPill'
import Favicon from '../Favicon'

export const ConnectionSettings = ({ snapshot }: { readonly snapshot: SnapshotFieldsFragment }) => {
  const identityId = snapshot.identity?.id
  const domainName = getLikelyIdentityHost(snapshot.instructions.url)

  if (!identityId) {
    return <></>
  }

  const connectionStatus = snapshot.identity?.connectionStatus

  if (!connectionStatus) {
    return <></>
  }

  return (
    <div className="flex flex-col gap-y-1">
      <Label htmlFor="sidebar-connection-button" className="mb-0 text-base font-semibold">
        Connection
      </Label>
      <Button
        id="sidebar-connection-button"
        variant="secondary"
        testId={ButtonTestId.ConnectionStatus}
        tooltip={
          <>
            Plus uses your browser&apos;s
            <br />
            connection to {domainName}
            <br />
            to take Snapshots.
          </>
        }
      >
        <Link
          to={`/${snapshot.organization.slug}/connection/${identityId}`}
          className="flex h-full w-full items-center justify-between gap-2"
        >
          <div>
            <Favicon defaultIcon={<Connection />} domainName={domainName} size="16" />
          </div>
          <div className="flex-grow truncate text-left">{domainName}</div>
          <ConnectionStatusPill status={connectionStatus} />
        </Link>
      </Button>
    </div>
  )
}
