import { useHistory } from 'react-router-dom'

import { Button, PlusLogo } from '@/shared/components'
import { isEmbed, openLink } from '@/util'
import PietroThumbsUp from '@/web/assets/pietro_thumbs_up.png'

const NotFound = (): JSX.Element => {
  const history = useHistory()
  const isEmbedded = isEmbed()

  if (isEmbedded) {
    return <EmbedNotFound />
  }

  return (
    <div className="mt-[33vh] flex h-full w-full flex-col items-center justify-center p-2 text-center">
      <PlusLogo width="32" />
      <p className="text-l mt-3 font-semibold md:text-xl">
        We couldn&apos;t find the page you&apos;re looking for.
      </p>
      <p className="mt-1 mb-3 text-base">
        The address you entered may be broken or the content may have been removed.
      </p>
      <Button onClick={() => history.push('/')}>Back to Plus</Button>
      <img className="absolute bottom-0 left-0" src={PietroThumbsUp} />
    </div>
  )
}

export default NotFound

const EmbedNotFound = (): JSX.Element => {
  return (
    <div className="border-divider-light-gray flex h-screen w-full flex-col items-center justify-center border p-2 text-center">
      <p className="text-l mt-3 font-semibold md:text-xl">
        We couldn&apos;t find the page you&apos;re looking for.
      </p>
      <p className="mt-1 mb-3 text-base">
        The address you entered may be broken or the content may have been removed.
      </p>
      <Button onClick={() => openLink(window.location.href)}>View on Plus</Button>
    </div>
  )
}
