import { useFolderQuery } from '@/generated/graphql'

export const useFolder = (folderId?: string) => {
  const { data, loading } = useFolderQuery({
    skip: !folderId,
    variables: { folderId: folderId ?? '' },
  })

  return {
    folder: data?.folder,
    loading,
  }
}

export default useFolder
