import { ApolloProvider } from '@apollo/client'
import clsx from 'clsx'
import { format } from 'date-fns'
import * as React from 'react'
import { useCallback } from 'react'
import { CheckCircle, ExternalLink, Link, Lock, Share, X } from 'react-feather'
import { Provider } from 'react-redux'

import {
  ContentPrivacy,
  Document,
  PublicDocument,
  PublicSnapshot,
  Snapshot,
  SnapshotDocument,
  SnapshotFieldsFragment,
  UserActivity,
  useSnapshotQuery,
  useUpdateContentPrivacyMutation,
  ViewerNavDataQuery,
} from '@/generated/graphql'
import { apolloClient } from '@/shared/apollo/apolloClient'
import {
  Button,
  Checkbox,
  DesktopDisplayOnly,
  Dialog,
  MobileDisplayOnly,
  Toast,
} from '@/shared/components'
import Select, { SelectOption } from '@/shared/components/Select'
import { useTrackUserActivity } from '@/shared/hooks/useTrackUserActivity'
import { copyTextToClipboard, getPublicLink, isEmbed, openLink, showModal } from '@/util'

import { useAppDispatch, useAppSelector, useViewer } from '../hooks'
import store from '../store'
import { selectDocument, updateProperties } from '../store/document'

interface IModalProps {
  content: Snapshot | Document
  versionInfo?: {
    id: string
    createdAt: string
  }
  activeOrganization: ViewerNavDataQuery['viewer']['organizations'][0] | undefined
}
// eslint-disable-next-line complexity
const ShareModal: React.FC<IModalProps> = ({
  content: _content,
  versionInfo,
  activeOrganization,
}: IModalProps) => {
  // we have to fetch the latest data in the modal, because showModal is not reactive
  const { data: snapshotData } = useSnapshotQuery({
    fetchPolicy: 'cache-only',
    skip: _content.__typename !== 'Snapshot',
    variables: {
      id: _content.id,
    },
  })
  const documentData = useAppSelector(selectDocument)
  const content =
    _content.__typename === 'Snapshot'
      ? (snapshotData?.snapshot as SnapshotFieldsFragment)
      : documentData

  const [open, setOpen] = React.useState(true)
  const dispatch = useAppDispatch()
  const [isCopied, setIsCopied] = React.useState(false)
  const [isPasscodeCopied, setIsPasscodeCopied] = React.useState(false)
  const [shareVersion, setShareVersion] = React.useState(false)

  const [updateContentPrivacy] = useUpdateContentPrivacyMutation({
    // Because the pages editor still uses redux, we need to use this callback
    // to update the redux store whenever the content privacy changes.
    onCompleted: (data) => {
      if (content?.__typename === 'Document' && data.result.content?.properties) {
        void dispatch(updateProperties(data.result.content?.properties))
      }
    },

    refetchQueries: content?.__typename === 'Snapshot' ? [SnapshotDocument] : [],
  })
  const trackUserActivity = useTrackUserActivity()

  const isEmbedded = isEmbed()

  const isTeamContent = !!content?.team
  const contentPrivacyOptions = getPrivacyOptions(isTeamContent, activeOrganization?.name || '')

  const contentPrivacyOption =
    (content &&
      (content.__typename === 'Document' || content.__typename == 'Snapshot') &&
      contentPrivacyOptions.find((c) => c.value === content?.properties?.privacy)) ||
    contentPrivacyOptions[0]

  const isPrivateEmbed =
    !content?.team && isEmbedded && contentPrivacyOption.value === ContentPrivacy.Private

  const shareUrl = getPublicLink(
    content,
    activeOrganization?.slug || '',
    false,
    shareVersion && versionInfo ? versionInfo.id : undefined,
  )

  const onChangeContentPrivacy = (selectedOption: SelectOption) => {
    void updateContentPrivacy({
      variables: {
        id: content.id,
        input: {
          privacy: selectedOption.value as ContentPrivacy,
        },
      },
    })
  }

  const onCopyLinkPress = () => {
    trackUserActivity(UserActivity.CopyLinkClicked, content.urn)

    void copyTextToClipboard(shareUrl)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 5000)
  }

  const onCopyPasscodePress = () => {
    void copyTextToClipboard(content.properties?.accessCode ?? '')
    setIsPasscodeCopied(false)
    setTimeout(() => {
      setIsPasscodeCopied(true)
    }, 5000)
  }

  return (
    <Dialog isOpen={open} setIsOpen={setOpen} showBackgroundOverlay>
      <div className="border-divider-light-gray bg-background-white mx-auto mt-3 inline-block w-full max-w-[432px] transform rounded-md border text-left align-top transition-all md:mt-12">
        <Button
          className="absolute right-1 top-1 h-8 w-8"
          variant="ghost"
          tabIndex={-1}
          onClick={() => setOpen(false)}
        >
          <X size={16} />
        </Button>
        <div className="flex h-full flex-col gap-3 p-3">
          <span className="text-lg font-semibold">
            {getShareModalHeaderText(isEmbedded, contentPrivacyOption.value, !!content?.team)}
          </span>
          {isPrivateEmbed && (
            <span className="text-interactive-destructive text-base">
              Change the privacy setting for this document to allow others to view it
            </span>
          )}
          <Select
            className={clsx(isPrivateEmbed && 'border-interactive-destructive')}
            value={
              contentPrivacyOptions.find(
                (option) => option.value === contentPrivacyOption?.value,
              ) || contentPrivacyOptions[0]
            }
            onChange={onChangeContentPrivacy}
            options={contentPrivacyOptions}
            selectedValueLabel={(option: SelectOption) => option.label}
          />
          {(contentPrivacyOption?.value !== ContentPrivacy.Private ||
            (contentPrivacyOption?.value === ContentPrivacy.Private && isTeamContent)) && (
            <>
              {versionInfo && (
                <Checkbox
                  checked={shareVersion}
                  onChange={(e) => setShareVersion(e.target.checked)}
                >
                  Link to this version: {format(new Date(versionInfo.createdAt), 'p')} on{' '}
                  {format(new Date(versionInfo.createdAt), 'LLL dd y')}
                </Checkbox>
              )}
              <div className="flex flex-row gap-x-2">
                <div className="flex">
                  <Button variant="primary" className="rounded-r-none" onClick={onCopyLinkPress}>
                    {isCopied ? 'Copied!' : 'Copy link'}
                  </Button>
                  <div className="bg-interactive-dark-active h-[32px] w-[1px]"></div>
                  <Button
                    variant="primary"
                    className="rounded-l-none border-l-0 focus:border-l-0 active:border-l-0"
                    tooltip="Open in new window"
                    tooltipPlacement="bottom"
                    onClick={() => {
                      openLink(shareUrl)
                    }}
                  >
                    <ExternalLink size={16} />
                  </Button>
                </div>
                {contentPrivacyOption?.value === ContentPrivacy.Restricted && (
                  <div className="flex">
                    <Button variant="secondary" onClick={onCopyPasscodePress}>
                      {isPasscodeCopied ? 'Copied!' : 'Copy passcode'}
                    </Button>
                    <div className="flex items-center gap-x-1 pl-1 text-base">
                      <Lock className="hidden md:block" size={16} />
                      {content.properties?.accessCode}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {isPrivateEmbed && (
            <a className="w-fit" href={shareUrl} target="_blank" rel="noreferrer">
              <Button variant="secondary">View on Plus</Button>
            </a>
          )}
        </div>
      </div>
    </Dialog>
  )
}

interface ITriggerProps {
  content: SnapshotFieldsFragment | Document | PublicDocument | PublicSnapshot
  versionInfo?: {
    id: string
    createdAt: string
  }
  appLinkOverride?: string
  isNewTab?: boolean
}

export const ShareModalTrigger: React.FC<ITriggerProps> = ({
  content,
  versionInfo,
}: ITriggerProps) => {
  const { activeOrganization } = useViewer()
  const [hasMounted, setHasMounted] = React.useState(false)
  const trackUserActivity = useTrackUserActivity()

  const isEmbedded = isEmbed()
  const isEditor = content.viewer?.canEdit ?? false

  const onEditorShareButtonPress = useCallback(() => {
    void showModal(
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <ShareModal
            content={content as Snapshot | Document}
            versionInfo={versionInfo}
            activeOrganization={activeOrganization}
          />
        </ApolloProvider>
      </Provider>,
    )
  }, [activeOrganization, content, versionInfo])

  const onPublicShareButtonPress = useCallback(() => {
    void copyTextToClipboard(getPublicLink(content, activeOrganization?.slug || '', !isEditor))
    void showModal(
      <Toast>
        <CheckCircle size={15} className="text-interactive-primary mr-2" />
        <span className="text-base">Link copied!</span>
      </Toast>,
    )
  }, [activeOrganization?.slug, content, isEditor])

  const onCopyLinkButtonPress = () => {
    trackUserActivity(UserActivity.CopyLinkClicked, content.urn)
    void copyTextToClipboard(getPublicLink(content, activeOrganization?.slug || '', false))
    void showModal(
      <Toast>
        <CheckCircle size={15} className="text-interactive-primary mr-2" />
        <span className="text-base">Link copied!</span>
      </Toast>,
    )
  }

  const onShareButtonPress = useCallback(() => {
    trackUserActivity(UserActivity.ShareModalOpened, content.urn)
    if (isEditor && (content.__typename === 'Snapshot' || content.__typename === 'Document')) {
      onEditorShareButtonPress()
    } else {
      onPublicShareButtonPress()
    }
  }, [
    content.__typename,
    content.urn,
    isEditor,
    onEditorShareButtonPress,
    onPublicShareButtonPress,
    trackUserActivity,
  ])

  // OnMount Effect to auto-open modal for embeds
  // that cannot be viewed by any users outside the original embedder
  React.useEffect(() => {
    if (!hasMounted && (content.__typename === 'Document' || content.__typename === 'Snapshot')) {
      if (
        isEmbedded &&
        isEditor &&
        !content.team &&
        content.properties.privacy === ContentPrivacy.Private
      ) {
        setHasMounted(true)
        onShareButtonPress()
      }
    }
  }, [content, hasMounted, isEditor, isEmbedded, onShareButtonPress])

  if (isEmbedded) {
    return (
      <Button className="!p-0 text-base" onClick={onShareButtonPress} variant={'ghost'} size="tiny">
        <span className="p-1">Share</span>
      </Button>
    )
  }

  return (
    <>
      <DesktopDisplayOnly>
        <div className="flex flex-row" data-star-tours-id="share-button">
          <Button
            className={clsx('!p-0 text-base', {
              'border-r-interactive-dark-active !rounded-l !rounded-r-none border-r': isEditor,
            })}
            variant="secondary-dark"
            onClick={onShareButtonPress}
          >
            <span className="whitespace-nowrap p-2">{isEditor ? 'Share' : 'Copy link'}</span>
          </Button>
          {isEditor && (
            <Button
              className="!rounded-r !rounded-l-none border-l-0 !p-0 text-base active:!border-l-0"
              variant="secondary-dark"
              onClick={onCopyLinkButtonPress}
            >
              <span className="p-2">
                <Link size={16} />
              </span>
            </Button>
          )}
        </div>
      </DesktopDisplayOnly>

      <MobileDisplayOnly>
        <Button
          className="whitespace-nowrap !p-0 text-base"
          onClick={onShareButtonPress}
          variant="secondary-dark"
        >
          <div className="p-2">{isEditor ? <Share size={16} /> : <Link size={16} />}</div>
        </Button>
      </MobileDisplayOnly>
    </>
  )
}

const getPrivacyOptions = (
  isTeamContent: boolean,
  orgName: string,
): {
  label: string
  value: ContentPrivacy
}[] => {
  if (!isTeamContent) {
    return [
      { label: 'Only I can see this', value: ContentPrivacy.Private },
      {
        label: 'Anyone with the link can see this',
        value: ContentPrivacy.Public,
      },
      {
        label: 'Anyone with the passcode can see this',
        value: ContentPrivacy.Restricted,
      },
    ]
  }

  return [
    {
      label: `Anyone at ${orgName} can see this`,
      value: ContentPrivacy.Private,
    },
    {
      label: 'Anyone with the link can see this',
      value: ContentPrivacy.Public,
    },
    {
      label: `Anyone with the passcode or at ${orgName} can see this`,
      value: ContentPrivacy.Restricted,
    },
  ]
}

const getShareModalHeaderText = (
  isEmbedded: boolean,
  privacy: ContentPrivacy,
  isTeam: boolean,
): JSX.Element => {
  if (!isEmbedded) {
    return <span>Share via link</span>
  }

  if (!isTeam && privacy === ContentPrivacy.Private) {
    return <span className="text-interactive-destructive">Only you can see this</span>
  }

  return <span>Share</span>
}
