import {
  DocumentCardFragment,
  FolderCardFragment,
  Maybe,
  OrderDirection,
  SearchResultOrderField,
  SnapshotFieldsFragment,
} from '@/generated/graphql'
import { useContentGridSearch } from '@/web/hooks/useSearch'

export const useContentGridData = (
  teamId: string | undefined,
  folderId: string | undefined,
  sortField: Maybe<SearchResultOrderField> | undefined,
  organizationUrn: string | undefined,
  shouldSkip?: boolean,
) => {
  const { results, loading, refresh, counts } = useContentGridSearch(
    {
      filter: {
        // TODO: Remove once graphQL teams-safety is added
        contentIOwn: teamId ? false : true,
        folders: folderId ? [folderId] : [],
        teams: teamId ? [teamId] : [],
      },
      sort: {
        direction:
          sortField === SearchResultOrderField.Name ? OrderDirection.Asc : OrderDirection.Desc,
        field: sortField || SearchResultOrderField.CreatedAt,
      },
    },
    organizationUrn,
    shouldSkip,
  )

  return {
    counts,
    documents: (results?.documents ?? []) as DocumentCardFragment[],
    folders: (results?.folders ?? []) as FolderCardFragment[],
    loading,
    refresh,
    snapshots: (results?.snapshots ?? []) as SnapshotFieldsFragment[],
  }
}
