import { ApolloProvider } from '@apollo/client'
import { StarTourProvider, StarTourRenderer } from '@plusdocs/star-tours'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import * as React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'

import { apolloClient } from '@/shared/apollo/apolloClient'
import { AuthProvider } from '@/shared/auth/AuthProvider'
import { StatsigProvider } from '@/shared/components/StatsigProvider'
import { ViewerProvider } from '@/shared/providers/ViewerProvider'

import Tours from './fixtures/tours'
import { useViewer } from './hooks'
import store from './store'

interface AppProvidersProps {
  readonly children: React.ReactNode
}

function AppStatsigProvider({ children }: AppProvidersProps): JSX.Element {
  const { viewer } = useViewer()

  return (
    <StatsigProvider viewer={viewer} client="web-app">
      {children}
    </StatsigProvider>
  )
}

export default function AppProviders({ children }: AppProvidersProps): JSX.Element {
  return (
    <HelmetProvider>
      <AuthProvider>
        <ApolloProvider client={apolloClient}>
          <ViewerProvider>
            <ReduxProvider store={store}>
              <DndProvider backend={HTML5Backend}>
                <TooltipProvider>
                  <AppStatsigProvider>
                    <StarTourProvider tours={Tours}>
                      {children}
                      <StarTourRenderer />
                    </StarTourProvider>
                  </AppStatsigProvider>
                </TooltipProvider>
              </DndProvider>
            </ReduxProvider>
          </ViewerProvider>
        </ApolloProvider>
      </AuthProvider>
    </HelmetProvider>
  )
}
