import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import appState from './appState'
import documentState from './document'
import history from './history'
import snapshotsState from './snapshots'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    const type = action.type as string
    if (type.includes('history/')) return null
    return action
  },
  stateTransformer: (state) => {
    return {
      ...state,
      history: null,
    }
  },
})

const store = configureStore({
  enhancers:
    import.meta.env.MODE === 'production'
      ? (defaultEnhancers) => [...defaultEnhancers, sentryReduxEnhancer]
      : undefined,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    appState,
    document: documentState,
    history,
    snapshotsState,
  },
})

export default store

/**
 * Testing utility
 * NOT FOR USE OUTSIDE TESTS
 * @returns
 */
export const __createMockStore__ = (): typeof store =>
  configureStore({
    reducer: {
      appState,
      document: documentState,
      history,
      snapshotsState,
    },
  })
