import { useReactiveVar } from '@apollo/client'
import { getLikelyIdentityHost } from '@plusdocs/utils/common/string'
import { buildSnapshotVersionUrn } from '@plusdocs/utils/common/urns'
import {
  ScrollArea,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '@radix-ui/react-scroll-area'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { SnapshotFieldsFragment, useDismissRepairSuggestionMutation } from '@/generated/graphql'
import { extensionClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Button, InstallExtensionModal, OwnerAttribution } from '@/shared/components'
import useRefreshSnapshot from '@/shared/hooks/useRefreshSnapshot'
import { showModal } from '@/util'
import { createReauthHandler, createRepairHandler } from '@/util/helpers'
import { getSnapshotStatus } from '@/util/snapshotHelpers'
import { useViewer } from '@/web/hooks'
import { useReportSnapshotIssueCallback } from '@/web/hooks/useReportSnapshotIssueCallback'
import { useSnapshotDelete } from '@/web/hooks/useSnapshotDelete'

import {
  ChangeNotificationSetting,
  ConnectionSettings,
  IdentitySettings,
  RefreshButton,
  RefreshSettings,
  RepairButton,
  TitleAndDescriptionSettings,
} from '../SnapshotSidebarSettings'
import { RepairSnapshotSidebar } from './RepairSnapshotSidebar'

const Divider = () => {
  return <div className="bg-divider-light-gray -mx-3 h-[1px]" />
}

const LoadingPlaceholder = (
  <div className="border-divider-light-gray bg-background-panel md:h-viewport-minus-header  h-full w-full overflow-y-auto py-3 px-3 leading-4 md:w-[248px] md:border-l" />
)

// eslint-disable-next-line complexity
const SnapshotSidebarInner = ({
  onDismissRepairHandler,
  onLoginHandler,
  onRefreshHandler,
  onRepairHandler,
  onReportIssueHandler,
  snapshot,
}: {
  onDismissRepairHandler?: () => void
  onLoginHandler: () => void
  onRefreshHandler: () => void
  onRepairHandler: () => void
  onReportIssueHandler: () => void
  snapshot: SnapshotFieldsFragment
}): JSX.Element => {
  const { activeOrganization } = useViewer()
  const {
    isRefreshingSnapshot,
    isFirstCapture,
    snapshotHasError,
    snapshotSessionExpired,
    snapshotNeedsRepair,
  } = getSnapshotStatus(snapshot)

  const isDeletedUser = snapshot.createdBy.id === '__DELETED_USER__'

  const domainName = getLikelyIdentityHost(snapshot.instructions.url)

  return (
    <ScrollArea>
      <ScrollAreaViewport>
        <div className="border-divider-light-gray bg-background-panel lg:h-viewport-minus-header h-full w-full py-3 px-3 pb-24 leading-4 md:border md:border-t-0 md:pb-0 lg:w-[248px] lg:border-l">
          <div className="flex h-full flex-col space-y-3">
            {!isRefreshingSnapshot && isDeletedUser ? (
              <div className="text-copy-alert flex flex-col text-xs">
                <span className="font-semibold">Refresh disabled</span>
                <span>
                  This Snapshot can&apos;t be refreshed because its owner was removed from your Plus
                  organization.
                </span>
              </div>
            ) : (
              (snapshotHasError || snapshotSessionExpired) && (
                <>
                  {isRefreshingSnapshot ? (
                    <div className="flex flex-col text-xs">
                      <span className="font-semibold">Repairing Snapshot</span>
                      <span>Your Snapshot will update soon.</span>
                    </div>
                  ) : (
                    <>
                      {snapshotNeedsRepair || snapshotSessionExpired ? (
                        <div className="text-copy-alert flex flex-col text-xs">
                          <span className="font-semibold">Refresh paused</span>
                          <span>
                            {snapshot.viewer.isOwner
                              ? `This Snapshot won't refresh until the connection to ${domainName} is repaired.`
                              : `This Snapshot won't refresh until it's repaired by its owner.`}
                            {snapshot.viewer.isOwner && (
                              <span className="inline md:hidden">
                                {' '}
                                Snapshots can only be repaired on a desktop browser.
                              </span>
                            )}
                          </span>
                        </div>
                      ) : (
                        <div className="text-copy-alert flex flex-col text-xs">
                          <span className="font-semibold">Capture Failed</span>
                          <span>
                            We’ll automatically try to capture this Snapshot again when its time for
                            its next scheduled refresh.
                          </span>
                        </div>
                      )}
                    </>
                  )}

                  <Divider />
                </>
              )
            )}
            <div className="flex flex-col space-y-2">
              <RefreshButton
                onClickRefreshSnapshot={onRefreshHandler}
                isLoggedOut={snapshotSessionExpired}
                needsRepair={snapshotSessionExpired || snapshotNeedsRepair}
                refreshOrCaptureState={
                  isFirstCapture ? 'capturing' : isRefreshingSnapshot ? 'refreshing' : 'none'
                }
              />
              <a
                href={snapshot.instructions.url}
                target="_blank"
                className="flex w-full"
                rel="noreferrer"
              >
                <Button variant="secondary" className="w-full">
                  Visit source
                </Button>
              </a>
              {snapshot.viewer.isOwner && (
                <RepairButton
                  domainName={domainName}
                  isDisabled={isFirstCapture || isRefreshingSnapshot}
                  onClickSnapshotNotBroken={onDismissRepairHandler}
                  onClickReauthSnapshot={onLoginHandler}
                  onClickReportSnapshotIssue={onReportIssueHandler}
                  onClickRetakeSnapshot={onRepairHandler}
                />
              )}
            </div>

            <Divider />

            <OwnerAttribution
              userName={snapshot.createdBy.name}
              userImageUrl={snapshot.createdBy.image}
              userEmail={snapshot.createdBy.email}
            />

            {snapshot.viewer.isOwner && <ConnectionSettings snapshot={snapshot} />}

            <Divider />

            <TitleAndDescriptionSettings
              snapshot={snapshot}
              labels={{
                description: 'Snapshot description',
                title: 'Snapshot title',
              }}
            />
            <RefreshSettings snapshot={snapshot} />
            {!activeOrganization?.currentPlan?.id && activeOrganization?.viewer.isOwner && (
              <Link
                to={`/${activeOrganization?.slug ?? ''}/settings/organization`}
                className="w-full"
              >
                <Button className="w-full" variant="upgrade-gradient">
                  Unlock more refresh rates
                </Button>
              </Link>
            )}
            <IdentitySettings
              snapshot={snapshot}
              isOwner={snapshot.viewer.isOwner}
              showLogBackInModal={false}
            />
            <ChangeNotificationSetting snapshot={snapshot} />
          </div>
        </div>
      </ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="vertical" className="p-1">
        <ScrollAreaThumb className="text-divider-dark-gray rounded border-2" />
      </ScrollAreaScrollbar>
    </ScrollArea>
  )
}

// eslint-disable-next-line complexity
export const SnapshotSidebarContainer = ({
  snapshot,
  snapshotVersionId,
}: {
  snapshot: SnapshotFieldsFragment
  snapshotVersionId: string
}): JSX.Element => {
  const [userDismissedRepairSidebar, setUserDismissedRepairSidebar] = useState(false)
  const dismissRepairSidebarHandler = () => setUserDismissedRepairSidebar(true)

  const { extensionRuntimeId, isExtensionInstalled } = useReactiveVar(extensionClientStateVar)

  const { isRefreshingSnapshot, primaryRepairOption, snapshotNeedsRepair, snapshotSessionExpired } =
    getSnapshotStatus(snapshot)

  const isRefreshingFailedLogin = snapshotSessionExpired && isRefreshingSnapshot

  const onRefreshSnapshotClick = useRefreshSnapshot(snapshot.id)

  // Effect to handle re-enabling the repair sidebar when the user batch navigates to a new snapshot
  useEffect(() => {
    setUserDismissedRepairSidebar(false)
  }, [snapshot.id])

  const onLogBackInClick = useCallback(() => {
    if (!isExtensionInstalled) {
      void showModal(<InstallExtensionModal />)
      return
    }

    return createReauthHandler({
      extensionRuntimeId,
      orgId: snapshot.organization.id,
      orgSlug: snapshot.organization.slug,
      snapshotId: snapshot.id,
      snapshotImageUrl: snapshot.currentVersion?.image ?? '',
      snapshotUrl: snapshot.instructions.url,
    })()
  }, [
    isExtensionInstalled,
    snapshot.currentVersion?.image,
    snapshot.id,
    snapshot.instructions.url,
    extensionRuntimeId,
    snapshot.organization.id,
    snapshot.organization.slug,
  ])

  const onRepairClick = useCallback(() => {
    if (!isExtensionInstalled) {
      void showModal(<InstallExtensionModal />)
      return
    }
    return createRepairHandler({
      extensionRuntimeId,
      orgId: snapshot.organization.id,
      orgSlug: snapshot.organization.slug,
      snapshotId: snapshot.id,
      snapshotImageUrl: snapshot.currentVersion?.image ?? '',
      snapshotUrl: snapshot.instructions.url,
    })()
  }, [
    isExtensionInstalled,
    snapshot.currentVersion?.image,
    snapshot.id,
    snapshot.instructions.url,
    extensionRuntimeId,
    snapshot.organization.id,
    snapshot.organization.slug,
  ])

  const [dismissRepairSuggestion] = useDismissRepairSuggestionMutation({
    variables: {
      id: snapshot.id,
    },
  })

  const onDeleteSnapshot = useSnapshotDelete(snapshot)

  const onDismissRepairSuggestionClick = snapshotNeedsRepair
    ? () => void dismissRepairSuggestion()
    : undefined

  const onReportIssueClick = useReportSnapshotIssueCallback(
    buildSnapshotVersionUrn({
      organizationId: snapshot.organization.id,
      snapshotId: snapshot.id,
      versionId: snapshotVersionId,
    }),
  )

  const hasRepairSidebarPermission =
    snapshot.viewer.isOwner || (snapshot.viewer.canEdit && primaryRepairOption === 'captureFailed')

  if (
    primaryRepairOption &&
    hasRepairSidebarPermission &&
    !userDismissedRepairSidebar &&
    !isRefreshingFailedLogin &&
    (isExtensionInstalled || primaryRepairOption === 'captureFailed')
  ) {
    return (
      <RepairSnapshotSidebar
        domainName={getLikelyIdentityHost(snapshot.instructions.url)}
        handleDeleteSnapshot={onDeleteSnapshot}
        handleDimissRepair={onDismissRepairSuggestionClick}
        handleHideThisMessage={dismissRepairSidebarHandler}
        handleReconnect={onLogBackInClick}
        handleReportIssue={onReportIssueClick}
        handleRetakeSnapshot={onRepairClick}
        handleRetryCapture={onRefreshSnapshotClick}
        primaryRepairOption={primaryRepairOption}
        refreshOrCaptureState={isRefreshingSnapshot ? 'refreshing' : 'none'}
        snapshotId={snapshot.id}
      />
    )
  }

  return (
    <SnapshotSidebarInner
      onDismissRepairHandler={onDismissRepairSuggestionClick}
      onLoginHandler={onLogBackInClick}
      onRefreshHandler={onRefreshSnapshotClick}
      onRepairHandler={onRepairClick}
      onReportIssueHandler={onReportIssueClick}
      snapshot={snapshot}
    />
  )
}

export const SnapshotSidebar = ({
  snapshot,
  snapshotVersionId,
}: {
  snapshot: SnapshotFieldsFragment
  snapshotVersionId?: string
}): JSX.Element => {
  if (!snapshot) {
    return LoadingPlaceholder
  }

  return (
    <SnapshotSidebarContainer snapshot={snapshot} snapshotVersionId={snapshotVersionId ?? ''} />
  )
}
