import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu'
import { Popover, PopoverContent, PopoverPortal, PopoverTrigger } from '@radix-ui/react-popover'
import cx from 'clsx'
import { useState } from 'react'
import * as React from 'react'
import { Menu, RefreshCw, Sliders, Type } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'

import { Document, useDeleteDocumentMutation } from '@/generated/graphql'
import { deleteFromCacheDuringMutation } from '@/shared/apollo/apolloClient'
import SnapshotIcon from '@/shared/components/Icons/Snapshot'
import type { SnapshotPageObject } from '@/shared/types/document'
import { generateNewPageUrl, showModal } from '@/util'
import documentSync from '@/util/documentSync'
import { useAppDispatch, useAppSelector, useViewer } from '@/web/hooks'
import { matchSidebarState, toggleSidebar } from '@/web/store/appState'
import { selectDocument, selectObjectsOfTypeOnPage, updateTitle } from '@/web/store/document'
import { refreshSnapshot, selectAllStatuses } from '@/web/store/snapshots'

import { Button, DesktopDisplayOnly, Modal } from '../../../shared/components'
import { useMoveToFolderCallback } from '../Feed/actions'
import { HeaderBreadcrumbs } from '../HeaderBreadcrumbs'
import { ShareModalTrigger } from '../ShareModal'
import SnapshotsPopover from '../SnapshotsPopover'
import TextPopover from '../TextPopover'

export function EditorHeader({
  documentTitle,
  isSyncing,
}: {
  documentTitle: string | null
  isSyncing: boolean
}): JSX.Element {
  const dispatch = useAppDispatch()
  const statuses = useAppSelector(selectAllStatuses)
  const [isSnapshotsPopoverOpen, setIsSnapshotsPopoverOpen] = useState(false)
  const [isTextPopoverOpen, setIsTextPopoverOpen] = useState(false)
  const document = useAppSelector(selectDocument)
  const isSidebarOpen = useAppSelector(matchSidebarState('open'))
  const [open, setOpen] = useState(false)
  const [deleteDocument] = useDeleteDocumentMutation()

  const { activeOrganization } = useViewer()

  const history = useHistory()

  const areAnySnapshotsRefreshing = React.useMemo(() => statuses.includes('refreshing'), [statuses])

  const onDeletePageClick = React.useCallback(() => {
    const deleteDocumentCallback = async () => {
      const backLink = generateBackToDashboardLink(document, activeOrganization?.slug)
      documentSync.clear()
      await deleteDocument({
        update: deleteFromCacheDuringMutation({
          __typename: 'Document',
          id: document.id,
        }),
        variables: {
          id: document.id,
        },
      })
      history.replace(backLink)
    }

    showModal(
      <Modal
        variant="destructive"
        title="Delete Page?"
        content={`This page will will be permanently deleted.`}
        primaryButtonText="Delete"
        onSubmit={deleteDocumentCallback}
        secondaryButtonText="Cancel"
      />,
    )
  }, [activeOrganization, deleteDocument, document, history])
  const onMovePageClick = useMoveToFolderCallback([document])

  const onChangeTitle = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTitle(value))
  }

  const setDefaultTitle = () => {
    if (!documentTitle || documentTitle === '') {
      dispatch(updateTitle('Untitled page'))
    }
  }

  const snapshotObjects = useAppSelector(
    (state) => selectObjectsOfTypeOnPage(state, 'Snapshot') as SnapshotPageObject[],
  )

  const onClickRefreshAllSnapshots = React.useCallback(() => {
    const uniqueSnapshotIds = Array.from(new Set(snapshotObjects.map((obj) => obj.content.id)))

    uniqueSnapshotIds.forEach((snapshotId) => {
      dispatch(refreshSnapshot(snapshotId))
    })
  }, [dispatch, snapshotObjects])

  return (
    <div
      className="border-divider-light-gray bg-background-panel grid h-12 w-full border-b px-3"
      style={{ gridTemplateColumns: '1fr auto 1fr', gridTemplateRows: '3rem' }}
    >
      <div className="flex max-w-full items-center overflow-visible text-base">
        <DropdownMenu open={open} onOpenChange={setOpen} modal={false}>
          <DropdownMenuTrigger asChild>
            <Button
              icon={<Menu size={16} />}
              variant="ghost"
              aria-hidden="true"
              className={cx('focus:outline-none', {
                '!bg-interactive-secondary-active': open,
                '!border-divider-light-blue': open,
                '!border-transparent': !open,
                '!text-interactive-primary': open,
                'focus:!border-divider-light-blue': open,
              })}
              active={open}
            />
          </DropdownMenuTrigger>
          <DropdownMenuPortal>
            <DropdownMenuContent className="font-base border-divider-light-gray bg-background-white shadow-soft w-52 rounded-md border text-base outline-none">
              <DropdownMenuItem asChild>
                <div className="px-2 pt-2 outline-none">
                  <Link
                    className="hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover flex cursor-pointer rounded-md p-2 outline-none"
                    to={generateBackToDashboardLink(document, activeOrganization?.slug)}
                  >
                    <span className="line-clamp-1 text-ellipsis">
                      Back to {document.team ? 'Team' : 'Personal'}
                      {document.folder && ' / ' + document.folder.name}
                    </span>
                  </Link>
                </div>
              </DropdownMenuItem>
              <div className="bg-divider-light-gray my-2 h-[1px] w-full"></div>

              <DropdownMenuItem asChild>
                <div className="hidden px-2 outline-none md:block">
                  <Link
                    className="hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover flex cursor-pointer rounded-md p-2 outline-none"
                    to={generateNewPageUrl(
                      activeOrganization?.slug,
                      document.team?.id,
                      document.folder?.id,
                    )}
                  >
                    Create new Page
                  </Link>
                </div>
              </DropdownMenuItem>

              <div className="px-2">
                <DropdownMenuItem
                  className="hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover flex cursor-pointer rounded-md p-2 outline-none"
                  onClick={onMovePageClick}
                >
                  Move Page
                </DropdownMenuItem>
              </div>
              <div className="px-2 pb-2">
                <DropdownMenuItem
                  className="hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover flex cursor-pointer rounded-md p-2 outline-none"
                  onClick={onDeletePageClick}
                >
                  Delete Page
                </DropdownMenuItem>
              </div>
            </DropdownMenuContent>
          </DropdownMenuPortal>
        </DropdownMenu>
        {document?.id && (
          <HeaderBreadcrumbs
            content={document}
            name={documentTitle ?? ''}
            onChangeName={onChangeTitle}
            onBlurName={setDefaultTitle}
          />
        )}
        {isSyncing && <div className="text-interactive-primary p-2 pt-2.5">Autosaving…</div>}
      </div>
      <div className="flex items-center space-x-2 px-4">
        <DesktopDisplayOnly>
          <Popover open={isTextPopoverOpen} onOpenChange={setIsTextPopoverOpen}>
            <PopoverTrigger asChild>
              <Button
                icon={<Type size={16} />}
                variant="ghost"
                aria-hidden="true"
                className={cx('focus:outline-none', {
                  '!bg-interactive-secondary-active': isTextPopoverOpen,
                  '!border-divider-light-blue': isTextPopoverOpen,
                  '!border-transparent': !isTextPopoverOpen,
                  '!text-interactive-primary': isTextPopoverOpen,
                  'focus:!border-divider-light-blue': isTextPopoverOpen,
                })}
              />
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent onOpenAutoFocus={(ev) => ev.preventDefault()}>
                <TextPopover />
              </PopoverContent>
            </PopoverPortal>
          </Popover>
          <div>
            <Popover open={isSnapshotsPopoverOpen} onOpenChange={setIsSnapshotsPopoverOpen}>
              <PopoverTrigger asChild>
                <Button
                  icon={
                    <SnapshotIcon
                      className={cx({
                        'fill-interactive-primary': isSnapshotsPopoverOpen,
                      })}
                    />
                  }
                  variant="ghost"
                  aria-hidden="true"
                  className={cx('focus:outline-none', {
                    '!bg-interactive-secondary-active': isSnapshotsPopoverOpen,
                    '!border-divider-light-blue': isSnapshotsPopoverOpen,
                    '!border-transparent': !isSnapshotsPopoverOpen,
                    '!text-interactive-primary': isSnapshotsPopoverOpen,
                    'focus:!border-divider-light-blue': isSnapshotsPopoverOpen,
                  })}
                />
              </PopoverTrigger>
              <PopoverPortal>
                <PopoverContent>
                  <SnapshotsPopover />
                </PopoverContent>
              </PopoverPortal>
            </Popover>
          </div>
          <Button
            icon={
              <RefreshCw
                size={16}
                className={cx({
                  'motion-safe:animate-spin-slow': areAnySnapshotsRefreshing,
                })}
              />
            }
            variant="ghost"
            onClick={onClickRefreshAllSnapshots}
            className={cx('focus:outline-none')}
            tooltip="Refresh all Snapshots"
            tooltipPlacement="bottom"
            disabled={areAnySnapshotsRefreshing}
          />
        </DesktopDisplayOnly>
      </div>
      <div className="flex items-center justify-end space-x-2">
        <DesktopDisplayOnly>
          <Button
            icon={<Sliders size={16} />}
            variant="ghost"
            aria-hidden="true"
            className={cx('focus:outline-none', {
              '!bg-interactive-secondary-active': isSidebarOpen,
              '!border-divider-light-blue': isSidebarOpen,
              '!border-transparent': !isSidebarOpen,
              '!text-interactive-primary': isSidebarOpen,
            })}
            onClick={() => dispatch(toggleSidebar())}
          ></Button>
        </DesktopDisplayOnly>
        <ShareModalTrigger content={document} />
      </div>
    </div>
  )
}

const generateBackToDashboardLink = (document: Document, activeOrgSlug?: string) => {
  const folderPath = document.folder && `/folders/${document.folder.id}`
  const teamPath = document.team && `/team/${document.team.id}`

  if (!activeOrgSlug) {
    return '/'
  }

  return `/${activeOrgSlug}${teamPath || ''}${folderPath || ''}`
}
