import { useReactiveVar } from '@apollo/client'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import { contentPrivacyStateVar } from '@/shared/apollo/apolloLocalState'
import { Button, Input } from '@/shared/components'
import PlusLogo from '@/shared/components/PlusLogo'

interface IPasswordPromptForContentProps {
  onSubmit: () => void
  message?: string
}

const PasswordPromptForContent = ({
  onSubmit,
  message,
}: IPasswordPromptForContentProps): JSX.Element => {
  const { password } = useReactiveVar(contentPrivacyStateVar)

  const { documentId, snapshotId } = useParams<{
    documentId?: string
    snapshotId?: string
  }>()

  const onInputPassword = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const password = value.replace(/\D/g, '')
    contentPrivacyStateVar({
      id: documentId || snapshotId,
      password,
    })
  }

  return (
    <div className="bg-background-canvas fixed flex h-full w-full items-center justify-center">
      <div className="border-divider-light-gray bg-background-white shadow-soft w-[300px] rounded border p-3">
        <div className="mb-3 flex w-full justify-center">
          <PlusLogo />
        </div>
        <form
          className="mx-auto flex w-full flex-col gap-y-2"
          onSubmit={(ev) => {
            ev.preventDefault()
            onSubmit()
          }}
        >
          {message && (
            <span className="text-copy-alert text-center text-base font-semibold">{message}</span>
          )}
          <label className="text-copy text-base font-semibold" htmlFor="document-password">
            Document passcode
          </label>
          <Input
            id="document-password"
            value={password ?? ''}
            name="password"
            onInput={onInputPassword}
            maxLength={6}
            autoFocus
          />
          <Button variant="primary" className="w-full" disabled={!password}>
            Submit
          </Button>
        </form>
      </div>
    </div>
  )
}

export default PasswordPromptForContent
