import clsx from 'clsx'
import { ReactNode } from 'react'
import { ConnectDropTarget } from 'react-dnd'
import { ChevronLeft } from 'react-feather'
import { Link } from 'react-router-dom'

import { Button, DesktopDisplayOnly, MobileDisplayOnly } from '@/shared/components'

export interface IFeedHeaderViewProps {
  /**
   * The buttons to display after the heading.
   */
  readonly buttonBarChildren: ReactNode

  /**
   * An optional element to display under the breadcrumb. In desktop view, it displays as a separate row under the
   * breadcrumb and button bar. On mobile, it appears under the heading but above the button bar.
   */
  readonly explanatoryText?: ReactNode

  /**
   * Used as a ref for the HTML element that represents the drop target for the heading. Some pages allow content to be
   * dropped onto the heading, like 'Team' or 'Personal.'
   */
  readonly headingDropTarget?: ConnectDropTarget

  /**
   * An optional link to another route in the application.
   */
  readonly headingHref?: string

  /**
   * The heading is the main display and typically shows the page title, for example 'Team', 'Personal', 'Gallery', etc.
   */
  readonly heading: ReactNode

  /** Some layouts require no margin on the bottom. */
  readonly ignoreMarginBottom?: boolean

  /** Indicates whether there is a current drag operation where the heading can be a drop target. */
  readonly isHeadingDropTargetHighlighted?: boolean

  /**
   * Callback function for when the subheading is clicked. Some views show a "Rename folder" dialog, for example.
   */
  readonly onSubheadingClick?: () => void

  /**
   * The subheading is displayed after the heading, like a breadcrumb.
   */
  readonly subheading?: ReactNode
}

/**
 * Represents a "dumb" component that shows a Heading / Subheading breadcrumb and a button bar on the right (filter, sort,
 * etc.). The breadcrumb can be a single level or two levels deep. If it is two levels deep, the first level can be a
 * drop target, allowing the user to drag a piece of content to the folder to move it.
 */
const FeedHeaderView = (props: IFeedHeaderViewProps): JSX.Element => {
  const {
    buttonBarChildren,
    explanatoryText,
    headingDropTarget,
    headingHref,
    heading,
    ignoreMarginBottom,
    isHeadingDropTargetHighlighted,
    onSubheadingClick,
    subheading,
  } = props

  const isMultiLevel = subheading !== undefined

  const parentLinkOrTitle = ({ isMobile }: { readonly isMobile: boolean }) =>
    headingHref ? (
      <Link to={headingHref}>
        {isMobile ? (
          <Button className="mr-2 p-0" variant="ghost" icon={<ChevronLeft size="18px" />} />
        ) : (
          <>{heading}</>
        )}
      </Link>
    ) : (
      <>{heading}</>
    )

  const parentOnlyHeader = (
    <>
      <DesktopDisplayOnly>{parentLinkOrTitle({ isMobile: false })}</DesktopDisplayOnly>
      <MobileDisplayOnly className="mb-2">{heading}</MobileDisplayOnly>
    </>
  )

  const parentChildHeader = (
    <>
      <DesktopDisplayOnly>
        <span
          className={
            isHeadingDropTargetHighlighted ? 'text-interactive-primary' : 'text-copy-secondary'
          }
          ref={headingDropTarget}
        >
          {parentLinkOrTitle({ isMobile: false })}
        </span>
        <span className="text-copy-secondary">&nbsp;/&nbsp;</span>
        <span
          className={clsx(
            onSubheadingClick &&
              'hover:text-interactive-primary active:text-interactive-primary cursor-pointer',
          )}
          onClick={onSubheadingClick}
        >
          {subheading}
        </span>
      </DesktopDisplayOnly>

      <MobileDisplayOnly className="mb-2 flex-wrap items-center gap-0.5">
        <span className="text-copy-secondary">{parentLinkOrTitle({ isMobile: true })}</span>
        {!headingHref && <span className="text-copy-secondary">&nbsp;/&nbsp;</span>}
        <span
          className="hover:text-interactive-primary active:text-interactive-primary cursor-pointer"
          onClick={onSubheadingClick}
        >
          {subheading}
        </span>
      </MobileDisplayOnly>
    </>
  )

  return (
    <div className="w-full lg:max-w-7xl">
      <div
        className={clsx(
          'bg-background-gridBackground mt-2 self-start px-4 lg:mt-6 lg:px-6',
          ignoreMarginBottom ? '' : 'pb-4 lg:pb-2',
        )}
      >
        <div className="flex flex-col content-end justify-between lg:flex-row">
          <h1 className="text-lg font-semibold lg:text-xl">
            {isMultiLevel ? parentChildHeader : parentOnlyHeader}
          </h1>
          <div className="mb-2 lg:hidden">{explanatoryText}</div>
          <div className="flex flex-row gap-2">{buttonBarChildren}</div>
        </div>

        <div className="mt-2 hidden lg:block">{explanatoryText}</div>
      </div>
    </div>
  )
}

export default FeedHeaderView
