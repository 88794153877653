import { NetworkStatus } from '@apollo/client'
import { useCallback } from 'react'

import {
  Identity,
  IdentityQuery,
  IdentityQueryVariables,
  SnapshotFieldsFragment,
  useIdentityQuery,
} from '@/generated/graphql'

import { useAuth } from '../auth/useAuth'

interface UseIdentityHookResults {
  readonly identity?: Omit<Identity, 'snapshots'>
  readonly isLoading: boolean
  readonly isLoadingMoreSnapshots: boolean
  readonly loadMoreSnapshots: () => void
  readonly networkStatus: NetworkStatus
  readonly refresh: () => void
  readonly snapshots: readonly SnapshotFieldsFragment[]
  readonly totalCount: number
}

export const useIdentity = (
  variables: IdentityQueryVariables,
  onCompleted?: (data: IdentityQuery) => void,
): UseIdentityHookResults => {
  const auth = useAuth()
  const { data, error, fetchMore, networkStatus, refetch } = useIdentityQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted,
    skip: auth.loading || !auth.isLoggedIn(),
    variables,
  })

  if (error) {
    throw error
  }

  const pageInfo = data?.identity?.snapshots.pageInfo
  const totalCount = data?.identity?.snapshots.totalCount ?? 0

  const loadMoreSnapshots = useCallback(() => {
    if (!pageInfo?.hasNextPage) {
      return
    }

    void fetchMore({
      variables: { ...variables, after: pageInfo.endCursor },
    })
  }, [fetchMore, pageInfo, variables])

  const refresh = useCallback(() => void refetch(), [refetch])

  return {
    identity: data?.identity ?? undefined,
    isLoading: [NetworkStatus.loading, NetworkStatus.setVariables, NetworkStatus.refetch].includes(
      networkStatus,
    ),
    isLoadingMoreSnapshots: networkStatus === NetworkStatus.fetchMore,
    loadMoreSnapshots,
    networkStatus,
    refresh,
    snapshots: data?.identity?.snapshots.edges.map((edge) => ({ ...edge.node })) ?? [],
    totalCount,
  }
}
