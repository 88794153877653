import { useReactiveVar } from '@apollo/client'
import { useCallback } from 'react'
import { CheckCircle } from 'react-feather'

import { DocumentCardFragment, SnapshotFieldsFragment } from '@/generated/graphql'
import { activeOrganizationState } from '@/shared/apollo/apolloLocalState'
import Toast from '@/shared/components/Toast'
import useViewer from '@/shared/hooks/useViewer'
import { copyTextToClipboard, getPublicLink, showModal } from '@/util'

const useCopyLink = (): ((
  content: SnapshotFieldsFragment | DocumentCardFragment,
  unselectAllItems?: () => void,
) => void) => {
  const { activeOrganization } = useViewer()
  const activeOrgState = useReactiveVar(activeOrganizationState)

  const copyLinkCallback = useCallback(
    (content: SnapshotFieldsFragment | DocumentCardFragment, unselectAllItems?: () => void) => {
      copyTextToClipboard(
        getPublicLink(content, activeOrganization?.slug || activeOrgState?.slug || '', false),
      )
      showModal(
        <Toast>
          <CheckCircle size={15} className="text-interactive-primary mr-2" />
          <span className="text-base">Link copied!</span>
        </Toast>,
      )
      if (unselectAllItems) {
        unselectAllItems()
      }
    },
    [activeOrganization, activeOrgState],
  )

  return copyLinkCallback
}

export default useCopyLink
