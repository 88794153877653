import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu'
import React from 'react'
import { File, FilePlus, Folder, Plus } from 'react-feather'

import Button from '@/shared/components/Button/Button'
import SnapshotIcon from '@/shared/components/Icons/Snapshot'

interface IProps {
  inFolder?: boolean
  onCreateFolder: () => void
  onCreatePage: () => void
  onCreateSnapshot: () => void
}

const FeedCreateMenu = ({
  inFolder,
  onCreateFolder,
  onCreatePage,
  onCreateSnapshot,
}: IProps): JSX.Element => {
  const [open, setOpen] = React.useState(false)

  if (inFolder) {
    return (
      <Button
        align="left"
        className="hidden h-8 w-fit gap-x-1 font-semibold md:inline-flex"
        icon={<FilePlus size={16} />}
        size="standard"
        variant="secondary"
        onClick={onCreatePage}
      >
        Create Page
      </Button>
    )
  }

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen} modal={false}>
        <DropdownMenuTrigger asChild>
          <Button
            align="left"
            className="hidden h-8 gap-x-1 font-semibold md:inline-flex"
            icon={<Plus size={16} />}
            size="standard"
            variant="secondary"
            active={open}
          >
            Create
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
          <DropdownMenuContent
            align="end"
            className="font-base border-divider-light-gray bg-background-white shadow-soft w-[160px] rounded-md border p-2 text-base outline-none"
          >
            <DropdownMenuItem
              className="hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover flex cursor-pointer rounded-md p-2 outline-none"
              onClick={onCreateSnapshot}
            >
              <div className="flex flex-row gap-2">
                <SnapshotIcon />
                Snapshot
              </div>
            </DropdownMenuItem>
            <DropdownMenuItem
              className="hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover flex cursor-pointer rounded-md p-2 outline-none"
              onClick={onCreatePage}
            >
              <div className="flex flex-row gap-2">
                <File size={16} />
                Page
              </div>
            </DropdownMenuItem>
            <DropdownMenuItem
              className="hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover flex cursor-pointer rounded-md p-2 outline-none"
              onClick={onCreateFolder}
            >
              <div className="flex flex-row gap-2">
                <Folder size={16} />
                Folder
              </div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenuPortal>
      </DropdownMenu>
      <Button
        align="left"
        className="inline-flex h-8 gap-x-1 font-semibold md:hidden"
        icon={<Plus size={16} />}
        size="standard"
        variant="secondary"
        onClick={onCreateFolder}
      >
        Folder
      </Button>
    </>
  )
}

export default FeedCreateMenu
