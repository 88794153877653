import clsx from 'clsx'
import { debounce } from 'lodash-es'
import { useState } from 'react'
import { Check } from 'react-feather'

import { UploadPurpose, UserImageFragmentDoc, useUpdateUserMutation } from '@/generated/graphql'
import { apolloCache } from '@/shared/apollo/apolloClient'
import { Input, UserImage } from '@/shared/components'
import ImagePicker, { useImageUpload } from '@/web/components/ImagePicker'
import { useViewer } from '@/web/hooks'

const EditProfile = (): JSX.Element => {
  const { viewer } = useViewer()
  const [updateUser] = useUpdateUserMutation()

  const [userName, setUserName] = useState<string>(viewer?.user?.name || '')
  const [showSaved, setShowSaved] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')

  const onNameChange = (name: string) => {
    setShowSaved(false)
    setUserName(name)
    setErrorMessage(undefined)
  }

  const persistNameChange = debounce(async (name: string) => {
    await updateUser({
      variables: { id: viewer?.user?.id || '', input: { name } },
    })
    setShowSaved(true)
  }, 250)

  const [imageUrl, setImage, uploadImage] = useImageUpload(UploadPurpose.UserAvatar)

  const onImagePicked = async (blob: Blob) => {
    setImage(blob)
    if (viewer?.user?.id) {
      const url = URL.createObjectURL(blob)
      // we update the cache with the blob URL, since the API takes a few seconds to update the associated image after its uploaded
      // due to it listening for the s3 creation event, which takes a few seconds to fire. we want to be instantly updated!
      // the next time there's a viewer nav data query, it'll get the new URL, and it'll be all good
      apolloCache.writeFragment({
        data: {
          image: url,
        },
        fragment: UserImageFragmentDoc,
        id: `User:${viewer.user.id}`,
      })
      await uploadImage(viewer.user.id, blob)
    }
  }

  return (
    <div className="flex flex-col gap-3 p-6">
      <h1 className="text-lg font-semibold">Edit profile</h1>
      {errorMessage && (
        <div className="mb-5 self-center">
          <h1 className="text-interactive-destructive m-auto">{errorMessage}</h1>
        </div>
      )}
      <div className="flex flex-col gap-2">
        <div className="text-xs font-semibold">
          <span>Profile photo</span>
        </div>
        <ImagePicker onImagePicked={onImagePicked} imageWidth={512} imageHeight={512} shape="round">
          <UserImage
            size="large"
            userName={userName}
            userImageUrl={imageUrl ?? viewer?.user?.image}
            userEmail={viewer?.user?.email}
            editable
          />
        </ImagePicker>
      </div>
      <div className="w-[240px]">
        <label
          htmlFor="name-input"
          className={clsx([
            errorMessage && 'text-interactive-destructive',
            'text-xs font-semibold',
          ])}
        >
          Your name
        </label>
        <div className="flex w-fit flex-row items-center gap-2">
          <Input
            className="w-[240px]"
            id="name-input"
            name="name"
            onChange={(x) => onNameChange(x.target.value)}
            onBlur={() => void persistNameChange(userName)}
            placeholder="e.g. Pietro Crespi"
            value={userName}
            autoComplete="name"
            hasError={!!errorMessage}
          />
          <div
            className={clsx(
              'text-interactive-primary flex flex-row text-base transition-opacity duration-150',
              showSaved ? 'opacity-100' : 'opacity-0',
            )}
          >
            <Check size={16} />
            &nbsp;
            <span>Name saved</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditProfile
