import { ToursConfig, TourStateMachine } from '@plusdocs/star-tours'

import CaptureFailedTour from './CaptureFailed'
import LoggedOutTour from './LoggedOut'
import ReviewTour from './Review'
import SnapshotViewerTour from './SnapshotViewer'
import UpgradeTour from './Upgrade'
import WelcomeTour from './Welcome'

export enum AvailableTours {
  CaptureFailed = 'CaptureFailed',
  LoggedOut = 'LoggedOut',
  Review = 'Review',
  SnapshotViewer = 'SnapshotViewer',
  Upgrade = 'Upgrade',
  Welcome = 'Welcome',
}

const toursConfig: ToursConfig<AvailableTours> = {
  CaptureFailed: {
    tour: CaptureFailedTour as TourStateMachine,
  },
  LoggedOut: {
    tour: LoggedOutTour as TourStateMachine,
  },
  Review: {
    tour: ReviewTour as TourStateMachine,
  },
  SnapshotViewer: {
    tour: SnapshotViewerTour as TourStateMachine,
  },
  Upgrade: {
    tour: UpgradeTour as TourStateMachine,
  },
  Welcome: {
    options: {
      layout: 'position',
      transition: { duration: 0.25 },
    },
    tour: WelcomeTour as TourStateMachine,
  },
}

export default toursConfig
