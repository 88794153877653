import clsx from 'clsx'
import { useState } from 'react'
import { Check, RefreshCw } from 'react-feather'

import {
  Plan,
  PlanFrequency,
  PlanTier,
  useCreateCheckoutLinkMutation,
  useCreatePaymentPortalLinkMutation,
} from '@/generated/graphql'
import { Button, Snapshot } from '@/shared/components'

import { useViewer } from '../hooks'

const frequencyToHumanReadable = (frequency: PlanFrequency) => {
  switch (frequency) {
    case PlanFrequency.Monthly:
    case PlanFrequency.Yearly:
      return 'per user, per month'
  }
}

const getTierText = (tier: PlanTier) => {
  if (tier === PlanTier.Slides) {
    return 'BASIC'
  }

  return tier
}

const TierBlob = ({
  currencySymbol,
  price,
  tier,
  frequency,
  isCurrentPlan,
}: {
  currencySymbol: string
  price: number
  tier: PlanTier
  frequency: PlanFrequency
  isCurrentPlan: boolean
}) => (
  <div
    className={clsx(
      'shadow-soft relative flex w-full flex-col items-center justify-center rounded py-6',
      {
        'bg-base-highlightA': tier === PlanTier.Free,
        'from-base-highlightB to-base-highlightC bg-[linear-gradient(110.18deg,var(--tw-gradient-stops))]':
          tier !== PlanTier.Free,
      },
    )}
  >
    {isCurrentPlan && (
      <div className="bg-background-overlay text-copy-active absolute top-1 left-1 rounded-sm px-1 text-base">
        Current plan
      </div>
    )}
    <div className="text-base-white text-xl font-semibold">
      {currencySymbol}
      {frequency === PlanFrequency.Monthly ? price : price / 12}
    </div>
    <div className="text-base-white text-lg font-semibold">
      {frequencyToHumanReadable(frequency)}
    </div>
    <div className="text-base-white text-lg font-semibold">
      {frequency === PlanFrequency.Yearly ? 'billed anually' : ' '}
    </div>
  </div>
)

const PlanItem = ({
  currencySymbol,
  price,
  tier,
  frequency,
  refreshRate,
  snapshots,
  currentPriceId,
  priceId,
  upgrade,
  downgrade,
  isCurrentPlan,
}: {
  currencySymbol: string
  price: number
  tier: PlanTier
  frequency: PlanFrequency
  refreshRate: string
  snapshots: string
  currentPriceId: string | null | undefined
  priceId: string
  upgrade: boolean
  downgrade: boolean
  isCurrentPlan: boolean
}) => {
  const [createCheckoutLink] = useCreateCheckoutLinkMutation()
  const [createPaymentPortalLink] = useCreatePaymentPortalLinkMutation()
  const [paymentLoading, setPaymentLoading] = useState(false)

  return (
    <div
      className={clsx(
        'first:border-divider-light-gray flex h-full w-full flex-col gap-y-3 p-4 first:border-r last:border-r-0',
        {
          'bg-interactive-selected': isCurrentPlan,
        },
      )}
    >
      <span className="text-copy title text-lg font-semibold capitalize">
        {getTierText(tier).toLowerCase()}&nbsp;
      </span>
      <span className="text-copy-secondary text-base capitalize">
        {tier === PlanTier.Free && 'Start with Plus AI and Live Snapshots for free'}
        {tier === PlanTier.Slides && 'Unlimited AI to create and edit presentations'}
        {tier === PlanTier.Pro && 'Unlimited AI, custom themes, more Snapshots'}
      </span>

      <TierBlob
        currencySymbol={currencySymbol}
        price={price}
        tier={tier}
        frequency={frequency}
        isCurrentPlan={isCurrentPlan}
      />

      <div className="flex h-full flex-col gap-y-2">
        <div className="text-copy flex flex-row items-center gap-x-2 text-[11px] font-semibold">
          AI Features
        </div>

        {tier === PlanTier.Slides && (
          <>
            <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
              <Check size={14} className="text-copy-secondary" /> Unlimited AI presentations
            </div>
            <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
              <Check size={14} className="text-copy-secondary" /> 100+ slide layouts
            </div>
            <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
              <Check size={14} className="text-copy-secondary" /> Remix and rewrite
            </div>
          </>
        )}
        {tier === PlanTier.Pro && (
          <>
            <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
              <Check size={14} className="text-copy-secondary" /> Unlimited AI presentations
            </div>
            <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
              <Check size={14} className="text-copy-secondary" /> 100+ slide layouts
            </div>
            <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
              <Check size={14} className="text-copy-secondary" /> Remix and rewrite
            </div>
            <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
              <Check size={14} className="text-copy-secondary" /> Custom themes
            </div>
          </>
        )}

        <div className="mb-0 mt-auto flex flex-col gap-y-2 pt-5">
          <div className="text-copy flex flex-row items-center gap-x-2 text-[11px] font-semibold">
            Live Snapshots
          </div>
          <div className="text-copy flex-cold flex items-center gap-x-2 text-[11px]">
            <RefreshCw size={14} className="text-copy-secondary" /> {refreshRate}
          </div>
          <div className="text-copy flex-cold flex items-center gap-x-2 text-[11px]">
            <Snapshot size={14} className="text-copy-secondary" /> {snapshots}
          </div>
        </div>
        {currentPriceId !== 'free' || tier !== PlanTier.Free ? (
          <Button
            variant={!currentPriceId || upgrade ? 'upgrade' : 'secondary'}
            onClick={() => {
              setPaymentLoading(true)
              if (currentPriceId && currentPriceId !== 'free') {
                void createPaymentPortalLink().then((v) => {
                  if (v.data?.createPaymentPortalLink) {
                    window.location.href = v.data.createPaymentPortalLink
                  }
                })
              } else {
                void createCheckoutLink({
                  variables: {
                    priceId,
                  },
                }).then((v) => {
                  if (v.data?.createCheckoutLink) {
                    window.location.href = v.data.createCheckoutLink
                  }
                })
              }
            }}
          >
            {paymentLoading
              ? 'One second...'
              : isCurrentPlan
              ? 'Manage'
              : downgrade
              ? 'Downgrade'
              : 'Upgrade'}
          </Button>
        ) : (
          <Button variant="secondary" disabled>
            Current
          </Button>
        )}
      </div>
    </div>
  )
}

const EnterprisePlanItem = ({ isFreeUser }: { readonly isFreeUser: boolean }) => {
  return (
    <div
      className={clsx(
        'first:border-divider-light-gray flex h-full w-full flex-col gap-y-3 p-4',
        'bg-background-white',
        isFreeUser && 'rounded-tr',
      )}
    >
      <span className="text-copy title text-lg font-semibold capitalize">Enterprise</span>
      <span className="text-copy-secondary text-base capitalize">
        Custom-built AI for your company
      </span>
      <div
        className={clsx(
          'shadow-soft bg-base-highlightC relative flex h-[120px] w-full flex-col items-center justify-center rounded py-6',
        )}
      >
        <div className="my-auto text-xl font-semibold">Contact us</div>
      </div>

      <div className="flex flex-col gap-y-2">
        <div className="text-copy flex flex-row items-center gap-x-2 text-[11px] font-semibold">
          AI Features
        </div>
        <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
          <Check size={14} className="text-copy-secondary" /> Custom-trained AI
        </div>
        <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
          <Check size={14} className="text-copy-secondary" /> Branded presentations
        </div>
        <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
          <Check size={14} className="text-copy-secondary" /> Custom integrations
        </div>
        <div className="text-copy flex flex-row items-center gap-x-2 text-[11px]">
          <Check size={14} className="text-copy-secondary" /> Custom workflows
        </div>

        <div className="mb-0 mt-auto flex flex-col gap-y-2 pt-8">
          <div className="text-copy flex flex-row items-center gap-x-2 text-[11px] font-semibold">
            Live Snapshots
          </div>
          <div className="text-copy flex-cold flex items-center gap-x-2 text-[11px]">
            <RefreshCw size={14} className="text-copy-secondary" /> Unlimited Snapshots
          </div>
          <div className="text-copy flex-cold flex items-center gap-x-2 text-[11px]">
            <Snapshot size={14} className="text-copy-secondary" /> Custom workflows
          </div>
        </div>
      </div>
      <a href="mailto:support@plusdocs.com">
        <Button variant="secondary" className="w-full">
          Contact us
        </Button>
      </a>
    </div>
  )
}

const TIERS = [PlanTier.Slides, PlanTier.Pro]

export const PlanPicker = ({ planFrequency }: { planFrequency: PlanFrequency }) => {
  const { activeOrganization, viewer } = useViewer()
  const currentPriceId = activeOrganization?.currentPlan?.id
  const plans = [...(viewer?.availablePlans ?? [])]
    .sort((a, b) => a.price - b.price)
    .filter((x) => x.frequency === planFrequency || x.id === 'free')

  const renderPlan = (p: Plan) => (
    <PlanItem
      key={p.id}
      currencySymbol={p.currencySymbol}
      priceId={p.id}
      price={p.price}
      tier={p.tier}
      frequency={planFrequency}
      refreshRate={p.refreshRatesLineItem}
      snapshots={p.snapshotsLineItem}
      currentPriceId={currentPriceId}
      isCurrentPlan={
        activeOrganization?.currentPlan?.tier === p.tier &&
        p.frequency === activeOrganization?.currentPlan?.frequency
      }
      upgrade={
        currentPriceId
          ? plans.findIndex((p2) => p2.id === currentPriceId) <
            plans.findIndex((p2) => p2.id === p.id)
          : false
      }
      downgrade={
        currentPriceId
          ? plans.findIndex((p2) => p2.id === currentPriceId) >
            plans.findIndex((p2) => p2.id === p.id)
          : false
      }
    />
  )

  return (
    <div className="flex w-full flex-row">
      {TIERS.map((tier) => (
        <div
          className="border-divider-light-gray flex w-full flex-col border-r last:border-r-0"
          key={tier}
        >
          <div className="flex h-full w-full flex-row">
            {plans?.filter((p) => p.tier === tier)?.map(renderPlan)}
          </div>
        </div>
      ))}
      <div className="border-divider-light-gray flex w-full flex-col rounded-tl">
        <div className="flex h-full w-full flex-row">
          <EnterprisePlanItem isFreeUser={currentPriceId === 'free'} />
        </div>
      </div>
    </div>
  )
}
