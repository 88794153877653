import clsx from 'clsx'
import * as React from 'react'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'

import { useAuth } from '@/shared/auth/useAuth'
import { Button, GoogleLogo, Input, PlusLogo } from '@/shared/components'
import MicrosoftLogo from '@/shared/components/MicrosoftLogo'

type RegisterPayload = {
  email: string
  password: string
  confirmPassword: string
}

const validateForm = (formValues: RegisterPayload): boolean | string => {
  // make sure everything is filled out
  if (Object.values(formValues).some((value) => !value)) {
    return 'All fields must be completed.'
  }

  // verify passwords match
  if (formValues.password !== formValues.confirmPassword) {
    return 'Passwords do not match.'
  }

  return true
}

const generateErrorMessage = (err: Error): string => {
  if (err.name === 'InvalidPasswordException') {
    return err.message.replace('Password did not conform with policy: ', '')
  }

  return 'An unknown error occurred. Please try again.'
}

const BackToLoginLink = ({ className }: { className?: string }) => (
  <div className={className}>
    <Link
      className="text-interactive-primary hover:text-interactive-primary-hover flex justify-center text-xs hover:underline"
      to="/login"
    >
      Log in
    </Link>
  </div>
)

const Register = (): JSX.Element => {
  const auth = useAuth()
  const [loading, setLoading] = React.useState(false)
  const [hasRegistered, setHasRegistered] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [formValues, setFormValues] = React.useState<RegisterPayload>({
    confirmPassword: '',
    email: '',
    password: '',
  })

  const onFormFieldChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormValues((prev) => ({ ...prev, [name]: value }))
  }, [])

  const isFormValid = React.useMemo(() => {
    if (hasError) {
      return false
    }

    return validateForm(formValues)
  }, [hasError, formValues])

  const signupWithGoogle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    auth.startLoginWithOAuth(false, 'Google')
  }

  const signupWithMicrosoft = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    auth.startLoginWithOAuth(false, 'Microsoft')
  }

  const onSubmit = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()

      if (typeof isFormValid === 'string') {
        setErrorMessage(isFormValid)
        setHasError(true)
        return
      }

      setLoading(true)

      auth
        .register({
          email: formValues.email,
          password: formValues.password,
        })
        .then(() => {
          if (ReactGA.isInitialized) {
            ReactGA.gtag('event', 'sign_up', {
              method: 'Email',
            })
          }
          setHasError(false)
          setHasRegistered(true)
        })
        .catch((err) => {
          setErrorMessage(generateErrorMessage(err))
          setHasError(true)
          setLoading(false)
        })
    },
    [auth, isFormValid, formValues],
  )

  if (hasRegistered) {
    return (
      <div>
        <div className="border-divider-light-gray flex w-full max-w-[400px] flex-col items-center justify-center gap-3 border-b p-6">
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <PlusLogo width="48" height="48" />
            <h1 className="text-center text-xl font-semibold">Verify account</h1>
          </div>
        </div>
        <div className="p-6 text-center text-xs font-normal">
          We sent an email to {formValues.email} with a verification link. Click the button in that
          email to start using Plus.
          <BackToLoginLink className="mt-3 font-normal" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="border-divider-light-gray flex w-full max-w-[400px] flex-col items-center justify-center gap-3 border-b p-6">
        <div className="flex w-full flex-col items-center justify-center gap-1">
          <PlusLogo width="48" height="48" />
          <h1 className="text-center text-xl font-semibold">Sign up for Plus AI</h1>
        </div>
        <Button
          className="w-full gap-x-2"
          variant="secondary"
          onClick={signupWithGoogle}
          icon={<GoogleLogo />}
        >
          Continue with Google
        </Button>
        <Button
          className="w-full gap-x-2"
          variant="secondary"
          onClick={signupWithMicrosoft}
          icon={<MicrosoftLogo />}
        >
          Continue with Microsoft
        </Button>
      </div>
      <form
        onSubmit={onSubmit}
        onChange={() => setHasError(false)}
        className={clsx('space-y-3', 'flex flex-col p-6', hasError && 'text-copy-alert')}
      >
        {hasError && (
          <div className="self-center text-center text-xs font-normal">{errorMessage}</div>
        )}
        <div>
          <label htmlFor="email-input" className="text-base font-semibold">
            Work email
          </label>
          <Input
            id="email-input"
            name="email"
            type="email"
            value={formValues.email}
            autoComplete="username"
            onChange={onFormFieldChange}
            hasError={hasError}
            required
          />
        </div>
        <div>
          <label htmlFor="password-input" className="text-base font-semibold">
            Password
          </label>
          <Input
            id="password-input"
            name="password"
            type="password"
            value={formValues.password}
            autoComplete="new-password"
            onChange={onFormFieldChange}
            hasError={hasError}
            required
            minLength={10}
          />
        </div>
        <div>
          <label htmlFor="confirm-password-input" className="text-base font-semibold">
            Confirm password
          </label>
          <Input
            id="confirm-password-input"
            name="confirmPassword"
            type="password"
            value={formValues.confirmPassword}
            autoComplete="new-password"
            onChange={onFormFieldChange}
            hasError={hasError}
            required
            minLength={10}
          />
        </div>
        <div>
          <Button className="w-full" disabled={loading}>
            {loading ? 'Signing up…' : 'Sign up'}
          </Button>
        </div>
        <div className="flex flex-col items-center gap-3">
          <Link
            className="text-interactive-primary hover:text-interactive-primary-hover flex justify-center text-center text-base hover:underline"
            to="/reset-password"
          >
            Reset password
          </Link>
          <Link
            className="text-interactive-primary hover:text-interactive-primary-hover flex justify-center text-center text-base hover:underline"
            to="/login"
          >
            Log in
          </Link>
        </div>
      </form>
    </>
  )
}

export default Register
