import { buildTour, ComponentProps } from '@plusdocs/star-tours'
import clsx from 'clsx'
import * as React from 'react'

import { Button } from '@/shared/components'
import SnapshotIcon from '@/shared/components/Icons/Snapshot'
import IntegrationsOnboardingRow from '@/web/assets/IntegrationLogos/onboarding-row.png'

const BaseTourStepComponent = (
  props: ComponentProps & {
    children: React.ReactNode
    secondaryButtonText?: string
    secondaryButtonLink?: string
  },
) => {
  return (
    <div
      className="bg-background-white pointer-events-auto flex flex-col items-center justify-center gap-y-3 rounded p-3"
      style={{ width: 240 }}
    >
      {props.children}
      {props.currentStep.anchor.type === 'element' && (
        <div className="flex flex-row gap-x-2">
          {new Array(props.totalSteps - 1).fill('').map((_, i) => (
            <div
              key={i}
              className={clsx(
                'h-[6px] w-[6px] rounded-full',
                props.currentStepIndex === i + 1 ? 'bg-divider-dark-blue' : 'bg-divider-light-blue',
              )}
            />
          ))}
        </div>
      )}
      <Button className="w-full" onClick={props.nextStep}>
        {props.currentStepIndex === 0
          ? 'Show me'
          : props.currentStepIndex === props.totalSteps - 1
          ? 'Done'
          : 'Next'}
      </Button>
      {props.secondaryButtonText && (
        <a href={props.secondaryButtonLink} target="_blank" rel="noreferrer" className="w-full">
          <Button variant="secondary" className="w-full">
            {props.secondaryButtonText}
          </Button>
        </a>
      )}
    </div>
  )
}

export default buildTour([
  {
    anchor: {
      type: 'screen',
    },
    component: (props) => {
      return (
        <BaseTourStepComponent {...props}>
          <div className="flex flex-col items-center justify-center gap-y-3">
            <SnapshotIcon className="fill-base-primaryBlue h-8 w-8" />
            <span className="text-center text-lg font-semibold">Take a look at your Snapshot</span>
            <span className="text-center text-base">
              Let’s take a quick tour of configuration options and tools.
            </span>
          </div>
        </BaseTourStepComponent>
      )
    },
  },
  {
    anchor: {
      type: 'element',
      useHighlightedElement: true,
    },
    component: (props) => {
      return (
        <BaseTourStepComponent {...props}>
          <span className="text-center text-base">
            Capture a new version of your Snapshot with the latest data.
          </span>
        </BaseTourStepComponent>
      )
    },
    highlight: {
      padding: 8,
      selectorId: 'refresh-button',
    },
  },
  {
    anchor: {
      type: 'element',
      useHighlightedElement: true,
    },
    component: (props) => {
      return (
        <BaseTourStepComponent {...props}>
          <span className="text-center text-base">
            Set how often your Snapshot is automatically refreshed.
          </span>
        </BaseTourStepComponent>
      )
    },
    highlight: {
      padding: 8,
      selectorId: 'refresh-rate',
    },
  },
  {
    anchor: {
      type: 'element',
      useHighlightedElement: true,
    },
    component: (props) => {
      return (
        <BaseTourStepComponent {...props}>
          <span className="text-center text-base">
            Pin your Snapshot to the New Tab page to see whenever it updates.
          </span>
        </BaseTourStepComponent>
      )
    },
    highlight: {
      padding: 8,
      selectorId: 'pin-new-tab',
    },
  },
  {
    anchor: {
      type: 'element',
      useHighlightedElement: true,
    },
    component: (props) => {
      return (
        <BaseTourStepComponent
          {...props}
          secondaryButtonLink="https://guide.plusdocs.com/integrations/embed-plus-in-other-apps"
          secondaryButtonText="Learn more about integrations"
        >
          <div className="flex flex-col items-center justify-center gap-y-3">
            <span className="text-center text-base">
              Copy the share link to share with colleagues or embed in other tools.
            </span>
            <img
              src={IntegrationsOnboardingRow}
              style={{
                height: 54,
                width: 216,
              }}
              alt="Slack, Notion, Confluence and Coda logos"
            />
          </div>
        </BaseTourStepComponent>
      )
    },
    highlight: {
      padding: 8,
      selectorId: 'share-button',
    },
  },
])
