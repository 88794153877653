import { Route, RouteProps } from 'react-router-dom'

import ContentPrivacyContainer from '@/web/components/ContentPrivacyContainer'

import { ActiveOrgHandler } from './ActiveOrgHandler'

// Middleware for routes that require content privacy validation
export const ContentPrivacyMiddlewareRoute = ({
  component: Component,
  ...rest
}: RouteProps): JSX.Element => {
  if (!Component) return <></>

  return (
    <Route
      render={(props) => (
        <ActiveOrgHandler>
          <ContentPrivacyContainer>
            <Component {...props} />
          </ContentPrivacyContainer>
        </ActiveOrgHandler>
      )}
      {...rest}
    />
  )
}
