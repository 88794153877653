import clsx from 'clsx'
import { useState } from 'react'
import { Mail } from 'react-feather'

import {
  useDeleteOrgInviteMutation,
  useDeleteOrgUserMutation,
  useSendInviteMutation,
  ViewerNavDataDocument,
} from '@/generated/graphql'
import { Button, Input, Modal, UserImage } from '@/shared/components'
import { showModal } from '@/util'
import { useViewer } from '@/web/hooks'

interface IInviteTeammatesProps {
  showRemovalButtons?: boolean
  isModal?: boolean
}

const InviteTeammates = (props: IInviteTeammatesProps): JSX.Element => {
  const [inviteInput, setInviteInput] = useState<string>('')
  const [deleteInvite] = useDeleteOrgInviteMutation({
    refetchQueries: [ViewerNavDataDocument],
  })
  const [deleteUser] = useDeleteOrgUserMutation({
    refetchQueries: [ViewerNavDataDocument],
  })

  const [sendInvite, { loading }] = useSendInviteMutation({
    refetchQueries: [ViewerNavDataDocument],
  })
  const { activeOrganization, viewer } = useViewer()

  const onSubmitCallback = async () => {
    const invites = inviteInput.split(',').map((email) => {
      return { email: email.trim() }
    })

    await Promise.all(
      invites.map((invite) =>
        invite.email === viewer?.user?.email
          ? Promise.resolve()
          : sendInvite({ variables: { input: invite } }),
      ),
    )

    setInviteInput('')
  }

  const deleteInviteCallback = async (inviteId: string) => {
    await deleteInvite({ variables: { id: inviteId } })
  }

  const deleteUserCallback = async (userId: string) => {
    await deleteUser({ variables: { id: userId } })
  }

  if (!viewer || !activeOrganization) {
    return <></>
  }

  return (
    <div className={clsx('flex flex-col', { 'h-[500px]': props.isModal })}>
      <div className="mb-3 flex space-x-2">
        <Input
          aria-label="Invite input"
          value={inviteInput}
          onChange={(x) => setInviteInput(x.target.value)}
          placeholder="Separate emails with commas"
          autoComplete="off"
          disabled={loading}
        />
        <Button disabled={inviteInput.length === 0 || loading} onClick={onSubmitCallback}>
          Invite
        </Button>
      </div>
      <div className={'flex flex-col gap-1 overflow-y-auto'}>
        {activeOrganization.users.nodes.map((user) => (
          <div className="flex" key={user.id}>
            <UserImage
              size="small"
              userName={user.name}
              userImageUrl={user.image}
              userEmail={user.email}
            />
            <div className="my-auto pl-3 text-xs">
              <span className="font-semibold">{user.name}</span>
              <br />
              <span className="text-copy-secondary">
                {user.email} {user.id === activeOrganization.createdBy.id && '• Owner'}
              </span>
            </div>
            {props.showRemovalButtons &&
              activeOrganization.createdBy.id !== user.id &&
              activeOrganization.createdBy.id === viewer.user?.id && (
                <Button
                  className="my-1 ml-auto"
                  onClick={() => showDeleteUserModal(user.id, user.name, deleteUserCallback)}
                  variant="secondary"
                >
                  Remove
                </Button>
              )}
          </div>
        ))}
        {activeOrganization.invitations.nodes.map((invite) => (
          <div className="flex" key={invite.id}>
            <Mail className="m-1" />
            <div className="my-auto pl-3 text-xs">
              <span className="font-semibold">{invite.email}</span>
              <br />
              <span className="text-copy-secondary">Pending invitation</span>
            </div>
            {props.showRemovalButtons &&
              (viewer.user?.id === invite.createdBy?.id ||
                activeOrganization.createdBy.id === viewer.user?.id) && (
                <Button
                  className="my-1 ml-auto"
                  onClick={() => deleteInviteCallback(invite.id)}
                  variant="secondary"
                >
                  Remove
                </Button>
              )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default InviteTeammates

const showDeleteUserModal = (
  userId: string,
  userName: string,
  deleteUserCallback: (userId: string) => void,
) => {
  showModal(
    <Modal
      variant="destructive"
      title={`Permanently delete ${userName}?`}
      content={`Are you sure you want to delete ${userName}? You'll lose access to all Snapshots and Pages they own that are not in your shared Team space. This action cannot be undone.`}
      primaryButtonText="Delete"
      onSubmit={() => deleteUserCallback(userId)}
      secondaryButtonText="Cancel"
    />,
  )
}
