import { NetworkStatus } from '@apollo/client'
import { useCallback } from 'react'

import {
  CurrentOrganizationSnapshotsQuery,
  CurrentOrganizationSnapshotsQueryVariables,
  SnapshotFieldsFragment,
  useCurrentOrganizationSnapshotsQuery,
} from '@/generated/graphql'
import { useAuth } from '@/shared/auth/useAuth'

interface UseCurrentOrganizationSnapshots {
  loading: boolean
  nodes: SnapshotFieldsFragment[]
  refresh: () => void
  loadMore: () => void
  loadingMore: boolean
  totalCount: number
  networkStatus: NetworkStatus
}

export const useCurrentOrganizationSnapshots = (
  variables: CurrentOrganizationSnapshotsQueryVariables,
  onCompleted?: (data: CurrentOrganizationSnapshotsQuery) => void,
): UseCurrentOrganizationSnapshots => {
  const auth = useAuth()
  const { error, data, fetchMore, refetch, networkStatus } = useCurrentOrganizationSnapshotsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted,
    skip: auth.loading || !auth.isLoggedIn(),
    variables,
  })

  if (error) {
    throw error
  }

  const pageInfo = data?.viewer?.currentOrganization?.snapshots?.pageInfo
  const totalCount = data?.viewer?.currentOrganization?.snapshots?.totalCount ?? 0

  const loadMore = useCallback(() => {
    if (!pageInfo?.hasNextPage) return

    void fetchMore({
      variables: {
        ...variables,
        after: pageInfo?.endCursor,
      },
    })
  }, [fetchMore, pageInfo, variables])

  return {
    loadMore,
    loading:
      networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables ||
      networkStatus === NetworkStatus.refetch,
    loadingMore: networkStatus === NetworkStatus.fetchMore,
    networkStatus,
    nodes: data?.viewer?.currentOrganization?.snapshots?.edges.map((edge) => edge.node) || [],
    refresh: () => void refetch(),
    totalCount,
  }
}

export default useCurrentOrganizationSnapshots
