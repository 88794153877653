import { ApolloProvider } from '@apollo/client'
import { DialogClose } from '@radix-ui/react-dialog'
import { useState } from 'react'
import { CheckCircle, X } from 'react-feather'

import { apolloClient } from '@/shared/apollo/apolloClient'
import { Button, Dialog, TextArea } from '@/shared/components'
import { useViewer } from '@/shared/hooks/useViewer'
import { ViewerProvider } from '@/shared/providers/ViewerProvider'

const ReportSnapshotIssueForm = ({
  description,
  onSubmit,
  setDescription,
}: {
  description: string
  onSubmit: () => void
  setDescription: (description: string) => void
}) => {
  return (
    <div className="flex h-full flex-col p-3">
      <div className="flex items-center justify-between">
        <span className="text-lg font-semibold">Report Snapshot issue</span>
        <DialogClose asChild>
          <Button variant="ghost" tabIndex={-1}>
            <X size={16} />
          </Button>
        </DialogClose>
      </div>
      <div className="flex flex-col gap-y-3">
        <p className="text-copy-secondary text-base">
          By reporting this issue, you grant the Plus team permission to view your Snapshot and
          images recorded during the capture process. Note that we never have access to your account
          on the site where the Snapshot was taken.
        </p>
        <TextArea
          rows={6}
          value={description}
          onChange={({ target: { value } }) => setDescription(value)}
          placeholder="(Optional) Describe the issue you’re seeing"
        />
        <Button className="w-full" onClick={onSubmit}>
          Send
        </Button>
      </div>
    </div>
  )
}

const ReportSnapshotIssueSuccess = () => {
  const { viewer } = useViewer()
  return (
    <div className="flex h-full flex-col items-center gap-y-3 p-3">
      <CheckCircle size={32} className="text-interactive-primary" />
      <span className="text-lg font-semibold">Issue reported!</span>
      <p className="text-copy-secondary text-center text-base">
        Thanks for reporting this issue. We&apos;ll reach out to you at {viewer?.email} with a
        response soon.
      </p>
      <DialogClose asChild>
        <Button className="w-full">Close</Button>
      </DialogClose>
    </div>
  )
}

export const ReportSnapshotIssueModal = ({
  reportIssueCallback,
}: {
  reportIssueCallback: (description?: string) => Promise<void>
}) => {
  const [open, setOpen] = useState(true)
  const [description, setDescription] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = () => {
    reportIssueCallback(description.trim()).finally(() => setSubmitted(true))
  }

  return (
    <ApolloProvider client={apolloClient}>
      <ViewerProvider>
        <Dialog isOpen={open} setIsOpen={setOpen} showBackgroundOverlay>
          <div className="border-base-black bg-background-white inline-block w-full max-w-[400px] transform rounded-md border text-left align-middle transition-all">
            {submitted ? (
              <ReportSnapshotIssueSuccess />
            ) : (
              <ReportSnapshotIssueForm
                description={description}
                onSubmit={onSubmit}
                setDescription={setDescription}
              />
            )}
          </div>
        </Dialog>
      </ViewerProvider>
    </ApolloProvider>
  )
}
