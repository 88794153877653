import { useReactiveVar } from '@apollo/client'
import { buildTour, ComponentProps } from '@plusdocs/star-tours'
import Player from '@vimeo/player'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { X } from 'react-feather'
import { useGate } from 'statsig-react'

import { extensionClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Button } from '@/shared/components'
import {
  ChromeExtensionStep,
  TakeSnapshotStep,
} from '@/shared/components/Onboarding/OnboardingTourSteps'
import AISlideNux1 from '@/web/assets/ai-slides-nux-1.png'
import AISlideNux2 from '@/web/assets/ai-slides-nux-2.png'
import OnboardingPlayButton from '@/web/assets/onboarding-play-button.png'
import OnboardingThumbnail from '@/web/assets/onboarding-thumbnail.png'
import OnboardingWorldPopulation from '@/web/assets/onboarding-world-population.png'

const BaseTourStepComponent = (
  props: ComponentProps & {
    children: React.ReactNode
    title: string
    disableNext?: boolean
  },
) => {
  return (
    <div
      className="border-divider-light-gray bg-background-white shadow-soft flex flex-col gap-y-3 rounded border p-3"
      style={{ width: 480 }}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <h1 className="text-popover-small text-copy font-semibold">{props.title}</h1>
        <Button variant="ghost" onClick={props.endTour}>
          <X size={16} />
        </Button>
      </div>
      {props.children}
      <div className="grid w-full grid-flow-col grid-cols-3">
        <div className="flex flex-row items-center justify-start">
          <Button
            variant="secondary"
            onClick={props.previousStep}
            disabled={!props.hasPreviousStep}
          >
            Previous
          </Button>
        </div>
        <div className="flex flex-row items-center justify-center">
          <div className="flex flex-row gap-x-2">
            {new Array(props.totalSteps).fill('').map((_, i) => (
              <div
                key={i}
                className={clsx(
                  'h-[6px] w-[6px] rounded-full',
                  props.currentStepIndex === i ? 'bg-divider-dark-blue' : 'bg-divider-light-blue',
                )}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center justify-end">
          <Button variant="secondary" onClick={props.nextStep} disabled={props.disableNext}>
            {props.currentStepIndex + 1 === props.totalSteps ? 'Finish' : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  )
}

const WelcomeStep = (props: ComponentProps) => {
  const [showVideo, setShowVideo] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const player = useRef<Player | null>(null)

  useEffect(() => {
    if (iframeRef.current && !player.current) {
      player.current = new Player(iframeRef.current)
      player.current.on('ended', () => {
        setShowVideo(false)
      })
    }
    if (showVideo && player.current) {
      void player.current?.play()
    }
  }, [showVideo])

  return (
    <BaseTourStepComponent {...props} title="Welcome to Plus!">
      <div className="flex flex-col items-center gap-y-4">
        <div className="pb-2" style={{ height: 255, width: 456 }}>
          <motion.iframe
            layout
            transition={{ duration: 0.25 }}
            initial={{ opacity: 0 }}
            animate={{
              opacity: showVideo ? 1 : 0,
            }}
            ref={iframeRef}
            src="https://player.vimeo.com/video/744815549?h=fdc20552df&app_id=122963&byline=0&badge=0&portrait=0&title=0"
            width="456"
            height="255"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; encrypted-media"
            style={{
              height: 255,
              width: 456,
            }}
            className="shadow-dark-hard absolute rounded"
          />
          <motion.div
            layout
            initial={{ opacity: 1 }}
            animate={{
              opacity: showVideo ? 0 : 1,
            }}
            transition={{ duration: 0.25 }}
            style={{
              backgroundImage: `url(${OnboardingThumbnail})`,
              height: 255,
              pointerEvents: showVideo ? 'none' : undefined,
              width: 456,
            }}
            className="shadow-dark-hard absolute flex cursor-pointer items-center justify-center rounded bg-cover"
            onClick={() => setShowVideo(true)}
            whileHover="hover"
            whileTap="active"
            variants={{ active: {}, hover: {} }}
          >
            <motion.div
              className="bg-interactive-dark shadow-soft absolute flex h-16 w-16 items-center justify-center rounded-full"
              variants={{
                active: { backgroundColor: '#100F19', scale: 0.95 },
                hover: { backgroundColor: '#272640', scale: 1.05 },
              }}
              transition={{ duration: 0.125 }}
            >
              <img src={OnboardingPlayButton} style={{ width: 32 }} />
            </motion.div>
          </motion.div>
        </div>

        <div className="text-base">
          Plus lets you take Snapshots of apps and websites that stay up to date wherever you use
          them. Watch this 2 minute video to see how it works.
        </div>
      </div>
    </BaseTourStepComponent>
  )
}

const ChromeExtensionStepWrapper = (props: ComponentProps) => {
  const { isExtensionInstalled } = useReactiveVar(extensionClientStateVar)
  return (
    <BaseTourStepComponent
      {...props}
      disableNext={!isExtensionInstalled}
      title="Install our Chrome extension"
    >
      <ChromeExtensionStep />
    </BaseTourStepComponent>
  )
}

const AIStepWrapper = (props: ComponentProps) => {
  return (
    <div
      className="border-divider-light-gray bg-background-white shadow-soft flex flex-col gap-y-3 rounded border p-3"
      style={{ width: 480 }}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <h1 className="text-popover-small text-copy font-semibold">Welcome to Plus!</h1>
        <Button variant="ghost" onClick={props.endTour}>
          <X size={16} />
        </Button>
      </div>
      <div className="flex gap-x-2 text-center">
        <div className="border-divider-light-gray flex flex-col justify-center rounded border p-2">
          <img src={AISlideNux1} height={160} />

          <span className="my-3 text-lg font-semibold">AI for Google Slides</span>
          <span className="text-base">
            Create presentations and edit slides using generative AI in Google Slides.
          </span>
          <a
            href="https://workspace.google.com/marketplace/app/plus_ai_for_google_slides/214277172452"
            className="block w-full"
            rel="noreferrer"
          >
            <Button className="my-2 w-full">Install Plus AI</Button>
          </a>
        </div>
        <div className="border-divider-light-gray flex flex-col justify-center rounded border p-2">
          <img src={AISlideNux2} height={160} />

          <span className="my-3 text-lg font-semibold">Live Snapshots</span>
          <span className="text-base">
            Take screenshots of any app or website that stays up-to-date automatically.
          </span>
          <Button className="my-2" onClick={props.nextStep}>
            Start with Snapshots
          </Button>
        </div>
      </div>
    </div>
  )
}

const TakeSnapshotStepWrapper = (props: ComponentProps) => {
  const { value: useExtendedNux } = useGate('extended_first_snapshot_nux')
  return (
    <BaseTourStepComponent {...props} title="Let’s take your first Snapshot!">
      {useExtendedNux ? (
        <TakeSnapshotStep />
      ) : (
        <div className="flex flex-col items-center gap-y-4">
          <div className="bg-base-highlightA shadow-dark-hard w-full rounded p-4">
            <img
              src={OnboardingWorldPopulation}
              alt="Screenshot of world population data, with the Plus extension open"
              className="shadow-soft rounded"
            />
          </div>
          <div className="text-base">
            We’ll walk you through capturing a Snapshot of world population data that stays up to
            date automatically.
          </div>
          <a
            href="https://populationcounter.world/#plus-onboarding"
            className="block w-full"
            rel="noreferrer"
          >
            <Button className="h-12 w-full p-4">Take your first Snapshot</Button>
          </a>
        </div>
      )}
    </BaseTourStepComponent>
  )
}

export default buildTour([
  {
    anchor: {
      type: 'screen',
    },
    component: AIStepWrapper,
  },
  {
    anchor: {
      type: 'screen',
    },
    component: WelcomeStep,
  },
  {
    anchor: {
      type: 'screen',
    },
    component: ChromeExtensionStepWrapper,
  },
  {
    anchor: {
      type: 'screen',
    },
    component: TakeSnapshotStepWrapper,
  },
])
