import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import {
  InvitationType,
  useAcceptInvitationInputMutation,
  useInvitationQuery,
} from '@/generated/graphql'
import { useAuth } from '@/shared/auth/useAuth'
import { OrgImage } from '@/shared/components'
import { FullPageLoader } from '@/web/components/FullPageLoader'
import NotFound from '@/web/routes/NotFound'

const AcceptInvitation = (): JSX.Element => {
  const { account, refreshToken } = useAuth()
  const { invitationId, organizationSlug } = useParams<{
    invitationId: string
    organizationSlug: string
  }>()

  const { data, error, loading } = useInvitationQuery({
    variables: {
      invitationId: organizationSlug ?? invitationId,
      type: organizationSlug ? InvitationType.Organization : InvitationType.Email,
    },
  })

  const [acceptInvitation] = useAcceptInvitationInputMutation()

  React.useEffect(() => {
    void acceptInvitation({
      variables: {
        input: {
          invitationId: organizationSlug ?? invitationId,
          name: account?.name ?? account?.email ?? '',
          type: data?.viewer.invitation?.type,
        },
      },
    })
      .then(refreshToken)
      .then(() => {
        // force navigation to ensure clean state
        // TODO – see if we can make this a transition instead of force navigation
        location.href = `/${data?.viewer.invitation?.organization.slug || ''}?isFtu`
      })
  }, [acceptInvitation, data, invitationId, organizationSlug, refreshToken])

  if (loading) {
    return <FullPageLoader loading={loading} />
  }

  if (!data?.viewer.invitation) {
    return <NotFound />
  }

  const { invitation } = data.viewer

  return (
    <>
      {error ? (
        <NotFound />
      ) : (
        <>
          <Helmet title={'Join'} />
          <div className="bg-base-gray960 flex h-screen flex-col items-center gap-0 p-3 sm:justify-center">
            <div className="border-divider-light-gray bg-base-white shadow-soft flex flex-col items-center justify-center rounded border sm:max-w-[288px]">
              <div className="border-divider-light-gray flex flex-col items-center justify-center gap-1 border-b p-6">
                <OrgImage
                  size="large"
                  orgName={invitation.organization.name || ''}
                  orgLogo={invitation.organization.image}
                />
                <div className="text-center text-xl font-semibold">
                  Joining {invitation.organization.name}…
                </div>
                <div className="text-copy-secondary text-center text-base">
                  You’re in! Please wait just a moment while we set things up for you.
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default AcceptInvitation
