import { ConnectDragSource, ConnectDropTarget, DragPreviewImage, useDrag, useDrop } from 'react-dnd'

import { ContentType, SnapshotFieldsFragment } from '@/generated/graphql'
import { ContentV2, ContentV2ObjectMap } from '@/shared/types'

import { useContentGrid } from '../ContentGrid/useContentGrid'
import { ContentCard, IContentCardProps } from './ContentCard'
import {
  FilesDragToken,
  FilesStackedDragToken,
  FolderDragToken,
  FolderStackedDragToken,
  SnapshotDragToken,
  SnapshotStackedDragToken,
} from './DragTokens'

export type DragAndDropUtils = {
  canDrop: boolean
  dragRef: ConnectDragSource
  dropRef: ConnectDropTarget
  isHovering: boolean
  isDragging: boolean
}

const DragAndDropWrapper = ({
  content,
  children,
}: {
  content: ContentV2
  children: JSX.Element
}): JSX.Element => {
  const { selectedItems, moveContentCallback } = useContentGrid()

  const [{ isDragging }, dragRef, preview] = useDrag(
    () => ({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: content,
      options: {
        dropEffect: 'move',
      },
      type: content.__typename,
    }),
    [],
  )

  const dropAcceptTypes = () => {
    if (
      selectedItems.length > 1 &&
      selectedItems.filter((item) => item.__typename === 'Folder').length > 0
    ) {
      return []
    } else {
      return ['Snapshot', 'Document']
    }
  }

  const [{ canDrop, isHovering }, dropRef] = useDrop(
    () => ({
      accept: dropAcceptTypes(),
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isHovering: monitor.isOver(),
      }),
      drop: (item: ContentV2) => moveContentCallback(item, content.id, undefined, content.name),
    }),
    [moveContentCallback],
  )

  const dragAndDropUtils: DragAndDropUtils = {
    canDrop,
    dragRef,
    dropRef,
    isDragging,
    isHovering,
  }

  const imgSrc = (): string => {
    if (selectedItems.length > 1 && selectedItems.includes(content)) {
      if (content.__typename === 'Snapshot') {
        if (
          selectedItems.filter((element) => element.__typename === 'Snapshot').length ===
          selectedItems.length
        ) {
          return SnapshotStackedDragToken
        } else {
          return FilesStackedDragToken
        }
      }

      if (content.__typename === 'Document') {
        return FilesStackedDragToken
      }

      if (content.__typename === 'Folder') {
        if (
          selectedItems.filter((element) => element.__typename === 'Folder').length ===
          selectedItems.length
        ) {
          return FolderStackedDragToken
        } else {
          return FilesStackedDragToken
        }
      }
    } else {
      if (content.__typename === 'Snapshot') {
        return SnapshotDragToken
      }

      if (content.__typename === 'Document') {
        return FilesDragToken
      }

      if (content.__typename === 'Folder') {
        return FolderDragToken
      }
    }

    return FilesDragToken
  }

  return (
    <>
      <DragPreviewImage connect={preview} src={imgSrc()} />
      <children.type {...{ ...children.props, dragAndDropUtils }} />
    </>
  )
}

const SnapshotDataWrapper = ({
  children,
}: {
  snapshot: SnapshotFieldsFragment
  children: JSX.Element
}): JSX.Element => {
  return <>{children}</>
}

export const ContentCardDataWrapper = (props: IContentCardProps): JSX.Element => {
  const card = (): JSX.Element => {
    if (props.disableDragAndDrop) {
      return <ContentCard {...props} />
    } else {
      return (
        <DragAndDropWrapper content={props.content}>
          <ContentCard {...props} />
        </DragAndDropWrapper>
      )
    }
  }

  if (ContentV2ObjectMap[props.content.__typename] === ContentType.Snapshot) {
    return (
      <SnapshotDataWrapper snapshot={props.content as SnapshotFieldsFragment}>
        <div onClick={(e) => e.stopPropagation()}>{card()}</div>
      </SnapshotDataWrapper>
    )
  }
  return <div onClick={(e) => e.stopPropagation()}>{card()}</div>
}
